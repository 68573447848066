import React from 'react';

import { normalizeCustomResponsiveValue } from '../../styles/breakpoints';
import { mapResponsiveValue, RequiredResponsiveValue } from '../../styles/sprinkles.css';
import Text, { TextOwnProps } from '../Text';

import type { ForwardRefComponent } from '@radix-ui/react-polymorphic';

const levelToFontSize = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'large',
} as const;

export interface HeadingOwnProps extends Omit<TextOwnProps, 'fontSize'> {
  level: RequiredResponsiveValue<1 | 2 | 3 | 4 | 5>
}
/**
 * Get your h# elements here
 * Implemented on top of Text, so it includes the same automatic foreground
 * coloring logic
 * @param level - Sets the appropriate fontSize and h tag automatically. If
 * responsive, the tag will stay at the desktop level. Tag can be overridden by
 * `as`.
 * @param fontWeight - Does nothing when `fontFamily` is set to `'nutmeg'`
 */
const Heading = React.forwardRef((props, ref) => {
  const { as: is, level, ...rest } = props;
  const component = is || `h${normalizeCustomResponsiveValue(level).desktop ?? '1'}` as const;
  return (
    <Text
      {...rest}
      fontWeight={props.fontFamily === 'nutmeg' ? undefined : (props.fontWeight ?? 'bold')}
      fontSize={mapResponsiveValue(level, level => levelToFontSize[level])}
      ref={ref}
      as={component}
    />
  );
}) as ForwardRefComponent<'h1', HeadingOwnProps>;

export default Heading;
