var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PressResponder } from '@react-aria/interactions';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DismissButton, FocusScope, mergeProps, useButton, useFocus, useMenu, useMenuItem, useMenuSection, useMenuTrigger, useOverlay, useOverlayPosition, usePress, useSeparator } from 'react-aria';
import { Item, Section, useMenuTriggerState, useTreeState } from 'react-stately';
import useHoverDelay from '../../hooks/useHoverDelay';
import useHTMLElementRef from '../../hooks/useHTMLElementRef';
import usePrevious from '../../hooks/usePrevious';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import Box from '../Box';
import Loading from '../Loading';
import { OverlayContainerSSR } from '../Portal';
import Text from '../Text';
import TextLink from '../TextLink';
import TextLinkButton from '../TextLinkButton';
var menuContext = React.createContext(null);
export var useMenuContext = function (componentName) {
    var ctx = useContext(menuContext);
    if (ctx === null) {
        throw new Error("".concat(componentName, " must be rendered inside Menu"));
    }
    return ctx;
};
export var useMenuHoverableTriggerState = function (props) {
    var _a, _b;
    var trigger = props.trigger;
    var _c = useState({
        hoverTrigger: false,
        hoverOverlay: false,
        press: false,
    }), hoverAndPressState = _c[0], setHoverAndPressState = _c[1];
    var hoverAndPressIsOpen = hoverAndPressState.press
        || hoverAndPressState.hoverOverlay
        || hoverAndPressState.hoverTrigger;
    var previousHoverAndPressIsOpen = usePrevious(hoverAndPressIsOpen);
    if (trigger === 'hoverAndPress' && hoverAndPressIsOpen !== previousHoverAndPressIsOpen) {
        (_a = props.onOpenChange) === null || _a === void 0 ? void 0 : _a.call(props, hoverAndPressIsOpen);
    }
    // We temporarily disable hover on the trigger/overlay when we forcibly close
    // the menu to flush the internal state of `useHoverDelay`/`useHover`, as the
    // `onHoverEnd` event doesn't fire when the overlay is removed from the dom while
    // under your cursor
    var _d = useState(false), disableHoverOverride = _d[0], setDisableHoverOverride = _d[1];
    useEffect(function () {
        if (disableHoverOverride) {
            setDisableHoverOverride(false);
        }
    }, [disableHoverOverride]);
    var lastHoverOpenTimeRef = useRef(undefined);
    var overrideCloseRef = useRef(false);
    var hoverDelay = 333;
    var triggerHoverProps = useHoverDelay({
        isDisabled: disableHoverOverride || props.isDisabled,
        onHoverChange: function (isHovering) {
            if (!hoverAndPressIsOpen && isHovering) {
                lastHoverOpenTimeRef.current = Date.now();
            }
            setHoverAndPressState(function (s) { return (__assign(__assign({}, s), { hoverTrigger: isHovering })); });
        },
        delay: hoverDelay,
    }).hoverProps;
    var overlayHoverProps = useHoverDelay({
        isDisabled: disableHoverOverride || props.isDisabled,
        onHoverChange: function (isHovering) {
            setHoverAndPressState(function (s) { return (__assign(__assign({}, s), { hoverOverlay: isHovering })); });
        },
        delay: hoverDelay,
    }).hoverProps;
    var pressProps = usePress({
        isDisabled: props.isDisabled,
        onPressStart: function () {
            // Prevent user from opening and closing the menu in quick succession by
            // hovering it open and then clicking on it
            if (hoverAndPressIsOpen && lastHoverOpenTimeRef.current && Date.now() - lastHoverOpenTimeRef.current <= 333) {
                overrideCloseRef.current = true;
            }
        },
    }).pressProps;
    var menuTriggerState = useMenuTriggerState(__assign(__assign({}, props), { trigger: trigger === 'hoverAndPress' ? 'press' : trigger, isOpen: (_b = props.isOpen) !== null && _b !== void 0 ? _b : (trigger === 'hoverAndPress' ? hoverAndPressIsOpen : undefined), onOpenChange: function (isOpen) {
            var _a;
            if (!isOpen) {
                var intercept = trigger === 'hoverAndPress' && overrideCloseRef.current;
                overrideCloseRef.current = false;
                if (intercept) {
                    return;
                }
            }
            if (trigger === 'hoverAndPress') {
                // Don't close the menu if user clicks on the trigger shortly after opening it via hover
                setHoverAndPressState(function (s) { return ({
                    press: isOpen,
                    hoverTrigger: isOpen ? s.hoverTrigger : false,
                    hoverOverlay: isOpen ? s.hoverOverlay : false,
                }); });
                // Temporarily disable hover triggers to forcibly trigger an onHoverEnd
                // event and flush internal state
                setDisableHoverOverride(true);
                return;
            }
            (_a = props.onOpenChange) === null || _a === void 0 ? void 0 : _a.call(props, isOpen);
        } }));
    return {
        menuTriggerState: menuTriggerState,
        menuHoverableTriggerProps: mergeProps(pressProps, triggerHoverProps),
        overlayHoverableProps: overlayHoverProps,
    };
};
var Menu = function (props) {
    var trigger = props.trigger, children = props.children, _a = props.closeOnSelect, closeOnSelect = _a === void 0 ? true : _a;
    var menuTriggerRef = useRef(null);
    var menuRef = useRef(null);
    var _b = useMenuHoverableTriggerState(__assign(__assign({}, props), { menuRef: menuRef })), menuTriggerState = _b.menuTriggerState, menuHoverableTriggerProps = _b.menuHoverableTriggerProps, overlayHoverableProps = _b.overlayHoverableProps;
    var _c = useMenuTrigger({
        isDisabled: props.isDisabled,
        trigger: trigger === 'hoverAndPress' ? 'press' : trigger,
        type: 'menu',
    }, menuTriggerState, menuTriggerRef), menuProps = _c.menuProps, menuTriggerBaseProps = _c.menuTriggerProps;
    var buttonProps = useButton(__assign(__assign({}, menuTriggerBaseProps), { isDisabled: props.isDisabled }), menuTriggerRef).buttonProps;
    var value = useMemo(function () { return ({
        menuTriggerRef: menuTriggerRef,
        menuTriggerState: menuTriggerState,
        menuTriggerProps: mergeProps(menuHoverableTriggerProps, buttonProps),
        // TODO: support focus strategy correctly
        menuProps: __assign(__assign({}, menuProps), { autoFocus: !!menuProps.autoFocus }),
        menuRef: menuRef,
        overlayProps: overlayHoverableProps,
        closeOnSelect: closeOnSelect,
    }); }, [menuTriggerState, buttonProps, menuHoverableTriggerProps, menuProps, menuRef, overlayHoverableProps, closeOnSelect]);
    return (<menuContext.Provider value={value}>
      {children}
    </menuContext.Provider>);
};
/** Makes the child button act as a menu trigger */
var MenuTrigger = function (props) {
    var isLegacyOnClickHandler = props.isLegacyOnClickHandler, triggerElem = props.children;
    var _a = useMenuContext('Menu.Trigger'), menuTriggerProps = _a.menuTriggerProps, menuTriggerRef = _a.menuTriggerRef, menuTriggerState = _a.menuTriggerState;
    if (isLegacyOnClickHandler) {
        return React.cloneElement(triggerElem, mergeProps(menuTriggerProps, { ref: menuTriggerRef }, triggerElem.props));
    }
    return (<PressResponder {...menuTriggerProps} ref={menuTriggerRef} isPressed={menuTriggerState.isOpen}>
      {triggerElem}
    </PressResponder>);
};
/** Contains `Menu.Item'/`Menu.LinkItem`'s. i
 * Refer to https://react-spectrum.adobe.com/react-stately/collections.html
 * for general use of collection components
 */
var MenuContents = function (props) {
    var menuTriggerState = useMenuContext('Menu.Contents').menuTriggerState;
    if (!menuTriggerState.isOpen)
        return null;
    return (<OverlayContainerSSR>
      <MenuContentsInternal {...props}/>
    </OverlayContainerSSR>);
};
var MenuContentsInternal = function (props) {
    var _a, _b, _c;
    var disabledKeys = props.disabledKeys, loadingKeys = props.loadingKeys, rest = __rest(props, ["disabledKeys", "loadingKeys"]);
    var overlayRef = useRef(null);
    var _d = useMenuContext('Menu.Contents'), menuPropsContext = _d.menuProps, menuRef = _d.menuRef, menuTriggerState = _d.menuTriggerState, menuTriggerRef = _d.menuTriggerRef, overlayPropsContext = _d.overlayProps, closeOnSelect = _d.closeOnSelect;
    var combinedDisabledKeys = __spreadArray(__spreadArray([], Array.from(disabledKeys !== null && disabledKeys !== void 0 ? disabledKeys : []), true), Array.from(loadingKeys !== null && loadingKeys !== void 0 ? loadingKeys : []), true);
    var loadingSet = new Set(loadingKeys !== null && loadingKeys !== void 0 ? loadingKeys : []);
    var overlayProps = useOverlay({
        shouldCloseOnBlur: true,
        isDismissable: true,
        isOpen: menuTriggerState.isOpen,
        onClose: menuTriggerState.close,
        shouldCloseOnInteractOutside: function (elem) {
            var _a;
            return elem !== menuTriggerRef.current && !((_a = menuTriggerRef.current) === null || _a === void 0 ? void 0 : _a.contains(elem));
        },
    }, overlayRef).overlayProps;
    var overlayPositionProps = useOverlayPosition({
        targetRef: menuTriggerRef,
        overlayRef: overlayRef,
        isOpen: menuTriggerState.isOpen,
        placement: (_a = rest.placement) !== null && _a !== void 0 ? _a : 'bottom left',
        containerPadding: rest.containerPadding,
        offset: (_b = rest.offset) !== null && _b !== void 0 ? _b : 10,
        crossOffset: rest.crossOffset,
        shouldFlip: (_c = rest.shouldFlip) !== null && _c !== void 0 ? _c : true,
    }).overlayProps;
    var filteredChildren = (function () {
        if (typeof props.children === 'function')
            return props.children;
        var children = [];
        React.Children.forEach(props.children, function (elem) {
            if (elem === null)
                return;
            children.push(elem);
        });
        return children;
    })();
    var menuState = useTreeState(__assign(__assign({}, rest), { children: filteredChildren, disabledKeys: combinedDisabledKeys, selectionMode: 'none' }));
    var menuProps = useMenu(__assign(__assign({ autoFocus: 'first', shouldFocusWrap: true }, rest), { disabledKeys: combinedDisabledKeys }), menuState, menuRef).menuProps;
    return (<FocusScope restoreFocus>
      <Box paddingY='space-0.5' border='greyLine' shadow='normal' background='white' borderRadius='medium' overflow='auto' {...mergeProps({ ref: overlayRef }, overlayProps, overlayPositionProps, overlayPropsContext)}>
        <DismissButton onDismiss={menuTriggerState.close}/>
        <ul {...mergeProps({ ref: menuRef, style: { maxWidth: '20rem' } }, menuProps, menuPropsContext)}>
          {Array.from(menuState.collection).map(function (item) {
            if (item.type === 'section') {
                return (<MenuSectionInternal key={item.key} section={item} menuState={menuState} onAction={props.onAction} onClose={menuTriggerState.close} loadingSet={loadingSet} closeOnSelect={closeOnSelect}/>);
            }
            return (<MenuItemInternal key={item.key} item={item} menuState={menuState} onAction={props.onAction} onClose={menuTriggerState.close} isLoading={loadingSet.has(item.key)} closeOnSelect={closeOnSelect}/>);
        })}
        </ul>
        <DismissButton onDismiss={menuTriggerState.close}/>
      </Box>
    </FocusScope>);
};
/** Base Item, renders as a clickable button.
 * If children is a text literal, it is automatically warpped with a truncated
 * `Text` component. If custom children markup is used make sure to wrap leaf
 * text nodes in `Text` to ensure consistent spacing.
 */
var MenuItem = Item;
/** Renders as a Link, preferred over navigation in `onAction`.
 * If children is a text literal, it is automatically warpped with a truncated
 * `Text` component. If custom children markup is used make sure to wrap leaf
 * text nodes in `Text` to ensure consistent spacing.
 */
var MenuLinkItem = Object.assign(function (_props) {
    return null;
}, {
    // This is a custom function used by react-aria/react-stately's collection
    // components https://react-spectrum.adobe.com/react-stately/collections.html
    // to allow the collection to gather information on each child node component
    // without actually rendering them directly
    // TODO: Create a utility that makes it easier to create custom Collection types
    getCollectionNode: function MenuLinkItemGetCollectionNode(props, context) {
        var generator;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    generator = Item.getCollectionNode(props, context);
                    return [4 /*yield*/, __assign(__assign({}, generator.next().value), { type: 'item', props: props })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    },
});
var MenuItemInternal = function (props) {
    var _a, _b, _c;
    var item = props.item, menuState = props.menuState, onAction = props.onAction, onClose = props.onClose, isLoading = props.isLoading, closeOnSelect = props.closeOnSelect;
    var isDisabled = isLoading || menuState.disabledKeys.has(item.key);
    var menuItemRef = useHTMLElementRef();
    var router = useRouter();
    var menuItemProps = useMenuItem({
        'aria-label': (_a = item['aria-label']) !== null && _a !== void 0 ? _a : item.textValue,
        closeOnSelect: (_c = (_b = item.props) === null || _b === void 0 ? void 0 : _b.closeOnSelect) !== null && _c !== void 0 ? _c : closeOnSelect,
        // Delay the close by an execution cycle to give link navigation events a
        // chance to happen
        onClose: function () { return setTimeout(onClose); },
        key: item.key,
        isDisabled: isDisabled,
        onAction: function (key) {
            var _a, _b;
            if (item.props && 'onAction' in item.props) {
                (_b = (_a = item.props).onAction) === null || _b === void 0 ? void 0 : _b.call(_a);
            }
            if (item.props && 'href' in item.props && item.props.href) {
                router.push(item.props.href).catch(function () { return null; });
            }
            onAction === null || onAction === void 0 ? void 0 : onAction(key);
        },
    }, menuState, menuItemRef).menuItemProps;
    var _d = useState(false), isFocused = _d[0], setIsFocused = _d[1];
    var focusProps = useFocus({
        isDisabled: isDisabled,
        onFocusChange: setIsFocused,
    }).focusProps;
    var content = (function () {
        var commonProps = {
            minWidth: '100%',
            paddingY: 'space-0.75',
            paddingLeft: 'space-1.5',
            paddingRight: 'space-2',
            foreground: 'inherit',
            position: 'relative',
            'aria-busy': isLoading ? 'true' : 'false',
            textAlign: 'left',
        };
        var loading = isLoading && (<Box position='absolute' style={{ right: '0.5rem', top: '50%', transform: 'translateY(-50%)' }}>
        <Loading size={16} strokeWidth={6}/>
      </Box>);
        if (typeof item.rendered === 'string') {
            return (<Text truncate {...commonProps}>
          {item.rendered}
          {loading}
        </Text>);
        }
        return (<Box {...commonProps}>
        {item.rendered}
        {loading}
      </Box>);
    })();
    // must omit color because Link improperly masks the builtin html attribute
    var combinedProps = omit(mergeProps({
        ref: menuItemRef.assign,
        style: { width: '100%' },
        color: isFocused ? 'slateMedium' : 'slateDark',
        underline: !!isFocused,
        disabled: isDisabled,
        subtleDisabled: isDisabled,
    }, menuItemProps, focusProps), 'color');
    return (<Box as='li' display='flex' {...combinedProps}>
      {(item.props && 'href' in item.props)
            ? (<TextLink href={item.props.href || ''} minWidth='100%' foreground='greyDark' underline='hover' textBoxTrim={false}>
            {content}
          </TextLink>) : (<TextLinkButton role='none' minWidth='100%' foreground='greyDark' underline='hover' textBoxTrim={false}>
            {content}
          </TextLinkButton>)}
    </Box>);
};
var MenuSectionInternal = function (props) {
    var section = props.section, menuState = props.menuState, loadingSet = props.loadingSet, rest = __rest(props, ["section", "menuState", "loadingSet"]);
    var _a = useMenuSection({
        'aria-label': section['aria-label'],
        heading: section.rendered,
    }), groupProps = _a.groupProps, headingProps = _a.headingProps, itemProps = _a.itemProps;
    var separatorProps = useSeparator({
        elementType: 'li',
    }).separatorProps;
    var commonProps = {
        minWidth: '100%',
        paddingY: 'space-0.75',
        paddingLeft: 'space-1.5',
        paddingRight: 'space-2',
        foreground: 'inherit',
        position: 'relative',
    };
    return (<>
      {section.key !== menuState.collection.getFirstKey() && (<Box as='li' {...separatorProps} borderTop='greyLine' paddingY='space-0.25'/>)}
      <li {...itemProps}>
        {!!section.rendered &&
            (childrenAreLiteral(section.rendered)
                ? (<Text {...headingProps} {...commonProps} truncate>
                {section.rendered}
              </Text>) : (<Box {...headingProps} {...commonProps}>
                {section.rendered}
              </Box>))}
        <ul {...groupProps} style={{
            padding: 0,
            listStyle: 'none',
        }}>
          {Array.from(section.childNodes).map(function (item) {
            if (item.type === 'section') {
                return (<MenuSectionInternal {...rest} key={item.key} section={item} menuState={menuState} loadingSet={loadingSet}/>);
            }
            return (<MenuItemInternal {...rest} key={item.key} item={item} menuState={menuState} isLoading={loadingSet.has(item.key)}/>);
        })}
        </ul>
      </li>
    </>);
};
export default Object.assign(Menu, {
    Trigger: MenuTrigger,
    Contents: MenuContents,
    Item: MenuItem,
    LinkItem: MenuLinkItem,
    Section: Section,
});
