import { ForwardRefComponent } from '@radix-ui/react-polymorphic';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React from 'react';

import { BoxExcludeProps, flexPositioning, FlexProperties, flexProperties } from '../../styles/atomValues';
import { OptionalResponsiveValue } from '../../styles/sprinkles.css';
import { InternalBox, InternalBoxOwnProps, seperateSprinklesProps } from '../Box';

import * as styles from './Flex.css';

type GapProps = Parameters<typeof styles.gapSprinkles>[0];

// Safari doesn't support start and end in Flex, but we still need it for Grid, so
// we manually exclude it as an option here
type Positioning = {
  alignItems?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'start' | 'end'>>,
  alignJustify?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'start' | 'end'>>,
  justifyContent?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'start' | 'end'>>,
  justifyItems?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'start' | 'end'>>,
  placeItems?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'start' | 'end'>>,
}

export type FlexProps = Omit<InternalBoxOwnProps, Exclude<BoxExcludeProps, FlexProperties> | 'display' | keyof Positioning> & GapProps & Positioning & {
  display?: OptionalResponsiveValue<'flex' | 'inline-flex' | 'none'>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

/** Implements all common flex css properties as props, including a polyfill for gap
 * Ex. `<Flex justifyContent='space-between' alignItems='center' gap='space-1'>,,,</Flex>`
 * @params gap - The gap works by setting margins on its children, and negative
 * margins on the parent node to cancel them out. This neccesitates that no
 * childrne of `Flex` have their own margins, as they will be overwritten (or
 * not, both outcomes are bad). See also `rowGap` and `colGap`.
 */
const Flex = React.forwardRef((props, ref) => {
  const { children, as: is = 'div', display = 'flex', ...rest } = props;
  const { sprinklesProps, cleanProps } = seperateSprinklesProps(styles.gapSprinkles, rest);
  const flexProps = pick(cleanProps, flexProperties);
  const restProps = omit(cleanProps, flexProperties);

  // Don't use two dom elements if there is no gap
  if (!(sprinklesProps.colGap || sprinklesProps.rowGap || sprinklesProps.gap)) {
    return (
      <InternalBox
        {...restProps}
        {...flexProps}
        as={is}
        className={cleanProps.className}
        ref={ref}
        display={display}
      >
        {children}
      </InternalBox>
    );
  }

  return (
    <InternalBox
      {...restProps}
      className={[styles.flexContainer, cleanProps.className]}
      ref={ref}
      // display flex[-inline] prevents margins from affecting outer elements
      display={display}
    >
      <InternalBox
        {...flexProps}
        as={is}
        display={display}
        className={[styles.flexGap, styles.gapSprinkles(sprinklesProps)]}
      >
        {children}
      </InternalBox>
    </InternalBox>
  );
}) as ForwardRefComponent<'div', FlexProps>;

Flex.displayName = 'Flex';

export default Flex;
