import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import * as yup from 'yup';

import { Image, Product, ProductDisplayInfo, ProductVariant, ProductVariantDisplayInfo, SellerDisplayInfo } from '@mablemarket/core-api-client';

export const upToWidth = (width: number) => (
  `c_limit,w_${width}`
);

export const ImageTransforms = {
  webVariantCard: {
    transform: upToWidth,
    width: {
      desktop: 216,
      tablet: 201,
      phone: 268,
    },
  },
  webPDP: {
    transform: upToWidth,
    width: 600,
  },
  webVariantRow: {
    transform: upToWidth,
    width: 60,
  },
} as const;

export type ImageTransformation = {
  transform: (width: number, dpi: number) => string;
  width: number | { desktop: number, tablet?: number, phone?: number };
};

export function imageURLfromImage(
  image: Pick<Image, 'externalId' | 'externalBucketId' | 'format' | 'version'>,
  /** Consider `c_limit,w_${maxWidth}` or `c_limit,h_${maxHeight}`. Only make
    * this an empty string if you know what you are doing.
    * https://cloudinary.com/documentation/image_transformations
    */
  transform: string,
  opts?: {
    /** Automatically compress image to reasonable quality. Defaults to true.
      * Only disable this if you have a very good reason to.
      */
    autoQuality?: boolean;
    /** Automatically choose appropraite image format based on user agent
      * (helpful for clients like mobile safari that have spotty image format
      * converage). Defaults to true.
      * Only disable this if you have a very good reason to, or if you are doing
      * email stuff.
      */
    autoFormat?: boolean;
  },
) {
  const { autoQuality = true, autoFormat = true } = opts ?? {};

  const formatSuffix = image.format ? `.${image.format.replace(/webp/ig, 'jpg')}` : '';
  const version = image.version ? `/v${image.version}` : '';
  const combinedTransform = [autoQuality ? 'q_auto' : '', transform].filter(x => x).join(',');
  return `https://res.cloudinary.com/${image.externalBucketId}/image/upload/${combinedTransform}${autoFormat ? '/f_auto' : ''}${version}/${image.externalId}${formatSuffix}`;
}

export const addCloudinaryFilters = (imageURL: string | undefined, customTransform?: string) => {
  if (!imageURL) {
    return undefined;
  }
  // Sample URL:
  // https://res.cloudinary.com/mable/image/upload//v1583621533/marketplace/epegfbytikobgsyt1c5o.jpg

  const regex = /(https:\/\/res.cloudinary.com\/.*?\/.*?\/.*?\/)(.*?)(v\d+.*)/;
  const matches = regex.exec(imageURL);
  if (!matches || matches.length < 4) {
    // Unexpected result, return with no transformations
    return imageURL;
  }

  return `${matches[1]}${customTransform ?? ''}/${matches[3]}`;
};

export const allProductImagesFromDisplayInfo = (product: ProductDisplayInfo, seller?: SellerDisplayInfo): [Image, ...Image[]] | null => {
  const images = [
    ...(product.images || []),
    ...flatMap(product.variants || [], v => v.images || []),
  ];
  if (isEmpty(images) && seller?.fallbackProductImage) {
    images.push(seller.fallbackProductImage);
  }
  if (!images[0]) {
    return null;
  }
  return uniqBy(images, i => i.id) as [Image, ...Image[]];
};

// TODO: ENG-4978 When the app is switched to use ProductDisplayInfo,
// use the `allProductImagesFromDisplayInfo` function above instead, and delete this one.
/** @deprecated */
export const allProductImages = (product: Product, seller?: SellerDisplayInfo): [Image, ...Image[]] | null => {
  const images = [
    ...(product.images || []),
    ...flatMap(product.variants || [], v => v.images || []),
  ];
  if (isEmpty(images) && seller?.fallbackProductImage) {
    images.push(seller.fallbackProductImage);
  }
  if (!images[0]) {
    return null;
  }
  return uniqBy(images, i => i.id) as [Image, ...Image[]];
};

export const variantImage = (opts: {
  variant: ProductVariant | ProductVariantDisplayInfo;
  fallbackToProduct?: Product | ProductDisplayInfo;
}) => {
  const {
    variant,
    fallbackToProduct,
  } = opts;

  if ('images' in variant) {
    if (variant.images && variant.images[0]) {
      return variant.images[0];
    }
  }

  // ProductVariantDisplayInfo's old, single image property
  if ('image' in variant && variant.image) {
    return variant.image;
  }

  if (fallbackToProduct?.images && fallbackToProduct.images[0]) {
    return fallbackToProduct.images[0];
  }

  return null;
};

const imageSource = yup.string().oneOf(['adminportal', 'adminportalbulk', 'ixone', 'upcitemdb', 'csv', 'sellerportal']);

export const ImageSchema = yup.object({
  id: yup.number().optional(),
  type: yup.string().required().optional(),
  externalBucketId: yup.string().required(),
  externalId: yup.string().required(),
  version: yup.string(),
  format: yup.string(),
  width: yup.number().required(),
  height: yup.number().required(),
  source: imageSource,
  sourceExternalId: yup.string(),
});

/**
 * Workaround for Yup jank - if you want to make a Yup schema for an object that has a property
 * that can be either an image or undefined, you're going to have some issues because the `.required()`
 * fields of ImageSchema will make your validation fail even when the maybe-image is undefined. You can
 * use NullableImageSchema to let the image be undefined.
 *
 * If at all possible, please don't use this and make your thing a react-hook-form.
 *
 * Context: https://meetmable.slack.com/archives/C01GYJYPRT8/p1687809829471639
 */
export const OptionalImageSchema = yup.object({}).test({
  name: 'is-image-or-undefined',
  message: 'Image is not valid',
  test: (obj: unknown) => obj === undefined || ImageSchema.isValidSync(obj),
});
