var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { shallowCompare } from '@mablemarket/common-lib';
import { ProductHelpers } from '@mablemarket/mable-lib';
// TODO: ENG-4978 Make the app use the new "makeProductOptionSelectionPropsFromDisplayInfo" function below and delete this one.
/** @deprecated */
export var makeProductOptionSelectionProps = function (data) {
    var product = data.product, selectedVariant = data.selectedVariant;
    var variants = (product.variants || []).filter(function (v) { return v.options && Object.keys(v.options).length; });
    if (!product.options || !product.options.length || variants.length === 1) {
        if (variants.length > 1) {
            // This is likely older data when we allowed variants without options sets
            // Return one option selection area with all the variant names
            return [{
                    title: 'Variant',
                    optionButtonProps: variants.map(function (v) {
                        return {
                            title: ProductHelpers.productVariantName({ product: product, variant: v, includeProductName: false }),
                            style: v.id === selectedVariant.id ? 'selected' : 'unselected',
                            resultingValue: v,
                        };
                    }),
                }];
        }
        // No options, only one variant; nothing to show.
        return [];
    }
    return product.options.map(function (option) {
        var _a;
        var optionButtonProps = option.values.map(function (value) {
            var isSelected = selectedVariant.options[option.name] === value;
            var compatibleVariant = variants.find(function (v) {
                var _a;
                return shallowCompare(v.options, __assign(__assign({}, selectedVariant.options), (_a = {}, _a[option.name] = value, _a)));
            });
            var closestIncompatibleVariant = !compatibleVariant && variants.find(function (v) { return (v.options[option.name] === value); });
            var style = 'unselected';
            var resultingVariant;
            if (isSelected) {
                style = 'selected';
                resultingVariant = selectedVariant;
            }
            else if (compatibleVariant) {
                resultingVariant = compatibleVariant;
            }
            else if (closestIncompatibleVariant) {
                style = 'incompatible';
                resultingVariant = closestIncompatibleVariant;
            }
            if (!resultingVariant) {
                return undefined;
            }
            return {
                title: value,
                style: style,
                resultingValue: resultingVariant,
            };
        }).filter(function (o) { return o !== undefined; });
        return {
            title: ((_a = product.options) !== null && _a !== void 0 ? _a : []).length > 1 ? option.name : undefined,
            optionButtonProps: optionButtonProps,
        };
    });
};
export var makeProductOptionSelectionPropsFromDisplayInfo = function (data) {
    var product = data.product, selectedVariant = data.selectedVariant;
    var variants = (product.variants || []).filter(function (v) { return v.options && Object.keys(v.options).length; });
    if (!product.options || !product.options.length || variants.length === 1) {
        if (variants.length > 1) {
            // This is likely older data when we allowed variants without options sets
            // Return one option selection area with all the variant names
            return [{
                    title: 'Variant',
                    optionButtonProps: variants.map(function (v) {
                        return {
                            title: v.name,
                            style: v.variantId === selectedVariant.variantId ? 'selected' : 'unselected',
                            resultingValue: v,
                        };
                    }),
                }];
        }
        // No options, only one variant; nothing to show.
        return [];
    }
    return product.options.map(function (option) {
        var _a;
        var optionButtonProps = option.values.map(function (value) {
            var isSelected = selectedVariant.options[option.name] === value;
            var compatibleVariant = variants.find(function (v) {
                var _a;
                return shallowCompare(v.options, __assign(__assign({}, selectedVariant.options), (_a = {}, _a[option.name] = value, _a)));
            });
            var closestIncompatibleVariant = !compatibleVariant && variants.find(function (v) { return (v.options[option.name] === value); });
            var style = 'unselected';
            var resultingVariant;
            if (isSelected) {
                style = 'selected';
                resultingVariant = selectedVariant;
            }
            else if (compatibleVariant) {
                resultingVariant = compatibleVariant;
            }
            else if (closestIncompatibleVariant) {
                style = 'incompatible';
                resultingVariant = closestIncompatibleVariant;
            }
            if (!resultingVariant) {
                return undefined;
            }
            return {
                title: value,
                style: style,
                resultingValue: resultingVariant,
            };
        }).filter(function (o) { return o !== undefined; });
        return {
            title: ((_a = product.options) !== null && _a !== void 0 ? _a : []).length > 1 ? option.name : undefined,
            optionButtonProps: optionButtonProps,
        };
    });
};
