import first from 'lodash/first';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

// Lifted from https://github.com/niradler/tracking-number-validation
// And converted to TypeScript

type CourierId = 'ups' | 'usps' | 'dhl' | 'fedex' | 'ontrac' | 'speedee' | 'unknown';

interface CourierSpec {
  patterns: RegExp[];
  id: CourierId;
  name: string;
  getTrackingUrl: (trackingNumbers: string[]) => string;
}

export const courierInfo: CourierSpec[] = [
  {
    id: 'ups',
    name: 'UPS',
    patterns: [new RegExp(/^\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|T\d{3} ?\d{4} ?\d{3})\b$/i)],
    getTrackingUrl: (trackingNumbers: string[]) => {
      // e.g. https://wwwapps.ups.com/WebTracking?TypeOfInquiryNumber=T&InquiryNumber1=1Z6873320344197237&InquiryNumber2=1Z905RF50391530692/
      const queryParam = trackingNumbers.map((number, idx) => {
        return `InquiryNumber${idx + 1}=${number}`;
      }).join('&');

      return `http://wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&${queryParam}`;
    },
  },
  {
    id: 'usps',
    name: 'USPS',
    patterns: [
      new RegExp(/^\b((420 ?\d{5} ?)?(91|92|93|94|95|01|03|04|70|23|13)\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4}( ?\d{2,6})?)\b$/i),
      new RegExp(/^\b((M|P[A-Z]?|D[C-Z]|LK|E[A-C]|V[A-Z]|R[A-Z]|CP|CJ|LC|LJ) ?\d{3} ?\d{3} ?\d{3} ?[A-Z]?[A-Z]?)\b$/i),
      new RegExp(/^\b(82 ?\d{3} ?\d{3} ?\d{2})\b$/i),
    ],
    getTrackingUrl: (trackingNumbers: string[]) => {
      // e.g. https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=EC000000000US&qtc_tLabels2=9400109206094809504228
      const queryParam = trackingNumbers.map((number, idx) => {
        return `qtc_tLabels${idx + 1}=${number}`;
      }).join('&');

      return `https://tools.usps.com/go/TrackConfirmAction?TypeOfInquiryNumber=T&${queryParam}`;
    },
  },
  {
    id: 'ontrac',
    name: 'OnTrac',
    patterns: [new RegExp(/^\b(\w{1}\d{14})\b$/i)],
    getTrackingUrl: (trackingNumbers: string[]) => {
      // e.g. https://www.ontrac.com/tracking.asp?trackingres=submit&tracking_number=1231231%0D%0A123123123
      const queryParam = trackingNumbers.join('%0D%0A');

      return `https://www.ontrac.com/tracking.asp?trackingres=submit&tracking_number=${queryParam}`;
    },
  },
  {
    id: 'dhl',
    name: 'DHL',
    patterns: [new RegExp(/^\b(\d{4}[- ]?\d{4}[- ]?\d{2}|\d{3}[- ]?\d{8}|[A-Z]{3}\d{7})\b$/i)],
    getTrackingUrl: (trackingNumbers: string[]) => {
      return `https://www.dhl.com/content/g0/en/express/tracking.shtml?AWB=${trackingNumbers.join(',')}&brand=DHL`;
    },
  },
  {
    id: 'fedex',
    name: 'FedEx',
    patterns: [new RegExp(/^\b(((96\d\d|6\d)\d{3} ?\d{4}|96\d{2}|\d{4}) ?\d{4} ?\d{4}( ?\d{3})?)\b$/i)],
    getTrackingUrl: (trackingNumbers: string[]) => {
      return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumbers.join(',')}`;
    },
  },
  {
    id: 'speedee',
    name: 'Spee-Dee',
    patterns: [new RegExp(/^\bsp\d+\b$/i)],
    getTrackingUrl: (trackingNumbers: string[]) => {
      // e.g. http://speedeedelivery.com/track-a-shipment/?barcodes=SP020391032172010483,SP020391032172010482
      return `http://speedeedelivery.com/track-a-shipment/?barcodes=${trackingNumbers.join(',')}`;
    },
  },
];

/**
 * @returns All matching couriers
 */
export const getCouriers = (trackingNumber: string) => {
  return courierInfo
    .filter((c) => {
      return c.patterns.filter(p => p.test(trackingNumber)).length > 0;
    });
};

/**
 * @returns First matching courier, or undefined
 */
export const getCourier = (trackingNumber: string) => {
  return first(getCouriers(trackingNumber));
};

/**
 * @returns `true` if the given number is a match with the given `courierId`'s format
 */
export const isCourier = (trackingNumber: string, courierId: CourierId) => {
  return getCouriers(trackingNumber).map(c => c.id).includes(courierId);
};

/**
 * @param courierId The ID of the courer for the URL being generated. Optional.
 *   If not provided, the courier of the first tracking number will be used.
 * @returns A tracking URL to track all of the given tracking numbers.
 *   Assumes all the tracking numbers for the same courier. `undefined` if a URL could not be generated.
 */
export const getTrackingUrl = (trackingNumbers: string[], courierId?: CourierId) => {
  if (isEmpty(trackingNumbers)) {
    return undefined;
  }

  if (courierId) {
    const spec = courierInfo.find(c => c.id === courierId);
    return spec && spec.getTrackingUrl(trackingNumbers);
  }

  const matchingCourier = getCourier(trackingNumbers[0]);
  if (!matchingCourier) {
    return undefined;
  }
  return matchingCourier.getTrackingUrl(trackingNumbers);
};

/**
 * @returns `true` if this number appears to be a valid tracking number with our known couriers
 */
export const isValid = (trackingNumber: string) => {
  return getCouriers(trackingNumber).length > 0;
};

export const groupByCourier = (trackingNumbers: string[]) => {
  // : Record<CourierId, string[]>
  return groupBy(trackingNumbers, (number: string) => {
    return getCourier(number)?.id ?? 'unknown';
  });
};

export const ensureValidCourierId = (id: string): CourierId => {
  if (courierInfo.some(c => c.id === id)) {
    return id as CourierId;
  }
  return 'unknown';
};
