import * as yup from 'yup';

import { maximumEmailsPerCampaign } from '@mablemarket/common-lib';

/** Given a string, it will separate it into an array based on comma, new line, tab, or space */
export const cleanEmailList = (value?: string) => {
  return value
    ?.replace(/\n/g, ',')
    ?.split(/\s*,+\s*/)
    ?.map((email: string) => email.trim())
    ?.filter((v: string) => v.length > 0)
    ?? [];
};

const isEmailSchema = yup.string().trim().email();

export const EmailsSchema = yup.string()
  .test({
    name: 'emails',
    // eslint-disable-next-line func-names, object-shorthand
    test: function (value) {
      const firstInvalidEmail = cleanEmailList(value)?.find((v: string) => !isEmailSchema.isValidSync(v));
      return !firstInvalidEmail
        ? true
        : this.createError({
          message: `The email address '${firstInvalidEmail}' is invalid.`,
        });
    },
  })
  .test({
    name: 'campaignMaximumEmails',
    // eslint-disable-next-line func-names, object-shorthand
    test: function (value) {
      const emails = cleanEmailList(value);
      return emails?.length <= maximumEmailsPerCampaign
        ? true
        : this.createError({
          message: `There is a maximum of ${maximumEmailsPerCampaign} emails per campaign`,
        });
    },
  }).required('At least one email is required');

export const EmailsFormSchema = yup.object({
  emails: EmailsSchema,
  referralText: yup.string().default(''),
});

export type EmailsFormValues = yup.InferType<typeof EmailsFormSchema>;
