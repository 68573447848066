var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React from 'react';
import { flexProperties } from '../../styles/atomValues';
import { InternalBox, seperateSprinklesProps } from '../Box';
import * as styles from './Flex.css';
/** Implements all common flex css properties as props, including a polyfill for gap
 * Ex. `<Flex justifyContent='space-between' alignItems='center' gap='space-1'>,,,</Flex>`
 * @params gap - The gap works by setting margins on its children, and negative
 * margins on the parent node to cancel them out. This neccesitates that no
 * childrne of `Flex` have their own margins, as they will be overwritten (or
 * not, both outcomes are bad). See also `rowGap` and `colGap`.
 */
var Flex = React.forwardRef(function (props, ref) {
    var children = props.children, _a = props.as, is = _a === void 0 ? 'div' : _a, _b = props.display, display = _b === void 0 ? 'flex' : _b, rest = __rest(props, ["children", "as", "display"]);
    var _c = seperateSprinklesProps(styles.gapSprinkles, rest), sprinklesProps = _c.sprinklesProps, cleanProps = _c.cleanProps;
    var flexProps = pick(cleanProps, flexProperties);
    var restProps = omit(cleanProps, flexProperties);
    // Don't use two dom elements if there is no gap
    if (!(sprinklesProps.colGap || sprinklesProps.rowGap || sprinklesProps.gap)) {
        return (<InternalBox {...restProps} {...flexProps} as={is} className={cleanProps.className} ref={ref} display={display}>
        {children}
      </InternalBox>);
    }
    return (<InternalBox {...restProps} className={[styles.flexContainer, cleanProps.className]} ref={ref} 
    // display flex[-inline] prevents margins from affecting outer elements
    display={display}>
      <InternalBox {...flexProps} as={is} display={display} className={[styles.flexGap, styles.gapSprinkles(sprinklesProps)]}>
        {children}
      </InternalBox>
    </InternalBox>);
});
Flex.displayName = 'Flex';
export default Flex;
