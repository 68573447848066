import hoistNonReactStatics from 'hoist-non-react-statics';
import { NextComponentType, NextPageContext } from 'next';
import React from 'react';

export type ComponentWithLayout<P = {}> = NextComponentType<NextPageContext, any, P> & {
  pageLayout?: (page: React.ReactElement) => JSX.Element;
}
/** Render function, not a component. If you want hooks use them in the component this returns */
export type RenderLayoutFunction = (page: React.ReactElement) => JSX.Element;

/**
  * Attach a shared layout render function to a page component.
  * Curried so it can be composed and reused, ex `const withCheckoutLayout = withLayout(....)`
  * Note renderLayout is a render function, not a component. If you need to use
  * hook, then put them in the component it returns.
  * Layout functions are ordered from outer to inner
 */
const withLayout = (...renderLayouts: RenderLayoutFunction[]) => (Component: ComponentWithLayout): ComponentWithLayout => {
  const layout: RenderLayoutFunction = renderLayouts.reduce((acc, layout) => page => acc(layout(page)));
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const TargetComponent = React.forwardRef((props: any, ref: any) => <Component {...props} ref={ref} />);
  const HoistedComponent = hoistNonReactStatics(TargetComponent, Component);
  // TODO: Wrap and hoist-non-react-statics? (or just manually hoist userShould?);
  const AppliedComponent = Object.defineProperty(HoistedComponent, 'pageLayout', { value: layout });
  return AppliedComponent;
};

export const withoutLayout = withLayout(page => page);

export default withLayout;
