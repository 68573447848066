var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { assignResponsiveProperty } from '../../styles/utils';
import { InternalBox } from '../Box';
import * as styles from './Grid.css';
/** Implements all common flex css properties as props, including a polyfill for gap
 * Ex. `<Flex justifyContent='space-between' alignItems='center' gap='space-1'>,,,</Flex>`
 * @params gap - The gap works by setting margins on its children, and negative
 * margins on the parent node to cancel them out. This neccesitates that no
 * children of `Flex` have their own margins, as they will be overwritten (or
 * not, both outcomes are bad). See also `rowGap` and `colGap`.
 */
var Grid = React.forwardRef(function (props, ref) {
    var children = props.children, _a = props.as, is = _a === void 0 ? 'div' : _a, _b = props.display, display = _b === void 0 ? 'grid' : _b, className = props.className, style = props.style, gridTemplateColumns = props.gridTemplateColumns, gridTemplateRows = props.gridTemplateRows, gridTemplateAreas = props.gridTemplateAreas, gridAutoColumns = props.gridAutoColumns, gridAutoRows = props.gridAutoRows, gap = props.gap, rest = __rest(props, ["children", "as", "display", "className", "style", "gridTemplateColumns", "gridTemplateRows", "gridTemplateAreas", "gridAutoColumns", "gridAutoRows", "gap"]);
    var gridTemplateColumnsProps = assignResponsiveProperty(styles.gridTemplateColumnsTheme, gridTemplateColumns);
    var gridTemplateRowsProps = assignResponsiveProperty(styles.gridTemplateRowsTheme, gridTemplateRows);
    var gridTemplateAreasProps = assignResponsiveProperty(styles.gridTemplateAreasTheme, gridTemplateAreas);
    var gridAutoColumnsProps = assignResponsiveProperty(styles.gridAutoColumnsTheme, gridAutoColumns);
    var gridAutoRowsProps = assignResponsiveProperty(styles.gridAutoRowsTheme, gridAutoRows);
    return (<InternalBox ref={ref} display={display} as={is} 
    // eslint-disable-next-line prefer-object-spread
    style={Object.assign({}, style, gridTemplateColumnsProps.style, gridTemplateRowsProps.style, gridTemplateAreasProps.style, gridAutoColumnsProps.style, gridAutoRowsProps.style)} className={[
            className,
            gridTemplateColumnsProps.className,
            gridTemplateRowsProps.className,
            gridTemplateAreasProps.className,
            gridAutoColumnsProps.className,
            gridAutoRowsProps.className,
        ]} rowGap={gap} columnGap={gap} {...rest}>
      {children}
    </InternalBox>);
});
export default Grid;
