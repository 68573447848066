import React from 'react';

import { mapCustomResponsiveValue, OptionalResponsiveCustomValue } from '../../styles/breakpoints';
import Box from '../Box';

import * as styles from './PageContainer.css';

interface PageContainerProps extends React.ComponentProps<'div'> {
  bleed?: OptionalResponsiveCustomValue<boolean>;
  padding?: OptionalResponsiveCustomValue<boolean>;
}
/**
 * A centered container with a maximum width constrained to `contentWidth`
 * @param paddding - horizontal padding for screen edge gap. Defaults to `true`.
 * @param bleed - contains content with paddinng, but allow
 * overflow to spill over into it. Used in mobile designs when an element,
 * like a carousel, wants to be visible to the edge of the screen
 */
const PageContainer: React.FC<PageContainerProps> = ({
  children,
  className,
  padding = true,
  bleed = false,
  ...rest
}) => {
  return (
    <Box
      {...rest}
      maxWidth='content'
      as='div'
      className={[
        className,
        styles.base,
        styles.pageContainerSprinkles({
          padding: mapCustomResponsiveValue(padding, p => (p ? 'true' : 'false')),
          bleed: mapCustomResponsiveValue(bleed, b => (b ? 'true' : 'false')),
        }),
      ]}
    >
      {children}
    </Box>
  );
};

export default PageContainer;
