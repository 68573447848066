var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo, useRef } from 'react';
/** Creates a ref that holds any HTMLElement. This is different from
 *  useRef<HTMLElement> as the ref prop on elements requires the given
 *  React.RefObject to be of the type of element. Passing a
 *  React.RefObject<HTMLElement> will fail as it lacks properties of that
 *  element.
 *
 *  This hook returns a ref object that also contains a `.assign` method, which
 *  contains a ref callback function that will assign the `.current` property of
 *  the ref. This can be passed to the `ref` prop of any element.
 *
 *  `ref` props accept a callback ref that takes an argument of the
 *  element or null, which will succeed with `HTMLElement` as it matches a
 *  subset of the given element.
*/
var useHTMLElementRef = function () {
    var ref = useRef(null);
    var htmlRef = useMemo(function () { return (__assign(__assign({}, ref), { assign: function (elem) {
            htmlRef.current = elem;
        } })); }, [ref]);
    return htmlRef;
};
export default useHTMLElementRef;
