import React from 'react';
import { mergeProps } from 'react-aria';

import { notUndefined } from '@mablemarket/common-lib';

import { resets } from '../../styles/sprinkles.css';
import { cn } from '../../webutils/webutils';
import Box, { BoxOwnProps, FlexAndGridChildProps } from '../Box';
import Grid from '../Grid';
import { InputDescription, InputErrorMessage, InputLabel, InputLabelProvider, useInputLabelContext, useTextInputStyles } from '../TextInput';

import * as styles from './Select.css';

export interface SelectProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'className'>,
  FlexAndGridChildProps,
  Pick<BoxOwnProps, 'className' | 'textAlign'> {
  outerRef?: React.Ref<HTMLDivElement>;
  left?: React.ReactNode;
  right?: React.ReactNode;
  label?: React.ReactNode;
  description?: React.ReactNode;
  error?: boolean | React.ReactNode;
}

const Select = React.forwardRef((props: SelectProps, ref: React.ForwardedRef<HTMLSelectElement>) => {
  const {
    className,
    style,
    flexBasis,
    flexGrow,
    flexShrink,
    gridArea,
    gridColumnEnd,
    gridColumnStart,
    gridRowEnd,
    gridRowStart,
    textAlign,
    outerRef,
    label,
    description,
    error,
    left,
    right,
    ...rest
  } = props;
  const { containerProps, inputProps } = useTextInputStyles({
    disabled: rest.disabled,
    error: !!error,
    left: !!left,
    right: !!right,
  });

  const { inputId, descriptionId, errorId } = useInputLabelContext({
    inputId: props.id,
  });

  return (
    <InputLabelProvider inputId={inputId} descriptionId={descriptionId} errorId={errorId}>
      <Grid
        gridAutoFlow='row'
        gap='space-0.75'
        ref={outerRef}
        style={style}
        className={className}
        flexBasis={flexBasis}
        flexGrow={flexGrow}
        flexShrink={flexShrink}
        gridArea={gridArea}
        gridColumnEnd={gridColumnEnd}
        gridColumnStart={gridColumnStart}
        gridRowEnd={gridRowEnd}
        gridRowStart={gridRowStart}
        textAlign={textAlign}
      >
        {label && (<InputLabel>{label}</InputLabel>)}
        {description && (<InputDescription>{description}</InputDescription>)}
        <Box {...containerProps}>
          {left ? <Box flexShrink={0} paddingLeft='space-0.75'>{left}</Box> : null}
          <select
            id={inputId}
            ref={ref}
            aria-invalid={!!error}
            aria-describedby={[
              description ? descriptionId : undefined,
              error ? errorId : undefined,
            ].filter(notUndefined).join(' ')}
            {...mergeProps(inputProps, rest)}
            className={cn(resets.input, resets.select, styles.select, inputProps.className)}
          />
          {right ? <Box flexShrink={0} paddingRight='space-0.75'>{right}</Box> : null}
        </Box>
        {error && error !== true && <InputErrorMessage>{error}</InputErrorMessage>}
      </Grid>
    </InputLabelProvider>
  );
});

export default Select;
