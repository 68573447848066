var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import BigNumber from 'bignumber.js';
import React, { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { formatCurrencyInput } from '@mablemarket/common-lib';
import TextInput from '../TextInput';
export var BigNumberInput = React.forwardRef(function (props, ref) {
    var _a;
    var value = props.value, onChange = props.onChange, _b = props.isCurrencyInput, isCurrencyInput = _b === void 0 ? false : _b, rest = __rest(props, ["value", "onChange", "isCurrencyInput"]);
    var stringifiedValue = (function () {
        if (value === undefined) {
            return '';
        }
        if (isCurrencyInput) {
            return value.toFixed(2);
        }
        return value.toFixed();
    })();
    var formattedStringifiedValue = isCurrencyInput ? formatCurrencyInput((_a = value === null || value === void 0 ? void 0 : value.toFixed(2)) !== null && _a !== void 0 ? _a : '', {
        allowZero: true,
        allowCents: true,
    }) : stringifiedValue;
    var _c = useState(stringifiedValue), internalValue = _c[0], setInternalValue = _c[1];
    var _d = useState(false), isFocused = _d[0], setIsFocused = _d[1];
    // Detect when the given value is changed and reset the internal value (read:
    // what the input actually displays) to it
    var _e = useState(stringifiedValue), previousStringifiedValue = _e[0], setPreviousStringifiedValue = _e[1];
    useEffect(function () {
        setPreviousStringifiedValue(stringifiedValue);
    }, [stringifiedValue]);
    // We do this during render so that it appears to happen synchronously instead of one-render-late
    if (stringifiedValue !== previousStringifiedValue
        && (value === undefined ? (internalValue !== '') : !value.eq(new BigNumber(internalValue !== null && internalValue !== void 0 ? internalValue : '')))) {
        setInternalValue(stringifiedValue);
    }
    var derivedValue = (function () {
        if (isCurrencyInput && !isFocused) {
            return formattedStringifiedValue;
        }
        if (new BigNumber(internalValue).eq(value !== null && value !== void 0 ? value : NaN)
            || (internalValue !== '' && new BigNumber(internalValue).isNaN())) {
            return internalValue;
        }
        return stringifiedValue;
    })();
    return (<TextInput ref={ref} value={derivedValue} {...rest} onFocus={function (event) {
            var _a, _b, _c, _d, _e, _f, _g;
            (_a = rest.onFocus) === null || _a === void 0 ? void 0 : _a.call(rest, event);
            var elem = event.target;
            var selectionStart = (_b = elem.selectionStart) !== null && _b !== void 0 ? _b : undefined;
            var selectionEnd = (_c = elem.selectionEnd) !== null && _c !== void 0 ? _c : undefined;
            if (isCurrencyInput) {
                // Focusing causes the value to be unformatted, potentially changing
                // the value externally and causing the cursor positon to reset to the
                // end of the input. Instead, flush that update then set the cursor
                // positon to where the user intended
                flushSync(function () {
                    setIsFocused(true);
                });
                if (selectionStart !== undefined) {
                    elem.selectionStart = selectionStart - ((_e = (_d = formattedStringifiedValue.slice(0, selectionStart).match(/,/)) === null || _d === void 0 ? void 0 : _d.length) !== null && _e !== void 0 ? _e : 0);
                }
                if (selectionEnd !== undefined) {
                    elem.selectionEnd = selectionEnd - ((_g = (_f = formattedStringifiedValue.slice(0, selectionEnd).match(/,/)) === null || _f === void 0 ? void 0 : _f.length) !== null && _g !== void 0 ? _g : 0);
                }
            }
            else {
                setIsFocused(true);
            }
        }} onChange={function (event) {
            var cleanedValue = event.target.value.replace(new RegExp("[^-0-9".concat(getDecimalSeparator(), "]"), 'g'), '');
            setInternalValue(cleanedValue);
            var parsedValue = cleanedValue === '' ? undefined : new BigNumber(cleanedValue);
            if ((parsedValue === null || parsedValue === void 0 ? void 0 : parsedValue.eq(value !== null && value !== void 0 ? value : NaN)) || (parsedValue === null || parsedValue === void 0 ? void 0 : parsedValue.isNaN()))
                return;
            if (isCurrencyInput) {
                parsedValue = parsedValue === null || parsedValue === void 0 ? void 0 : parsedValue.decimalPlaces(2, BigNumber.ROUND_DOWN);
            }
            onChange === null || onChange === void 0 ? void 0 : onChange(parsedValue);
        }} onBlur={function (event) {
            var _a;
            (_a = rest.onBlur) === null || _a === void 0 ? void 0 : _a.call(rest, event);
            setIsFocused(false);
            setInternalValue(stringifiedValue);
        }}/>);
});
var getDecimalSeparator = function () {
    return (1.1).toLocaleString().substring(1, 2);
};
export default BigNumberInput;
