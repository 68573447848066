var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useRef, useState } from 'react';
import { mergeProps, useFocusRing, useHover, VisuallyHidden } from 'react-aria';
import { notUndefined } from '@mablemarket/common-lib';
import { useForwardClick } from '../../hooks/useForwardClick';
import { focusRing, sprinkles } from '../../styles/sprinkles.css';
import { vars } from '../../styles/theme.css';
import { composeRefs } from '../../util';
import Box from '../Box';
import Grid from '../Grid';
import { InputDescription, InputErrorMessage, InputLabel, InputLabelProvider, useInputLabelContext } from '../TextInput';
var CheckboxOutlineVariants = {
    deselected: {
        stroke: vars.color.foreground.greyLine,
        fill: vars.color.background.white,
    },
    selectedError: {
        stroke: vars.color.foreground.redError,
        fill: vars.color.background.white,
    },
    deselectedError: {
        stroke: vars.color.foreground.redError,
        fill: vars.color.background.white,
    },
    deselectedHovered: {
        stroke: vars.color.foreground.black,
        fill: vars.color.background.white,
    },
    selected: {
        stroke: vars.color.foreground.greyLine,
        fill: vars.color.background.white,
    },
    selectedHovered: {
        stroke: vars.color.foreground.black,
        fill: vars.color.background.white,
    },
    disabled: {
        fill: vars.color.foreground.greyLine,
        stroke: undefined,
    },
    disabledSelected: {
        fill: vars.color.foreground.greyLine,
        stroke: undefined,
    },
};
var CheckboxFillingVariants = {
    deselected: {
        scale: 0,
        opacity: 0,
    },
    deselectedError: {
        scale: 0,
        opacity: 0,
    },
    deselectedHovered: {
        scale: 0,
        opacity: 0,
    },
    selected: {
        scale: 1,
        opacity: 1,
    },
    selectedHovered: {
        scale: 1,
        opacity: 1,
    },
    selectedError: {
        scale: 1,
        opacity: 1,
    },
    disabled: {
        scale: 0,
        opacity: 0,
    },
    disabledSelected: {
        scale: 1,
        opacity: 1,
    },
};
export var CheckboxIcon = function (props) {
    var _a = props.height, height = _a === void 0 ? 20 : _a, _b = props.width, width = _b === void 0 ? 20 : _b, _c = props.state, state = _c === void 0 ? 'deselected' : _c, className = props.className, _d = props.hovered, hovered = _d === void 0 ? false : _d, _e = props.error, error = _e === void 0 ? false : _e, _f = props.disabled, disabled = _f === void 0 ? false : _f, _g = props.noBorder, noBorder = _g === void 0 ? false : _g, rest = __rest(props, ["height", "width", "state", "className", "hovered", "error", "disabled", "noBorder"]);
    var borderStrokeWidth = noBorder ? '0' : undefined;
    var derivedState = (function () {
        if (disabled) {
            return state === 'selected' ? 'disabledSelected' : 'disabled';
        }
        if (state === 'selected') {
            if (hovered)
                return 'selectedHovered';
            if (error)
                return 'selectedError';
            return 'selected';
        }
        if (hovered)
            return 'deselectedHovered';
        if (error)
            return 'deselectedError';
        return 'deselected';
    })();
    return (<svg height={height} width={width} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <g fill='none' fillRule='evenodd'>
        <rect x='.5' y='.5' width='19' height='19' rx='3' strokeWidth={borderStrokeWidth} style={{
            fill: CheckboxOutlineVariants[derivedState].fill,
            stroke: CheckboxOutlineVariants[derivedState].stroke,
        }}/>
        <path stroke='#111' strokeWidth='2.138' d='M4 9l4.48 5.325 7.302-8.995' style={{
            transform: "scale(".concat(CheckboxFillingVariants[derivedState].scale, ")"),
            transformOrigin: '50%',
            opacity: CheckboxFillingVariants[derivedState].opacity,
            transition: 'transform 0.3s, opacity 0.3s',
        }}/>
      </g>
    </svg>);
};
export var Checkbox = React.forwardRef(function (props, ref) {
    var className = props.className, style = props.style, flexBasis = props.flexBasis, flexGrow = props.flexGrow, flexShrink = props.flexShrink, gridArea = props.gridArea, gridColumnEnd = props.gridColumnEnd, gridColumnStart = props.gridColumnStart, gridRowEnd = props.gridRowEnd, gridRowStart = props.gridRowStart, outerRef = props.outerRef, noBorder = props.noBorder, label = props.label, description = props.description, error = props.error, rest = __rest(props, ["className", "style", "flexBasis", "flexGrow", "flexShrink", "gridArea", "gridColumnEnd", "gridColumnStart", "gridRowEnd", "gridRowStart", "outerRef", "noBorder", "label", "description", "error"]);
    var inputRef = useRef(null);
    var forwardClickProps = useForwardClick({ targetRef: inputRef }).forwardClickProps;
    var checkboxHeight = 20;
    // must track 'uncontrolled' state to render icon correctly
    var _a = useState(false), internalChecked = _a[0], setInternalChecked = _a[1];
    var checked = props.checked === undefined ? internalChecked : props.checked;
    var setInitialInternalChecked = useCallback(function (elem) {
        elem && setInternalChecked(elem.checked);
    }, []);
    var _b = useHover({}), isCheckboxHovered = _b.isHovered, checkboxHoverProps = _b.hoverProps;
    var _c = useHover({}), isLabelHovered = _c.isHovered, labelHoverProps = _c.hoverProps;
    var isHovered = isCheckboxHovered || isLabelHovered;
    var _d = useInputLabelContext({
        inputId: props.id,
    }), inputId = _d.inputId, descriptionId = _d.descriptionId, errorId = _d.errorId;
    var _e = useFocusRing({ within: true }), focusProps = _e.focusProps, isFocusVisible = _e.isFocusVisible;
    var checkbox = (<Box display='flex' {...mergeProps(checkboxHoverProps, focusProps)} className={isFocusVisible ? focusRing : undefined}>
      <span {...forwardClickProps} style={{ height: "".concat(checkboxHeight, "px") }}>
        <CheckboxIcon state={checked ? 'selected' : 'deselected'} error={!!error} noBorder={noBorder} hovered={isHovered} disabled={rest.disabled} aria-hidden height={checkboxHeight}/>
      </span>
      {/* The visually hidden input element will still capture all of the normal
            events, like focus as well as forwarded click events from an
            associated label */}
      <VisuallyHidden>
        <input type='checkbox' id={inputId} aria-describedby={[
            description ? descriptionId : undefined,
            error ? errorId : undefined,
        ].filter(notUndefined).join(' ')} {...mergeProps(rest, { onChange: function (e) { return setInternalChecked(e.target.checked); } })} checked={checked} ref={composeRefs(inputRef, ref, setInitialInternalChecked)}/>
      </VisuallyHidden>
    </Box>);
    return (<InputLabelProvider inputId={inputId} descriptionId={descriptionId} errorId={errorId}>
      <Grid ref={outerRef} style={style} className={className} gridTemplateColumns='auto 1fr' rowGap='space-0.25' alignItems='center' flexBasis={flexBasis} flexGrow={flexGrow} flexShrink={flexShrink} gridArea={gridArea} gridColumnEnd={gridColumnEnd} gridColumnStart={gridColumnStart} gridRowEnd={gridRowEnd} gridRowStart={gridRowStart}>
        {checkbox}
        {label && (<InputLabel {...labelHoverProps} className={sprinkles.atoms({ paddingLeft: 'space-0.5' })}>{label}</InputLabel>)}
        {description && (<InputDescription gridColumnStart='2' className={sprinkles.atoms({ paddingLeft: 'space-0.5' })}>
            {description}
          </InputDescription>)}
        {error && error !== true && (<InputErrorMessage gridColumnStart='2' className={sprinkles.atoms({ paddingLeft: 'space-0.5' })}>
            {error}
          </InputErrorMessage>)}
      </Grid>
    </InputLabelProvider>);
});
export default Checkbox;
