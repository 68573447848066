import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';

import { Address } from '@mablemarket/core-api-client';
import { RequestSampleType } from '@mablemarket/mable-lib';
import { isAddress } from '@mablemarket/mable-lib/build/helpers/AddressHelpers';

export const RequestSampleFormSchemaWithoutAddress = yup.object().shape({
  note: yup.string().required('A note is required.'),
  sampleTypes: yup.array().of(yup.mixed<RequestSampleType>().oneOf(Object.values(RequestSampleType)).required())
    .when('sampleTypesExtraInfo', {
      is: (sampleTypesExtraInfo: string) => !isEmpty(sampleTypesExtraInfo),
      otherwise: schema => schema.required('Please provide an idea of what you would like to sample'),
    }),
  sampleTypesExtraInfo: yup.string()
    .when('sampleTypes', {
      is: (sampleTypes: unknown[]) => !isEmpty(sampleTypes),
      otherwise: schema => schema.required('Please provide an idea of what you would like to sample'),
    }),
}, [['sampleTypes', 'sampleTypesExtraInfo']]);

/** Only used in mable-app, now that the web has a dedicated address picker that returns just an address ID */
export const RequestSampleFormSchema = RequestSampleFormSchemaWithoutAddress.concat(
  yup.object().shape({
    address: yup.mixed<Address>().notRequired().test(
      'is-address',
      'Address is needed to know where to ship the samples',
      value => isAddress(value),
    ),
  }),
);
export type RequestSampleWithoutAddressFormValues = yup.InferType<typeof RequestSampleFormSchemaWithoutAddress>;

export type RequestSampleWithAddressFormValues = yup.InferType<typeof RequestSampleFormSchema>;
