/**
 * Barcode references
 *
 * https://www.gtin.info/barcode-101/
 * https://www.morovia.com/education/utility/upc-ean.asp
 * https://www.gs1.org/services/check-digit-calculator
 * https://bytescout.com/blog/2013/10/upc-and-upc-e-purpose-advantages.html
 */

import * as t from 'io-ts';

import { isMableError, MableError } from './MableError';
import { notUndefined } from './util';

export const cleanBarcode = (barcode: string): string => {
  return barcode.trim().replace(/\D/g, '');
};

export const stripLeadingZeros = (str: string) => {
  return str.replace(/^0+/, '');
};

// TODO: UPC-E is 6 or 8 digits, that I think could be translated to UPC A. Excluded here.
const UpcType = t.keyof({
  EAN8: null,
  UPC11: null,
  UPCA: null,
  EAN13: null,
  GTIN14: null,
  UCC128: null,
});
export type UpcType = t.TypeOf<typeof UpcType>;

export const BarcodeInfo = t.intersection([
  t.type({
    /** If this barcode was deemed valid by checking length & checksum */
    isValid: t.boolean,
    /** The raw code given to the normalizing function */
    rawCode: t.string,
    /** The code, cleaned of whitespace and non-digits */
    code: t.string,
  }),
  t.partial({
    /** If this barcode is not valid, this is the reason why */
    validationErrorMessage: t.string,
    /** The code, with the check digit removed, if possible. */
    codeNoCheckDigit: t.string,
    /** The original type of the UPC that was assumed in parsing the partcode */
    originalUpcType: UpcType,
    /**
   * 8 digits long
   * EAN-8 is a GS1 barcode for use on small items when a full EAN-13 barcode label would be too large to fit.
   * "same kind of encoding as UPC-A and EAN-13, with the last digit being used as a checksum.'
   * Includes a check digit
  */
    EAN8: t.string,
    /** EAN8, but with the check digit removed */
    EAN8noCheck: t.string,
    /** This is the UPC12 minus the leading digit and check digit.
     * A 10 digit code on its own is not a valid UPC code, and the 12 digit code cannot be inferred from 10 digits.
     */
    UPC10: t.string,

    /** This is the UPC12 minus the check digit */
    UPC11: t.string,
    /**
     * a.k.a. UPC12. The standard retail “price code” barcode in the United States.
     * The most common type of UPC in our industry.
     * Includes a check digit.
     */
    UPCA: t.string,
    /**
     * EAN-13 code. The International Article Number of the product.
     *
     * The EAN-13 code is basically an international version of UPC-A.
     * EAN-13 adds a 13th digit on the far left side of the UPC-A code (so that it becomes the first digit).
     * The EAN-13 standard includes UPC-A barcodes; adding a leading 0 to a UPC-A code turns it into the equivalent
     *
     * Includes a check digit.
     */
    EAN13: t.string,
    /** EAN13, but with the check digit removed */
    EAN13noCheck: t.string,
    /**
     * 14 digits. Can just add 2 leading 0's to a UPCA to get it
     * Includes a check digit
     */
    GTIN14: t.string,
    /** GTIN14, but with the check digit removed */
    GTIN14noCheck: t.string,
    checkDigit: t.string,
    /**
     * 19 digits.
     * The UCC-128 barcode is specifically used for shipping containers for those who ship items with a UPC code.
     * Includes a checksum
     */
    UCC128: t.string,
    /** UCC128, but with the check digit removed */
    UCC128noCheck: t.string,
  }),
]);
export type BarcodeInfo = t.TypeOf<typeof BarcodeInfo>;

export const getCheckSum = (code: string, {
  codeIncludesCheckDigit,
}: { codeIncludesCheckDigit: boolean }) => {
  const characters = codeIncludesCheckDigit
    ? code.substring(0, code.length - 1).split('').reverse()
    : code.split('').reverse();
  let oddTotal = 0;
  let evenTotal = 0;

  characters.forEach((c, i) => {
    if (i % 2 === 0) {
      oddTotal += Number(c) * 3;
    } else {
      evenTotal += Number(c);
    }
  });
  const checkSum = (10 - ((evenTotal + oddTotal) % 10)) % 10;
  return checkSum.toString();
};

/** Validates the checksum of a barcode expected to have a checksum */
export const validateChecksum = (code: string) => {
  const validLengths = [8, 12, 13, 14, 19];
  if (!validLengths.includes(code.length)) {
    return {
      isValid: false,
      validationErrorMessage: `${code.length} length isn't expected to have a checksum`,
    };
  }

  const checkSum = getCheckSum(code, { codeIncludesCheckDigit: true });
  const lastDigit = code.substring(code.length - 1);

  if (checkSum === lastDigit) {
    return {
      isValid: true,
      checkDigit: checkSum,
    };
  }
  return {
    isValid: false,
    validationErrorMessage: `Checksum ${checkSum} is not equal to last digit ${lastDigit}`,
    checkDigit: checkSum,
  };
};

type ParseBarcodeOpts = {
  /** If true, do not attempt to infer checksums, default is `false` */
  strict?: boolean
  /** If true, allow the 8 character length barcodes to parse successfully */
  allowEAN8?: boolean
}

/** Given a barcode of any length, try to turn it into all the other formats. */
export const parseBarcode = (rawCode: string, opts?: ParseBarcodeOpts) => {
  const code = rawCode.trim();
  const { strict = false, allowEAN8 = true } = opts ?? {};

  if (code === undefined || code.length === 0) {
    return new MableError({
      code: 'BarcodeIsEmpty',
      message: 'The barcode contains nothing',
      displayMessage: 'Empty barcode supplied',
      logData: { rawCode, code },
      data: undefined,
    });
  }

  if (/^[0-9]+$/.test(code) === false) {
    return new MableError({
      code: 'BarcodeContainsNonDigits',
      message: 'The barcode contains non digit characters',
      displayMessage: 'Barcode contains a non digit character',
      logData: { rawCode, code },
      data: undefined,
    });
  }

  // TODO: Accept 7 as EAN8 without a check digit?
  const validLengths = [12, 13, 14, 19];
  if (!strict) {
    validLengths.push(11);
  }
  if (allowEAN8) {
    validLengths.push(8);
  }
  if (!validLengths.includes(code.length)) {
    return new MableError({
      code: 'BarcodeInvalidLength',
      message: 'The barcode is an invalid length',
      displayMessage: 'The barcode does not contain the correct amount of digits',
      logData: { rawCode, code },
      data: undefined,
    });
  }

  const checkRes = validateChecksum(code);
  if (code.length !== 11) {
    if (!checkRes.isValid) {
      return new MableError({
        code: 'InvalidChecksum',
        message: checkRes.validationErrorMessage ?? 'Unable to validate the barcode\'s checksum',
        displayMessage: checkRes.validationErrorMessage ?? 'Unable to validate the barcode\'s checksum',
        logData: { rawCode, code },
        data: undefined,
      });
    }
  }

  const makeBarcodeInfo = (info: Omit<BarcodeInfo, 'code' | 'rawCode' | 'isValid'>): BarcodeInfo => {
    return {
      code,
      rawCode,
      isValid: true,
      ...info,
    };
  };

  // Do something different for each length

  if (code.length === 8) {
    // EAN 8
    const UPCA = `0000${code}`;
    const EAN13 = `0${UPCA}`;
    const GTIN14 = `00${UPCA}`;
    const EAN8noCheck = code.substring(0, code.length - 1);
    return makeBarcodeInfo({
      originalUpcType: 'EAN8',
      EAN8: code,
      EAN8noCheck,
      codeNoCheckDigit: EAN8noCheck,
      checkDigit: checkRes.checkDigit,
      UPCA,
      EAN13,
      EAN13noCheck: EAN13.substring(0, EAN13.length - 1),
      GTIN14,
      GTIN14noCheck: GTIN14.substring(0, GTIN14.length - 1),
    });
  }

  if (code.length === 11) {
    // UPC 11.
    // This is essentially an UPC A missing the check digit, so we infer and append it
    const checkDigit = getCheckSum(code, { codeIncludesCheckDigit: false });
    const UPC11 = code;
    const UPCA = `${UPC11}${checkDigit}`;
    const EAN13 = `0${UPCA}`;
    const GTIN14 = `00${UPCA}`;
    return makeBarcodeInfo({
      originalUpcType: 'UPC11',
      UPC11,
      UPCA,
      checkDigit,
      EAN13,
      EAN13noCheck: EAN13.substring(0, EAN13.length - 1),
      GTIN14,
      GTIN14noCheck: GTIN14.substring(0, GTIN14.length - 1),
    });
  }

  if (code.length === 12) {
    // UPC A
    const UPCA = code;
    const UPC11 = code.substring(0, code.length - 1); // Remove check digit
    const EAN13 = `0${UPCA}`;
    const GTIN14 = `00${UPCA}`;
    return makeBarcodeInfo({
      originalUpcType: 'UPCA',
      UPC11,
      codeNoCheckDigit: UPC11,
      UPCA,
      checkDigit: checkRes.checkDigit,
      EAN13,
      EAN13noCheck: EAN13.substring(0, EAN13.length - 1),
      GTIN14,
      GTIN14noCheck: GTIN14.substring(0, GTIN14.length - 1),
    });
  }

  if (code.length === 13) {
    // EAN 13
    const EAN13 = code;
    const EAN13noCheck = EAN13.substring(0, EAN13.length - 1);
    const UPCA = code.startsWith('0') ? code.substring(1) : undefined;
    const UPC11 = UPCA?.substring(0, code.length - 1); // Remove check digit
    const GTIN14 = UPCA ? `00${UPCA}` : `0${EAN13}`;
    const GTIN14noCheck = GTIN14 ? GTIN14.substring(0, GTIN14.length - 1) : undefined;
    return makeBarcodeInfo({
      originalUpcType: 'EAN13',
      checkDigit: checkRes.checkDigit,
      UPC11,
      UPCA,
      EAN13,
      EAN13noCheck,
      codeNoCheckDigit: EAN13noCheck,
      GTIN14,
      GTIN14noCheck,
    });
  }

  if (code.length === 14) {
    // GTIN 14
    const GTIN14 = code;
    const GTIN14noCheck = GTIN14.substring(0, GTIN14.length - 1);
    const EAN13 = code.startsWith('0') ? code.substring(1) : undefined;
    const UPCA = code.startsWith('00') ? code.substring(2) : undefined;
    const UPC11 = UPCA?.substring(0, code.length - 1); // Remove check digit
    return makeBarcodeInfo({
      originalUpcType: 'GTIN14',
      checkDigit: checkRes.checkDigit,
      UPCA,
      UPC11,
      EAN13,
      GTIN14,
      GTIN14noCheck,
      codeNoCheckDigit: GTIN14noCheck,
    });
  }

  if (code.length === 19) {
    // UCC128
    const UCC128 = code;
    const UCC128noCheck = UCC128.substring(0, UCC128.length - 1);
    return makeBarcodeInfo({
      originalUpcType: 'UCC128',
      checkDigit: checkRes.checkDigit,
      UCC128,
      UCC128noCheck,
      codeNoCheckDigit: UCC128noCheck,
    });
  }

  // Unexpected
  return new MableError({
    code: 'UnexpectedError',
    message: 'Unexpectedly reached the end of barcode parsing function without handling the provided barcode.',
    displayMessage: 'An unexpected error happened while parsing the barcode',
    logData: { rawCode, code },
    data: undefined,
  });
};


export const formatBarcodeForCsv = (value?: string) => {
  return (value && !value.match(/\D/))
    ? `'${value}'`
    : '';
};

export const barcodeFormatObject = ({
  eachBarcode = '',
  caseBarcode = '',
  formatForCSV,
}: {
  eachBarcode: string | undefined;
  caseBarcode: string | undefined;
  formatForCSV: boolean;
}) => {
  const eachBarcodeInfo = parseBarcode(cleanBarcode(eachBarcode));
  const caseBarcodeInfo = parseBarcode(cleanBarcode(caseBarcode));

  const maybeFormat = formatForCSV
    ? formatBarcodeForCsv
    : (s?: string) => s;

  return {
    barcodeInput: maybeFormat(eachBarcode),
    upc10: isMableError(eachBarcodeInfo) ? undefined : maybeFormat(eachBarcodeInfo.UPC10),
    upc11: isMableError(eachBarcodeInfo) ? undefined : maybeFormat(eachBarcodeInfo.UPC11),
    upcA: isMableError(eachBarcodeInfo) ? undefined : maybeFormat(eachBarcodeInfo.UPCA),
    ean13: isMableError(eachBarcodeInfo) ? undefined : maybeFormat(eachBarcodeInfo.EAN13),

    // TODO: Maybe return a "best UPC": bestUPC: UPCA ?? EAN13 ?? UPC10 ?? UPC11 ?? code,
    caseBarcodeInput: maybeFormat(caseBarcode),
    case_upc10: isMableError(caseBarcodeInfo) ? undefined : maybeFormat(caseBarcodeInfo.UPC10),
    case_upc11: isMableError(caseBarcodeInfo) ? undefined : maybeFormat(caseBarcodeInfo.UPC11),
    case_upcA: isMableError(caseBarcodeInfo) ? undefined : maybeFormat(caseBarcodeInfo.UPCA),
    case_ean13: isMableError(caseBarcodeInfo) ? undefined : maybeFormat(caseBarcodeInfo.EAN13),
  };
};

export const barcodeInfoValues = (info: BarcodeInfo) => {
  return [
    info.EAN8noCheck,
    info.EAN8,
    info.UPC10,
    info.UPC11,
    info.UPCA,
    info.EAN13noCheck,
    info.EAN13,
    info.GTIN14noCheck,
    info.GTIN14,
    info.UCC128noCheck,
    info.UCC128,
  ].filter(notUndefined);
};

export const isValidBarcode = (value: string, opts?: ParseBarcodeOpts) => !isMableError(parseBarcode(value, opts));

export const normalizeGtin = (gtin: string) => {
  const parsed = parseBarcode(gtin);
  if (isMableError(parsed)) {
    // if error, just return the original. We want to normalize, not validate or fix
    return gtin;
  }
  // Go with a normalized version of the GTIN in descending order of specificity

  // UCC128 is the most specific, so go with that
  if (parsed.UCC128) {
    return parsed.UCC128;
  }
  // IF we parsed a GTIN14, and it doesn't start with 00, then it's the next most specific
  // otherwise it's just a UPC A
  if (parsed.GTIN14 && !/^00/.test(parsed.GTIN14)) {
    return parsed.GTIN14;
  }
  // EAN13 is the next most specific, an international version of UPC A. If it starts with 0,
  // similar to GTIN14 its just padding for a UPC A
  if (parsed.EAN13 && !/^0/.test(parsed.EAN13)) {
    return parsed.EAN13;
  }
  // Most common is UPC A
  if (parsed.UPCA) {
    return parsed.UPCA;
  }
  // EAN8 is a special case for small items
  if (parsed.EAN8) {
    return parsed.EAN8;
  }
  return gtin;
};
