import { HoverEvent } from '@react-types/shared';
import { useCallback, useRef, useState } from 'react';
import { useHover } from 'react-aria';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';


type useHoverDelayProps = Parameters<typeof useHover>[0] & {
  /** Number of milliseconds to delay hovering off */
  delay: number;
}
const useHoverDelay = (props: useHoverDelayProps) => {
  const { delay, ...rest } = props;

  const [isHovered, setIsHovered] = useState(false);

  const hoverTimeoutRef = useRef<{ timeout: NodeJS.Timeout, hoverEvent: HoverEvent } | null>(null);

  // Must track current props so delayed onHoverEnd events get current values
  const propsRef = useRef(props);
  useIsomorphicLayoutEffect(() => {
    propsRef.current = props;
  }, [props]);

  const clearHoverTimeout = useCallback(({ startNew }: { startNew?: HoverEvent }) => {
    if (hoverTimeoutRef.current !== null) {
      clearTimeout(hoverTimeoutRef.current.timeout);
      hoverTimeoutRef.current = null;
    }
    if (startNew) {
      hoverTimeoutRef.current = {
        timeout: setTimeout(() => {
          propsRef.current.onHoverEnd?.(startNew);
          propsRef.current.onHoverChange?.(false);
          setIsHovered(false);
        }, propsRef.current.delay),
        hoverEvent: startNew,
      };
    }
  }, []);

  const hoverResult = useHover({
    ...rest,
    onHoverChange: (isHovered) => {
      if (isHovered) {
        setIsHovered(true);
        rest.onHoverChange?.(true);
      }
    },
    onHoverStart: (hoverEvent) => {
      if (hoverTimeoutRef.current !== null) {
        rest?.onHoverStart?.(hoverEvent);
      }
      clearHoverTimeout({});
    },
    onHoverEnd: (hoverEvent) => {
      clearHoverTimeout({ startNew: hoverEvent });
    },
  });

  return { ...hoverResult, isHovered };
};

export default useHoverDelay;
