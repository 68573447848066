import React from 'react';

import Box from '../Box';

import useIconProps, { IconDefaultProps } from './useIconProps';

const IconMeatballs = (props: IconDefaultProps) => {
  const { height = 5, width = 21, ...rest } = props;
  const iconProps = useIconProps({ ...rest, height });
  return (
    <Box
      as='svg'
      width={width}
      height={height}
      viewBox='0 0 21 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
      {...iconProps}
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-1208.000000, -711.000000)' fill='currentColor'>
          <path d='M1210.5,711 C1211.88071,711 1213,712.119288 1213,713.5 C1213,714.880712 1211.88071,716 1210.5,716 C1209.11929,716 1208,714.880712 1208,713.5 C1208,712.119288 1209.11929,711 1210.5,711 Z M1226.5,711 C1227.88071,711 1229,712.119288 1229,713.5 C1229,714.880712 1227.88071,716 1226.5,716 C1225.11929,716 1224,714.880712 1224,713.5 C1224,712.119288 1225.11929,711 1226.5,711 Z M1218.5,711 C1219.88071,711 1221,712.119288 1221,713.5 C1221,714.880712 1219.88071,716 1218.5,716 C1217.11929,716 1216,714.880712 1216,713.5 C1216,712.119288 1217.11929,711 1218.5,711 Z' />
        </g>
      </g>
    </Box>
  );
};

export default IconMeatballs;
