var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useRef, useState } from 'react';
import { ensureMableError } from '@mablemarket/common-lib';
import { useEffectAbort } from './useEffectAbort';
export function useRequest(client, routeName, input, deps) {
    var _this = this;
    var _a = useState(null), data = _a[0], setData = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useState(function () { return !!deps; }), loading = _c[0], setLoading = _c[1];
    var _d = useState(undefined), loadedTime = _d[0], setLoadedTime = _d[1];
    var reqIdRef = useRef();
    var req = function (input, abortController) { return __awaiter(_this, void 0, void 0, function () {
        var thisReqId, res, body, err_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    thisReqId = Symbol('currentRequest');
                    reqIdRef.current = thisReqId;
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.req(routeName, input, { abortController: abortController })];
                case 2:
                    res = _a.sent();
                    body = res.body;
                    setLoadedTime(new Date());
                    setData(body !== null && body !== void 0 ? body : null);
                    setError(null);
                    if (thisReqId === reqIdRef.current) {
                        setLoading(false);
                    }
                    return [2 /*return*/, { body: body }];
                case 3:
                    err_1 = _a.sent();
                    error_1 = err_1 instanceof Error ? err_1 : ensureMableError(err_1);
                    setError(error_1);
                    if (thisReqId === reqIdRef.current) {
                        setLoading(false);
                    }
                    return [2 /*return*/, { error: error_1 }];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffectAbort(function (abortController) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(deps && input)) return [3 /*break*/, 2];
                    return [4 /*yield*/, req(input, abortController)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, deps);
    var hookInput = input;
    var request = function (_a) {
        var _b;
        var input = _a.input, abortController = _a.abortController;
        return req((_b = input !== null && input !== void 0 ? input : hookInput) !== null && _b !== void 0 ? _b : {}, abortController);
    };
    return {
        data: data,
        setData: setData,
        error: error,
        setError: setError,
        loading: loading,
        setLoading: setLoading,
        request: request,
        loadedTime: loadedTime,
    };
}
export function useUnspeccedRequest(client, method, path, deps) {
    var _this = this;
    var _a = useState(null), response = _a[0], setResponse = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useState(function () { return !!deps; }), loading = _c[0], setLoading = _c[1];
    var reqIdRef = useRef();
    var req = function (opts) { return __awaiter(_this, void 0, void 0, function () {
        var thisReqId, res, err_2, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    thisReqId = Symbol('currentRequest');
                    reqIdRef.current = thisReqId;
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.unspeccedReq(method, path, opts)];
                case 2:
                    res = _a.sent();
                    setResponse(res);
                    setError(null);
                    if (thisReqId === reqIdRef.current) {
                        setLoading(false);
                    }
                    return [2 /*return*/, { res: res, error: undefined }];
                case 3:
                    err_2 = _a.sent();
                    error_2 = err_2 instanceof Error ? err_2 : ensureMableError(err_2);
                    setError(error_2);
                    if (thisReqId === reqIdRef.current) {
                        setLoading(false);
                    }
                    return [2 /*return*/, { res: undefined, error: error_2 }];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffectAbort(function (abortController) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!deps) return [3 /*break*/, 2];
                    return [4 /*yield*/, req({ abortController: abortController })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, deps);
    var request = function (opts) {
        if (opts === void 0) { opts = {}; }
        return req(opts);
    };
    return {
        response: response,
        setResponse: setResponse,
        error: error,
        setError: setError,
        loading: loading,
        setLoading: setLoading,
        request: request,
    };
}
