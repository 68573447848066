/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { shallowCompare } from '@mablemarket/common-lib';
import { Product, ProductDisplayInfo, ProductVariant, ProductVariantDisplayInfo } from '@mablemarket/core-api-client';
import { ProductHelpers } from '@mablemarket/mable-lib';

/**
 * Presentation style for option button.
 * 'incompatible' means this option is not compatible with
 * the other options already specified, and it gets a different UI treatment
 */
export type ProductOptionButtonStyle = 'unselected' | 'selected' | 'incompatible';

export interface OptionButtonInfo<T> {
  title: string;
  style: ProductOptionButtonStyle;
  resultingValue: T;
}

export interface ProductOptionSelectionProps<T> {
  title?: string;
  optionButtonProps: OptionButtonInfo<T>[];
}

// TODO: ENG-4978 Make the app use the new "makeProductOptionSelectionPropsFromDisplayInfo" function below and delete this one.
/** @deprecated */
export const makeProductOptionSelectionProps = (data: {
  product: Pick<Product, 'options' | 'variants' | 'name'>;
  selectedVariant: ProductVariant;
}): ProductOptionSelectionProps<ProductVariant>[] => {
  const {
    product,
    selectedVariant,
  } = data;

  const variants = (product.variants || []).filter(v => v.options && Object.keys(v.options).length);

  if (!product.options || !product.options.length || variants.length === 1) {
    if (variants.length > 1) {
      // This is likely older data when we allowed variants without options sets
      // Return one option selection area with all the variant names

      return [{
        title: 'Variant',
        optionButtonProps: variants.map((v) => {
          return {
            title: ProductHelpers.productVariantName({ product, variant: v, includeProductName: false }),
            style: v.id === selectedVariant.id ? 'selected' : 'unselected',
            resultingValue: v,
          };
        }),
      }];
    }
    // No options, only one variant; nothing to show.
    return [];
  }

  return product.options.map((option) => {
    const optionButtonProps = option.values.map((value) => {
      const isSelected = selectedVariant.options![option.name] === value;

      const compatibleVariant = variants.find((v) => {
        return shallowCompare(
          v.options!,
          { ...selectedVariant.options, [option.name]: value },
        );
      });
      const closestIncompatibleVariant = !compatibleVariant && variants.find(v => (
        v.options![option.name] === value
      ));

      let style: ProductOptionButtonStyle = 'unselected';
      let resultingVariant: ProductVariant | undefined;
      if (isSelected) {
        style = 'selected';
        resultingVariant = selectedVariant;
      } else if (compatibleVariant) {
        resultingVariant = compatibleVariant;
      } else if (closestIncompatibleVariant) {
        style = 'incompatible';
        resultingVariant = closestIncompatibleVariant;
      }

      if (!resultingVariant) {
        return undefined;
      }

      return {
        title: value,
        style,
        resultingValue: resultingVariant,
      };
    }).filter((o): o is OptionButtonInfo<ProductVariant> => o !== undefined);

    return {
      title: (product.options ?? []).length > 1 ? option.name : undefined,
      optionButtonProps,
    };
  });
};

export const makeProductOptionSelectionPropsFromDisplayInfo = (data: {
  product: Pick<ProductDisplayInfo, 'options' | 'variants'>;
  selectedVariant: ProductVariantDisplayInfo;
}): ProductOptionSelectionProps<ProductVariantDisplayInfo>[] => {
  const {
    product,
    selectedVariant,
  } = data;

  const variants = (product.variants || []).filter(v => v.options && Object.keys(v.options).length);

  if (!product.options || !product.options.length || variants.length === 1) {
    if (variants.length > 1) {
      // This is likely older data when we allowed variants without options sets
      // Return one option selection area with all the variant names

      return [{
        title: 'Variant',
        optionButtonProps: variants.map((v) => {
          return {
            title: v.name,
            style: v.variantId === selectedVariant.variantId ? 'selected' : 'unselected',
            resultingValue: v,
          };
        }),
      }];
    }
    // No options, only one variant; nothing to show.
    return [];
  }

  return product.options.map((option) => {
    const optionButtonProps = option.values.map((value) => {
      const isSelected = selectedVariant.options![option.name] === value;

      const compatibleVariant = variants.find((v) => {
        return shallowCompare(
          v.options!,
          { ...selectedVariant.options, [option.name]: value },
        );
      });
      const closestIncompatibleVariant = !compatibleVariant && variants.find(v => (
        v.options![option.name] === value
      ));

      let style: ProductOptionButtonStyle = 'unselected';
      let resultingVariant: ProductVariantDisplayInfo | undefined;
      if (isSelected) {
        style = 'selected';
        resultingVariant = selectedVariant;
      } else if (compatibleVariant) {
        resultingVariant = compatibleVariant;
      } else if (closestIncompatibleVariant) {
        style = 'incompatible';
        resultingVariant = closestIncompatibleVariant;
      }

      if (!resultingVariant) {
        return undefined;
      }

      return {
        title: value,
        style,
        resultingValue: resultingVariant,
      };
    }).filter((o): o is OptionButtonInfo<ProductVariantDisplayInfo> => o !== undefined);

    return {
      title: (product.options ?? []).length > 1 ? option.name : undefined,
      optionButtonProps,
    };
  });
};
