import { ForwardRefComponent } from '@radix-ui/react-polymorphic';
import React from 'react';

import { BoxExcludeProps, flexPositioning, GridProperties } from '../../styles/atomValues';
import { OptionalResponsiveValue } from '../../styles/sprinkles.css';
import { assignResponsiveProperty, DynamicResponsiveProps } from '../../styles/utils';
import { InternalBox, InternalBoxOwnProps } from '../Box';

import * as styles from './Grid.css';


// Safari doesn't support start and end in Flex, but we still need it for Grid, so
// we manually exclude it as an option here
type Positioning = {
  alignItems?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'flex-start' | 'flex-end'>>,
  alignJustify?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'flex-start' | 'flex-end'>>,
  justifyContent?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'flex-start' | 'flex-end'>>,
  justifyItems?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'flex-start' | 'flex-end'>>,
  placeItems?: OptionalResponsiveValue<Exclude<typeof flexPositioning[number], 'flex-start' | 'flex-end'>>,
}

type GridDynamicProps =
  & DynamicResponsiveProps<typeof styles.gridTemplateColumnsTheme>
  & DynamicResponsiveProps<typeof styles.gridTemplateRowsTheme>
  & DynamicResponsiveProps<typeof styles.gridTemplateAreasTheme>
  & DynamicResponsiveProps<typeof styles.gridAutoColumnsTheme>
  & DynamicResponsiveProps<typeof styles.gridAutoRowsTheme>;
export type GridProps = Omit<InternalBoxOwnProps, Exclude<BoxExcludeProps, GridProperties> | 'display' | keyof Positioning> & GridDynamicProps & Positioning & {
  gap?: InternalBoxOwnProps['rowGap'];
  display?: OptionalResponsiveValue<'grid' | 'inline-grid' | 'none'>;
  children?: React.ReactNode;
}

/** Implements all common flex css properties as props, including a polyfill for gap
 * Ex. `<Flex justifyContent='space-between' alignItems='center' gap='space-1'>,,,</Flex>`
 * @params gap - The gap works by setting margins on its children, and negative
 * margins on the parent node to cancel them out. This neccesitates that no
 * children of `Flex` have their own margins, as they will be overwritten (or
 * not, both outcomes are bad). See also `rowGap` and `colGap`.
 */
const Grid = React.forwardRef((props, ref) => {
  const {
    children,
    as: is = 'div',
    display = 'grid',
    className,
    style,
    gridTemplateColumns,
    gridTemplateRows,
    gridTemplateAreas,
    gridAutoColumns,
    gridAutoRows,
    gap,
    ...rest
  } = props;

  const gridTemplateColumnsProps = assignResponsiveProperty(styles.gridTemplateColumnsTheme, gridTemplateColumns);
  const gridTemplateRowsProps = assignResponsiveProperty(styles.gridTemplateRowsTheme, gridTemplateRows);
  const gridTemplateAreasProps = assignResponsiveProperty(styles.gridTemplateAreasTheme, gridTemplateAreas);
  const gridAutoColumnsProps = assignResponsiveProperty(styles.gridAutoColumnsTheme, gridAutoColumns);
  const gridAutoRowsProps = assignResponsiveProperty(styles.gridAutoRowsTheme, gridAutoRows);


  return (
    <InternalBox
      ref={ref}
      display={display}
      as={is}
      // eslint-disable-next-line prefer-object-spread
      style={Object.assign(
        {},
        style,
        gridTemplateColumnsProps.style,
        gridTemplateRowsProps.style,
        gridTemplateAreasProps.style,
        gridAutoColumnsProps.style,
        gridAutoRowsProps.style,
      )}
      className={[
        className,
        gridTemplateColumnsProps.className,
        gridTemplateRowsProps.className,
        gridTemplateAreasProps.className,
        gridAutoColumnsProps.className,
        gridAutoRowsProps.className,
      ]}
      rowGap={gap}
      columnGap={gap}
      {...rest}
    >
      {children}
    </InternalBox>
  );
}) as ForwardRefComponent<'div', GridProps>;

export default Grid;
