var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { normalizeCustomResponsiveValue } from '../../styles/breakpoints';
import { mapResponsiveValue } from '../../styles/sprinkles.css';
import Text from '../Text';
var levelToFontSize = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'large',
};
/**
 * Get your h# elements here
 * Implemented on top of Text, so it includes the same automatic foreground
 * coloring logic
 * @param level - Sets the appropriate fontSize and h tag automatically. If
 * responsive, the tag will stay at the desktop level. Tag can be overridden by
 * `as`.
 * @param fontWeight - Does nothing when `fontFamily` is set to `'nutmeg'`
 */
var Heading = React.forwardRef(function (props, ref) {
    var _a, _b;
    var is = props.as, level = props.level, rest = __rest(props, ["as", "level"]);
    var component = is || "h".concat((_a = normalizeCustomResponsiveValue(level).desktop) !== null && _a !== void 0 ? _a : '1');
    return (<Text {...rest} fontWeight={props.fontFamily === 'nutmeg' ? undefined : ((_b = props.fontWeight) !== null && _b !== void 0 ? _b : 'bold')} fontSize={mapResponsiveValue(level, function (level) { return levelToFontSize[level]; })} ref={ref} as={component}/>);
});
export default Heading;
