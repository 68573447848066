var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback, useRef, useState } from 'react';
import { useHover } from 'react-aria';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';
var useHoverDelay = function (props) {
    var delay = props.delay, rest = __rest(props, ["delay"]);
    var _a = useState(false), isHovered = _a[0], setIsHovered = _a[1];
    var hoverTimeoutRef = useRef(null);
    // Must track current props so delayed onHoverEnd events get current values
    var propsRef = useRef(props);
    useIsomorphicLayoutEffect(function () {
        propsRef.current = props;
    }, [props]);
    var clearHoverTimeout = useCallback(function (_a) {
        var startNew = _a.startNew;
        if (hoverTimeoutRef.current !== null) {
            clearTimeout(hoverTimeoutRef.current.timeout);
            hoverTimeoutRef.current = null;
        }
        if (startNew) {
            hoverTimeoutRef.current = {
                timeout: setTimeout(function () {
                    var _a, _b, _c, _d;
                    (_b = (_a = propsRef.current).onHoverEnd) === null || _b === void 0 ? void 0 : _b.call(_a, startNew);
                    (_d = (_c = propsRef.current).onHoverChange) === null || _d === void 0 ? void 0 : _d.call(_c, false);
                    setIsHovered(false);
                }, propsRef.current.delay),
                hoverEvent: startNew,
            };
        }
    }, []);
    var hoverResult = useHover(__assign(__assign({}, rest), { onHoverChange: function (isHovered) {
            var _a;
            if (isHovered) {
                setIsHovered(true);
                (_a = rest.onHoverChange) === null || _a === void 0 ? void 0 : _a.call(rest, true);
            }
        }, onHoverStart: function (hoverEvent) {
            var _a;
            if (hoverTimeoutRef.current !== null) {
                (_a = rest === null || rest === void 0 ? void 0 : rest.onHoverStart) === null || _a === void 0 ? void 0 : _a.call(rest, hoverEvent);
            }
            clearHoverTimeout({});
        }, onHoverEnd: function (hoverEvent) {
            clearHoverTimeout({ startNew: hoverEvent });
        } }));
    return __assign(__assign({}, hoverResult), { isHovered: isHovered });
};
export default useHoverDelay;
