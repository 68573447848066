import * as t from 'io-ts';

import { BuyerNote, SampleRequest } from '@mablemarket/core-api-client';

import { addressSummaryString } from './AddressHelpers';

export const ineligibleInfo = {
  SampleRequestRequiresOrder: {
    title: 'You have not made an order yet',
    narrowToSeller: false,
  },
  OutstandingSampleFeedbackRequest: {
    title: 'You have requested a sample from this brand',
    narrowToSeller: true,
  },
  SampleRequestLimitHit: {
    title: 'Please provide feedback for received samples',
    narrowToSeller: false,
  },
  AllSampleRequestsPending: {
    title: 'Sample requests pending',
    narrowToSeller: false,
  },
  default: {
    title: 'Unable to request samples',
    narrowToSeller: false,
  },
};

export const ineligibleDisplayInfo = (code?: keyof typeof ineligibleInfo) => {
  return ineligibleInfo[code ?? 'default'] ?? ineligibleInfo.default;
};

/** Given any version of the buyer sample request note, parse it into most recent version note. */
export function parseSampleRequestNote(opts: { note?: string, noteObject?: Record<string, unknown> }): {
  note: string,
  noteObject: BuyerNote,
} | {
  errorCode: string,
  errorMessage: string,
} {
  const { note, noteObject } = opts;

  if (note === undefined && noteObject === undefined) {
    return {
      errorCode: 'NoteAndNoteObjectUndefined',
      errorMessage: 'Note and noteObject are both undefined.',
    };
  }
  if (note?.trim()) {
    return parseSampleRequestNoteV0(note.trim());
  }
  switch (noteObject?.version) {
    case 0:
      return parseSampleRequestNoteV0((noteObject as BuyerNote).note);
    case 1:
      return parseSampleRequestNoteV1(noteObject as BuyerNote);
    case 2:
      return parseSampleRequestNoteV2(noteObject as BuyerNote);
    default:
      return {
        errorCode: 'NoKnownNoteObjectVersion',
        errorMessage: 'No known version was given with the note object.',
      };
  }
}

/** Parse a v0 note into a v1 note object to be processed */
export function parseSampleRequestNoteV0(note: string) {
  return parseSampleRequestNoteV1({
    note,
    version: 0,
  });
}

export function parseSampleRequestNoteV1(noteObject: BuyerNote) {
  const note = [
    noteObject.note,
    noteObject.qualities && `\nWhat the buyer likes about your products:\n${noteObject.qualities.join(', ')}`,
    noteObject.extraOptions && `\nWhat the buyer is requesting to sample:\n${noteObject.extraOptions.join(', ')}`,
    noteObject.extraOptionsNote,
  ].filter(n => n).join('\n');
  return { note, noteObject };
}

export function parseSampleRequestNoteV2(noteObject: BuyerNote) {
  const note = [
    noteObject.note,
    noteObject.extraOptions && `\nWhat the buyer is requesting to sample:\n${noteObject.extraOptions.join(', ')}`,
    noteObject.extraOptionsNote,
  ].filter(n => n).join('\n');
  return { note, noteObject };
}

export enum RequestSampleType {
  Bestsellers = 'Bestsellers',
  MostUnique = 'Most unique',
  HealthConscious = 'Health conscious',
}

export const sampleSupportNumber = '6179383953';
export const samplesSupportEmail = 'samples@meetmable.com';
export const samplesSupportFrom = 'Mable Samples';

export const getSampleRequestAddressLines = (sampleRequest: SampleRequest) => {
  return sampleRequest.shipToAddress ? addressSummaryString(sampleRequest.shipToAddress, { style: 'letter' }).split('\n') : [];
};

// ENG-2824 - use this codec and similar when constructing samples reminder offsets
// On the 8th business day of no response, the request is cancelled
export const SampleConfirmationReminderDaysOffset = t.union([
  t.literal(2),
  t.literal(4),
  t.literal(7),
  t.literal(8),
]);
export type SampleConfirmationReminderDaysOffset = t.TypeOf<typeof SampleConfirmationReminderDaysOffset>;

// ENG-2824 - use this codec when constructing samples feedback reminder offsets
export const SampleBuyerFeedbackReminderDaysOffset = t.union([
  t.literal(10),
  t.literal(13),
]);
export type SampleBuyerFeedbackReminderDaysOffset = t.TypeOf<typeof SampleBuyerFeedbackReminderDaysOffset>;

// ENG-2824 - use this codec and similar when constructing samples reminder offsets
// On the 29th business day of no response, the request is cancelled
export const SampleShippingReminderDaysOffset = t.union([
  t.literal(4),
  t.literal(7),
  t.literal(10),
  t.literal(29),
]);
export type SampleShippingReminderDaysOffset = t.TypeOf<typeof SampleShippingReminderDaysOffset>;

export const SampleFeedbackCodec = t.partial({
  ratings: t.partial({
    price: t.number,
    taste: t.number,
    packaging: t.number,
    shelfLife: t.number,
    ingredients: t.number,
  }),
  thoughts: t.string,
  comments: t.string,
  suggestions: t.array(t.string),
  placeAnOrder: t.string,
});
