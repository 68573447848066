import { assignInlineVars } from '@vanilla-extract/dynamic';
import fromPairs from 'lodash/fromPairs';
import React from 'react';

import { BreakPointNames, normalizeCustomResponsiveValue, OptionalResponsiveCustomValue, responsiveStyle } from './breakpoints';
import { CreateThemeContract, CSSProperties, Style } from './utils.css';

type DynamicPropertyTheme<Property extends keyof CSSProperties> = {
  inlineVars: Record<BreakPointNames, string>;
  baseClassName: string;
  property: Property;
}

// Must explicitly decalre type as there is some 'A type annotation is
// neccessary' error that I cannot track down the cause of
type DynamicResponsiveProperty = <P extends keyof CSSProperties>(property: P, defaultValue?: string) => DynamicPropertyTheme<P>;

export const makeCssUtils = (css: { createThemeContract: CreateThemeContract, style: Style }): {
  dynamicResponsiveProperty: DynamicResponsiveProperty
} => {
  /** Create a css property that can be set to arbitary values via inline
   * styles, but retains a responsive interface through clever use of css variables.
   * Used in .css.ts files
   */
  const dynamicResponsiveProperty = <P extends keyof CSSProperties>(property: P, defaultValue = ''): DynamicPropertyTheme<P> => {
    const inlineVars = css.createThemeContract({
      desktop: null,
      tablet: null,
      phone: null,
    });

    const baseClassName = css.style(responsiveStyle({
      desktop: {
        [property]: inlineVars.desktop,
        vars: {
          [inlineVars.desktop]: defaultValue,
          [inlineVars.tablet]: inlineVars.desktop,
          [inlineVars.phone]: inlineVars.tablet,
        },
      },
      tablet: { [property]: inlineVars.tablet },
      phone: { [property]: inlineVars.phone },
    }));
    return { inlineVars, baseClassName, property };
  };
  return {
    dynamicResponsiveProperty,
  };
};

export type DynamicResponsiveProps<DynamicTheme> = DynamicTheme extends DynamicPropertyTheme<infer P>
  ? Partial<Record<P, OptionalResponsiveCustomValue<NonNullable<CSSProperties[P]>>>>
  : never;

// Must explicitly decalre type as there is some 'A type annotation is
// neccessary' error that I cannot track down the cause of
type AssignResponsiveProperty = <Property extends keyof CSSProperties>(
  { inlineVars, baseClassName }: DynamicPropertyTheme<Property>,
  value?: OptionalResponsiveCustomValue<NonNullable<CSSProperties[Property]>>,
) => { style: React.CSSProperties, className: string | undefined };

/** Assigns responsive values to a property created by `dynamicResponsiveProperty`.
 * Used in component definitions.
 * @returns Both a `style` prop to set the css variables and a `className` to
 * set the property value to the variables
 */
export const assignResponsiveProperty: AssignResponsiveProperty = (
  { inlineVars, baseClassName },
  value,
): {
  style: React.CSSProperties
  className: string | undefined
} => {
  if (value === undefined) {
    return { style: {}, className: undefined };
  }
  const normalizedValue = normalizeCustomResponsiveValue(value);
  const theme = fromPairs(
    Object.entries(normalizedValue)
      .map(([k, v]) => [inlineVars[k as keyof typeof inlineVars], typeof v === 'number' ? v : String(v)]),
  );
  const style = assignInlineVars(theme as Record<keyof typeof inlineVars, string>);
  return { style, className: baseClassName };
};
