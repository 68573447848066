import React, { HTMLAttributes, useContext, useRef } from 'react';
import { AriaRadioGroupProps, AriaRadioProps, mergeProps, useFocusRing, useHover, useRadio, useRadioGroup, VisuallyHidden } from 'react-aria';
import { RadioGroupState, useRadioGroupState } from 'react-stately';

import { useForwardClick } from '../../hooks/useForwardClick';
import { focusRing } from '../../styles/sprinkles.css';
import { vars } from '../../styles/theme.css';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import Box from '../Box';
import Grid from '../Grid';
import Text from '../Text';
import { InputDescription, InputErrorMessage } from '../TextInput';

const RadioGroupContext = React.createContext<{ state: RadioGroupState, variant: Variant } | null>(null);
const useRadioGroupContext = () => {
  const context = useContext(RadioGroupContext);
  if (context === null) {
    throw new Error('RadioGroup.Item must be used inside of RadioGroup.');
  }
  return context;
};

type Variant = 'default' | 'boxed';

export interface RadioGroupProps extends
  Omit<AriaRadioGroupProps, 'validationState' | 'errorMessage' | 'isDisabled'>,
  Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'value' | 'defaultValue'> {
  children?: React.ReactNode;
  label?: React.ReactNode;
  description?: React.ReactNode;
  error?: boolean | React.ReactNode;
  disabled?: boolean,
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  variant?: Variant;
}

const RadioGroup = React.forwardRef((props: RadioGroupProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { label, children, error, description, disabled, variant = 'default', orientation = 'vertical', ...rest } = props;
  const state = useRadioGroupState({
    ...props,
    errorMessage: error,
    isDisabled: disabled,
    validationState: error ? 'invalid' : 'valid',
  });
  const { labelProps, radioGroupProps, descriptionProps, errorMessageProps } = useRadioGroup({
    ...props,
    errorMessage: error,
    isDisabled: disabled,
    validationState: error ? 'invalid' : 'valid',
  }, state);
  return (
    <Grid {...mergeProps(radioGroupProps, rest)} gridAutoFlow='row' gap='space-0.75' ref={ref}>
      {label && (
        <div {...labelProps}>
          {childrenAreLiteral(label) ? <Text>{label}</Text> : label}
        </div>
      )}
      {description && (<InputDescription {...descriptionProps}>{description}</InputDescription>)}
      <Grid
        gridAutoFlow={orientation === 'vertical' ? 'row' : 'column'}
        rowGap='space-1'
        columnGap='space-1.5'
        gridAutoColumns='max-content'
      >
        <RadioGroupContext.Provider value={{ state, variant }}>
          {children}
        </RadioGroupContext.Provider>
      </Grid>
      {error && error !== true && <InputErrorMessage {...errorMessageProps}>{error}</InputErrorMessage>}
    </Grid>
  );
});

export interface RadioItemProps extends AriaRadioProps {
  label?: React.ReactNode;
  description?: React.ReactNode;
}

const RadioItem = (props: RadioItemProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { forwardClickProps } = useForwardClick({ targetRef: inputRef });
  const { isHovered, hoverProps } = useHover({});

  const { state, variant } = useRadioGroupContext();

  const { inputProps, isDisabled, isSelected } = useRadio({ ...props, children: props.label }, state, inputRef);

  const { focusProps, isFocusVisible } = useFocusRing({ within: true });

  return (
    <Grid
      as='label'
      gridTemplateColumns='auto 1fr'
      alignItems='center'
      justifyContent='start'
      gap='space-0.75'
      {...hoverProps}
      {...(variant === 'boxed' ? {
        border: 'greyLine',
        borderRadius: 'small',
        padding: 'space-0.75',
        paddingBottom: 'space-1.25',
      } : {})}
    >
      <Box display='flex' {...focusProps} className={isFocusVisible ? focusRing : undefined}>
        <span {...forwardClickProps} style={{ height: '20px' }}>
          <RadioIcon
            state={isSelected ? 'selected' : 'deselected'}
            hovered={isHovered}
            disabled={isDisabled}
            aria-hidden
          />
        </span>
        {/* The visually hidden input element will still capture all of the normal
          events, like focus as well as forwarded click events from an
          associated label */}
        <VisuallyHidden>
          <input
            type='radio'
            ref={inputRef}
            {...inputProps}
          />
        </VisuallyHidden>
      </Box>
      <div>
        {childrenAreLiteral(props.label) ? <Text>{props.label}</Text> : props.label}
      </div>
      {props.description && (
        <InputDescription gridColumnStart='2'>
          {props.description}
        </InputDescription>
      )}
    </Grid>
  );
};


const RadioOutlineVariants = {
  deselected: {
    stroke: vars.color.foreground.greyLine,
    fill: undefined,
  },
  deselectedHovered: {
    stroke: vars.color.foreground.black,
    fill: undefined,
  },
  selected: {
    stroke: vars.color.foreground.greyLine,
    fill: undefined,
  },
  selectedHovered: {
    stroke: vars.color.foreground.black,
    fill: undefined,
  },
  disabled: {
    stroke: undefined,
    fill: vars.color.foreground.greyLine,
  },
  disabledSelected: {
    stroke: undefined,
    fill: vars.color.foreground.greyLine,
  },
};

const RadioFillingVariants = {
  deselected: {
    scale: 0,
    opacity: 0,
  },
  deselectedHovered: {
    scale: 0,
    opacity: 0,
  },
  selected: {
    scale: 1,
    opacity: 1,
  },
  selectedHovered: {
    scale: 1,
    opacity: 1,
  },
  disabled: {
    scale: 0,
    opacity: 0,
  },
  disabledSelected: {
    scale: 1,
    opacity: 1,
  },
};

export const RadioIcon: React.FC<React.SVGAttributes<SVGElement> & {
  state?: 'deselected' | 'selected',
  hovered?: boolean,
  error?: boolean,
  disabled?: boolean,
}> = (props) => {
  const {
    height = 20,
    width = 20,
    state = 'deselected',
    hovered = false,
    disabled = false,
    ...rest
  } = props;
  const derivedState: keyof typeof RadioFillingVariants & keyof typeof RadioOutlineVariants = (() => {
    if (disabled) {
      return state === 'selected' ? 'disabledSelected' : 'disabled';
    }
    if (state === 'selected') {
      if (hovered) return 'selectedHovered';
      return 'selected';
    }
    if (hovered) return 'deselectedHovered';
    return 'deselected';
  })();

  return (
    <svg
      height={height}
      width={width}
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g fill='none' fillRule='evenodd'>
        <rect
          fill='#fff'
          stroke='#dbdbdb'
          x='.5'
          y='.5'
          width='19'
          height='19'
          rx='9.5'
          strokeWidth={1}
          style={{
            fill: RadioOutlineVariants[derivedState].fill,
            stroke: RadioOutlineVariants[derivedState].stroke,
            transition: 'stroke 0.3s',
          }}
        />
        <rect
          fill='#111'
          x='4'
          y='4'
          width='12'
          height='12'
          rx='6'
          style={{
            transform: `scale(${RadioFillingVariants[derivedState].scale})`,
            transformOrigin: '50%',
            opacity: RadioFillingVariants[derivedState].opacity,
            transition: 'transform 0.3s, opacity 0.3s',
          }}
        />
      </g>
    </svg>
  );
};

export default Object.assign(RadioGroup, {
  Item: RadioItem,
});
