import { useContext } from 'react';

import { NextLocationContext } from '../contexts/nextRouter';

export interface NextLocation {
  pathname: string;
  search: string;
  hash: string;
}

const useNextLocation = (): NextLocation & { key?: string } => {
  const location = useContext(NextLocationContext);
  if (location === null) {
    throw new Error('useNextLocation must be used inside of NextRouterProvider');
  }
  return location;
};

export default useNextLocation;
