import isURL from 'validator/lib/isURL';
import * as yup from 'yup';

import { inceptionMethods, isNonEmptyString } from '@mablemarket/common-lib';
import { postalCodeRegex } from '@mablemarket/mable-lib/build/helpers/AddressHelpers';

// These need to be in sync with `storeCategoryToRecommendedCategorySlugs` on the buyer dashboard
export const storeCategories = [
  'Air Travel Convenience',
  'Bakery/Cafe/Coffee Shop',
  'Beer & Wine Shop',
  'Co-op',
  'Farm Stand',
  'Foodservice',
  'General Store',
  'Gift Shop',
  'Grocery Store',
  'Ground Travel Convenience',
  'Health | Fitness | Recreational',
  'Hospitality | Lodging',
  'Market',
  'Pet Store',
  'Specialty Food Store',
  'Specialty Online Reseller',
  'Restaurant',
  'University/Education',
  'Other Reseller',
];

export const locationsRanges = [
  '1',
  '2-4',
  '5-9',
  '10-49',
  '50-99',
  '100+',
  'none',
];

export const locationsRangesOptions = locationsRanges.map(r => (
  r === 'none'
    ? { value: r, label: 'None (i.e. I sell online only)' }
    : { value: r, label: r }
));

export const urlSchema = yup.string().trim().test(
  'is-url',
  'Must be a valid url',
  (value?: string) => !!value && isURL(value),
);

export const signUpSchema = (options: {
  externalCustomerNumberRequired?: boolean;
  externalCustomerNumberCollected?: boolean;
  confirmReseller?: boolean;
  websiteRequired?: boolean;
  inceptionMethodRequired?: boolean;
}) => {
  const {
    externalCustomerNumberRequired = false,
    externalCustomerNumberCollected = false,
    confirmReseller = false,
    websiteRequired = true,
    inceptionMethodRequired = false,
  } = options;

  let externalCustomerNumber = externalCustomerNumberRequired
    ? yup.string().required('Required')
    : yup.string();
  let noCustomerNumber = yup.boolean();

  if (externalCustomerNumberCollected && !externalCustomerNumberRequired) {
    externalCustomerNumber = yup.string()
      .when('noCustomerNumber', {
        is: (noCustomerNumber: boolean) => noCustomerNumber,
        otherwise: schema => schema.required('Provide customer number or check the box below'),
      });
    noCustomerNumber = yup.boolean()
      .when('externalCustomerNumber', {
        is: (externalCustomerNumber: string) => isNonEmptyString(externalCustomerNumber),
        otherwise: schema => schema.oneOf([true], 'Enter customer number or check this box'),
      });
  }
  return yup.object().shape({
    credentials: yup.object().shape({
      email: yup.string().trim().required('Required').email('Must be a valid email'),
      password: yup.string().required('Required').min(5, 'Must be at least 5 characters long'),
    }),
    contact: yup.object().shape({
      firstName: yup.string().required('Required'),
      lastName: yup.string().required('Required'),
      phone: yup.string().required('Required').test(
        'is-us-phone',
        'Please enter a valid phone number',
        (value?: string) => {
          if (!value) {
            return false;
          }
          return /^1?[0-9]{10}$/.test(value.replace(/\D/g, ''));
        },
      ),
    }),
    websiteUrl: websiteRequired ? urlSchema.required('Required') : yup.string(),
    businessName: yup.string().required('Required'),
    referralCode: yup.string(),
    storeCategory: yup.string().required('Required').oneOf(storeCategories, 'Required'),
    locationsRange: yup.string().required('Required').oneOf(locationsRanges, 'Required'),
    customerNumberInfo: yup.object().shape({
      externalCustomerNumber,
      noCustomerNumber,
    }, [['externalCustomerNumber', 'noCustomerNumber']]),
    postalCode: yup.string().required('Required').matches(postalCodeRegex, 'Must be a valid US zipcode'),
    ...(confirmReseller ? {
      confirmReseller: yup.boolean().oneOf([true], 'Required'),
      ein: yup.string().matches(/^\d{9}$/g, 'Must be 9 digits long'),
    } : {}),
    ...(inceptionMethodRequired ? {
      inceptionMethod: yup.string().required('Required').oneOf(Object.keys(inceptionMethods), 'Required'),
    } : {}),
  });
};

const defaultSchema = signUpSchema({});
export type SignUpValues = yup.InferType<typeof defaultSchema>;


export function initialValues(defaults: {
  email?: string;
  initialReferralCode?: string;
  externalCustomerNumber?: string;
  inceptionMethod?: string;
}) {
  return {
    credentials: {
      email: defaults.email ?? '',
      password: '',
    },
    contact: {
      firstName: '',
      lastName: '',
      phone: '',
    },
    websiteUrl: '',
    businessName: '',
    referralCode: defaults.initialReferralCode ?? '',
    storeCategory: '',
    locationsRange: '',
    customerNumberInfo: {
      externalCustomerNumber: defaults.externalCustomerNumber ?? '',
      noCustomerNumber: false,
    },
    confirmReseller: false,
    ein: '',
    // We need some entry here for inceptionMethod so it will be validated
    // without having interacted with it, but it is also an enum in the API
    // so we need to give it this specific typing. Later we remove it from the
    // call body if it is falsy (the empty string) leaving only the valid strings
    inceptionMethod: (defaults.inceptionMethod ?? '') as (keyof typeof inceptionMethods) | '',
  };
}
