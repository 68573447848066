import React from 'react';

import Box from '../Box';

import useIconProps, { IconDefaultProps } from './useIconProps';

export const IconSortable = (props: IconDefaultProps) => {
  const { height = 12, width = 8, ...rest } = props;
  const iconProps = useIconProps({ ...rest, height });
  return (
    <Box
      as='svg'
      width={width}
      height={height}
      viewBox='0 0 8 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
      {...iconProps}
    >
      <path fillRule='evenodd' clipRule='evenodd' d='M3.75 0L7.5 5H0L3.75 0Z' fill='currentColor' />
      <path fillRule='evenodd' clipRule='evenodd' d='M3.75 12L0 7L7.5 7L3.75 12Z' fill='currentColor' />
    </Box>
  );
};
