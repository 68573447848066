var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { calc } from '@vanilla-extract/css-utils';
import { capHeightVar } from '../../styles/sprinkles.css';
var useIconProps = function (props) {
    var _a;
    var height = typeof props.height === 'number' ? "".concat(props.height, "px") : props.height;
    var marginVert = (props.textCenter && height !== undefined) ? calc.multiply(calc.subtract(capHeightVar, height), 0.5) : undefined;
    var style = marginVert === undefined ? props.style : __assign(__assign({}, ((_a = props.style) !== null && _a !== void 0 ? _a : {})), { marginBottom: marginVert, marginTop: marginVert });
    return { style: style };
};
export default useIconProps;
