var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMutation as useRQMutation } from '@tanstack/react-query';
import { useField } from 'formik';
import last from 'lodash/last';
import { useState } from 'react';
import { ensureMableError, MableError } from '@mablemarket/common-lib';
import { useMutation } from './useQuery';
export var useResaleCertificateUpload = function (_a) {
    var buyerIdOrMe = _a.buyerIdOrMe, fieldName = _a.fieldName, onUploadError = _a.onUploadError;
    // We don't return uploaded files names in the GET, but we do have it
    // available during upload to display in the pill
    var _b = useState('Resale Certificate'), fileName = _b[0], setFileName = _b[1];
    var signedUrlMutation = useMutation('POST /v1/buyers/{buyerIdOrMe}/certificateSignedUrl');
    var uploadMutation = useRQMutation({
        mutationFn: function (_a) {
            var signedUrl = _a.signedUrl, file = _a.file;
            return (fetch(signedUrl, { method: 'PUT', body: file }));
        },
    });
    var _c = useField(fieldName), fieldHelpers = _c[2];
    var _d = useState({
        showSuccess: false,
        timeout: undefined,
    }), _e = _d[0], showSuccess = _e.showSuccess, timeout = _e.timeout, setShowSuccess = _d[1];
    var handleUpload = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var file, extension, _a, signedUrl, s3Key, uploadRes, uploadError, error_1, err;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    file = (_b = event.target.files) === null || _b === void 0 ? void 0 : _b[0];
                    fieldHelpers.setTouched(true, false);
                    fieldHelpers.setValue('', false);
                    fieldHelpers.setError(undefined);
                    window.clearTimeout(timeout);
                    setShowSuccess({ showSuccess: false, timeout: undefined });
                    setFileName((file === null || file === void 0 ? void 0 : file.name) || 'Resale Certificate');
                    if (!(file instanceof File)) {
                        fieldHelpers.setError('Required');
                        return [2 /*return*/];
                    }
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 4, , 5]);
                    extension = (_c = last(file.name.toLowerCase().split('.'))) !== null && _c !== void 0 ? _c : 'pdf';
                    return [4 /*yield*/, signedUrlMutation.mutateAsync({
                            buyerIdOrMe: "".concat(buyerIdOrMe),
                            params: {
                                extension: extension,
                            },
                        })];
                case 2:
                    _a = _d.sent(), signedUrl = _a.signedUrl, s3Key = _a.s3Key;
                    if (!signedUrl) {
                        throw new MableError({
                            code: 'NoSignedUrl',
                            message: 'no signed url returned',
                            data: undefined,
                        });
                    }
                    if (!s3Key) {
                        throw new MableError({
                            code: 'NoS3key',
                            message: 'no s3 key returned',
                            data: undefined,
                        });
                    }
                    return [4 /*yield*/, uploadMutation.mutateAsync({
                            file: file,
                            signedUrl: signedUrl,
                        })];
                case 3:
                    uploadRes = _d.sent();
                    if (!uploadRes.ok) {
                        uploadError = new MableError({
                            code: 'AmazonS3CertificateUploadFailed',
                            message: uploadRes.statusText,
                            status: uploadRes.status,
                            data: undefined,
                        });
                        onUploadError(uploadError);
                        throw uploadError;
                    }
                    fieldHelpers.setValue(s3Key, true);
                    fieldHelpers.setError(undefined);
                    setShowSuccess({
                        showSuccess: true,
                        timeout: window.setTimeout(function () { return setShowSuccess({ showSuccess: false, timeout: undefined }); }, 3000),
                    });
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _d.sent();
                    err = ensureMableError(error_1, {
                        // FIXME: `defaults` doesn't work, it won't set displayMessage
                        overrides: {
                            displayMessage: (error_1 instanceof MableError && error_1.displayMessage) ||
                                'An error occured when uploading your resale certificate, please try again',
                        },
                    });
                    fieldHelpers.setError(err.displayMessage);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return {
        fileName: fileName,
        handleUpload: handleUpload,
        showSuccess: showSuccess,
        isLoading: signedUrlMutation.isLoading || uploadMutation.isLoading,
    };
};
export default useResaleCertificateUpload;
