export * from './MableError';
export * from './MableServerResponseError';
export * from './Formatters';
export * from './util';
export * from './const';
export * from './Codecs';
export * from './AnyCodecs';
export * from './csv';
export * from './honeycomb';
export * from './guards';
export * from './types';
export * from './barcodeUtils';

export const inceptionMethods = {
  sample: 'Free sample invitation',
  vendor: 'One of my vendors referred me',
  searchEngine: 'Google or other search engines',
  socialMedia: 'Facebook, Instagram, or other social media',
  sales: 'A member of the Mable sales team',
  wordOfMouth: 'Word of mouth',
  other: 'Other',
};
