import { useMemo, useRef } from 'react';

/** Creates a ref that holds any HTMLElement. This is different from
 *  useRef<HTMLElement> as the ref prop on elements requires the given
 *  React.RefObject to be of the type of element. Passing a
 *  React.RefObject<HTMLElement> will fail as it lacks properties of that
 *  element.
 *
 *  This hook returns a ref object that also contains a `.assign` method, which
 *  contains a ref callback function that will assign the `.current` property of
 *  the ref. This can be passed to the `ref` prop of any element.
 *
 *  `ref` props accept a callback ref that takes an argument of the
 *  element or null, which will succeed with `HTMLElement` as it matches a
 *  subset of the given element.
*/
const useHTMLElementRef = (): React.RefObject<HTMLElement> & { assign: React.RefCallback<HTMLElement> } => {
  const ref = useRef<HTMLElement | null>(null);
  const htmlRef = useMemo(() => ({
    ...ref,
    assign: (elem: HTMLElement | null) => {
      htmlRef.current = elem;
    },
  }), [ref]);
  return htmlRef;
};

export default useHTMLElementRef;
