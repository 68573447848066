var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Box from '../Box';
import useIconProps from './useIconProps';
export var IconSortable = function (props) {
    var _a = props.height, height = _a === void 0 ? 12 : _a, _b = props.width, width = _b === void 0 ? 8 : _b, rest = __rest(props, ["height", "width"]);
    var iconProps = useIconProps(__assign(__assign({}, rest), { height: height }));
    return (<Box as='svg' width={width} height={height} viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest} {...iconProps}>
      <path fillRule='evenodd' clipRule='evenodd' d='M3.75 0L7.5 5H0L3.75 0Z' fill='currentColor'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M3.75 12L0 7L7.5 7L3.75 12Z' fill='currentColor'/>
    </Box>);
};
