import { useContext } from 'react';

import { IsHydratedContext } from '../contexts/nextRouter';

const useIsHydrated = (): boolean => {
  const isHydrated = useContext(IsHydratedContext);
  if (isHydrated === null) {
    throw new Error('useIsHydrated must be used inside of NextRouterProvider');
  }
  return isHydrated;
};

export default useIsHydrated;
