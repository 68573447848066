var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import useNextLocation from '../hooks/useNextLocation';
import { matchPath } from '../hooks/useRouteMatch';
// Switch will inject the computedMatch prop so we don't have to recalculate,
// but normal callers should not be able to set it
var Route = function (props) {
    var location = useNextLocation();
    var children = props.children, component = props.component, _a = props.computedMatch, computedMatch = _a === void 0 ? false : _a;
    var match = computedMatch
        || matchPath(location.pathname, __assign(__assign({}, props), { path: props.path || '/', location: location }));
    if (match) {
        if (children) {
            return (<>
          {children}
          {' '}
        </>);
        }
        if (component) {
            return React.createElement(component);
        }
    }
    return null;
};
export default Route;
