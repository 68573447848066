import '../sass/Bootstrap.scss';
import '../global.css';
import '../sass/index.scss';
import '@mablemarket/common-react-lib/build/styles/base.scss';
import React, { useContext, useMemo, useState } from 'react';
import useIsHydrated from '@mablemarket/common-react-lib/build/modules/NextRouter/hooks/useIsHydrated';
import { NextRouterProvider } from '@mablemarket/common-react-lib/build/modules/NextRouter/contexts/nextRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuth0 } from "../Auth0Provider";
import { Auth0Provider } from "../Auth0Provider";
import { QueryParamProvider } from 'use-query-params';

// Pages
import Loading from "../components/Loading";

// Components
import Sidebar from '../components/Sidebar';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getClient } from '../request';
import { ApiClient } from '@mablemarket/core-api-client';
import { Box, PageContainer } from '@mablemarket/common-react-lib/build/components';
import { AppProps } from 'next/dist/shared/lib/router/router';
import { useRouter } from 'next/router';
import useNextLocation from '@mablemarket/common-react-lib/build/modules/NextRouter/hooks/useNextLocation';

import styles from "../App.module.scss";
import { ApiClientProvider, useApiClient } from '@mablemarket/common-react-lib';
import { ComponentWithLayout } from '../helpers/withLayout';
import { renderAdminLayout } from '../helpers/withAdminLayout';
import { ensureMableError } from '@mablemarket/common-lib';

import { Modal } from "react-bootstrap";

// Monkeypatch default props to make sure modals are styled with bootstrap even
// on non-bootstrap pages
if (typeof window !== 'undefined') {
  const defaultProps = Modal.defaultProps ?? {};
  defaultProps.container = document.querySelector('#bootstrap-portal-root');
  Modal.defaultProps = defaultProps;
}

export type AppContextType = {
  client: ApiClient
}
export const AppContext = React.createContext<AppContextType | null>(null);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, err) => {
        const error = ensureMableError(err);
        if (count > 3) return false;
        if (error.status !== undefined && [502, 503, 504].includes(error.status)) return true;
        // client side timeout (error thrown in api-client-support). Duration set in auth0 context.
        return error.name === 'Timeout';
      },
      // This may cause very aggressive refetching, especially during development.
      // Disabling it for now
      refetchOnWindowFocus: false,
      // ignore browser reported network status and always fire requests to work
      // around mac chromium bug
      // https://meetmable.slack.com/archives/CMB0ZRL4Q/p1676999230132349
      networkMode: 'always',
    },
  },
});

type MableApp = React.FC<Omit<AppProps, 'Component'> & { Component: ComponentWithLayout }>;

const App: MableApp = (props) => {
  const { Component, pageProps } = props;
  const layout = Component.pageLayout || renderAdminLayout;
  const Page = layout(<Component {...pageProps} />);

  const { loading } = useAuth0();
  const isHydrated = useIsHydrated();
  const router = useRouter();
  const location = useNextLocation();
  const history = {
    push: ({ search }: Location) => {
      return router.push({ search, pathname: location.pathname });
    },
    replace: ({ search }: Location) => {
      return router.replace({ search, pathname: location.pathname });
    },
  };
  const client = useApiClient();

  if (loading || !isHydrated /* There is a lot of legacy code that assumes window will be defined */) {
    return <Loading />;
  }

  return (
    <QueryParamProvider history={history} location={location as Location}>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={{ client }}>
          <Box as='main' background='white'>
            <SidebarProvider>
              <PageContainer>
                <ToggleSidebar />
              </PageContainer>
              <div className={styles.wrapper}>
                <WrappedSidebar />
                {Page}
              </div>
              <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                hideProgressBar={true}
                style={{ width: 'auto', maxWidth: '500px', minWidth: '320px'}}
              />
            </SidebarProvider>
          </Box>
        </AppContext.Provider>
      </QueryClientProvider >
    </QueryParamProvider >
  );
}

const RouterWrapped = (props: AppProps) => {
  const client = useMemo(() => getClient(), []);
  return (
    <ApiClientProvider value={client}>
      <NextRouterProvider>
        <App {...props} />
      </NextRouterProvider>
    </ApiClientProvider>
  );
};

const AuthWrapped = (props: AppProps) => {
  return (
    <Auth0Provider>
      <RouterWrapped {...props} />
    </Auth0Provider>
  );
};

const SidebarContext = React.createContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>({
  isOpen: false,
  setIsOpen: () => null,
});
const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <SidebarContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

const ToggleSidebar = () => {
  const { setIsOpen, isOpen } = useContext(SidebarContext);
  return (
    <Box as='button' display={{ desktop: 'none', tablet: 'block' }} onClick={() => setIsOpen(!isOpen)}>
      nav
    </Box>
  )
};

const WrappedSidebar = () => {
  const { isOpen, setIsOpen } = useContext(SidebarContext);
  return (
    <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
  );
};

export default AuthWrapped;
