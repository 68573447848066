import React, { useEffect, useRef } from 'react';
import { AriaButtonProps, FocusRing, PressHookProps, useButton } from 'react-aria';

import { focusRing } from '../../styles/sprinkles.css';
import { composeRefs } from '../../util';
import { cn } from '../../webutils/webutils';
import Box, { BoxOwnProps } from '../Box';

interface UnstyledButtonOwnProps extends BoxOwnProps, Omit<PressHookProps, 'isDisabled' | 'ref'> {
}

export interface UnstyledButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'>, UnstyledButtonOwnProps {
}

const UnstyledButton = React.forwardRef((props: UnstyledButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const { children, disabled, ...rest } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton({
    // Need to cast because react aria expects event handlers for HTMLElement,
    // which conflict with ours for HTMLButtonElement. The types are compatible,
    // but typescript isn't smart enough to know that.
    ...rest as AriaButtonProps,
    isDisabled: disabled,
  }, buttonRef);

  // Workaround for
  // https://github.com/facebook/react/issues/9809
  // https://github.com/adobe/react-spectrum/issues/1513
  const hasOnClickHandler = !!props.onClick;
  useEffect(() => {
    const button = buttonRef.current;
    const preventDefault = (e: TouchEvent) => {
      // Disabling touchend breaks form submission on mobile
      if (
        (e.currentTarget as HTMLElement)?.getAttribute('type') === 'submit'
        || (e.currentTarget as HTMLElement)?.getAttribute('type') === 'reset'
      ) {
        return;
      }
      e.preventDefault();
    };
    if (!hasOnClickHandler) {
      button?.addEventListener('touchend', preventDefault, { passive: false });
    }
    return () => button?.removeEventListener('touchend', preventDefault);
  }, [hasOnClickHandler]);

  return (
    <FocusRing focusRingClass={focusRing}>
      <Box
        as='button'
        type='button'
        {...rest}
        {...buttonProps}
        ref={composeRefs(buttonRef, ref)}
        className={cn(rest.className)}
      >
        {children}
      </Box>
    </FocusRing>
  );
});

export default UnstyledButton;
