import Hashids from 'hashids';

import { ExcludeUnexpected } from '@mablemarket/common-lib';
import { ManualTaskType } from '@mablemarket/core-api-client';

export function nullOutBlankString(string?: string) {
  if (string === undefined) {
    return string;
  }

  if (string === null) {
    return null;
  }

  if (string.trim.length === 0) {
    return null;
  }

  return string;
}

export function undefinedForNullAndBlankString(string?: string) {
  if (!string) {
    return undefined;
  }

  if (string && string.trim().length === 0) {
    return undefined;
  }

  return string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeBlankStringsForKeys<T extends Record<string, any>>(obj: T, keys: Array<keyof T>): T {
  const retVal = { ...obj };
  keys.forEach((k) => {
    const v = retVal[k];
    if (typeof v === 'string' && v.trim().length === 0) { delete retVal[k]; }
    if (!v) { delete retVal[k]; }
  });
  return retVal;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function trimStringsForKeys<T extends Record<string, any>>(obj: T, keys: Array<keyof T>): T {
  const retVal = { ...obj };
  keys.forEach((k) => {
    const v = retVal[k];
    if (typeof v === 'string') { retVal[k] = retVal[k].trim(); }
  });
  return retVal;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeNullsFromObject<T extends Record<string, any>>(obj: T) {
  const result = { ...obj };
  Object.getOwnPropertyNames(obj).forEach((key) => {
    if (result[key] === null) {
      delete result[key];
    }
  });
  return result;
}

// This isn't a secure hash, and the salt should not need to be changed or rotated ever
const mableSkuHasher = new Hashids('MABLESKUSALT', 5, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890');

export const getMableSku = ({ sellerId, variantId }: { sellerId: number, variantId: number }) => `${sellerId}-${mableSkuHasher.encode(variantId)}`;

export const taskTypeToEmoji: Record<ExcludeUnexpected<ManualTaskType>, string> = {
  buyerCoversShippingCheck: '📦☑',
  buyerInMultiplePricingTiersPerSeller: '📈',
  buyerRequestedOrderGuideUpdate: '📨',
  buyerRequestedSample: '🆓',
  deletedUser: '🫥',
  ediCreditRequested: '💵',
  ediFileProcessingError: ':edi:🚨',
  ediNegativeFunctionalAckReceived: '␆❌',
  futureLandedCostsUpdated: '📅💰',
  highPurchaseTotalPartnerships: '👫💰',
  inconsistentlyDeletedVariants: '🛍⚠️',
  missingFreeShippingMin: '🚚',
  missingNACSCategoryInPartnerAPL: '🗂',
  noExternalLocationInformationForPurchaseOrder: '🤷🏪',
  noNewOrders: '🚨',
  nonReducingPriceReplacements: '💲⬆',
  oldAuthorizedPayments: '👴💰',
  oldPausedFulfillmentRoutingOrders: '👴🧾',
  oneWorldSyncProblem: '🌎🔄',
  orphanedProductVariantIdentifiers: '👶',
  parsingErrorForX12FunctionalAck: '␆🚨',
  partnerAccountConfiguration: '🤝',
  partnerAPLIncludesBuyerAPLVariants: '🖇️',
  partneredSellersMissingPartneredProducts: '🕴️',
  partnerPriceReview: '🫰',
  partnerPricingMismatch: '💱',
  personalizedPricingCheck: '🏷️',
  processExternalLocationInformationForPurchaseOrder: '🔎🏪',
  productAuthorizationRequestFailure: '✍️',
  retailUnitNeedsUsFoodsImageClassification: '📷',
  sellerMissingNavigableCategory: '😿',
  sellerPartnerProgramApplicationNeedsReview: '🤠',
  sellerShippingCharges: '🧾',
  stuckEdiFileUploads: '🛑',
  transactionAdjustmentRequest: '📝',
  unbalancedCompleteOrders: '⚖️',
  unbalancedSplitGroups: '⚖️',
  undeliverableEmail: '✉️',
  unsentBrandPOs: '📧🥕',
  unsentDistributorPOs: '📧🚚',
  unsentPartnerInvoices: '📧💰',
  unshippedPriorityOrder: '🚩',
  whitelistCheckForDistributorBuyerRelationships: '☑️🚚',
};

/**
 * Duplicated from the partner_account table, use getPartnerAccount instead
 */
export function getNicePartnerName(partnerAccountName?: string) {
  if (partnerAccountName === 'mable') return 'C-Store Ready';
  if (partnerAccountName === 'mclane') return 'McLane';
  if (partnerAccountName === 'sodexo') return 'Sodexo';
  if (partnerAccountName === 'ultimateSales') return 'Ultimate Sales';
  if (partnerAccountName === 'usfoods') return 'US Foods';
  if (partnerAccountName === 'kehe') return 'KeHE';
  if (partnerAccountName === 'kehe-demo') return 'KeHE (Demo)';
  return partnerAccountName;
}
