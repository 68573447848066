var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useMemo, useRef } from 'react';
import { mergeProps, useFocusRing, useHover, useId } from 'react-aria';
import { notUndefined } from '@mablemarket/common-lib';
import { focusRing, resets, sprinkles } from '../../styles/sprinkles.css';
import { composeRefs } from '../../util';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import { cn } from '../../webutils/webutils';
import Box from '../Box';
import Grid from '../Grid';
import Text from '../Text';
import * as styles from './TextInput.css';
var InputLabelContext = React.createContext(null);
export var InputLabelProvider = function (props) {
    var value = useInputLabelContext(props);
    return (<InputLabelContext.Provider value={value}>
      {props.children}
    </InputLabelContext.Provider>);
};
export var useInputLabelContext = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var contextValue = useContext(InputLabelContext);
    var internalInputId = useId();
    var internalDescriptionId = useId();
    var internalErrorId = useId();
    var inputId = (_b = (_a = props.inputId) !== null && _a !== void 0 ? _a : contextValue === null || contextValue === void 0 ? void 0 : contextValue.inputId) !== null && _b !== void 0 ? _b : internalInputId;
    var descriptionId = (_d = (_c = props.descriptionId) !== null && _c !== void 0 ? _c : contextValue === null || contextValue === void 0 ? void 0 : contextValue.descriptionId) !== null && _d !== void 0 ? _d : internalDescriptionId;
    var errorId = (_f = (_e = props.errorId) !== null && _e !== void 0 ? _e : contextValue === null || contextValue === void 0 ? void 0 : contextValue.errorId) !== null && _f !== void 0 ? _f : internalErrorId;
    var value = useMemo(function () { return ({ inputId: inputId, descriptionId: descriptionId, errorId: errorId }); }, [inputId, descriptionId, errorId]);
    return value;
};
var TextInput = React.forwardRef(function (props, ref) {
    var className = props.className, style = props.style, flexBasis = props.flexBasis, flexGrow = props.flexGrow, flexShrink = props.flexShrink, gridArea = props.gridArea, gridColumnEnd = props.gridColumnEnd, gridColumnStart = props.gridColumnStart, gridRowEnd = props.gridRowEnd, gridRowStart = props.gridRowStart, textAlign = props.textAlign, outerRef = props.outerRef, left = props.left, right = props.right, label = props.label, description = props.description, error = props.error, condensed = props.condensed, rest = __rest(props, ["className", "style", "flexBasis", "flexGrow", "flexShrink", "gridArea", "gridColumnEnd", "gridColumnStart", "gridRowEnd", "gridRowStart", "textAlign", "outerRef", "left", "right", "label", "description", "error", "condensed"]);
    var internalInputRef = useRef(null);
    var _a = useTextInputStyles({
        disabled: rest.disabled,
        error: !!error,
        left: !!left,
        right: !!right,
    }), containerProps = _a.containerProps, inputProps = _a.inputProps;
    var _b = useInputLabelContext({
        inputId: props.id,
    }), inputId = _b.inputId, descriptionId = _b.descriptionId, errorId = _b.errorId;
    return (<InputLabelProvider inputId={inputId} descriptionId={descriptionId} errorId={errorId}>
      <Grid gridAutoFlow='row' gap={condensed ? 'space-0.25' : 'space-0.75'} ref={outerRef} style={style} className={className} flexBasis={flexBasis} flexGrow={flexGrow} flexShrink={flexShrink} gridArea={gridArea} gridColumnEnd={gridColumnEnd} gridColumnStart={gridColumnStart} gridRowEnd={gridRowEnd} gridRowStart={gridRowStart}>
        {label && (<InputLabel>{label}</InputLabel>)}
        {description && (<InputDescription>{description}</InputDescription>)}
        <Box {...containerProps}>
          {left ? <Box flexShrink={0} display='flex' paddingLeft='space-0.75'>{left}</Box> : null}
          <input id={inputId} ref={composeRefs(ref, internalInputRef)} aria-invalid={!!error} aria-describedby={[
            description ? descriptionId : undefined,
            error ? errorId : undefined,
        ].filter(notUndefined).join(' ')} {...mergeProps(inputProps, rest, {
        className: cn(resets.input, styles.input, sprinkles.atoms({ textAlign: textAlign })),
    })}/>
          {right ? <Box flexShrink={0} display='flex' paddingRight='space-0.75'>{right}</Box> : null}
        </Box>
        {error && error !== true && <InputErrorMessage>{error}</InputErrorMessage>}
      </Grid>
    </InputLabelProvider>);
});
export var InputLabel = function (props) {
    var inputId = useInputLabelContext({}).inputId;
    return (childrenAreLiteral(props.children)
        ? <Text as='label' htmlFor={inputId} {...props}>{props.children}</Text>
        : <Box as='label' htmlFor={inputId} {...props}>{props.children}</Box>);
};
export var InputDescription = function (props) {
    var descriptionId = useInputLabelContext({ descriptionId: props.id }).descriptionId;
    return childrenAreLiteral(props.children)
        ? <Text id={descriptionId} {...props} fontSize='small' foreground='greyDark'>{props.children}</Text>
        : <Box id={descriptionId} {...props} fontSize='small' foreground='greyDark'>{props.children}</Box>;
};
export var InputErrorMessage = function (props) {
    var errorId = useInputLabelContext({ errorId: props.id }).errorId;
    return childrenAreLiteral(props.children)
        ? <Text id={errorId} {...props} fontSize='small' foreground='redError' whiteSpace='pre-line'>{props.children}</Text>
        : <Box id={errorId} {...props} fontSize='small' foreground='redError' whiteSpace='pre-line'>{props.children}</Box>;
};
export var useTextInputStyles = function (props) {
    var disabled = props.disabled, error = props.error, left = props.left, right = props.right;
    var _a = useHover({
        isDisabled: disabled,
    }), hoverProps = _a.hoverProps, isHovered = _a.isHovered;
    var _b = useFocusRing({
        isTextInput: true,
        within: true,
    }), focusProps = _b.focusProps, isFocused = _b.isFocused, isFocusVisible = _b.isFocusVisible;
    return {
        containerProps: mergeProps(hoverProps, {
            className: cn(styles.container, !isHovered && !isFocused && !error && styles.base, isHovered && styles.hovered, isFocused && styles.focused, error && styles.invalid, disabled && styles.disabled, isFocusVisible && focusRing),
        }),
        inputProps: mergeProps(focusProps, {
            className: cn(styles.input, left ? styles.withLeft : styles.withoutLeft, right ? styles.withRight : styles.withoutRight),
        }),
    };
};
export default TextInput;
