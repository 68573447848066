import React from 'react';
import { mergeProps } from 'react-aria';

import { notUndefined } from '@mablemarket/common-lib';

import { resets } from '../../styles/sprinkles.css';
import Box, { BoxOwnProps, FlexAndGridChildProps } from '../Box';
import Grid from '../Grid';
import { InputDescription, InputErrorMessage, InputLabel, InputLabelProvider, useInputLabelContext, useTextInputStyles } from '../TextInput';

export interface TextAreaProps extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'className'>,
  FlexAndGridChildProps,
  Pick<BoxOwnProps, 'className' | 'textAlign'> {
  label?: React.ReactNode;
  description?: React.ReactNode;
  error?: boolean | React.ReactNode;
  outerRef?: React.Ref<HTMLDivElement>;
}

const TextArea = React.forwardRef((props: TextAreaProps, ref: React.ForwardedRef<HTMLTextAreaElement>) => {
  const {
    className,
    label,
    description,
    error,
    style,
    textAlign,
    flexBasis,
    flexGrow,
    flexShrink,
    gridArea,
    gridColumnEnd,
    gridColumnStart,
    gridRowEnd,
    gridRowStart,
    outerRef,
    ...rest
  } = props;
  const { containerProps, inputProps } = useTextInputStyles({
    disabled: rest.disabled,
    error: !!error,
    left: false,
    right: false,
  });

  const { inputId, descriptionId, errorId } = useInputLabelContext({
    inputId: props.id,
  });

  return (
    <InputLabelProvider inputId={inputId} descriptionId={descriptionId} errorId={errorId}>
      <Grid
        gridAutoFlow='row'
        gap='space-0.75'
        ref={outerRef}
        style={style}
        className={className}
        flexBasis={flexBasis}
        flexGrow={flexGrow}
        flexShrink={flexShrink}
        gridArea={gridArea}
        gridColumnEnd={gridColumnEnd}
        gridColumnStart={gridColumnStart}
        gridRowEnd={gridRowEnd}
        gridRowStart={gridRowStart}
        textAlign={textAlign}
      >
        {label && (<InputLabel>{label}</InputLabel>)}
        {description && (<InputDescription>{description}</InputDescription>)}
        <Box
          as='textarea'
          id={inputId}
          aria-invalid={!!error}
          aria-describedby={[
            description ? descriptionId : undefined,
            error ? errorId : undefined,
          ].filter(notUndefined).join(' ')}
          {...mergeProps(containerProps, inputProps, rest, {
            className: resets.input,
          })}
          ref={ref}
        />
        {error && error !== true && <InputErrorMessage>{error}</InputErrorMessage>}
      </Grid>
    </InputLabelProvider>
  );
});


export default TextArea;
