var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { calc } from '@vanilla-extract/css-utils';
import mapValues from 'lodash/mapValues';
import './reset.css';
import { fontMetrics, vars } from './theme.css';
var space = __assign(__assign({}, vars.space), { none: 0 });
/** Trick to set multiple `box-shadows` on the element to support both borders and drop shadows
  * We avoid the `border` prop because it adds width/height to the element. An
  * inset box-shadow looks exactly the same, but adds no additional size.
  * However, if we want to set another box-shadow (like a drop shadow) it would
  * override the border. By using css vars we can set them independently and
  * then combine them back into a single property.
  *
  * This also works for user supplied `box-shadow` using an additional var.
  */
export var makeBoxShadowProperties = function (_a) {
    var borderVar = _a.borderVar, borderTopVar = _a.borderTopVar, borderBottomVar = _a.borderBottomVar, borderLeftVar = _a.borderLeftVar, borderRightVar = _a.borderRightVar, shadowVar = _a.shadowVar;
    return {
        // trailing comma is required due to contrusction of multiple variables into the box shadow property
        // This may require a separate set of colors
        borderTop: mapValues(__assign(__assign({}, vars.color.foreground), vars.color.background), function (color) {
            var _a;
            return ({
                vars: (_a = {},
                    _a[borderTopVar] = "inset 0 1px 0 0 ".concat(color, ","),
                    _a[borderVar] = "".concat(borderTopVar, " ").concat(borderBottomVar, " ").concat(borderLeftVar, " ").concat(borderRightVar),
                    _a),
            });
        }),
        borderBottom: mapValues(__assign(__assign({}, vars.color.foreground), vars.color.background), function (color) {
            var _a;
            return ({
                vars: (_a = {},
                    _a[borderBottomVar] = "inset 0 -1px 0 0 ".concat(color, ","),
                    _a[borderVar] = "".concat(borderTopVar, " ").concat(borderBottomVar, " ").concat(borderLeftVar, " ").concat(borderRightVar),
                    _a),
            });
        }),
        borderLeft: mapValues(__assign(__assign({}, vars.color.foreground), vars.color.background), function (color) {
            var _a;
            return ({
                vars: (_a = {},
                    _a[borderLeftVar] = "inset 1px 0 0 0 ".concat(color, ","),
                    _a[borderVar] = "".concat(borderTopVar, " ").concat(borderBottomVar, " ").concat(borderLeftVar, " ").concat(borderRightVar),
                    _a),
            });
        }),
        borderRight: mapValues(__assign(__assign({}, vars.color.foreground), vars.color.background), function (color) {
            var _a;
            return ({
                vars: (_a = {},
                    _a[borderRightVar] = "inset -1px 0 0 0 ".concat(color, ","),
                    _a[borderVar] = "".concat(borderTopVar, " ").concat(borderBottomVar, " ").concat(borderLeftVar, " ").concat(borderRightVar),
                    _a),
            });
        }),
        shadow: mapValues(vars.shadow, function (shadow) {
            var _a;
            return ({ vars: (_a = {}, _a[shadowVar] = "".concat(shadow, ","), _a) });
        }),
    };
};
var calculateCapHeightTrimConstant = function (fm) {
    var ascent = fm.ascent, capHeight = fm.capHeight, lineGap = fm.lineGap, descent = fm.descent, unitsPerEm = fm.unitsPerEm;
    // constant factor in equation to determine excess leading for given font
    // derived from https://github.com/seek-oss/capsize/blob/master/packages/core/src/precomputeValues.ts
    // returned without units, but effectively in em's (decimal percentage of font size)
    return (-1 * (((ascent / 2) - capHeight - (lineGap / 2) - (Math.abs(descent) / 2)) / unitsPerEm)).toString();
};
var calculateBaselineTrimConstant = function (fm) {
    var ascent = fm.ascent, descent = fm.descent, unitsPerEm = fm.unitsPerEm;
    // constant factor in equation to determine excess trailing line height for given font
    // derived from https://github.com/seek-oss/capsize/blob/master/packages/core/src/precomputeValues.ts
    // returned without units, but effectively in em's (decimal percentage of font size)
    return (-1 * ((Math.abs(descent) - ascent) / (2 * unitsPerEm))).toString();
};
var calculateFontSizeToCapHeightConstant = function (fm) {
    var capHeight = fm.capHeight, unitsPerEm = fm.unitsPerEm;
    // TODO: Should we round this here?
    return (capHeight / unitsPerEm).toString();
};
/** Enforce type of AtomicProperties but still allow us to get a const type */
var enforceProperties = function (properties) { return properties; };
export var makeUnresponsiveProperties = function (_a) {
    var capHeightTrimConstantVar = _a.capHeightTrimConstantVar, baselineTrimConstantVar = _a.baselineTrimConstantVar, fontSizeToCapHeightConstantVar = _a.fontSizeToCapHeightConstantVar;
    return enforceProperties({
        background: vars.color.background,
        foreground: __assign(__assign({}, mapValues(vars.color.foreground, function (color) { return ({ color: color }); })), { inherit: 'inherit' }),
        overflow: ['hidden', 'scroll', 'visible', 'auto'],
        overflowWrap: ['normal', 'break-word'],
        wordBreak: ['normal', 'break-word'],
        whiteSpace: ['normal', 'nowrap', 'pre-line'],
        userSelect: ['none'],
        zIndex: {
            0: 0,
            1: 1,
            2: 2,
            // TODO: collect and standardize z-index values
            // dropdownBackdrop: 90,
            // dropdown: 100,
            // sticky: 200,
            // modalBackdrop: 290,
            // modal: 300,
            // notification: 400,
        },
        cursor: ['default', 'pointer'],
        pointerEvents: ['none'],
        top: [0],
        bottom: [0],
        left: [0],
        right: [0],
        minWidth: {
            0: '0%',
            '100%': '100%',
        },
        maxWidth: {
            content: vars.contentWidth,
        },
        minHeight: ['0', '100%'],
        boxSizing: ['border-box', 'content-box'],
        fontFamily: mapValues(vars.typography.family, function (fontFamily, key) {
            var _a;
            return ({
                fontFamily: fontFamily,
                vars: (_a = {},
                    _a[capHeightTrimConstantVar] = calculateCapHeightTrimConstant(fontMetrics[key]),
                    _a[baselineTrimConstantVar] = calculateBaselineTrimConstant(fontMetrics[key]),
                    _a[fontSizeToCapHeightConstantVar] = calculateFontSizeToCapHeightConstant(fontMetrics[key]),
                    _a),
            });
        }),
    });
};
var selfPositioning = [
    'auto',
    'start',
    'center',
    'end',
    'stretch',
    'baseline',
    'flex-start',
    'flex-end',
];
export var flexPositioning = __spreadArray(__spreadArray([], selfPositioning, true), [
    'space-between',
    'space-around',
    'space-evenly',
], false);
var flexOnlyProperties = enforceProperties({
    flexWrap: ['wrap', 'nowrap', 'wrap-reverse'],
    flexDirection: ['row', 'row-reverse', 'column', 'column-reverse'],
});
var gridOnlyProperties = enforceProperties({
    gridAutoFlow: ['row', 'column'],
    rowGap: space,
    columnGap: space,
});
var flexGridCommonProperties = {
    alignItems: flexPositioning,
    alignJustify: flexPositioning,
    justifyContent: flexPositioning,
    justifyItems: flexPositioning,
};
export var flexProperties = __spreadArray(__spreadArray(__spreadArray([], Object.keys(flexOnlyProperties), true), Object.keys(flexGridCommonProperties), true), [
    'placeItems',
], false);
export var gridProperties = __spreadArray(__spreadArray([
    'gap',
    'placeItems'
], Object.keys(gridOnlyProperties), true), Object.keys(flexGridCommonProperties), true);
export var makeResponsiveProperties = function (_a) {
    var fontSizeVar = _a.fontSizeVar, lineHeightVar = _a.lineHeightVar, capHeightVar = _a.capHeightVar, fontSizeToCapHeightConstantVar = _a.fontSizeToCapHeightConstantVar;
    return enforceProperties(__assign(__assign(__assign({ display: ['none', 'block', 'inline', 'inline-block', 'flex', 'inline-flex', 'grid', 'inline-grid'], visibility: ['visible', 'hidden'], position: ['static', 'relative', 'absolute', 'fixed'], borderRadius: __assign({ none: '0px', pill: '9999px', round: '100%' }, vars.borderRadius), paddingTop: space, paddingBottom: space, paddingRight: space, paddingLeft: space, textAlign: ['left', 'center', 'right'], fontWeight: vars.typography.weight, fontSize: __assign(__assign({}, mapValues(vars.typography.size, function (size) {
            var _a;
            return (__assign(__assign({}, size), { vars: (_a = {},
                    _a[fontSizeVar] = size.fontSize,
                    _a[lineHeightVar] = size.lineHeight,
                    _a[capHeightVar] = calc.multiply(size.fontSize, fontSizeToCapHeightConstantVar),
                    _a) }));
        })), { inherit: 'inherit' }), flexShrink: [0, 1], flexGrow: [0, 1], alignSelf: selfPositioning, justifySelf: selfPositioning }, flexOnlyProperties), gridOnlyProperties), flexGridCommonProperties));
};
/** If the build is failing here, then that means that the sprinkles atoms
 *  contain a name already used by an intrinsic HTML element.
 *  You can find the offending names in OverwrittenPropNames.
 *  If there is a better way to do a type assert then feel free to change this.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var intrinsicPropCanary = true;
