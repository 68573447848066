import * as t from 'io-ts';

// The *Any codecs are replications of existing codecs in io-ts, but allow for
// setting I, the input type, of the codec. By default all of these prodcuts
// codces with an I of unknown which is very helpful when parsing unknown JSON,
// but not if you want to validate form data with an expected structure.
// None of these introduce new runtime logic.
export interface unionAnyC<CS extends [t.Any, t.Any, ...Array<t.Any>]>
  extends t.UnionType<CS, t.TypeOf<CS[number]>, t.OutputOf<CS[number]>, t.InputOf<CS[number]>> {}

export const unionAny = <CS extends [t.Any, t.Any, ...Array<t.Any>]>(codecs: CS, name?: string): unionAnyC<CS> => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t.union(codecs, name) as any
);

export interface PropsAny {
  [key: string]: t.Any,
}

export interface TypeAnyC<P extends PropsAny>
  extends t.InterfaceType<
    P,
    {
      [K in keyof P]: t.TypeOf<P[K]>
    },
    {
      [K in keyof P]: t.OutputOf<P[K]>
    },
    {
      [K in keyof P]: t.InputOf<P[K]>
    }
  > {}

export const typeAny = <P extends PropsAny>(props: P, name?: string): TypeAnyC<P> => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t.type(props, name) as any
);

export interface PartialAnyC<P extends PropsAny>
  extends t.PartialType<
    P,
    {
      [K in keyof P]?: t.TypeOf<P[K]>
    },
    {
      [K in keyof P]?: t.OutputOf<P[K]>
    },
    {
      [K in keyof P]?: t.InputOf<P[K]>
    }
  > {}

export const partialAny = <P extends PropsAny>(props: P, name?: string): PartialAnyC<P> => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t.partial(props, name) as any
);

export interface ArrayAnyC<C extends t.Any> extends t.ArrayType<C, Array<t.TypeOf<C>>, Array<t.OutputOf<C>>, Array<t.InputOf<C>>> {}

export const arrayAny = <C extends t.Any>(item: C, name?: string): ArrayAnyC<C> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return t.array(item, name) as any;
};

export interface IntersectionAnyC<CS extends [t.Any, t.Any, ...Array<t.Any>]>
  extends t.IntersectionType<
    CS,
    CS extends {
      length: 2
    }
      ? t.TypeOf<CS[0]> & t.TypeOf<CS[1]>
      : CS extends {
          length: 3
        }
      ? t.TypeOf<CS[0]> & t.TypeOf<CS[1]> & t.TypeOf<CS[2]>
      : CS extends {
          length: 4
        }
      ? t.TypeOf<CS[0]> & t.TypeOf<CS[1]> & t.TypeOf<CS[2]> & t.TypeOf<CS[3]>
      : CS extends {
          length: 5
        }
      ? t.TypeOf<CS[0]> & t.TypeOf<CS[1]> & t.TypeOf<CS[2]> & t.TypeOf<CS[3]> & t.TypeOf<CS[4]>
      : unknown,
    CS extends {
      length: 2
    }
      ? t.OutputOf<CS[0]> & t.OutputOf<CS[1]>
      : CS extends {
          length: 3
        }
      ? t.OutputOf<CS[0]> & t.OutputOf<CS[1]> & t.OutputOf<CS[2]>
      : CS extends {
          length: 4
        }
      ? t.OutputOf<CS[0]> & t.OutputOf<CS[1]> & t.OutputOf<CS[2]> & t.OutputOf<CS[3]>
      : CS extends {
          length: 5
        }
      ? t.OutputOf<CS[0]> & t.OutputOf<CS[1]> & t.OutputOf<CS[2]> & t.OutputOf<CS[3]> & t.OutputOf<CS[4]>
      : unknown,
    CS extends {
      length: 2
    }
      ? t.InputOf<CS[0]> & t.InputOf<CS[1]>
      : CS extends {
          length: 3
        }
      ? t.InputOf<CS[0]> & t.InputOf<CS[1]> & t.InputOf<CS[2]>
      : CS extends {
          length: 4
        }
      ? t.InputOf<CS[0]> & t.InputOf<CS[1]> & t.InputOf<CS[2]> & t.InputOf<CS[3]>
      : CS extends {
          length: 5
        }
      ? t.InputOf<CS[0]> & t.InputOf<CS[1]> & t.InputOf<CS[2]> & t.InputOf<CS[3]> & t.InputOf<CS[4]>
      : unknown
  > {}

export function intersectionAny<
  A extends t.Any,
  B extends t.Any,
  C extends t.Any,
  D extends t.Any,
  E extends t.Any
>(codecs: [A, B, C, D, E], name?: string): IntersectionAnyC<[A, B, C, D, E]>
export function intersectionAny<A extends t.Any, B extends t.Any, C extends t.Any, D extends t.Any>(
  codecs: [A, B, C, D],
  name?: string
): IntersectionAnyC<[A, B, C, D]>
export function intersectionAny<A extends t.Any, B extends t.Any, C extends t.Any>(
  codecs: [A, B, C],
  name?: string
): IntersectionAnyC<[A, B, C]>
export function intersectionAny<A extends t.Any, B extends t.Any>(
  codecs: [A, B],
  name?: string
): IntersectionAnyC<[A, B]>

export function intersectionAny<CS extends [t.Any, t.Any, ...Array<t.Any>]>(
  codecs: CS,
  name?: string,
): IntersectionAnyC<CS> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return t.intersection(codecs as any, name) as any;
}
