import React from 'react';

import { cn } from '../../webutils/webutils';
import Flex from '../Flex';

import * as styles from './Loading.css';

interface Props {
  className?: string;
  size?: number;
  center?: boolean;
  grow?: boolean;
  fullscreen?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  fadeIn?: boolean;
  limitHeight?: boolean;
  strokeWidth?: number;
  color?: 'white' | 'grey' | 'black';
}

const Loading: React.FC<Props> = ({
  className,
  size = 100,
  center,
  fullscreen,
  grow,
  children,
  loading = true,
  fadeIn,
  strokeWidth,
  limitHeight,
  color,
  ...rest
}) => {
  const shouldMask = loading && children;
  const containerClass = cn(
    styles.container,
    center && styles.centered,
    fullscreen && styles.fullHeight,
    shouldMask && styles.mask,
    limitHeight && styles.limitHeight,
    className,
  );
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      className={cn(loading ? containerClass : className)}
      flexGrow={grow ? 1 : 0}
      {...rest}
    >
      {loading && (
        <LoadingSpinner
          strokeWidth={strokeWidth}
          color={color}
          className={cn(
            fadeIn && styles.spinnerFadeIn,
            shouldMask && styles.spinnerMask,
          )}
          width={size}
          height={size}
        />
      )}
      {shouldMask ? null : children}
    </Flex>
  );
};

interface LoadingSpinnerProps {
  className?: string;
  strokeWidth?: number;
  width?: number;
  height?: number;
  color?: 'white' | 'grey' | 'black';
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  className,
  strokeWidth = 2,
  width = 100,
  height = 100,
  color = 'grey',
}) => {
  const viewBox = 38 + strokeWidth;
  const translate = 1 + (strokeWidth / 2);
  return (
    <svg
      className={cn(className)}
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      aria-label='loading'
      viewBox={`0 0 ${viewBox} ${viewBox}`}
      stroke={`${color}`}
    >
      <g fill='none' fillRule='evenodd'>
        <g
          strokeWidth={strokeWidth}
          transform={`translate(${translate} ${translate})`}
          strokeLinecap='round'
        >
          <circle strokeOpacity='.5' cx='18' cy='18' r='18' />
          <path d='M36 18c0-9.94-8.06-18-18-18'>
            <animateTransform
              attributeName='transform'
              type='rotate'
              from='0 18 18'
              to='360 18 18'
              dur='1s'
              repeatCount='indefinite'
            />
          </path>
        </g>
      </g>
    </svg>
  );
};

export default Loading;
