import { PressResponder } from '@react-aria/interactions';
import { AriaMenuProps, MenuTriggerProps as MenuTriggerHookProps } from '@react-types/menu';
import { PositionProps } from '@react-types/overlays';
import { Collection, CollectionElement, ItemProps, Node } from '@react-types/shared';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import React, { HTMLAttributes, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DismissButton, FocusScope, mergeProps, useButton, useFocus, useMenu, useMenuItem, useMenuSection, useMenuTrigger, useOverlay, useOverlayPosition, usePress, useSeparator } from 'react-aria';
import { Item, MenuTriggerState, Section, TreeState, useMenuTriggerState, useTreeState } from 'react-stately';

import useHoverDelay from '../../hooks/useHoverDelay';
import useHTMLElementRef from '../../hooks/useHTMLElementRef';
import usePrevious from '../../hooks/usePrevious';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import Box from '../Box';
import Loading from '../Loading';
import { OverlayContainerSSR } from '../Portal';
import Text from '../Text';
import TextLink from '../TextLink';
import TextLinkButton from '../TextLinkButton';

interface MenuContextValue {
  menuTriggerRef: React.RefObject<HTMLButtonElement>;
  menuTriggerState: MenuTriggerState;
  menuTriggerProps: React.HTMLAttributes<HTMLButtonElement>;
  menuProps: React.HtmlHTMLAttributes<HTMLUListElement>;
  menuRef: React.RefObject<HTMLUListElement>;
  overlayProps: React.HTMLAttributes<HTMLElement>;
  closeOnSelect: boolean;
}

const menuContext = React.createContext<MenuContextValue | null>(null);
export const useMenuContext = (componentName: string) => {
  const ctx = useContext(menuContext);
  if (ctx === null) {
    throw new Error(`${componentName} must be rendered inside Menu`);
  }
  return ctx;
};

interface MenuHoverableTriggerProps extends Omit<MenuTriggerHookProps, 'trigger'> {
  trigger?: MenuTriggerHookProps['trigger'] | 'hoverAndPress';
  isDisabled?: boolean;
  menuRef: React.RefObject<HTMLElement>;
}

export const useMenuHoverableTriggerState = (props: MenuHoverableTriggerProps): {
  menuTriggerState: MenuTriggerState,
  menuHoverableTriggerProps: HTMLAttributes<HTMLElement>,
  overlayHoverableProps: HTMLAttributes<HTMLElement>,
} => {
  const { trigger } = props;
  const [hoverAndPressState, setHoverAndPressState] = useState({
    hoverTrigger: false,
    hoverOverlay: false,
    press: false,
  });
  const hoverAndPressIsOpen = hoverAndPressState.press
    || hoverAndPressState.hoverOverlay
    || hoverAndPressState.hoverTrigger;

  const previousHoverAndPressIsOpen = usePrevious(hoverAndPressIsOpen);
  if (trigger === 'hoverAndPress' && hoverAndPressIsOpen !== previousHoverAndPressIsOpen) {
    props.onOpenChange?.(hoverAndPressIsOpen);
  }

  // We temporarily disable hover on the trigger/overlay when we forcibly close
  // the menu to flush the internal state of `useHoverDelay`/`useHover`, as the
  // `onHoverEnd` event doesn't fire when the overlay is removed from the dom while
  // under your cursor
  const [disableHoverOverride, setDisableHoverOverride] = useState(false);
  useEffect(() => {
    if (disableHoverOverride) {
      setDisableHoverOverride(false);
    }
  }, [disableHoverOverride]);

  const lastHoverOpenTimeRef = useRef<number | undefined>(undefined);
  const overrideCloseRef = useRef<boolean>(false);

  const hoverDelay = 333;
  const { hoverProps: triggerHoverProps } = useHoverDelay({
    isDisabled: disableHoverOverride || props.isDisabled,
    onHoverChange: (isHovering) => {
      if (!hoverAndPressIsOpen && isHovering) {
        lastHoverOpenTimeRef.current = Date.now();
      }
      setHoverAndPressState(s => ({ ...s, hoverTrigger: isHovering }));
    },
    delay: hoverDelay,
  });
  const { hoverProps: overlayHoverProps } = useHoverDelay({
    isDisabled: disableHoverOverride || props.isDisabled,
    onHoverChange: (isHovering) => {
      setHoverAndPressState(s => ({ ...s, hoverOverlay: isHovering }));
    },
    delay: hoverDelay,
  });

  const { pressProps } = usePress({
    isDisabled: props.isDisabled,
    onPressStart: () => {
      // Prevent user from opening and closing the menu in quick succession by
      // hovering it open and then clicking on it
      if (hoverAndPressIsOpen && lastHoverOpenTimeRef.current && Date.now() - lastHoverOpenTimeRef.current <= 333) {
        overrideCloseRef.current = true;
      }
    },
  });

  const menuTriggerState = useMenuTriggerState({
    ...props,
    trigger: trigger === 'hoverAndPress' ? 'press' : trigger,
    isOpen: props.isOpen ?? (trigger === 'hoverAndPress' ? hoverAndPressIsOpen : undefined),
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        const intercept = trigger === 'hoverAndPress' && overrideCloseRef.current;
        overrideCloseRef.current = false;
        if (intercept) {
          return;
        }
      }
      if (trigger === 'hoverAndPress') {
        // Don't close the menu if user clicks on the trigger shortly after opening it via hover
        setHoverAndPressState(s => ({
          press: isOpen,
          hoverTrigger: isOpen ? s.hoverTrigger : false,
          hoverOverlay: isOpen ? s.hoverOverlay : false,
        }));
        // Temporarily disable hover triggers to forcibly trigger an onHoverEnd
        // event and flush internal state
        setDisableHoverOverride(true);
        return;
      }
      props.onOpenChange?.(isOpen);
    },
  });

  return {
    menuTriggerState,
    menuHoverableTriggerProps: mergeProps(pressProps, triggerHoverProps),
    overlayHoverableProps: overlayHoverProps,
  };
};

export interface MenuProps {
  trigger?: 'press' | 'longPress' | 'hoverAndPress',
  children?: React.ReactNode;
  isDisabled?: boolean;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  closeOnSelect?: boolean;
}

const Menu = (props: MenuProps) => {
  const { trigger, children, closeOnSelect = true } = props;
  const menuTriggerRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const { menuTriggerState, menuHoverableTriggerProps, overlayHoverableProps } = useMenuHoverableTriggerState({
    ...props,
    menuRef,
  });

  const { menuProps, menuTriggerProps: menuTriggerBaseProps } = useMenuTrigger({
    isDisabled: props.isDisabled,
    trigger: trigger === 'hoverAndPress' ? 'press' : trigger,
    type: 'menu',
  }, menuTriggerState, menuTriggerRef);

  const { buttonProps } = useButton({
    ...menuTriggerBaseProps,
    isDisabled: props.isDisabled,
  }, menuTriggerRef);

  const value: MenuContextValue = useMemo(() => ({
    menuTriggerRef,
    menuTriggerState,
    menuTriggerProps: mergeProps(
      menuHoverableTriggerProps,
      buttonProps,
    ),
    // TODO: support focus strategy correctly
    menuProps: { ...menuProps, autoFocus: !!menuProps.autoFocus },
    menuRef,
    overlayProps: overlayHoverableProps,
    closeOnSelect,
  }), [menuTriggerState, buttonProps, menuHoverableTriggerProps, menuProps, menuRef, overlayHoverableProps, closeOnSelect]);

  return (
    <menuContext.Provider value={value}>
      {children}
    </menuContext.Provider>
  );
};

interface MenuTriggerProps {
  /** The child of Menu.Trigger requires an onClick prop, instead of onPress */
  isLegacyOnClickHandler?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any>;
}
/** Makes the child button act as a menu trigger */
const MenuTrigger = (props: MenuTriggerProps) => {
  const {
    isLegacyOnClickHandler,
    children: triggerElem,
  } = props;

  const {
    menuTriggerProps,
    menuTriggerRef,
    menuTriggerState,
  } = useMenuContext('Menu.Trigger');

  if (isLegacyOnClickHandler) {
    return React.cloneElement(
      triggerElem,
      mergeProps(menuTriggerProps, { ref: menuTriggerRef }, triggerElem.props),
    );
  }

  return (
    <PressResponder {...menuTriggerProps} ref={menuTriggerRef} isPressed={menuTriggerState.isOpen}>
      {triggerElem}
    </PressResponder>
  );
};

export interface MenuContentsProps<T> extends Omit<AriaMenuProps<T>, 'children'>, PositionProps {
  loadingKeys?: AriaMenuProps<T>['disabledKeys'];
  children: AriaMenuProps<T>['children'] | (CollectionElement<T> | null)[] | null;
}

/** Contains `Menu.Item'/`Menu.LinkItem`'s. i
 * Refer to https://react-spectrum.adobe.com/react-stately/collections.html
 * for general use of collection components
 */
const MenuContents = <T extends object>(props: MenuContentsProps<T>) => {
  const { menuTriggerState } = useMenuContext('Menu.Contents');
  if (!menuTriggerState.isOpen) return null;
  return (
    <OverlayContainerSSR>
      <MenuContentsInternal {...props} />
    </OverlayContainerSSR>
  );
};

const MenuContentsInternal = <T extends object>(props: MenuContentsProps<T>) => {
  const { disabledKeys, loadingKeys, ...rest } = props;
  const overlayRef = useRef<HTMLDivElement>(null);
  const {
    menuProps: menuPropsContext,
    menuRef,
    menuTriggerState,
    menuTriggerRef,
    overlayProps: overlayPropsContext,
    closeOnSelect,
  } = useMenuContext('Menu.Contents');
  const combinedDisabledKeys = [
    ...Array.from(disabledKeys ?? []),
    ...Array.from(loadingKeys ?? []),
  ];
  const loadingSet = new Set(loadingKeys ?? []);

  const { overlayProps } = useOverlay({
    shouldCloseOnBlur: true,
    isDismissable: true,
    isOpen: menuTriggerState.isOpen,
    onClose: menuTriggerState.close,
    shouldCloseOnInteractOutside: (elem) => {
      return elem !== menuTriggerRef.current && !menuTriggerRef.current?.contains(elem);
    },
  }, overlayRef);

  const { overlayProps: overlayPositionProps } = useOverlayPosition({
    targetRef: menuTriggerRef,
    overlayRef,
    isOpen: menuTriggerState.isOpen,
    placement: rest.placement ?? 'bottom left',
    containerPadding: rest.containerPadding,
    offset: rest.offset ?? 10,
    crossOffset: rest.crossOffset,
    shouldFlip: rest.shouldFlip ?? true,
  });

  const filteredChildren = (() => {
    if (typeof props.children === 'function') return props.children;
    const children: CollectionElement<T>[] = [];
    React.Children.forEach(props.children, (elem) => {
      if (elem === null) return;
      children.push(elem);
    });
    return children;
  })();
  const menuState = useTreeState({
    ...rest,
    children: filteredChildren,
    disabledKeys: combinedDisabledKeys,
    selectionMode: 'none',
  });
  const { menuProps } = useMenu({
    autoFocus: 'first',
    shouldFocusWrap: true,
    ...rest,
    disabledKeys: combinedDisabledKeys,
  }, menuState, menuRef);

  return (
    <FocusScope restoreFocus>
      <Box
        paddingY='space-0.5'
        border='greyLine'
        shadow='normal'
        background='white'
        borderRadius='medium'
        overflow='auto'
        {...mergeProps(
          { ref: overlayRef },
          overlayProps,
          overlayPositionProps,
          overlayPropsContext,
        )}
      >
        <DismissButton onDismiss={menuTriggerState.close} />
        <ul
          {...mergeProps(
            { ref: menuRef, style: { maxWidth: '20rem' } },
            menuProps,
            menuPropsContext,
          )}
        >
          {Array.from(menuState.collection as Collection<MenuNode<T>>).map((item) => {
            if (item.type === 'section') {
              return (
                <MenuSectionInternal
                  key={item.key}
                  section={item}
                  menuState={menuState}
                  onAction={props.onAction}
                  onClose={menuTriggerState.close}
                  loadingSet={loadingSet}
                  closeOnSelect={closeOnSelect}
                />
              );
            }
            return (
              <MenuItemInternal
                key={item.key}
                item={item}
                menuState={menuState}
                onAction={props.onAction}
                onClose={menuTriggerState.close}
                isLoading={loadingSet.has(item.key)}
                closeOnSelect={closeOnSelect}
              />
            );
          })}
        </ul>
        <DismissButton onDismiss={menuTriggerState.close} />
      </Box>
    </FocusScope>
  );
};

export interface MenuItemProps<T extends object> extends ItemProps<T> {
  onAction?: () => void;
  closeOnSelect?: boolean;
}
/** Base Item, renders as a clickable button.
 * If children is a text literal, it is automatically warpped with a truncated
 * `Text` component. If custom children markup is used make sure to wrap leaf
 * text nodes in `Text` to ensure consistent spacing.
 */
const MenuItem: <T extends object>(props: MenuItemProps<T>) => JSX.Element = Item;
export interface MenuItemNode<T extends object> extends Omit<Node<T>, 'props' | 'type'> {
  type: 'item';
  props?: MenuItemProps<T>;
}

export interface MenuLinkItem<T extends object> extends Omit<MenuItemProps<T>, 'href'>, Pick<React.ComponentProps<typeof TextLink>, 'href'> {
}
/** Renders as a Link, preferred over navigation in `onAction`.
 * If children is a text literal, it is automatically warpped with a truncated
 * `Text` component. If custom children markup is used make sure to wrap leaf
 * text nodes in `Text` to ensure consistent spacing.
 */
const MenuLinkItem = Object.assign(<T extends object>(_props: MenuLinkItem<T>) => {
  return null;
}, {
  // This is a custom function used by react-aria/react-stately's collection
  // components https://react-spectrum.adobe.com/react-stately/collections.html
  // to allow the collection to gather information on each child node component
  // without actually rendering them directly
  // TODO: Create a utility that makes it easier to create custom Collection types
  getCollectionNode: function* MenuLinkItemGetCollectionNode<T extends object>(
    props: MenuLinkItem<T>,
    context: unknown,
  ): Generator<Partial<MenuItemLinkNode<T>>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const generator = (Item as any).getCollectionNode(props, context);
    yield {
      ...generator.next().value,
      type: 'item',
      props,
    };
  },
});

export interface MenuItemLinkNode<T extends object> extends Omit<Node<T>, 'props' | 'type'> {
  type: 'item';
  props: MenuLinkItem<T>;
}

export type MenuLeafNode<T extends object> = MenuItemNode<T> | MenuItemLinkNode<T>;
export type MenuNode<T extends object> = MenuItemNode<T> | MenuItemLinkNode<T> | MenuSectionNode<T>;

interface MenuItemInternalProps<T extends object> extends Pick<AriaMenuProps<T>, 'onAction'> {
  item: MenuLeafNode<T>;
  menuState: TreeState<T>;
  onClose: () => void;
  isLoading: boolean;
  closeOnSelect: boolean;
}

const MenuItemInternal = <T extends object>(props: MenuItemInternalProps<T>) => {
  const { item, menuState, onAction, onClose, isLoading, closeOnSelect } = props;
  const isDisabled = isLoading || menuState.disabledKeys.has(item.key);
  const menuItemRef = useHTMLElementRef();
  const router = useRouter();
  const { menuItemProps } = useMenuItem(
    {
      'aria-label': item['aria-label'] ?? item.textValue,
      closeOnSelect: item.props?.closeOnSelect ?? closeOnSelect,
      // Delay the close by an execution cycle to give link navigation events a
      // chance to happen
      onClose: () => setTimeout(onClose),
      key: item.key,
      isDisabled,
      onAction: (key) => {
        if (item.props && 'onAction' in item.props) {
          item.props.onAction?.();
        }
        if (item.props && 'href' in item.props && item.props.href) {
          router.push(item.props.href).catch(() => null);
        }
        onAction?.(key);
      },
    },
    menuState,
    menuItemRef,
  );

  const [isFocused, setIsFocused] = useState(false);
  const { focusProps } = useFocus({
    isDisabled,
    onFocusChange: setIsFocused,
  });

  const content = (() => {
    const commonProps = {
      minWidth: '100%',
      paddingY: 'space-0.75',
      paddingLeft: 'space-1.5',
      paddingRight: 'space-2',
      foreground: 'inherit',
      position: 'relative',
      'aria-busy': isLoading ? 'true' : 'false',
      textAlign: 'left',
    } as const;

    const loading = isLoading && (
      <Box position='absolute' style={{ right: '0.5rem', top: '50%', transform: 'translateY(-50%)' }}>
        <Loading size={16} strokeWidth={6} />
      </Box>
    );
    if (typeof item.rendered === 'string') {
      return (
        <Text truncate {...commonProps}>
          {item.rendered}
          {loading}
        </Text>
      );
    }
    return (
      <Box {...commonProps}>
        {item.rendered}
        {loading}
      </Box>
    );
  })();

  // must omit color because Link improperly masks the builtin html attribute
  const combinedProps = omit(mergeProps(
    {
      ref: menuItemRef.assign,
      style: { width: '100%' },
      color: isFocused ? 'slateMedium' : 'slateDark',
      underline: !!isFocused,
      disabled: isDisabled,
      subtleDisabled: isDisabled,
    },
    menuItemProps,
    focusProps,
  ), 'color');

  return (
    <Box as='li' display='flex' {...combinedProps}>
      {(item.props && 'href' in item.props)
        ? (
          <TextLink href={item.props.href || ''} minWidth='100%' foreground='greyDark' underline='hover' textBoxTrim={false}>
            {content}
          </TextLink>
        ) : (
          <TextLinkButton role='none' minWidth='100%' foreground='greyDark' underline='hover' textBoxTrim={false}>
            {content}
          </TextLinkButton>
        )}
    </Box>
  );
};

interface MenuSectionNode<T> extends Omit<Node<T>, 'type'> {
  type: 'section';
}

interface MenuSectionInternalProps<T extends object> extends Pick<AriaMenuProps<T>, 'onAction'> {
  section: MenuSectionNode<T>;
  menuState: TreeState<T>;
  onClose: () => void;
  loadingSet: Set<React.Key>;
  closeOnSelect: boolean;
}

const MenuSectionInternal = <T extends object>(props: MenuSectionInternalProps<T>) => {
  const { section, menuState, loadingSet, ...rest } = props;
  const { groupProps, headingProps, itemProps } = useMenuSection({
    'aria-label': section['aria-label'],
    heading: section.rendered,
  });
  const { separatorProps } = useSeparator({
    elementType: 'li',
  });


  const commonProps = {
    minWidth: '100%',
    paddingY: 'space-0.75',
    paddingLeft: 'space-1.5',
    paddingRight: 'space-2',
    foreground: 'inherit',
    position: 'relative',
  } as const;

  return (
    <>
      {section.key !== menuState.collection.getFirstKey() && (
        <Box
          as='li'
          {...separatorProps}
          borderTop='greyLine'
          paddingY='space-0.25'
        />
      )}
      <li {...itemProps}>
        {!!section.rendered &&
          (childrenAreLiteral(section.rendered)
            ? (
              <Text {...headingProps} {...commonProps} truncate>
                {section.rendered}
              </Text>
            ) : (
              <Box {...headingProps} {...commonProps}>
                {section.rendered}
              </Box>
            )
          )}
        <ul
          {...groupProps}
          style={{
            padding: 0,
            listStyle: 'none',
          }}
        >
          {Array.from(section.childNodes as Collection<MenuNode<T>>).map((item) => {
            if (item.type === 'section') {
              return (
                <MenuSectionInternal
                  {...rest}
                  key={item.key}
                  section={item}
                  menuState={menuState}
                  loadingSet={loadingSet}
                />
              );
            }
            return (
              <MenuItemInternal
                {...rest}
                key={item.key}
                item={item}
                menuState={menuState}
                isLoading={loadingSet.has(item.key)}
              />
            );
          })}
        </ul>
      </li>
    </>
  );
};

export default Object.assign(Menu, {
  Trigger: MenuTrigger,
  Contents: MenuContents,
  Item: MenuItem,
  LinkItem: MenuLinkItem,
  Section,
});
