var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var useForwardClick = function (props) {
    var targetRef = props.targetRef;
    var redirectEvent = function (e) {
        // Forward click events to the input if possible
        if (targetRef.current) {
            // If the target is nested inside of the forwarded element ignore real and
            // cloned events that make it to the target
            if (e.target === targetRef.current)
                return;
            // For input elements, non-editable elements should not fire click events
            var inputRef = targetRef.current;
            if (inputRef.disabled || inputRef.readOnly) {
                return;
            }
            var clonedEvent = void 0;
            try {
                clonedEvent = new MouseEvent(e.type, __assign(__assign({}, e), { view: undefined }));
                if (e.isDefaultPrevented()) {
                    clonedEvent.preventDefault();
                }
                if (e.isPropagationStopped()) {
                    clonedEvent.stopPropagation();
                }
            }
            catch (error) {
                // one off polyfill for ie11
                if ('initMouseEvent' in MouseEvent) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    clonedEvent = MouseEvent.initMouseEvent(e.type, e.bubbles, e.cancelable, window, e.detail, e.screenX, e.screenY, e.clientX, e.clientY, e.ctrlKey, e.altKey, e.shiftKey, e.metaKey, e.button, e.relatedTarget);
                }
                else {
                    throw error;
                }
            }
            e.preventDefault();
            e.stopPropagation();
            targetRef.current.dispatchEvent(clonedEvent);
            if (e.type === 'click' || e.type === 'pointerdown') {
                targetRef.current.focus();
            }
        }
    };
    return {
        forwardClickProps: {
            onClick: redirectEvent,
            onPointerDown: redirectEvent,
            onPointerUp: redirectEvent,
            onPointerCancel: redirectEvent,
        },
    };
};
export default useForwardClick;
