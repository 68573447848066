import React from 'react';
import { useHover } from 'react-aria';

import Flex from '../Flex';
import IconMeatballs from '../Icons/IconMeatballs';
import Menu, { MenuContentsProps, MenuProps } from '../Menu';
import UnstyledButton from '../UnstyledButton';

interface OptionsNewMenuProps<T extends object> extends
  Omit<MenuProps, 'trigger' | 'children'>,
  MenuContentsProps<T> {
  /** Label applied to meatball menu trigger button */
  'aria-label'?: string;
}

const OptionsMenu = <T extends object>(props: OptionsNewMenuProps<T>) => {
  const {
    isDisabled,
    onOpenChange,
    isOpen,
    closeOnSelect,
    'aria-label': ariaLabel,
    ...rest
  } = props;

  const {
    hoverProps,
    isHovered,
  } = useHover({ isDisabled });

  return (
    <Menu
      trigger='press'
      isDisabled={isDisabled}
      onOpenChange={onOpenChange}
      closeOnSelect={closeOnSelect}
      isOpen={isOpen}
    >
      <Menu.Trigger>
        <UnstyledButton
          aria-label={ariaLabel ?? 'Options menu'}
          {...hoverProps}
        >
          <Flex
            alignItems='center'
            style={{
              width: 36,
              height: 36,
              transition: 'background 0.3s',
            }}
            justifyContent='center'
            background={isHovered ? 'greyFill' : 'white'}
            borderRadius='round'
          >
            <IconMeatballs
              height={3}
              width={13}
            />
          </Flex>
        </UnstyledButton>
      </Menu.Trigger>
      <Menu.Contents {...rest} />
    </Menu>
  );
};

export default Object.assign(OptionsMenu, {
  Item: Menu.Item,
  LinkItem: Menu.LinkItem,
});
