import { AriaLinkOptions, useLink } from '@react-aria/link';
import isEmpty from 'lodash/isEmpty';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React, { useRef } from 'react';
import { mergeProps, PressHookProps, useFocusRing, useHover } from 'react-aria';
import urlJoin from 'url-join';

import { notUndefined } from '@mablemarket/common-lib';

import useRouteMatch from '../../modules/NextRouter/hooks/useRouteMatch';
import { focusRing, noFocusRing } from '../../styles/sprinkles.css';
import { composeRefs } from '../../util';
import { cn } from '../../webutils/webutils';
import { useForeground } from '../Box';
import Text, { TextOwnProps } from '../Text';

import * as styles from './TextLink.css';

export interface hrefObject {
  pathname: string;
  hash?: string;
  origin?: string;
  // maybe this should accept something query-string able?
  query?: string;
}

type href = string | hrefObject;

export const stringifyHref = (href: href) => (
  typeof href === 'string' ? href : urlJoin([
    href.origin,
    href.pathname,
    (href.query && !href.query.startsWith('?')) ? `?${href.query}` : href.query,
    (href.hash && href.hash !== '#') ? `#${href.hash}` : href.hash,
  ].filter(notUndefined))
);

/** Currently this uses the presence of a protocol to determine if the url is
 * external, which may or may not be sufficient
 */
export const matchProtocolUrl = (urlOrPath: string) => {
  return !!urlOrPath.match(/^([a-z]+:)?\/\//i);
};

// default to blue instead of black
const defaultColor = 'blueMas';

export interface TextLinkProps extends
  Pick<
  NextLinkProps,
  'replace' | 'scroll' | 'shallow' | 'passHref' | 'prefetch' | 'locale'
  >,
  TextOwnProps,
  Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href' | 'className' | keyof TextOwnProps
  >,
  Omit<PressHookProps, 'isDisabled'> {
  href: href;
  underline?: boolean | 'hover';
  disabled?: boolean;
  external?: boolean;
  /* Props applied when href matches current location */
  activeProps?: Omit<Partial<TextLinkProps>, 'href'>;
  /* Use exact match when comparing href to location */
  exact?: boolean;
  /** overlayContainer
   * Works like Bootstrap's 'streched-link'. It adds an
   * absolutely positioned ::before To fill the container with clickable area that
   * will trigger the link. This allows us to do things like make cards
   * clickable without resorting to onClick events that break things like right or
   * middle clicking, or wrapping the whole thing in a Link.
   *
   * DO NOT USE THIS ON TABLES. It will work in modern Chrome and Firefox, but the
   * CSS 2.1 spec does not define position: relative on table elements, and it will
   * be broken on Safari. The transfrom trick does not work either.
   *
   * To use, you must make the parent you wish to act as a link `position:
   * relative`, and force a new stacking context. You can ensure this by setting a
   * transform property, like `transform: rotate(0)`.
   *
   * To make other items in that container clickable, set their z-index greater
   * than 1. This will stack them above the ::before.
   */
  overlayContainer?: boolean;
}

/**
 * When you need a link that is (conditionally) underlined text
 * Use `onPress` instead of `onClick` unless you need something in the `onClick` event that `onPress` doesn't expose.
*/
const TextLink = React.forwardRef((props: TextLinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
  const {
    href,
    replace,
    scroll,
    shallow,
    passHref,
    prefetch,
    locale,
    underline: givenUnderline,
    foreground: givenForeground,
    disabled,
    external,
    activeProps,
    exact,
    overlayContainer,
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,
    ...rest
  } = props;

  const { hoverProps, isHovered } = useHover({
    ...rest,
    isDisabled: disabled,
  });
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { linkProps, isPressed } = useLink({
    // Need to cast because react aria expects event handlers for HTMLElement,
    // which conflict with ours for HTMLAnchorElement. The types are compatible,
    // but typescript isn't smart enough to know that.
    ...rest as AriaLinkOptions,
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,
    isDisabled: disabled,
  }, linkRef);

  const { className, ...restStyles } = useTextLinkStyles({
    foreground: givenForeground,
    background: props.background,
    underline: givenUnderline,
    disabled,
    isHovered,
    isPressed,
    overlayContainer,
  });

  const stringHref = stringifyHref(href);
  const isExternal = external || matchProtocolUrl(stringHref);
  const match = useRouteMatch({
    path: isEmpty(activeProps) ? '' : stringHref,
    exact,
  });
  const isActive = match !== null;

  const Anchor = (
    <Text
      as='a'
      display='inline-block'
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      href={stringHref}
      {...mergeProps(rest, (isActive && activeProps) || {})}
      // We want this to override click handlers
      {...mergeProps(linkProps, restStyles, hoverProps)}
      ref={composeRefs(linkRef, ref)}
      className={[
        rest.className,
        className,
        isActive && activeProps?.className,
      ]}
    />
  );

  if (isExternal) {
    return Anchor;
  }

  return (
    <NextLink
      href={stringHref}
      scroll={scroll}
      shallow={shallow}
      replace={replace}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
    >
      {Anchor}
    </NextLink>
  );
});

TextLink.displayName = 'TextLink';

export const useTextLinkStyles = (props: {
  foreground: TextOwnProps['foreground'],
  background: TextOwnProps['background'],
  underline: TextLinkProps['underline'],
  isPressed: boolean,
  isHovered: boolean,
  disabled: boolean | undefined,
  overlayContainer: boolean | undefined,
}) => {
  const {
    foreground: givenForeground,
    underline: givenUnderline,
    disabled,
    isHovered,
    isPressed,
    overlayContainer,
  } = props;
  const { focusProps, isFocusVisible } = useFocusRing({});
  const contextForeground = useForeground(props) ?? defaultColor;
  const foreground = (contextForeground === 'black' && givenForeground === undefined)
    ? defaultColor
    : contextForeground;
  // default to underlining link if using non-default foreground
  const underline = givenUnderline ?? (foreground !== defaultColor ? true : 'hover');
  return {
    ...focusProps,
    foreground,
    underline,
    className: cn(
      ((isHovered || isPressed) && !disabled) && styles.active[foreground === 'inherit' ? defaultColor : foreground],
      (underline === true || (isHovered && underline === 'hover' && !disabled))
        ? styles.underline
        // This feels dumb, but is needed to defeat global css
        : styles.notUnderlined,
      styles.textLink,
      disabled && styles.disabled,
      isFocusVisible ? focusRing : noFocusRing,
      overlayContainer && styles.overlayContainer,
    ),
  } as const;
};

export default TextLink;
