import React from 'react';
import styles from './Loading.module.scss';
import loading from '../../assets/loading.svg';

const Loading: React.FC<{}> = () => (
  <div className={styles.spinner}>
    <img src={loading} alt="Loading" />
  </div>
);

export default Loading;
