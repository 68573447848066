var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { mergeProps } from 'react-aria';
import { useController, useWatch } from 'react-hook-form';
import BigNumberInput from '../BigNumberInput';
import Checkbox from '../Checkbox';
import NumberInput from '../NumberInput';
import RadioGroup from '../RadioGroup';
import Select from '../Select';
import TextArea from '../TextArea';
import TextInput from '../TextInput';
var HFRadioGroupInternal = function (props) {
    var _a;
    var _rules = props.rules, rest = __rest(props, ["rules"]);
    // Lie to RHF about the disabled state because otherwise it makes the value
    // `undefined` on validation/submit
    var disabled = props.disabled, opts = __rest(props, ["disabled"]);
    var _b = useController(opts), field = _b.field, fieldState = _b.fieldState;
    return (<RadioGroup error={(_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message} {...mergeProps(field, rest, { disabled: disabled })}/>);
};
export var HFRadioGroup = Object.assign(HFRadioGroupInternal, {
    Item: RadioGroup.Item,
});
export var HFCheckbox = function (props) {
    var _a;
    var _rules = props.rules, rest = __rest(props, ["rules"]);
    // Lie to RHF about the disabled state because otherwise it makes the value
    // `undefined` on validation/submit
    // Extract value to also lie to RHF so we can replicate the default behavior
    // of uncontrolled checkboxes where multiple with the same name will create an
    // array of values instead of a single value or true/false
    // We cannot detect if multiple checkboxes have been registered with the same
    // name, so the value/defaultValue must be an array for this to work properly
    var disabled = props.disabled, value = props.value, opts = __rest(props, ["disabled", "value"]);
    var _b = useController(opts), field = _b.field, fieldState = _b.fieldState;
    var checked = (function () {
        if (Array.isArray(field.value)) {
            return field.value.includes(value);
        }
        if ((value !== null && value !== void 0 ? value : '') === '') {
            return !!field.value;
        }
        return field.value === value;
    })();
    return (<Checkbox error={(_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message} {...mergeProps(field, rest, { disabled: disabled })} value={value} checked={checked} onChange={function (e) {
            var _a;
            if (Array.isArray(field.value)) {
                if (e.target.checked) {
                    field.onChange(__spreadArray(__spreadArray([], field.value, true), [value], false));
                }
                else {
                    field.onChange(field.value.filter(function (v) { return v !== value; }));
                }
            }
            else if (e.target.checked) {
                field.onChange((value !== null && value !== void 0 ? value : '') === '' ? true : value);
            }
            else {
                field.onChange(false);
            }
            (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, e);
        }}/>);
};
export var HFNumberInput = function (props) {
    var _a;
    var _rules = props.rules, rest = __rest(props, ["rules"]);
    // Lie to RHF about the disabled state because otherwise it makes the value
    // `undefined` on validation/submit
    var disabled = props.disabled, opts = __rest(props, ["disabled"]);
    var _b = useController(opts), field = _b.field, fieldState = _b.fieldState;
    var value = useWatch(opts);
    return (<NumberInput error={(_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message} {...mergeProps(rest, __assign(__assign({}, field), { value: value, disabled: disabled }))}/>);
};
export var HFBigNumberInput = function (props) {
    var _a;
    var _rules = props.rules, rest = __rest(props, ["rules"]);
    // Lie to RHF about the disabled state because otherwise it makes the value
    // `undefined` on validation/submit
    var disabled = props.disabled, opts = __rest(props, ["disabled"]);
    var _b = useController(opts), field = _b.field, fieldState = _b.fieldState;
    var value = useWatch(opts);
    return (<BigNumberInput error={(_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message} {...mergeProps(rest, __assign(__assign({}, field), { value: value, disabled: disabled }))}/>);
};
export var HFTextInput = function (props) {
    var _a;
    var _rules = props.rules, rest = __rest(props, ["rules"]);
    // Lie to RHF about the disabled state because otherwise it makes the value
    // `undefined` on validation/submit
    var disabled = props.disabled, opts = __rest(props, ["disabled"]);
    var _b = useController(opts), field = _b.field, fieldState = _b.fieldState;
    return (<TextInput error={(_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message} {...mergeProps(field, rest, { disabled: disabled })}/>);
};
export var HFTextArea = function (props) {
    var _a;
    var _rules = props.rules, rest = __rest(props, ["rules"]);
    // Lie to RHF about the disabled state because otherwise it makes the value
    // `undefined` on validation/submit
    var disabled = props.disabled, opts = __rest(props, ["disabled"]);
    var _b = useController(opts), field = _b.field, fieldState = _b.fieldState;
    return (<TextArea error={(_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message} {...mergeProps(field, rest, { disabled: disabled })}/>);
};
export var HFSelect = function (props) {
    var _a;
    var _rules = props.rules, rest = __rest(props, ["rules"]);
    // Lie to RHF about the disabled state because otherwise it makes the value
    // `undefined` on validation/submit
    var disabled = props.disabled, opts = __rest(props, ["disabled"]);
    var _b = useController(opts), field = _b.field, fieldState = _b.fieldState;
    return (<Select error={(_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message} {...mergeProps(field, rest, { disabled: disabled })}/>);
};
