import jwtDecode from 'jwt-decode';
import every from 'lodash/every';

export const rolesToPermissions = {
  buyer: ['buyer'],
  seller: ['seller'],
} as const;

export const whitelabelHeaderName = 'X-Whitelabel-Identifier';

export type ExplicitRole = keyof typeof rolesToPermissions;
export type Role = ExplicitRole | 'fallback' | 'unauthed';
export type UserIs = Record<Role | 'admin' | 'authed', boolean>;
export type UserShould = keyof UserIs | Array<keyof UserIs>;
export type AllowedRoles = Record<ExplicitRole, boolean>;

export function chooseRole(args: { role?: Role, isAuthenticated: boolean, allowedRoles: AllowedRoles, isAdmin: boolean }) {
  const { role, isAuthenticated, allowedRoles, isAdmin } = args;
  if (!isAuthenticated) return 'unauthed';
  if (role && role in allowedRoles && (allowedRoles[role as keyof AllowedRoles] || isAdmin)) return role;
  if (isAdmin) return 'buyer';
  // default to seller if possible, otherwise buyer if there's multiple roles
  if (allowedRoles.seller) return 'seller';
  // Need the early exit, which js had a reducer with a reduced return
  // eslint-disable-next-line no-restricted-syntax
  for (const [roleName, isRole] of Object.entries(allowedRoles)) {
    if (isRole) return roleName as keyof typeof allowedRoles;
  }
  return 'fallback';
}

export function rolesFromPermissions({ permissions, role }: { permissions: string[], role?: Role }) {
  const isAdmin = permissions.some(p => p === 'admin');
  const allowedRoles = Object.entries(rolesToPermissions).reduce((acc, [role, ps]) => ({
    ...acc,
    [role]: isAdmin || every(ps, (p: string) => permissions.includes(p)),
  }), {} as AllowedRoles);
  const newRole = chooseRole({ isAuthenticated: true, allowedRoles, isAdmin, role });
  return { allowedRoles, isAdmin, role: newRole };
}

export function decodeAccessToken(
  accessToken: string,
  role: Role,
  /** Optionally apply a filter to the list of permissions decoded from the token.
   *  This could be used to explicitly alter the user's role.
   *
   *  e.g. removing seller permissions if present if we are decoding on the
   *  mobile app since we do not support the seller role there. */
  permissionFilter: (permission: string) => unknown = (() => true),
): {
  permissions: string[],
  roles: ReturnType<typeof rolesFromPermissions>,
} {
  const token = jwtDecode<{ permissions?: string[], exp: number }>(accessToken);
  const permissions = token.permissions?.filter(permissionFilter) ?? [];
  const roles = rolesFromPermissions({ permissions, role });
  return { permissions, roles };
}
