/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

import { useFirstRender } from './useFirstRender';

// The same interface as useEffect, but only run the callback on changes to the
// dependency array, not when it is established on the first render.
export const useEffectChange = (cb: () => void, deps: any[]) => {
  const firstRender = useFirstRender();
  useEffect(() => {
    if (!firstRender) {
      cb();
    }
    // Ignore changes to cb and first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
