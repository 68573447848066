var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { assignInlineVars } from '@vanilla-extract/dynamic';
import React from 'react';
import Box, { useForeground } from '../Box';
import * as styles from './Text.css';
var TextDefaultElement = 'div';
/** Use this for text
 *  - Automatically trims leading and trailing line-height to allow exact
 *  placement and spacing. Use `Flex`, `Grid` or padding to space appropriately.
 *  If you really, really need that random line-height spacing then use `Box`.
 *  - Avoid nesting text.
 *  - Automatically sets foreground color based on Box background.
 *  - use `Heading` for titles
 * - defaults to `div`. Use `as='span'` or `display='inline'` if you need inline text, though that
      will remove the trimming. This should mostly be used inside of another
      `Text` node in order to style or color a section of the passage.
 */
var Text = React.forwardRef(function (props, ref) {
    var _a;
    var truncate = props.truncate, _b = props.textBoxTrim, textBoxTrim = _b === void 0 ? true : _b, rest = __rest(props, ["truncate", "textBoxTrim"]);
    var isTruncated = truncate === true || (truncate || 0) > 0;
    var foreground = useForeground(rest);
    var isInline = rest.as === 'span' || rest.display === 'inline';
    return (<Box foreground={foreground} 
    // these must be set by default to make text trimming work
    fontFamily='sans' fontSize='medium' 
    // Workaround for truncation when a Flex/Grid child https://css-tricks.com/flexbox-truncated-text/
    minWidth={isTruncated ? 0 : undefined} {...rest} className={[!isInline && textBoxTrim && styles.capsizeClass, props.className]} ref={ref}>
      {isTruncated ? (<Box as='span' fontSize='inherit' style={assignInlineVars((_a = {},
                _a[styles.linesToClamp] = typeof truncate === 'number' ? truncate.toString() : '',
                _a))} className={[styles.truncate, typeof truncate === 'number' ? styles.lineClamp : styles.singleLine]}>
          {props.children}
        </Box>) : props.children}
    </Box>);
});
export default Text;
