import * as t from 'io-ts';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';

import { hasOwnProperty } from './guards';
import { AntiClobber } from './types';


export interface MableErrorDetails {
  /**
   * An identifier for the piece of data this error detail relates to. For example, the name of a field
   * that failed validation. Not displayed to users.
   */
  identifier: string;
  /**
   * A user-visible message about what went wrong with the piece of data this error detail relates to.
   */
  displayMessage: string;
  /**
   * A code to indicate the type of error specific to the piece of data this error detail relates to.
   */
  code?: string;
}

type MableErrorData = unknown | undefined;

export interface MableErrorProperties<Code extends string, Data extends MableErrorData = unknown> {
  code: Code;
  message: string;
  status?: number;
  displayTitle?: string;
  displayMessage?: string;
  details?: MableErrorDetails[];
  logData?: Record<string, unknown>;
  data: Data;
  correlationId?: string;
  fingerprint?: string[];
  stack?: string;
  dontReport?: boolean;
  suppressStackTrace?: boolean;
  _bypassSeverityDowngrade?: boolean;
  _unignorable?: boolean;
}

export interface MableErrorDefaultsAndOverrides<Code extends string = AntiClobber<string>, Data extends MableErrorData = unknown> {
  defaults?: Partial<MableErrorProperties<Code, Data>>;
  overrides?: Partial<MableErrorProperties<Code, Data>>;
}

export type MableErrorOptions<Code extends string = AntiClobber<string>, Data extends MableErrorData = unknown> =
  Partial<MableErrorProperties<Code, Data>> | MableErrorDefaultsAndOverrides<Code, Data>;

interface isMableError {
  <T>(
    x: unknown extends T ? unknown : T
  ): x is unknown extends T ? T extends MableError<string> ? MableError<string> : MableError : Extract<T, MableError<string>>;
    <Code extends string, T = unknown>(
      x: unknown extends T ? unknown : T, code?: Code
    ): x is unknown extends T ? T extends MableError<Code> ? MableError<Code> : MableError<Code> : Extract<T, MableError<Code>>;
}

export const isMableError: isMableError = <Code extends string = AntiClobber<string>>(x: unknown, code?: Code): x is MableError<Code> => {
  if (x instanceof MableError) {
    if (code) {
      return x.code === code;
    }
    return true;
  }
  return false;
};

const mergeMableErrorProperties = <Code extends string, SourceData extends MableErrorData, DefaultData extends MableErrorData>(
  source: MableErrorProperties<Code, SourceData>,
  defaults?: Partial<MableErrorProperties<Code, DefaultData>>,
): MableErrorProperties<Code, SourceData extends undefined ? DefaultData : SourceData> => {
  if (defaults === undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return source as any;
  }
  const res = mergeWith(defaults, source, (obj, src) => (
    isArray(src) ? src.concat(obj) : undefined
  ));
  if (source.data !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { ...res, data: source.data as any };
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { ...res, data: defaults.data as any };
};

export const optionsIsDefaultsAndOverrides = <Code extends string, Data extends MableErrorData>(
  opts?: MableErrorOptions<Code, Data>,
): opts is MableErrorDefaultsAndOverrides<Code, Data> => {
  if (opts === undefined) {
    return false;
  }
  const x = opts as MableErrorDefaultsAndOverrides<Code, Data>;
  return ('defaults' in x) || ('overrides' in x);
};

export const optionsIsProperties = <Code extends string, Data extends MableErrorData>(
  opts?: MableErrorOptions<Code, Data>,
): opts is MableErrorProperties<Code, Data> => {
  return (opts !== undefined) && !optionsIsDefaultsAndOverrides(opts);
};

export const optionsToDefaultsAndOverrides = <Code extends string, Data extends MableErrorData>(
  opts?: MableErrorOptions<Code, Data>,
): MableErrorDefaultsAndOverrides<Code, Data> => {
  if (optionsIsProperties(opts)) {
    return { overrides: opts };
  }

  if (optionsIsDefaultsAndOverrides(opts)) {
    return opts;
  }

  return { defaults: undefined, overrides: undefined };
};

export class MableError<Code extends string = AntiClobber<string>, Data extends MableErrorData = unknown> extends Error {
  public readonly name: Code;

  public readonly message: string;

  public readonly status?: number;

  public readonly displayTitle?: string;

  public readonly displayMessage?: string;

  public readonly details: MableErrorDetails[];

  public readonly logData?: Record<string, unknown>;

  public readonly correlationId?: string;

  /**
   * Sentry groups error reports into 'issues' by their fingerprint. By default, this is just the
   * error's `code`. If you want to customize how your errors get grouped, set your own fingerprint
   * here. `code` should probably be the first element unless you're doing something weird.
   */
  public readonly fingerprint: string[];

  public readonly dontReport?: boolean;

  public readonly suppressStackTrace?: boolean;

  public readonly _bypassSeverityDowngrade?: boolean;

  public readonly _unignorable?: boolean;

  /** Typed data ready for use in business logic */
  public readonly data: Data;

  /**
   * If this error is 'wrapping' another MableError because you called `wrapError` (or `ensureMableError`
   * which calls `wrapError`, or various other things that call `ensureMableError`), then this array
   * contains values of the `MableErrorProperties` that were modified while
   */
  public wrapStack?: Partial<MableErrorProperties<Code, unknown>>[];

  public stack!: string;

  // 'name' is a built-in property of Error, but normally it's just 'Error'.
  // It shows up in logs, so we'll use it to store the error code.
  get code() {
    return this.name;
  }

  public constructor(opts: MableErrorProperties<Code, Data>) {
    super();

    // Terrible workaround for confusing TypeScript nonsense.
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, MableError.prototype);

    this.name = opts.code; // TODO: This line is dumb.
    this.message = opts.message;
    this.logData = opts.logData;
    this.status = opts.status;
    this.displayTitle = opts.displayTitle;
    this.displayMessage = opts.displayMessage;
    this.details = opts.details ?? [];
    this.correlationId = opts.correlationId;
    this.fingerprint = opts.fingerprint ?? [opts.code];
    this.dontReport = opts.dontReport;
    this.data = opts.data;
    this.suppressStackTrace = opts.suppressStackTrace;

    // eslint-disable-next-line no-underscore-dangle
    this._bypassSeverityDowngrade = opts._bypassSeverityDowngrade;
    // eslint-disable-next-line no-underscore-dangle
    this._unignorable = opts._unignorable;

    if (opts.suppressStackTrace) {
      this.stack = '';
    } else if (opts.stack) {
      this.stack = opts.stack;
    } else if (Error.captureStackTrace) { // Not available in browsers
      // Omit this constructor from the stack trace of the error.
      Error.captureStackTrace(this, MableError);
    }

    // Without this, `util.inspect()` on a MableError will include the message twice - once at the beginning of
    // the stack trace, and then again in the list of fields that appears below the stack trace.
    Object.defineProperty(this, 'message', { enumerable: false });
  }

  toJSON(): MableErrorProperties<Code> {
    return {
      code: this.code,
      message: this.message,
      stack: this.stack,
      status: this.status,
      displayTitle: this.displayTitle,
      displayMessage: this.displayMessage,
      details: this.details,
      logData: this.logData,
      correlationId: this.correlationId,
      fingerprint: this.fingerprint,
      data: this.data,
    };
  }
}


/**
 * Like `{ ...oldObject, ...newObject }`, except you also get a `changeLog` listing any values of
 * `oldObject` that got overwritten with values from `newObject`. Also, any keys in `deepKeys` will
 * do a 'deep' spread, which is like:
 * ```
 *   { ...oldObject, ...newObject, deepKey: { ...oldObject.deepKey, ...newObject.deepKey }}
 * ```
 *
 * For example:
 * ```
 *   modifiedObject({ a: 1, b: 2}, { b: 3, c: 4})
 *   { modified: { a: 1, b: 3, c: 4 }, changeLog: { b: 2 } }
 * ```
 * ```
 *   modifiedObject(
 *     { a: 1, b: 2, obj: { x: 1, y: 2} },
 *     { b: 3, c: 4, obj: { y: 3, z: 4 } },
 *     ['obj']
 *   )
 *   {
 *     modified: { a: 1, b: 3, obj: { x: 1, y: 3, z: 4 }, c: 4 },
 *     changeLog: { b: 2, obj: { y: 2 } }
 *   }
 * ```
 */
function modifiedObject<T extends object>(
  oldObject: T,
  newObject: Partial<T> | undefined,
  deepKeys: (keyof T)[] = [],
): { modified: T; changeLog: Partial<T> } {
  if (newObject === undefined) {
    return { modified: oldObject, changeLog: {} };
  }
  // If there wasn't actually an oldObject, the best we can do is return the newObject,
  // even though it's only a Partial<T> so we have to cast it.
  if (typeof oldObject !== 'object' || oldObject === null) {
    return { modified: newObject as T, changeLog: {} };
  }
  if (Array.isArray(oldObject) && Array.isArray(newObject)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { modified: [...oldObject, ...newObject], changeLog: {} } as any;
  }

  const changedKeys = (Object.keys(newObject) as Array<keyof T>).filter(k => (
    !deepKeys.includes(k) && oldObject[k] !== undefined && oldObject[k] !== newObject[k]
  ));
  const changeLog: Partial<T> = {};
  changedKeys.forEach((k) => { changeLog[k] = oldObject[k]; });

  const deepModified = {} as Record<keyof T, unknown>;
  const deepLog = {} as Record<keyof T, unknown>;
  deepKeys.forEach((dk) => {
    const { modified, changeLog } = modifiedObject(oldObject[dk], newObject[dk]);
    deepModified[dk] = modified;
    // If nothing on this deep object changed, then don't include its entry in the change log.
    let hasChanges = false;
    // eslint-disable-next-line guard-for-in, no-restricted-syntax, @typescript-eslint/no-unused-vars
    for (const _ in changeLog) {
      hasChanges = true;
      break;
    }
    if (hasChanges) {
      deepLog[dk] = changeLog;
    }
  });

  return {
    modified: { ...oldObject, ...newObject, ...deepModified },
    changeLog: { ...changeLog, ...deepLog },
  };
}

function wrapError<Code extends string, Data extends MableErrorData>(opts: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oldOpts: MableErrorProperties<Code, any>;
  oldWrapStack?: Partial<MableErrorProperties<Code, unknown>>[];
  stack?: string;
  newOpts?: Partial<MableErrorProperties<Code, Data>>;
}) {
  const { oldOpts, oldWrapStack, newOpts } = opts;
  const { modified, changeLog } = modifiedObject(oldOpts, newOpts, ['logData', 'fingerprint']);
  const retVal = new MableError<Code, Data>({ ...modified, code: modified.code });
  retVal.wrapStack = (oldWrapStack ?? []).concat(changeLog);
  return retVal;
}

export function ensureMableError<Code extends string, Data extends MableErrorData = unknown>(
  error: unknown,
  opts?: MableErrorOptions<Code, Data>,
): MableError<Code, Data> {
  // If the error is already a MableError and there weren't any opts passed in, then we're done.
  if (isMableError<Code>(error) && !opts) return error as MableError<Code, Data>;

  const options = optionsToDefaultsAndOverrides<Code, Data>(opts);

  let oldOpts: MableErrorProperties<Code> = {
    code: 'UnknownError' as Code,
    message: 'Unknown error',
    status: 500,
    logData: {},
    data: undefined,
  };
  let oldWrapStack: Partial<MableErrorProperties<Code>>[] | undefined;

  if (isMableError<Code>(error)) {
    oldOpts = error.toJSON();
    oldWrapStack = error.wrapStack;
    oldOpts.stack = error.stack;
    oldOpts.dontReport = error.dontReport;
    oldOpts.data = error.data;
    oldOpts.fingerprint = error.fingerprint;
  } else if (typeof error === 'object' && error !== null) {
    if (hasOwnProperty(error, 'stack')) {
      oldOpts.stack = `${error.stack}`;
    }
    if (hasOwnProperty(error, 'name')) {
      oldOpts.code = error.name as Code;
      // 401 if this is a JWT validation error: https://github.com/auth0/express-jwt#error-handling
      if (error.name === 'UnauthorizedError') {
        oldOpts.status = 401;
      }
    }
    // If the error has a code, we'll take it.
    if (hasOwnProperty(error, 'code')) {
      oldOpts.code = error.code as Code;
    }
    if (hasOwnProperty(error, 'message')) {
      oldOpts.message = `${error.message}`;
    }

    // HubSpot SDK errors and maybe others come with a 'response' property.
    // Generally it's an object with a whole bunch of methods and stuff, so we can't just log the whole thing
    // because it's huge, but we do want to extract the useful bits.
    if (error && typeof error === 'object' && hasOwnProperty(error, 'response') && error.response && typeof error.response === 'object') {
      oldOpts.logData = oldOpts.logData ?? {};
      if (hasOwnProperty(error.response, 'body')) {
        oldOpts.logData.responseBody = error.response.body;
      }
      if (hasOwnProperty(error.response, 'headers')) {
        oldOpts.logData.responseHeaders = error.response.headers;
      }
    }

    // AggregateError has an `errors` property, but we can't reference AggregateError without changing our TypeScript target.
    // Normalize `AggregateError.errors` into `logData.errors`, like errors created by `aggregateErrors`.
    if (error && typeof error === 'object' && hasOwnProperty(error, 'errors') && Array.isArray(error.errors)) {
      oldOpts.logData = oldOpts.logData ?? {};
      oldOpts.logData.errors = error.errors.map(error => ensureMableError(error));
    }
  }

  const optsWithDefaults = mergeMableErrorProperties<Code, unknown, Data>(oldOpts, options.defaults);

  return wrapError<Code, Data>({ oldOpts: optsWithDefaults, oldWrapStack, newOpts: options.overrides });
}

export const mableErrorFromIOTSValidationError = (validationError: t.ValidationError, opts?: {
  displayTitle?: string;
  displayMessage?: string;
}) => {
  const contextDump = validationError.context.map(entry => `key: ${entry.key}, type: ${entry.type}, actual: ${entry.actual}`).join('\n');
  const message = `Encoding/Decoding failed for value "${validationError.value}".\nMessage: ${validationError.message}. Context: ${contextDump}`;

  return new MableError({
    code: 'IOTSValidationError',
    message,
    displayTitle: opts?.displayTitle ?? 'An encoding or decoding error occurred.',
    displayMessage: message,
    data: undefined,
  });
};

export const isFatalError = (error: MableError) => {
  if (!error.status) {
    // Who knows what status was
    return false;
  }

  return error.status >= 400;
};

/**
 * Take a known existing error and decorate it with more data, and potentially a new error code
 * The soucecode position of this overload relative to the ones with `unknown` in the signiture is significant.
 * Overloads are processed in source order and `unknown` will always win if it comes first
*/
export function error<ECode extends string, EData extends MableErrorData, OCode extends string = ECode, OData extends MableErrorData = EData>(
  existingError: MableError<ECode, EData>,
  opts?: MableErrorOptions<OCode, OData>,
): MableError<OCode, OData>;

/** Create a new error with a code and other options */
export function error<Code extends string, Data extends MableErrorData>(
  code: Code,
  opts?: MableErrorOptions<Code, Data>
): MableError<Code, Data>;

/**
 * Take an existing error (or anything that's been thrown) and make a best-effort conversion into a MableError
 * with ensureMableError. Does nothing if the error is already a MableError. Otherwise, the given options
 * overwrite the best-effort options extracted from the non-Mable error.
 */
export function error<Code extends string, Data extends MableErrorData>(
  existingError: unknown,
  opts?: MableErrorOptions<Code, Data>,
): MableError<Code, Data>;

export function error<Code extends string, Data extends MableErrorData>(
  codeOrError: Code | unknown,
  opts?: MableErrorOptions<Code, Data>,
) {
  if (typeof codeOrError === 'string') {
    const code = codeOrError as Code;
    const message = (() => {
      if (!opts) return undefined;
      if ('message' in opts) return opts.message;
      if ('displayMessage' in opts) return opts.displayMessage;
      return undefined;
    })() ?? '';
    return new MableError({
      code,
      message,
      data: undefined,
      ...(opts ?? {}),
    });
  }
  return ensureMableError<Code, Data>(codeOrError, opts);
}

export function aggregateErrors(errors: (Error | MableError)[]) {
  if (errors.length === 1) {
    return ensureMableError(errors[0]);
  }

  // If multiple things threw, we have to throw something representing the combination of all those errors.
  // The built-in AggregateError is intended for this, but we'd have to change our TypeScript target to use it.

  // If they all had the same name/code, then reuse it.
  const name = uniq(errors.map(e => e.name)).length === 1
    ? errors[0].name
    : 'MultiError';
  return error(name, {
    dontReport: errors.every(e => isMableError(e) && e.dontReport),
    logData: { errors },
    data: { errors },
  });
}

/**
 * If the given array contains some errors, return a MableError that aggregates those errors.
 * Otherwise, return the array.
 *
 * Useful when you have an array of results, some of which might be errors, and you need a single MableError
 * to use with `isMableError`.
 */
export function maybeAggregateErrors<T>(
  maybeErrors: MableError | Array<T | MableError>,
): MableError | Array<T> {
  if (isMableError(maybeErrors)) return maybeErrors;
  const errors = maybeErrors.filter((x): x is MableError => isMableError(x));
  if (errors.length) {
    return aggregateErrors(errors);
  }
  return maybeErrors as Array<T>;
}


// Adapted from https://github.com/OliverJAsh/io-ts-reporters
export const formatValidationError = (error: t.ValidationError) => {
  const jsToString = (value: t.mixed) => (value === undefined ? 'undefined' : JSON.stringify(value));
  const { path } = error.context
    .reduce((acc, c) => {
      let pathComponent = '';
      // When one of the things in the context is an IntersectionType, the next thing
      // is just an index that says which part of the intersection this error is about.
      // It's not an actual path into the value that errored, so ignore it.
      if (c.key !== '' && !(acc.lastType instanceof t.IntersectionType || acc.lastType instanceof t.ExactType)) {
        pathComponent = Number.isNaN(Number(c.key)) ? `.${c.key}` : `[${c.key}]`;
      }
      return { path: acc.path + pathComponent, lastType: c.type };
    }, { path: '', lastType: null as unknown });

  const lastContext = error.context[error.context.length - 1];
  if (!lastContext) {
    // TODO?
  }
  const expectedType = lastContext.type.name;

  const firstContext = error.context[0];
  if (!firstContext) {
    // TODO?
  }
  const topLevelType = firstContext.type.name;

  return `\nExpecting ${expectedType}\n${
    path === '' ? '' : `at ${path} of ${topLevelType}\n`
  }but instead got: ${jsToString(error.value)}.`;
};

export function makeMableDecodeError(errors: t.Errors, decoding?: unknown, opts?: { status?: number }) {
  const formattedErrors = errors.map(formatValidationError).join('\n');
  const maybeDecoding = decoding ? `\n\nWhile attempting to decode value: ${JSON.stringify(decoding)}` : '';
  const message = `${formattedErrors}${maybeDecoding}`;
  return new MableError({
    code: 'DecodeError',
    message,
    status: opts?.status ?? 500,
    data: undefined,
  });
}
