var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMutation as externalUseMutation, useQuery as externalUseQuery, useQueryClient as externalUseQueryClient, } from '@tanstack/react-query';
import React, { useContext, useMemo } from 'react';
import { ensureMableError } from '@mablemarket/common-lib';
var ApiClientContext = React.createContext(null);
export var ApiClientProvider = ApiClientContext.Provider;
export var useApiClient = function () {
    var client = useContext(ApiClientContext);
    if (client === null) {
        throw new Error('useApiClient must be used inside of ApiClientProvider');
    }
    return client;
};
export function useQuery(routeName, input, options) {
    var _this = this;
    var client = useApiClient();
    var req = externalUseQuery(__assign({ queryKey: [routeName, input], queryFn: function (_a) {
            var _b = _a.queryKey, routeName = _b[0], input = _b[1];
            return __awaiter(_this, void 0, void 0, function () {
                var abortController, promise, promiseWithCancel;
                return __generator(this, function (_c) {
                    abortController = new AbortController();
                    promise = new Promise(function (resolve, reject) {
                        client
                            .req(routeName, input, { abortController: abortController, errorMiddlewares: options === null || options === void 0 ? void 0 : options.errorMiddlewares })
                            .then(function (res) { return resolve(res.body); })
                            .catch(function (e) { return reject(e); });
                    });
                    promiseWithCancel = Object.assign(promise, {
                        cancel: function () {
                            abortController.abort();
                        },
                    });
                    return [2 /*return*/, promiseWithCancel];
                });
            });
        } }, options));
    var modifiedReq = useMemo(function () {
        return (__assign(__assign({}, req), { isLoadingNewData: req.isLoading || (req.isPreviousData && req.isFetching) }));
    }, [req]);
    return modifiedReq;
}
export var dontReportErrorMiddleware = function (codesToNotReport) {
    return function (_a) {
        var err = _a.err;
        if (codesToNotReport.includes(err.code)) {
            return ensureMableError(err, { dontReport: true });
        }
        return err;
    };
};
export function useMutation(routeName, options) {
    var client = useApiClient();
    return externalUseMutation(__assign({ mutationFn: function (input) {
            var abortController = new AbortController();
            var promise = new Promise(function (resolve, reject) {
                client
                    .req(routeName, input, { abortController: abortController, errorMiddlewares: options === null || options === void 0 ? void 0 : options.errorMiddlewares })
                    .then(function (res) { return resolve(res.body); })
                    .catch(function (e) { return reject(e); });
            });
            var promiseWithCancel = Object.assign(promise, {
                cancel: function () {
                    abortController.abort();
                },
            });
            return promiseWithCancel;
        } }, options));
}
export function useQueryClient() {
    var client = externalUseQueryClient();
    // We unfortunately have to cast this through unknown, as we make a stronger
    // guarantee on the return type of the data for the get/set functions than
    // react-query
    return client;
}
