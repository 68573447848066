var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export var baseMableWebURLFromWindow = function () {
    var _a, _b;
    var hostname = (_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    if (!hostname) {
        return undefined;
    }
    if (hostname.startsWith('localhost')) {
        return 'http://localhost:3002/';
    }
    if (hostname.startsWith('staging')) {
        return 'https://staging.meetmable.com/';
    }
    return 'https://www.meetmable.com/';
};
export var baseAdminWebURLFromWindow = function () {
    var _a, _b;
    var hostname = (_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    if (!hostname) {
        return undefined;
    }
    if (hostname.startsWith('localhost')) {
        return 'http://localhost:3001/';
    }
    if (hostname.startsWith('staging')) {
        return 'https://admin.staging.meetmable.com/';
    }
    return 'https://admin.meetmable.com/';
};
export var baseAPIURLFromWindow = function () {
    var _a, _b;
    var hostname = (_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    if (!hostname) {
        return undefined;
    }
    if (hostname.startsWith('localhost')) {
        return 'http://localhost:3000/';
    }
    if (hostname.startsWith('staging')) {
        return 'https://api.staging.meetmable.com/';
    }
    return 'https://api.meetmable.com/';
};
export var honeycombDataset = function () {
    var hostname = window.location.hostname.toLowerCase();
    if (hostname.includes('staging') || hostname.includes('localhost')) {
        return 'staging-mable';
    }
    return 'prod-mable';
};
export var savedInviteKey = 'saved-invite-key';
// inspired by https://medium.com/trabe/using-bem-conventions-in-css-modules-leveraging-custom-webpack-loaders-fd985f72bcb2
// Camel Cases all sass/css module class names, as well as breaking up BEM
// identifiers into JS friendly sub objects. Modifiers are prefixed by a $
// This could be done via a modification to the webpack, as presented in the
// article above. Rather than get to deep into customizing our webpack config
// this early, we can use this function to transform the default styles object.
export var bemify = (function () {
    var camelCased = function (s) { return s && s.replace(/-([a-z0-9])/g, function (g) { return g[1].toUpperCase(); }); };
    var modifierPrefix = '$';
    return function (styles) {
        return Object.keys(styles)
            .sort()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .reduce(function (acc, identifier) {
            var _a, _b, _c;
            var _d = identifier.split('--'), rawBlockElement = _d[0], rawModifier = _d[1];
            var _e = rawBlockElement.split('__'), rawBlock = _e[0], rawElement = _e[1];
            // Camelcase each part.
            var block = camelCased(rawBlock);
            var element = camelCased(rawElement);
            var modifier = camelCased(rawModifier);
            // Get the exported className for the identifier
            var className = styles[identifier];
            // Element modifier. Add the modifier toString placeholder
            // to the block element
            if (element && modifier) {
                acc[block] = __assign(__assign({}, acc[block]), (_a = {}, _a[element] = __assign(__assign({}, acc[block][element]), (_b = {}, _b[modifierPrefix + modifier] = {
                    _bem_: true,
                    toString: function () { return className; },
                }, _b)), _a));
                // Element or block modifier. Add the element/modifier toString
                // placeholder to the the block
            }
            else if (modifier || element) {
                acc[block] = __assign(__assign({}, acc[block]), (_c = {}, _c[element || "".concat(modifierPrefix).concat(modifier)] = {
                    _bem_: true,
                    toString: function () { return className; },
                }, _c));
                // New block. Add the toString placeholder
            }
            else {
                acc[block] = {
                    _bem_: true,
                    toString: function () { return className; },
                };
            }
            return acc;
        }, {});
    };
})();
export var cn = function () {
    var classNames = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classNames[_i] = arguments[_i];
    }
    return (classNames
        .filter(function (elem) { return Boolean(elem); })
        .map(function (elem) { return ((Array.isArray(elem) && elem.length > 0) ? cn.apply(void 0, elem) : "".concat(elem)); })
        .join(' '));
};
export var downloadAsFile = function (_a) {
    var _b;
    var fileName = _a.fileName, fileType = _a.fileType, data = _a.data;
    var file = new Blob([data], { type: fileType });
    var fileURL = URL.createObjectURL(file);
    var link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    link.click();
    // IE11 does not support this method
    (_b = link.remove) === null || _b === void 0 ? void 0 : _b.call(link);
    // free up memory
    window.addEventListener('focus', function (e) { return URL.revokeObjectURL(link.href); }, { once: true });
};
export var openPdfResponse = function (res, filename) { return __awaiter(void 0, void 0, void 0, function () {
    var buffer;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, res.arrayBuffer()];
            case 1:
                buffer = _a.sent();
                downloadAsFile({
                    data: buffer,
                    fileName: "".concat(filename, ".pdf"),
                    fileType: 'application/pdf',
                });
                return [2 /*return*/];
        }
    });
}); };
export var openPngResponse = function (res, filename) { return __awaiter(void 0, void 0, void 0, function () {
    var buffer;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, res.arrayBuffer()];
            case 1:
                buffer = _a.sent();
                downloadAsFile({
                    data: buffer,
                    fileName: "".concat(filename, ".png"),
                    fileType: 'image/png',
                });
                return [2 /*return*/];
        }
    });
}); };
export var openCsvResponse = function (res, filename) { return __awaiter(void 0, void 0, void 0, function () {
    var buffer;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, res.arrayBuffer()];
            case 1:
                buffer = _a.sent();
                downloadAsFile({
                    data: buffer,
                    fileName: "".concat(filename, ".csv"),
                    fileType: 'text/csv',
                });
                return [2 /*return*/];
        }
    });
}); };
export var openPdfInNewTab = function (response) { return __awaiter(void 0, void 0, void 0, function () {
    var buffer, file, fileURL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, response.arrayBuffer()];
            case 1:
                buffer = _a.sent();
                file = new Blob([buffer], { type: 'application/pdf' });
                fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank');
                return [2 /*return*/];
        }
    });
}); };
