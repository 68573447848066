import React from 'react';

import useNextLocation, { NextLocation } from '../hooks/useNextLocation';
import { matchPath } from '../hooks/useRouteMatch';

export interface RouteProps {
  location?: NextLocation;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any>;
  children?: React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

// Switch will inject the computedMatch prop so we don't have to recalculate,
// but normal callers should not be able to set it
const Route: React.FC<RouteProps> = (props: RouteProps & { computedMatch?: boolean }) => {
  const location = useNextLocation();
  const { children, component, computedMatch = false } = props;

  const match = computedMatch
    || matchPath(location.pathname, {
      ...props,
      path: props.path || '/',
      location,
    });

  if (match) {
    if (children) {
      return (
        <>
          {children}
          {' '}
        </>
      );
    }
    if (component) {
      return React.createElement(component);
    }
  }
  return null;
};

export default Route;
