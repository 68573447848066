var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import NextLink from 'next/link';
import React, { useRef } from 'react';
import { mergeProps, useHover, useLink } from 'react-aria';
import { backgrounds } from '../../styles/theme.css';
import { composeRefs } from '../../util';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import Button, { useButtonStyles } from '../Button';
import Flex from '../Flex';
import Loading from '../Loading';
import Text from '../Text';
import { matchProtocolUrl, stringifyHref } from '../TextLink';
import * as styles from './ButtonLink.css';
export var ButtonLink = React.forwardRef(function (props, ref) {
    var givenDisabled = props.disabled, isLoading = props.isLoading, external = props.external, href = props.href, className = props.className, _a = props.variant, variant = _a === void 0 ? 'default' : _a, _b = props.palette, palette = _b === void 0 ? 'orangeCTA' : _b, children = props.children, _c = props.size, size = _c === void 0 ? 'medium' : _c, replace = props.replace, scroll = props.scroll, shallow = props.shallow, passHref = props.passHref, prefetch = props.prefetch, locale = props.locale, onPress = props.onPress, onPressStart = props.onPressStart, onPressEnd = props.onPressEnd, onPressChange = props.onPressChange, onPressUp = props.onPressUp, rest = __rest(props, ["disabled", "isLoading", "external", "href", "className", "variant", "palette", "children", "size", "replace", "scroll", "shallow", "passHref", "prefetch", "locale", "onPress", "onPressStart", "onPressEnd", "onPressChange", "onPressUp"]);
    var disabled = givenDisabled || isLoading;
    var linkRef = useRef(null);
    var stringHref = stringifyHref(href);
    var isExternal = external || matchProtocolUrl(stringHref);
    var _d = useLink(__assign(__assign({}, rest), { onPress: onPress, onPressStart: onPressStart, onPressEnd: onPressEnd, onPressChange: onPressChange, onPressUp: onPressUp, isDisabled: disabled }), linkRef), linkProps = _d.linkProps, isPressed = _d.isPressed;
    var _e = useHover({
        isDisabled: disabled,
    }), hoverProps = _e.hoverProps, isHovered = _e.isHovered;
    var buttonStyles = useButtonStyles({
        className: className,
        disabled: disabled,
        isHovered: isHovered,
        isLoading: isLoading,
        isPressed: isPressed,
        palette: palette,
        variant: variant,
        size: size,
    });
    var Anchor = (<Flex as='a' display='inline-flex' position='relative' target={isExternal ? '_blank' : undefined} rel={isExternal ? 'noopener noreferrer' : undefined} href={stringHref} {...mergeProps(hoverProps, rest)} 
    // We want this to override click handlers
    {...linkProps} ref={composeRefs(linkRef, ref)} className={[styles.linkReset, buttonStyles.className]} fontSize={buttonStyles.fontSize} justifyContent='center'>
      <Flex visibility={isLoading ? 'hidden' : undefined} fontSize={buttonStyles.fontSize} alignItems='center'>
        {childrenAreLiteral(children)
            ? <Text foreground='inherit' fontSize={buttonStyles.fontSize}>{children}</Text>
            : children}
      </Flex>
      {isLoading && (<Flex position='absolute' top={0} left={0} placeItems='center' minHeight='100%' minWidth='100%'>
          <Loading strokeWidth={8} color={(backgrounds[palette].lightness === 'dark' && variant === 'default')
                ? 'white'
                : 'grey'} size={30}/>
        </Flex>)}
    </Flex>);
    if (isExternal) {
        return Anchor;
    }
    return (<NextLink href={stringHref} scroll={scroll} shallow={shallow} replace={replace} passHref={passHref} prefetch={prefetch} locale={locale}>
      {Anchor}
    </NextLink>);
});
ButtonLink.displayName = 'ButtonLink';
export default Object.assign(ButtonLink, {
    Bleed: Button.Bleed,
});
