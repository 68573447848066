var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext } from 'react';
import { flexBasisTheme, gridAreaTheme, gridColumnEndTheme, gridColumnStartTheme, gridRowEndTheme, gridRowStartTheme, sprinkles } from '../styles/sprinkles.css';
import { backgrounds } from '../styles/theme.css';
import { assignResponsiveProperty } from '../styles/utils';
import { cn } from '../webutils/webutils';
var BoxDefaultElement = 'div';
export var seperateSprinklesProps = function (sprinkles, props) {
    var sprinklesHolder = {};
    var cleanHolder = {};
    Object.keys(props).forEach(function (key) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var value = props[key];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (sprinkles.properties.has(key)) {
            sprinklesHolder[key] = value;
        }
        else {
            cleanHolder[key] = value;
        }
    });
    var sprinklesProps = sprinklesHolder;
    var cleanProps = cleanHolder;
    return { cleanProps: cleanProps, sprinklesProps: sprinklesProps };
};
export var createBox = function (sprinkles, defaultClassName) {
    var Box = React.forwardRef(function (_a, ref) {
        var is = _a.as, props = __rest(_a, ["as"]);
        var Element = is || BoxDefaultElement;
        var _b = seperateSprinklesProps(sprinkles, props), sprinklesProps = _b.sprinklesProps, cleanProps = _b.cleanProps;
        return React.createElement(Element, __assign(__assign({}, cleanProps), { ref: ref, className: cn(defaultClassName, props.className, sprinkles(sprinklesProps)) }));
    });
    return Box;
};
/** For use by common-react-lib layout components early, as it exposes flex/grid
 * props that should only be used through the Flex/Grid components
 */
export var InternalBox = React.forwardRef(function (_a, ref) {
    var is = _a.as, flexBasis = _a.flexBasis, gridArea = _a.gridArea, gridColumnStart = _a.gridColumnStart, gridColumnEnd = _a.gridColumnEnd, gridRowStart = _a.gridRowStart, gridRowEnd = _a.gridRowEnd, style = _a.style, props = __rest(_a, ["as", "flexBasis", "gridArea", "gridColumnStart", "gridColumnEnd", "gridRowStart", "gridRowEnd", "style"]);
    var Element = is || BoxDefaultElement;
    var _b = seperateSprinklesProps(sprinkles.atoms, props), sprinklesProps = _b.sprinklesProps, cleanProps = _b.cleanProps;
    var flexBasisProps = assignResponsiveProperty(flexBasisTheme, flexBasis);
    var gridAreaProps = assignResponsiveProperty(gridAreaTheme, gridArea);
    var gridColumnStartProps = assignResponsiveProperty(gridColumnStartTheme, gridColumnStart);
    var gridColumnEndProps = assignResponsiveProperty(gridColumnEndTheme, gridColumnEnd);
    var gridRowStartProps = assignResponsiveProperty(gridRowStartTheme, gridRowStart);
    var gridRowEndProps = assignResponsiveProperty(gridRowEndTheme, gridRowEnd);
    var Component = React.createElement(Element, __assign(__assign({}, cleanProps), { 
        // eslint-disable-next-line prefer-object-spread
        style: Object.assign({}, style, flexBasisProps.style, gridAreaProps.style, gridColumnStartProps.style, gridColumnEndProps.style, gridRowStartProps.style, gridRowEndProps.style), ref: ref, className: cn(sprinkles.baseClass, props.className, sprinkles.atoms(__assign({ fontSize: props.fontSize === undefined ? 'medium' : undefined, minWidth: 0 }, sprinklesProps)), flexBasisProps.className, gridAreaProps.className, gridColumnStartProps.className, gridColumnEndProps.className, gridRowStartProps.className, gridRowEndProps.className) }));
    if (!sprinklesProps.foreground && sprinklesProps.background) {
        return (<backgroundContext.Provider value={sprinklesProps.background}>
        {Component}
      </backgroundContext.Provider>);
    }
    return Component;
});
/**
 * The base component with styling and minimal behavior
 * @param className - You can add your own styles, and you will have to as the
 * style props purposefully do not fully cover all possible requirements. Prefer
 * style props when available as they sometimes have compounding behavior.
 * Do not set the `boxShadow` property directly if you intend to use the
 * `border` or `shadow` props, as they all rely on `boxShadow`. Instead, set
 * `boxShadowVar` from `sprinkles.css.ts` on the element and it will behave as
 * expected.
 * @param padding - This is one of many responsive properties. The types may not
 * make it obvious at first glance, but its value can either be specified
 * normally, `padding='space-1'` or via a responsive object `padding={{ desktop:
 * 'space-1', tablet: 'space-0.5', phone: 'space-0.25' }}`
 * For a full listing of responsive properties, see `responsriveProperties` in
 * `atomValues`
*/
var Box = InternalBox;
export default Box;
export var backgroundContext = React.createContext(null);
export var useForeground = function (props) {
    var _a, _b;
    var backgroundContextValue = useContext(backgroundContext);
    var background = (_a = props.background) !== null && _a !== void 0 ? _a : backgroundContextValue;
    if (props.foreground)
        return props.foreground;
    // Automatic default foreground value, overwritten by props value
    var foreground = (background && background in backgrounds)
        ? (_b = backgrounds[background]) === null || _b === void 0 ? void 0 : _b.foreground
        : undefined;
    return foreground;
};
