import BigNumber from 'bignumber.js';
import * as datefns from 'date-fns';
import { isRight, map } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import mapValues from 'lodash/mapValues';
import isEmail from 'validator/lib/isEmail';

import { parseBarcode } from './barcodeUtils';
import { formatMoney } from './Formatters';
import { isMableError, makeMableDecodeError } from './MableError';
import { NonUndefined } from './types';
import { assertExhaustive } from './util';

export const getValueOrThrow = <A extends unknown>(either: t.Validation<A>): A => {
  if (isRight(either)) return either.right;
  throw makeMableDecodeError(either.left);
};

type RefineWithError = {
  <C extends t.Any, D extends t.TypeOf<C>>(
    codec: C,
    predicate: (u: t.TypeOf<C>) => u is D,
    error: string | ((u: t.TypeOf<C>) => string),
    name?: string,
  ): t.RefinementType<C, D, t.OutputOf<C>, t.InputOf<C>>;
  <C extends t.Any>(
    codec: C,
    predicate: (u: t.TypeOf<C>) => boolean,
    error: string | ((u: t.TypeOf<C>) => string),
    name?: string,
  ): t.RefinementType<C, t.TypeOf<C>, t.OutputOf<C>, t.InputOf<C>>;
}

export const refineWithError: RefineWithError = <C extends t.Any>(
  codec: C,
  predicate: (u: t.TypeOf<C>) => boolean,
  error: string | ((i: unknown) => string),
  name = `(${codec.name} | ${t.getFunctionName(predicate)})`,
) => {
  return new t.RefinementType<C, t.TypeOf<C>, t.OutputOf<C>, t.InputOf<C>>(
    name,
    (u): u is t.TypeOf<C> => codec.is(u) && predicate(u),
    (i, c) => {
      const e = codec.validate(i, c);
      if ('left' in e) {
        return e;
      }
      const a = e.right;
      return predicate(a) ? t.success(a) : t.failure(a, c, typeof error === 'string' ? error : error(i));
    },
    codec.encode,
    codec,
    predicate,
  );
};

export const brandWithError = <C extends t.Any, N extends string, B extends { readonly [K in N]: symbol }>(
  codec: C,
  predicate: (i: t.TypeOf<C>) => i is t.Branded<t.TypeOf<C>, B>,
  error: string | ((i: unknown) => string),
  name: N,
): t.BrandC<C, B> => {
  return refineWithError(codec, predicate, error, name);
};

export interface SlugBrand {
  readonly Slug: unique symbol;
}
export const Slug = brandWithError(
  t.string,
  // TODO: Remove `.` from this codec after all slugs with `.` are removed from the DB.
  (s): s is t.Branded<string, SlugBrand> => (/^[-_0-9a-zA-Z.]+$/).test(s),
  // Also include `.` to avoid changing existing seller slugs. But don't let anyone know.
  'Slug may only contain alphanumeric characters, dashes and underscores',
  'Slug',
);

export type Slug = t.TypeOf<typeof Slug>;

export const Nullable = <Codec extends t.Mixed>(x: Codec) => t.union([x, t.null]);

export interface OneOf {
  <C extends t.Mixed>(codec: [C]): C;
  <CS extends [t.Mixed, t.Mixed, ...t.Mixed[]]>(codecs: CS): t.UnionC<CS>;
}
/** Api spec `OneOf` codec that wraps unions that also supports a single codec
 * Clients are able to decode open `oneOf`'s with unexpected values. This makes it
 * valid to write a `oneOf` in the api spect that only specifies a single type
 * with the ability of expanding it later.
 * But on the server we need to be able to encode and decode with expected
 * values only. `t.union` expects multiple codecs so we need to work around the
 * single codec case.
  */
export const OneOf: OneOf = <CS extends [t.Mixed, t.Mixed, ...t.Mixed[]], C extends t.Mixed>(codecs: CS | [C]) => (
  codecs.length === 1 ? codecs[0] as C : t.union(codecs as CS)
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class EnumType<C extends { [key: string]: unknown }, A = any, O = A, I = unknown> extends t.Type<A, O, I> {
  readonly _tag: 'EnumType' = 'EnumType';

  constructor(
    name: string,
    is: EnumType<C, A, O, I>['is'],
    validate: EnumType<C, A, O, I>['validate'],
    encode: EnumType<C, A, O, I>['encode'],
    readonly keys: { [k in keyof C]: k },
  ) {
    super(name, is, validate, encode);
  }

  get values(): (keyof C)[] {
    return Object.keys(this.keys);
  }
}
export type EnumC<D extends { [key: string]: unknown }> = EnumType<D, keyof D>;
/** Union of string literals defined by given object keys
 * https://github.com/gcanti/io-ts#union-of-string-literals
 * On the server, all enums use `t.keyof`, so they will throw an error on unexpected values.
 * On the client this codec is used for closed enums that we never expect the
 * client to decode unexpected values with, usually enums only used as inputs.
 * Otherwise `api-client-support` defined `EnumOpen` to handle unexpected values
 * from api updates.
 */
export const Enum = <D extends { [key: string]: unknown }>(keys: D, name: string): EnumC<D> => {
  const keyOf = t.keyof(keys, name);
  return new EnumType(
    name,
    keyOf.is,
    keyOf.validate,
    keyOf.encode,
    mapValues(keys, (_v, k) => k) as { [k in keyof D]: k },
  );
};

/** used to handle unexpected values in open enums/oneOf's on the client */
export const unexpected = <C extends t.Any>(codec: C) => {
  const UnexpectedObject = t.exact(t.type({ __unexpected: codec }));
  return new t.Type<t.TypeOf<typeof UnexpectedObject>, t.TypeOf<C>, unknown>(
    `Unexpected(${codec.name})`,
    UnexpectedObject.is,
    (u, c) => (
      UnexpectedObject.is(u)
        ? t.success(u)
        : UnexpectedObject.validate({ __unexpected: u }, c)
    ),
    // eslint-disable-next-line no-underscore-dangle
    a => a.__unexpected,
  );
};

const AnyUnexpected = unexpected(t.unknown);
/** Removes Unexpecteds from unions created by open enums oneOf's */
export type ExcludeUnexpected<T extends unknown> = Exclude<T, t.TypeOf<typeof AnyUnexpected>>;
/** type guard to remove Unexpected's from open enums and oneOf's */
export const isNotUnexpected = <I extends unknown>(i: I): i is ExcludeUnexpected<I> => (
  !AnyUnexpected.is(i)
);
/** The opposite of `isNotUnexpected`, for convenience */
export const isUnexpected = AnyUnexpected.is;

/** Creates type guards for objects that have am open union at `key`
 * that will assert that there are no unexpecteds. For example, an EnumOpen or a
 * OneOfOpen.
 * This will automatically infer `T` when used inside of `filter`, but other `T`
 * will need to be manually specified due to the constraints of type guards.
 *
 * Examples of type guard where `productStorage extends { type: 'frozen' | 'fresh' | 'EnumUnexpected' }`
 * `productStorages.filter(isNotUnexpectedByKey('storageType')).map(storage => storage.type // will be a string enum)`
 * `if (isNotUnexpectedByKey<typoef productStorage, 'storageType'>('storageType')(productStorage)) storage.type // will be a string enum`
 */
export const isNotUnexpectedByKey = <T extends {}, K extends keyof T>(key: K) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (t: any, _i?: number, _a?: T[]): t is { [k in keyof T]: k extends K ? ExcludeUnexpected<T[K]> : T[k] } => isNotUnexpected((t as T)[key]);
};

// The brand will keep the string type from consuming the string literals in the
// encoded union and follow the value along if you try to use it
export interface EnumUnexpectedStringBrand {
  readonly EnumUnexpectedString: unique symbol;
}
/** Value of unexpected string from an open enum decode */
export type EnumUnexpectedString = t.Branded<string, EnumUnexpectedStringBrand>;
const EnumUnexpectedString = t.brand(
  t.string,
  (n): n is EnumUnexpectedString => t.string.is(n),
  'EnumUnexpectedString',
);

// eslint-disable-next-line no-underscore-dangle
const _EnumUnexpected = unexpected(EnumUnexpectedString);
const EnumUnexpected: typeof _EnumUnexpected = new t.Type(
  _EnumUnexpected.name,
  _EnumUnexpected.is,
  // wrap the normal validation/decode to secretly insert a toString method to
  // help avoid runtime errors if this accidentally ends up in something that we
  // intended to give an enum string (ex. a template string).
  (u, c) => (
    pipe(
      _EnumUnexpected.validate(u, c),
      map(
        a => ({
          ...a,
          // eslint-disable-next-line no-underscore-dangle
          toString: () => a.__unexpected,
        } as typeof a),
      ),
    )
  ),
  _EnumUnexpected.encode,
);
/** object containing unexpected string from an open enum decode */
type EnumUnexpected = t.TypeOf<typeof EnumUnexpected>;

export type EnumOpenC<D extends { [key: string]: unknown }> = EnumType<D, keyof D | EnumUnexpectedString>;
export type EnumOpenValues<S extends string> = S | EnumUnexpected;
/** An enum that decodes expected string literals and unexpected values to prevent
  * old clients from generating decode errors due to updated responses
 */
export const EnumOpen = <D extends { [key: string]: unknown }>(keys: D, name: string) => {
  const union = t.union([
    t.keyof(keys, name),
    EnumUnexpected,
  ], name);
  return new EnumType(
    name,
    union.is,
    union.validate,
    union.encode,
    mapValues(keys, (_v, k) => k) as { [k in keyof D]: k },
  );
};

/** An enum of string literals that does not support unexpected values
 * Equivalent to `Enum`, renamed to support code generation
 */
export const EnumClosed = Enum;

export const getEnumValue = <T extends string>(union: EnumOpenValues<T>): T | EnumUnexpectedString => (
  // eslint-disable-next-line no-underscore-dangle
  isNotUnexpected(union) ? union : (union as EnumUnexpected).__unexpected
);

/** Publicly available copy of the internal io-ts getProps method from
 * https://github.com/gcanti/io-ts/blob/e619fb1a769afb0dde3b17f4fc3ae28caa004466/src/index.ts#L516
*/
export function getProps(codec: t.HasProps): t.Props {
  // eslint-disable-next-line no-underscore-dangle
  const tag = codec._tag;
  switch (tag) {
    case 'RefinementType':
    case 'ReadonlyType':
      return getProps(codec.type);
    case 'InterfaceType':
    case 'StrictType':
    case 'PartialType':
      return codec.props;
    case 'IntersectionType':
      return codec.types.reduce<t.Props>((props, type) => Object.assign(props, getProps(type)), {});
    default:
      throw assertExhaustive(tag);
  }
}


export type Zipcode = string;

export const isZipcode = (s: unknown): s is Zipcode => {
  if (typeof s !== 'string') return false;
  const match = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return match.test(s);
};

export const Zipcode = new t.Type(
  'Zipcode',
  (u): u is Zipcode => isZipcode(u),
  (u, c) => {
    if (isZipcode(u)) {
      return t.success(u);
    }
    return t.failure(u, c);
  },
  (a) => {
    return a;
  },
);

export type PhoneNumber = string;

export const isPhoneNumber = (s: unknown): s is PhoneNumber => {
  if (typeof s !== 'string') return false;
  const match = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return match.test(s);
};

export const PhoneNumber = new t.Type(
  'PhoneNumber',
  (u): u is PhoneNumber => isPhoneNumber(u),
  (u, c) => {
    if (isPhoneNumber(u)) {
      return t.success(u);
    }
    return t.failure(u, c);
  },
  (a) => {
    return a;
  },
);

export type NonEmptyStringOrUndefined = string | undefined;
export const isNonEmptyStringOrUndefined = (s: unknown): s is NonEmptyStringOrUndefined => {
  if (s === undefined) return true;
  if (typeof s !== 'string') return false;
  const trimmed = s.trim();
  if (trimmed === '') return false;
  return true;
};

export const NonEmptyStringOrUndefined = new t.Type(
  'NonEmptyStringOrUndefined',
  (u): u is NonEmptyStringOrUndefined => isNonEmptyStringOrUndefined(u),
  (u, c) => {
    if (typeof u === 'string' && u.trim() === '') {
      return t.success(undefined);
    }
    if (isNonEmptyStringOrUndefined(u)) {
      return t.success(u);
    }
    return t.failure(u, c);
  },
  (a) => {
    return a;
  },
);

export const makePercentCodec = (decimals: number) => new t.Type(
  'Percent',
  (u): u is BigNumber => u instanceof BigNumber,
  (u, c) => {
    if (typeof u === 'string' && /^\d+(\.\d+)?%$/.test(u)) {
      return t.success(new BigNumber(u.replace('%', '')).dividedBy(100));
    }
    return t.failure(u, c);
  },
  (a) => {
    return `${new BigNumber(a).multipliedBy(100).toFixed(decimals)}%`;
  },
);


export const makeMoneyCodec = (opts: Parameters<typeof formatMoney>[1]) => {
  return new t.Type(
    'Money',
    (u): u is BigNumber => u instanceof BigNumber,
    (u, c) => {
      if (typeof u === 'string' && /^\$?(\d{1,3}(,\d{3})*(\.\d{1,2})?|\d+(\.\d{1,2})?)$/.test(u)) {
        return t.success(new BigNumber(u.replace('$', '').replace(/,/g, '')));
      }
      return t.failure(u, c);
    },
    (a) => {
      return formatMoney(a, opts);
    },
  );
};

export const makeDateCodec = (format: string) => {
  return new t.Type(
    'FormattedDate',
    (u: unknown): u is Date => u instanceof Date,
    (u, c) => {
      if (typeof u === 'string') {
        return t.success(datefns.parse(u, format, new Date()));
      }
      return t.failure(u, c);
    },
    (a) => {
      return datefns.format(a, format);
    },
  );
};

export const NoWhitespaceString = new t.Type<string, string, unknown>(
  'NoWhitespaceString',
  (input: unknown): input is string => typeof input === 'string' && /^[\S]+$/.test(input),
  (input, context) => {
    if (typeof input !== 'string') {
      return t.failure(input, context);
    }
    const cleanedString = input.replace(/\s/g, '');
    return t.success(cleanedString);
  },
  t.identity,
);

export interface GtinBrand {
  readonly Gtin: unique symbol;
}

export const Gtin = brandWithError(
  NoWhitespaceString,
  (s): s is t.Branded<string, GtinBrand> => {
    const parsed = parseBarcode(s, { strict: true });
    if (isMableError(parsed)) {
      return false;
    }
    return parsed.isValid;
  },
  'Must be a valid GTIN',
  'Gtin',
);

export type Gtin = t.TypeOf<typeof Gtin>;

export interface Gtin14Brand extends GtinBrand {
  readonly Gtin14: unique symbol;
}
export const Gtin14 = brandWithError(
  Gtin,
  (s): s is t.Branded<string, Gtin14Brand> => (/^\d{14}$/).test(s) && Gtin.is(s),
  'Gtin14 is a 14 digit valid GTIN number',
  'Gtin14',
);
export type Gtin14 = t.TypeOf<typeof Gtin14>;

const isStringURL = (u: unknown): u is string => {
  if (typeof u !== 'string') return false;
  try {
    const x = new URL(u);
    return x !== undefined;
  } catch (e) {
    return false;
  }
};
export const StringURL = new t.Type(
  'StringURL',
  (u: unknown): u is string => isStringURL(u),
  (u, c) => {
    if (isStringURL(u)) {
      return t.success(u);
    }
    return t.failure(u, c);
  },
  (a) => {
    return a;
  },
);
export type StringURL = t.TypeOf<typeof StringURL>;

export const OneOfCodec = <T extends readonly string[]>(arr: T) => {
  return t.keyof(Object.fromEntries(arr.map(v => [v, null])) as Record<T[number], null>);
};

export const cloneCodec = <C extends t.Any>(t: C): C => {
  const r = Object.create(Object.getPrototypeOf(t));
  Object.assign(r, t);
  return r;
};

export const withValidate = <C extends t.Any>(codec: C, validate: C['validate'], name: string = codec.name): C => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const r: any = cloneCodec(codec);
  r.validate = validate;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  r.decode = (i: any) => validate(i, t.getDefaultContext(r));
  r.name = name;
  return r;
};

export const withMessage = <C extends t.Any>(codec: C, message: string | ((i: t.InputOf<C>, c: t.Context) => string)): C => {
  return withValidate(codec, (i, c) => {
    const res = codec.validate(i, c);
    if ('left' in res) {
      return t.failure(i, c, typeof message === 'string' ? message : message(i, c));
    }
    return res;
  });
};

export interface BoundedStringBrand<MinLength extends number = number, MaxLength extends number | undefined = undefined> {
  readonly BoundedString: unique symbol;
  readonly minLength: MinLength;
  readonly maxLength: MaxLength;
}

export function makeBoundedStringCodec<
  MinLength extends number = number,
  MaxLength extends number | undefined = undefined
>(
  min: MinLength,
  max: MaxLength,
) {
  return brandWithError(
    t.string,
    (s): s is t.Branded<string, BoundedStringBrand<MinLength, MaxLength>> => s.length >= min && (!max || s.length <= max),
    `Must be at least ${min} characters long${max ? ` and at most ${max} characters long` : ''}`,
    'BoundedString',
  );
}

// TS doesn't recognize that MinLength and MaxLength are used on the right side of the assignment
// so we need to disable the unused-vars rule here.
/* eslint-disable @typescript-eslint/no-unused-vars */
export type BoundedString<
  MinLength extends number = number,
  MaxLength extends number | undefined = undefined
> = t.TypeOf<ReturnType<typeof makeBoundedStringCodec<MinLength, MaxLength>>>;
/* eslint-enable @typescript-eslint/no-unused-vars */

export const ParcelDimensions = t.partial({
  length: t.number,
  width: t.number,
  height: t.number,
});
export type ParcelDimensions = t.TypeOf<typeof ParcelDimensions>;


export type ExactlyOneKey<O extends {}> =
  {
    [K in keyof O]: { [P in K]-?: NonUndefined<O[P]> } & Partial<Record<Exclude<keyof O, K>, never>>
  }[keyof O];

/**
 * Given a codec with `props`, return a new codec that wraps the original and:
 *   - Fails if multiple properties from `props` are defined
 *   - Fails if no properties from `props` are defined
 *   - Otherwise succeeds with a result stripped of any properties not defined in `props` (like `t.exact`)
 */
export const withExactlyOneKey = <C extends t.HasProps>(
  baseCodec: C,
  name?: string,
): t.Type<ExactlyOneKey<t.TypeOf<C>>, ExactlyOneKey<t.OutputOf<C>>, t.InputOf<C>> => {
  type BaseType = t.TypeOf<C>;
  type ExactlyOneKeyType = ExactlyOneKey<BaseType>;
  const keys = Object.keys(getProps(baseCodec)) as (keyof BaseType)[];
  /**
   * If exactly one key from keys is defined, then return that key.
   * Otherwise, return undefined.
   */
  const getDefinedKey = (input: BaseType) => {
    let definedKey: keyof BaseType | undefined;
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      // eslint-disable-next-line no-continue
      if (input[key] === undefined) continue;
      if (definedKey !== undefined) return undefined;
      definedKey = key;
    }
    return definedKey;
  };
  const exactlyOneKeyCodec = new t.Type<ExactlyOneKeyType, ExactlyOneKeyType, BaseType>(
    name ?? baseCodec.name,
    (input): input is ExactlyOneKeyType => {
      if (typeof input !== 'object') return false;
      const definedKey = getDefinedKey(input as BaseType);
      return definedKey !== undefined;
    },
    (input, context): t.Validation<ExactlyOneKeyType> => {
      const definedKey = getDefinedKey(input);
      if (definedKey === undefined) return t.failure(input, context);
      return t.success({ [definedKey]: input[definedKey] } as ExactlyOneKeyType);
    },
    (input): ExactlyOneKeyType => {
      const definedKey = getDefinedKey(input);
      if (definedKey === undefined) return input;
      return { [definedKey]: input[definedKey] } as ExactlyOneKeyType;
    },
  );
  const partialified = t.partial(getProps(baseCodec)) as typeof baseCodec;
  return partialified.pipe(exactlyOneKeyCodec);
};

// TODO RX for fixed precision numbers https://mable.atlassian.net/browse/ENG-6304
/**
 * Used to convert between a number and X12 formatted string that is a number with an assumed amount of
 * decimals. N2 is a number with 2 decimals, N0 is a number with 0 decimals, etc. e.g. An N2 formatted
 * number 123.45 becomes '12345'. R is a number with decimals included in the string.
 *
 * minLength and maxLength are optional parameters that can be used to enforce a minimum and maximum length of the encoded string.
 */
export const makeX12NumberCodec = (format: 'N' | 'N0' | 'N1' | 'N2' | 'N3' | 'N4' | 'N5' | 'N6' | 'N7' | 'R', minLength?: number, maxLength?: number) => {
  const assumedDecimals = (() => {
    if (format === 'R') return undefined;
    if (format === 'N') return 0;
    return parseInt(format.slice(1), 10);
  })();
  return new t.Type(
    'X12Number',
    (u): u is BigNumber => u instanceof BigNumber,
    (u, c) => {
      if (typeof u !== 'string') {
        return t.failure(u, c);
      }
      const number = new BigNumber(u);
      if (number.isNaN()) {
        return t.failure(u, c);
      }
      return assumedDecimals === undefined
        ? t.success(number)
        : t.success(number.div(new BigNumber(10).pow(assumedDecimals)));
    },
    (a) => {
      if (assumedDecimals === undefined) {
        const str = a.toFixed();
        const numberOfNonDigitCharacters = str.replace(/\d/g, '').length;
        // If length exceeds the maximum length, return a string with precision of maxLength minus the number of non-digit characters
        if (maxLength !== undefined && str.length > maxLength) {
          // If the number is less than 1 or greater than -1, we need to account for the
          // leading 0 in the final string as its not included in the precision
          const leadingZeroCount = (a.abs().isLessThan(1)) ? 1 : 0;
          return a.toPrecision(maxLength - numberOfNonDigitCharacters - leadingZeroCount);
        }
        // If length is less than the minimum length, return a string with precision of at least minLength.
        // This will pad 0s to the right of the decimal.
        if (minLength !== undefined && str.length < minLength) {
          // This could actually be a string that is minLength + 1 if we are adding a decimal point. This could be a problem in
          // a case where the minLength and maxLength are the same and the number we are encoding is an integer. In this case, we
          // are just going to manually add a decimal point to the end of the string without any trailing 0s. Another solution might
          // be to pad the left of the number with zeros. In reality I don't think the case of wanting to have an exact length string
          // for a number is going to happen, but this should suffice.
          if (minLength === maxLength && numberOfNonDigitCharacters === 0 && str.length === minLength - 1) {
            return `${a.toFixed()}.`;
          }
          return a.toPrecision(minLength);
        }
        // If no length constraints, return the string representation of the number
        return a.toFixed();
      }
      // Adjust by assumed decimal places 123.456 -> 12345.6
      return a.times(new BigNumber(10).pow(assumedDecimals))
        // Drop the decimal -> 12346
        .toFixed(0)
        // Left pad to the minimum length
        .padStart(minLength ?? 0, '0');
    },
  );
};
export type X12Number = t.TypeOf<ReturnType<typeof makeX12NumberCodec>>;

export const X12Date = new t.Type<Date, string, unknown>(
  'X12Date',
  (u): u is Date => u instanceof Date,
  (u, c) => {
    if (u instanceof Date) {
      return t.success(u);
    }
    const s = t.string.validate(u, c);
    if ('left' in s) return s;
    const dateString = (() => {
      // X12 dates are in the format YYYYMMDD
      if (!s.right.includes('-') && s.right.length === 8) {
        const year = s.right.slice(0, 4);
        const month = s.right.slice(4, 6);
        const day = s.right.slice(6, 8);
        return `${year}-${month}-${day}`;
      }
      return s.right;
    })();
    const d = new Date(dateString);
    return Number.isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
  },
  (a) => {
    if (typeof a === 'string') return a;
    const month = `${a.getMonth() + 1}`.padStart(2, '0');
    const day = `${a.getDate()}`.padStart(2, '0');
    return `${a.getFullYear()}${month}${day}`;
  },
);
export type X12Date = t.TypeOf<typeof X12Date>;

export interface X12TimeStringBrand {
  readonly X12TimeString: unique symbol;
}
export const X12TimeString = brandWithError(
  t.string,
  (s): s is t.Branded<string, X12TimeStringBrand> => /^(?:[01]\d|2[0-3])(?:[0-5]\d)(?:[0-5]\d)?(?:\d\d)?$/.test(s),
  'Must be a valid X12 time string HHMM(SSDD)',
  'X12TimeString',
);
export type X12TimeString = t.TypeOf<typeof X12TimeString>;


export interface EmailBrand {
  readonly Email: unique symbol;
}
export const Email = brandWithError(
  t.string,
  (s): s is t.Branded<string, EmailBrand> => isEmail(s, undefined),
  'Invalid email',
  'Email',
);
export type Email = t.TypeOf<typeof Email>;
