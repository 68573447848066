import React, { useEffect } from 'react';
import withLayout, { ComponentWithLayout, RenderLayoutFunction } from './withLayout';
import styles from '../App.module.scss';
import PrivateRoute from '../PrivateRoute';

const Bootstrap = (): JSX.Element | null => {
  useEffect(() => {
    document.body.classList.add('bootstrap');
    return () => document.body.classList.remove('bootstrap');
  }, []);
  return null;
};

type RenderOpts = {
  bootstrap?: boolean;
};

const makeRenderAdminLayout = (opts: RenderOpts): RenderLayoutFunction => children => {
  const { bootstrap = false } = opts;
  return (
    <PrivateRoute>
      <div className={styles.content}>
        {bootstrap ? <Bootstrap /> : null}
        {children}
      </div>
    </PrivateRoute>
  );
};

export const renderAdminLayout = makeRenderAdminLayout({ bootstrap: false });

export const withAdminLayout = (page: ComponentWithLayout, opts: RenderOpts) => withLayout(makeRenderAdminLayout(opts))(page);

