var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { pipe } from 'fp-ts/lib/function';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { notUndefined, optionalizeFunctionArgument } from '@mablemarket/common-lib';
import { ImageHelpers } from '@mablemarket/mable-lib';
import { breakpointsMax, mapCustomResponsiveValue } from '../../styles/breakpoints';
import { sprinkles } from '../../styles/sprinkles.css';
import { cn } from '../../webutils/webutils';
import Box from '../Box';
import * as styles from './Img.css';
var emptyGif = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
var svgAspectRatio = function (_a) {
    var width = _a.width, height = _a.height;
    return ("data:image/svg+xml,%3Csvg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 ".concat(width, " ").concat(height, "\"%3E%3C/svg%3E"));
};
var suffix = /\.\w+$/;
var srcToStrs = function (src, transform) {
    var defaultSrc = typeof src === 'string'
        ? src
        : optionalizeFunctionArgument(ImageHelpers.imageURLfromImage, 0)(src, transform);
    var webpSrc = typeof src === 'string' ? undefined : (defaultSrc === null || defaultSrc === void 0 ? void 0 : defaultSrc.replace(suffix, '.webp'));
    if (defaultSrc === webpSrc) {
        return { defaultSrc: defaultSrc };
    }
    return { defaultSrc: defaultSrc, webpSrc: webpSrc };
};
/** Replacement for `img` that can handle transforming cloudinary `Image`
 * objects and transforms into urls, sizing images to aspect ratios, and
 * automatically serving retina images to hidpi clients.
 */
var Img = React.forwardRef(function (props, ref) {
    var _a = __assign({ retinaSrc: undefined, transform: undefined }, props), src = _a.src, retinaSrc = _a.retinaSrc, _b = _a.transform, transform = _b === void 0 ? '' : _b, height = _a.height, width = _a.width, grow = _a.grow, className = _a.className, alt = _a.alt, _c = _a.lazy, lazy = _c === void 0 ? true : _c, rest = __rest(_a, ["src", "retinaSrc", "transform", "height", "width", "grow", "className", "alt", "lazy"]);
    var sources;
    if (typeof src === 'string') {
        sources = {
            normal: {
                src: src,
                retinaSrc: retinaSrc,
                width: width,
                retinaWidth: width !== undefined ? width * 2 : undefined,
            },
        };
    }
    else if (typeof transform === 'string') {
        var srcs = srcToStrs(src, transform);
        sources = {
            normal: { src: srcs.defaultSrc },
            webp: { src: srcs.webpSrc },
        };
    }
    else {
        // We can only auto generate retina images if the caller passes in fixed
        // dimensions/aspect ratio, as otherwise they may be relying on the
        // intrinsic image size for layout.
        var allowAutoRetina_1 = (width !== null && width !== void 0 ? width : height) !== undefined;
        sources = {
            normal: mapCustomResponsiveValue(transform.width, function (width) { return (width === undefined
                ? undefined
                : {
                    src: srcToStrs(src, transform.transform(width, 1)).defaultSrc,
                    width: width,
                    retinaSrc: allowAutoRetina_1 ? srcToStrs(src, transform.transform(width * 2, 2)).defaultSrc : undefined,
                    retinaWidth: width * 2,
                }); }),
            webp: mapCustomResponsiveValue(transform.width, function (width) { return (width === undefined
                ? undefined
                : {
                    src: srcToStrs(src, transform.transform(width, 1)).webpSrc,
                    width: width,
                    retinaSrc: allowAutoRetina_1 ? srcToStrs(src, transform.transform(width * 2, 2)).webpSrc : undefined,
                    retinaWidth: width * 2,
                }); }),
        };
    }
    if (height === undefined || width === undefined) {
        return (<picture 
        // These ensure picture has the same height as the contained `img`
        style={{ lineHeight: 0 }} className={sprinkles.atoms({ display: 'flex' })}>
        <ImageSource as='source' sources={sources.webp} webp/>
        <ImageSource {...rest} ref={ref} as='img' sources={sources.normal} webp={false} fallbackSrc={emptyGif} fallbackClassName={styles.empty} loading={lazy ? 'lazy' : undefined} alt={alt} className={className}/>
      </picture>);
    }
    return (<Box position='relative' style={{ maxWidth: grow ? '100%' : width, width: '100%', height: '100%' }}>
      <Box position='relative' style={{ paddingTop: "".concat(((height / width) * 100).toFixed(), "%"), height: 0 }}/>
      <picture style={{ lineHeight: 0 }} className={sprinkles.atoms({ display: 'flex' })}>
        <ImageSource as='source' sources={sources.webp} webp/>
        <ImageSource {...rest} ref={ref} as='img' sources={sources.normal} webp={false} fallbackSrc={svgAspectRatio({ width: width, height: height })} loading={lazy ? 'lazy' : undefined} alt={alt} className={cn(styles.image, grow && styles.grow, className)}/>
      </picture>
    </Box>);
    // Must cast as the forwardRef breaks the props 'src' discrimination for some reason
});
export default Object.assign(Img, { transforms: ImageHelpers.ImageTransforms });
var ImageSource = React.forwardRef(function (props, ref) {
    var _a, _b, _c, _d;
    var sources = props.sources, webp = props.webp, _e = props.as, is = _e === void 0 ? 'img' : _e, fallbackSrc = props.fallbackSrc, className = props.className, fallbackClassName = props.fallbackClassName, rest = __rest(props, ["sources", "webp", "as", "fallbackSrc", "className", "fallbackClassName"]);
    var _f = mapCustomResponsiveValue(sources, function (image, breakpoint) {
        if (image === undefined)
            return undefined;
        var src = image.src, width = image.width, retinaSrc = image.retinaSrc, retinaWidth = image.retinaWidth;
        if (src === undefined)
            return undefined;
        return {
            src: src,
            srcSets: ([
                width !== undefined ? [makeSrcSet(src, width), width] : undefined,
                (retinaSrc !== undefined && retinaWidth !== undefined) ? [makeSrcSet(retinaSrc, retinaWidth), retinaWidth] : undefined,
            ]).filter(notUndefined),
            sizes: width === undefined ? undefined : makeSizes(breakpoint, width),
        };
    }), phone = _f.phone, tablet = _f.tablet, desktop = _f.desktop;
    // Property of the format 'image-1.jpg 100w, image-2 200w, image-3 300w',
    // where `Nw` is the image's intrinsic width in pixels.
    var srcSet = pipe(__spreadArray(__spreadArray(__spreadArray([], ((_a = phone === null || phone === void 0 ? void 0 : phone.srcSets) !== null && _a !== void 0 ? _a : []), true), ((_b = tablet === null || tablet === void 0 ? void 0 : tablet.srcSets) !== null && _b !== void 0 ? _b : []), true), ((_c = desktop === null || desktop === void 0 ? void 0 : desktop.srcSets) !== null && _c !== void 0 ? _c : []), true), 
    // The srcSet property must be unique by width value, so we refine the srcSet
    // tuples to only have one value per width
    function (srcSets) { return uniqBy(srcSets, function (_a) {
        var width = _a[1];
        return width;
    }); }, function (srcSets) { return srcSets.map(function (_a) {
        var srcSetValue = _a[0];
        return srcSetValue;
    }); }).join(', ');
    // Property of the format `(max-width: 600px): 100px, (max-width: 800px): 200px, 300px`, where the media
    // query is used to find the preferred image width.
    // That width (here in pixels) is used to lookup the closest value in
    // `srcSet`. It allows for use of the retina/hidpi images because here the width
    // is multiplied by the users display pixel ratio to find an image that better
    // matches the real resolution of the device instead of the fake one used in
    // media queries.
    // Browsers may also be intellignet enough to skip this step, allowing the
    // device to download a lower resolution image when the user is on slow
    // bandwidth, for example.
    // That is what makes this confusing approach better than more explicit
    // solutions (additional elements with the `media` attribute for dpi, or using
    // css background images with media queries), as well as adding 0 additional
    // dom elements to the page
    var sizes = [phone === null || phone === void 0 ? void 0 : phone.sizes, tablet === null || tablet === void 0 ? void 0 : tablet.sizes, desktop === null || desktop === void 0 ? void 0 : desktop.sizes]
        .filter(notUndefined).join(', ');
    var imgSrc = (_d = desktop === null || desktop === void 0 ? void 0 : desktop.src) !== null && _d !== void 0 ? _d : fallbackSrc;
    if (!imgSrc)
        return null;
    var Component = is;
    if (Component === 'img') {
        return (<img alt='' {...rest} ref={ref} srcSet={srcSet} sizes={sizes} src={imgSrc} className={cn(className, imgSrc === fallbackSrc && fallbackClassName)}/>);
    }
    return (<source {...rest} srcSet={srcSet} sizes={sizes} src={imgSrc} type={webp ? 'image/webp' : undefined}/>);
});
var makeSrcSet = function (url, width) {
    if (!url)
        return undefined;
    return "".concat(url, " ").concat(width, "w");
};
var makeSizes = function (breakpoint, width) {
    if (breakpoint === 'desktop') {
        return "".concat(width, "px");
    }
    return "(max-width: ".concat(breakpointsMax[breakpoint], "px) ").concat(width, "px");
};
