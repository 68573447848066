import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import useNextLocation, { NextLocation } from '../hooks/useNextLocation';
import { matchPath } from '../hooks/useRouteMatch';

export interface RedirectProps {
  to: Partial<NextLocation> | string;
  push?: boolean;
  path?: string;
  exact?: boolean;
  strict?: boolean;
}

// Switch will inject the computedMatch prop so we don't have to recalculate,
// but normal callers should not be able to set it
const Redirect: React.FC<RedirectProps> = (props: RedirectProps & { computedMatch?: boolean }) => {
  const router = useRouter();
  const location = useNextLocation();

  const { computedMatch = false } = props;

  const match = computedMatch
    || matchPath(location.pathname, {
      ...props,
      path: props.path || '/',
      location,
    });
  useEffect(() => {
    if (match) {
      const navigate = props.push ? router.push : router.replace;
      navigate(
        typeof props.to === 'string'
          ? props.to
          : [props.to.pathname, props.to.search, props.to.hash].join(''),
      ).catch(() => null);
    }
  }, [match, router, props.to, props.push]);

  return null;
};

export default Redirect;
