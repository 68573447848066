// TODO: This should be replaced by configuration in seller.extraData in the future

export const distributorSlugsWithWhiteListedProducts = [
  'associated-buyers',
  'garden-spot',
  'soft-stuff',
  'la-distributing-company',
];

export const distributorSlugsWithValidatedCustomerNumbers = [
  'associated-buyers',
  'garden-spot',
  'soft-stuff',
];

// Assumes 72 PPI - the standard for React PDF
export const inchesToPx = {
  4: 288,
  6: 432,
};
