import { Key, pathToRegexp } from 'path-to-regexp';

import useNextLocation, { NextLocation } from './useNextLocation';

const cache: Record<string, Record<string, { regexp: RegExp, keys: Key[] }>> = {};
const cacheLimit = 10000;
let cacheCount = 0;

// Modified from react-router v5
// https://github.com/remix-run/react-router/blob/5e2b04018847c799e8da1a5f9cc632b6d02da152/packages/react-router/modules/matchPath.js
const compilePath = (path: string, options: MatchPathOptions) => {
  const cacheKey = `${options.exact}${options.strict}${options.sensitive}`;
  const pathCache = cache[cacheKey] || (cache[cacheKey] = {});

  if (pathCache[path]) return pathCache[path];

  const keys: Key[] = [];
  const regexp = pathToRegexp(path, keys, { ...options, end: options.exact });
  const result = { regexp, keys };

  if (cacheCount < cacheLimit) {
    pathCache[path] = result;
    cacheCount += 1;
  }

  return result;
};

interface match<Params extends { [K in keyof Params]?: string } = {}> {
  params: Params;
  isExact: boolean;
  path: string;
  url: string;
}

interface MatchPathOptions {
  location?: NextLocation | undefined;
  path?: string | string[] | undefined;
  exact?: boolean | undefined;
  strict?: boolean | undefined;
  sensitive?: boolean | undefined;
}

export const matchPath = <Params extends { [K in keyof Params]?: string }>(
  pathname: string,
  options: MatchPathOptions = {},
): match<Params> | null => {
  const { path = '', exact = false, strict = false, sensitive = false } = options;

  const paths = typeof path === 'string' ? [path] : path;

  return paths.reduce((matched: match<Params> | null, path: string) => {
    if (!path && path !== '') return null;
    if (matched) return matched;

    const { regexp, keys } = compilePath(path, {
      exact,
      strict,
      sensitive,
    });
    const match = regexp.exec(pathname);

    if (!match) return null;

    const [url, ...values] = match;
    const isExact = pathname === url;

    if (exact && !isExact) return null;

    return {
      path, // the path used to match
      url: (path === '/' && url === '') ? '/' : url, // the matched portion of the URL
      isExact, // whether or not we matched exactly
      params: keys.reduce((memo: Params, key, index) => {
        memo[key.name as keyof Params] = values[index] as Params[keyof Params];
        return memo;
      }, {} as Params),
    };
  }, null);
};

interface MatchOptions {
  location?: NextLocation;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

const useRouteMatch = <Params extends { [K in keyof Params]?: string } = {}>(path: string | string[] | MatchOptions): match<Params> | null => {
  const nextLocation = useNextLocation();
  const location = (
    typeof path === 'object' && !Array.isArray(path) && path.location
  ) || nextLocation;

  const options = (typeof path === 'object' && !Array.isArray(path))
    ? { ...path, path: path.path || '/', location }
    : { path };

  return matchPath<Params>(location.pathname, options);
};

export default useRouteMatch;
