import fetch from 'isomorphic-fetch';
import { ApiClient } from '@mablemarket/core-api-client';
import { BASE_API_URL } from './constants';
import { v4 as uuid } from 'uuid';

export function getClient(): ApiClient {
  const client = new ApiClient({
    fetch,
    baseUrl: BASE_API_URL,
    uniqueId: uuid(),
    prettyCorrelationIds: !process.env.NEXT_PUBLIC_NON_PRETTY_CORRELATION_IDS,
  });

  const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';
  client.headers = {
    Authorization: `Bearer ${accessToken}`
  };
  return client;
}

// TODO - when we get into an UNAUTH state, we need a global routine that...
//   - Clears all Auth0 creds
//   - Clears localStorage (or cookie)
//
