import BigNumber from 'bignumber.js';
import React from 'react';

import BigNumberInput, { BigNumberInputProps } from '../BigNumberInput';

export interface NumberInputProps extends Omit<BigNumberInputProps, 'value' | 'onChange'> {
  value: number | undefined;
  onChange?: (value: number | undefined) => void;
}

const NumberInput = (props: NumberInputProps) => {
  return (
    <BigNumberInput
      {...props}
      value={props.value === undefined ? undefined : new BigNumber(props.value)}
      onChange={(value) => {
        props.onChange?.(value?.toNumber());
      }}
    />
  );
};

export default NumberInput;
