var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Box from '../Box';
import useIconProps from './useIconProps';
var rotation = {
    up: 'rotate(0deg)',
    right: 'rotate(90deg)',
    down: 'rotate(180deg)',
    left: 'rotate(270deg)',
};
export var IconTriangle = function (props) {
    var _a;
    var _b = props.height, height = _b === void 0 ? 9 : _b, _c = props.width, width = _c === void 0 ? 9 : _c, rest = __rest(props, ["height", "width"]);
    var iconProps = useIconProps(__assign(__assign({}, rest), { height: height }));
    return (<Box as='svg' width={width} height={height} fill='none' viewBox='0 0 9 6' xmlns='http://www.w3.org/2000/svg' {...rest} {...iconProps} style={__assign(__assign({}, iconProps.style), { transformOrigin: 'center', transition: 'transform 0.1s linear', transform: rotation[(_a = props.direction) !== null && _a !== void 0 ? _a : 'up'] })}>
      <g id='triangle' fill='currentColor' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <polygon points='4.5 0 9 6 0 6'/>
      </g>
    </Box>);
};
