import { useMutation as useRQMutation } from '@tanstack/react-query';
import { useField } from 'formik';
import last from 'lodash/last';
import { useState } from 'react';

import { ensureMableError, MableError } from '@mablemarket/common-lib';

import { useMutation } from './useQuery';


export const useResaleCertificateUpload = ({ buyerIdOrMe, fieldName, onUploadError }: {
  buyerIdOrMe: '@me' | number;
  fieldName: string;
  onUploadError: (err: MableError) => void;
}) => {
  // We don't return uploaded files names in the GET, but we do have it
  // available during upload to display in the pill
  const [fileName, setFileName] = useState('Resale Certificate');

  const signedUrlMutation = useMutation('POST /v1/buyers/{buyerIdOrMe}/certificateSignedUrl');
  const uploadMutation = useRQMutation({
    mutationFn: ({ signedUrl, file }: { signedUrl: string, file: File }) => (
      fetch(signedUrl, { method: 'PUT', body: file })
    ),
  });

  const [, , fieldHelpers] = useField<string>(fieldName);

  const [{ showSuccess, timeout }, setShowSuccess] = useState({
    showSuccess: false,
    timeout: undefined as number | undefined,
  });

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    fieldHelpers.setTouched(true, false);
    fieldHelpers.setValue('', false);
    fieldHelpers.setError(undefined);
    window.clearTimeout(timeout);
    setShowSuccess({ showSuccess: false, timeout: undefined });
    setFileName(file?.name || 'Resale Certificate');

    if (!(file instanceof File)) {
      fieldHelpers.setError('Required');
      return;
    }
    try {
      const extension = last(file.name.toLowerCase().split('.')) ?? 'pdf';
      const { signedUrl, s3Key } = await signedUrlMutation.mutateAsync({
        buyerIdOrMe: `${buyerIdOrMe}`,
        params: {
          extension,
        },
      });
      if (!signedUrl) {
        throw new MableError({
          code: 'NoSignedUrl',
          message: 'no signed url returned',
          data: undefined,
        });
      }
      if (!s3Key) {
        throw new MableError({
          code: 'NoS3key',
          message: 'no s3 key returned',
          data: undefined,
        });
      }
      const uploadRes = await uploadMutation.mutateAsync({
        file,
        signedUrl,
      });
      if (!uploadRes.ok) {
        const uploadError = new MableError({
          code: 'AmazonS3CertificateUploadFailed',
          message: uploadRes.statusText,
          status: uploadRes.status,
          data: undefined,
        });
        onUploadError(uploadError);
        throw uploadError;
      }
      fieldHelpers.setValue(s3Key, true);
      fieldHelpers.setError(undefined);
      setShowSuccess({
        showSuccess: true,
        timeout: window.setTimeout(() => setShowSuccess({ showSuccess: false, timeout: undefined }), 3000),
      });
    } catch (error: unknown) {
      const err = ensureMableError(error, {
        // FIXME: `defaults` doesn't work, it won't set displayMessage
        overrides: {
          displayMessage: (error instanceof MableError && error.displayMessage) ||
            'An error occured when uploading your resale certificate, please try again',
        },
      });
      fieldHelpers.setError(err.displayMessage);
    }
  };
  return {
    fileName,
    handleUpload,
    showSuccess,
    isLoading: signedUrlMutation.isLoading || uploadMutation.isLoading,
  };
};

export default useResaleCertificateUpload;
