var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useToggleState } from 'react-stately';
import { cn } from '../../webutils/webutils';
import Flex from '../Flex';
import UnstyledButton from '../UnstyledButton';
import * as styles from './ToggleButton.css';
var ToggleButton = React.forwardRef(function (props, ref) {
    var className = props.className, children = props.children, onChange = props.onChange, isSelected = props.isSelected, defaultSelected = props.defaultSelected, isReadOnly = props.isReadOnly, rest = __rest(props, ["className", "children", "onChange", "isSelected", "defaultSelected", "isReadOnly"]);
    var toggleState = useToggleState({
        isDisabled: rest.disabled,
        onChange: onChange,
        isSelected: isSelected,
        defaultSelected: defaultSelected,
        isReadOnly: isReadOnly,
    });
    return (<UnstyledButton ref={ref} type='button' aria-pressed={toggleState.isSelected} border={toggleState.isSelected ? 'blueMas' : 'greyLine'} foreground={toggleState.isSelected ? 'blueMas' : 'greyDark'} background={toggleState.isSelected ? 'blueFill' : 'white'} borderRadius='small' paddingY='space-0.5' paddingX='space-0.75' className={cn(styles.toggle, className)} {...rest} onPress={function (e) {
            var _a;
            (_a = props.onPress) === null || _a === void 0 ? void 0 : _a.call(props, e);
            toggleState.toggle();
        }}>
      {children}
    </UnstyledButton>);
});
var Group = function (_a) {
    var children = _a.children;
    return (<Flex className={styles.toggleGroup}>
      {children}
    </Flex>);
};
export default Object.assign(ToggleButton, {
    Group: Group,
});
