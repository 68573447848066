import { atob, btoa } from 'abab';
import * as t from 'io-ts';
import difference from 'lodash/difference';
import fromPairs from 'lodash/fromPairs';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';

import { FilterGroup, FilterGroupHierarchy, FilterGroupValue } from '@mablemarket/core-api-client';


export const AppliedFiltersCodec = t.record(t.string, t.array(t.string));
export type AppliedFilters = t.TypeOf<typeof AppliedFiltersCodec>;

export interface FilterTreeValue extends FilterGroupValue {
  type: 'value';
}
export interface FilterTreeParent extends FilterGroupHierarchy {
  type: 'parent';
  childValues: FilterTreeValue[];
}
export type FilterTreeNode = FilterTreeValue | FilterTreeParent;
export interface FilterGroupTree extends Omit<FilterGroup, 'values'> {
  values: FilterTreeValue[];
  tree: FilterTreeNode[];
}

export const AppliedFiltersSerialize = new t.Type<AppliedFilters, string, unknown>(
  'AppliedFiltersSerialization',
  (u): u is AppliedFilters => AppliedFiltersCodec.is(u),
  (u, c) => {
    try {
      const v = t.string.validate(u, c);
      if ('left' in v) throw v.left;
      const b = atob(decodeURIComponent(v.right));
      if (b === null) {
        throw v.right;
      }
      const j = JSON.parse(b);
      return AppliedFiltersCodec.decode(j);
    } catch (e) {
      // atob can and JSON.parse can throw
      return t.failure(u, c, (e instanceof Error ? e : undefined)?.message ?? 'Cannot parse');
    }
  },
  (a) => {
    const aa = btoa(JSON.stringify(a));
    if (aa === null) {
      throw a;
    }
    return aa;
  },
);

export type SearchAnalyticsContext = ('search_results' | 'category_detail' | 'seller_detail');

export const defaultSort = 'recommended';

export const appliedFiltersForAnalytics = (appliedFilters: AppliedFilters): string => {
  let activeFiltersString = '';
  Object.keys(appliedFilters).forEach((key) => {
    if (appliedFilters[key].length) {
      if (activeFiltersString.length) {
        activeFiltersString += '|';
      }
      activeFiltersString += `${key}=${appliedFilters[key].join(',')}`;
    }
  });
  return activeFiltersString;
};

type ApiFilterType = {
  filters: {
    [x: string]: {
      selections: string[];
    };
  };
};

export const appliedFiltersForApi = (appliedFilters?: AppliedFilters): ApiFilterType | undefined => {
  if (isEmpty(appliedFilters)) {
    return undefined;
  }

  const cleanedFilters = fromPairs(
    Object.entries(appliedFilters ?? {})
      .filter(([key, value]) => !isEmpty(value)),
  );
  return { filters: mapValues(cleanedFilters, v => ({ selections: v })) };
};

export const appliedFiltersCount = (appliedFilters?: AppliedFilters) => {
  return Object.entries(appliedFilters ?? {})
    .filter(([key, value]) => key !== 'sortMethod')
    .reduce((sum, [id, selections]) => sum + selections.length, 0);
};

export const initActiveFilters = (filterGroups: FilterGroup[]): AppliedFilters => {
  return filterGroups.reduce((obj: AppliedFilters, fg: FilterGroup) => {
    return {
      ...obj,
      [fg.id]: (fg.id === 'sortMethod') ? [defaultSort] : [],
    };
  }, {});
};

export const filterIdsToNames = (filterValues: FilterGroupValue[]) => filterValues.reduce((acc, val) => {
  acc[val.id] = val.name;
  return acc;
}, {} as Record<string, string>);

export const buildFilterTree = (filter: FilterGroup): FilterGroupTree => {
  let topLevelFilterValues = filter.values;
  const hierarchy: FilterTreeParent[] = filter.hierarchy?.filter(h => !isEmpty(h.childFilterIds))
    .map((h) => {
      const children = filter.values.filter(v => (h.childFilterIds.includes(v.id)));
      topLevelFilterValues = difference(topLevelFilterValues, children);
      return {
        ...h,
        childValues: children.map(v => ({ ...v, type: 'value' })),
        type: 'parent' as const,
      };
    }) ?? [];
  return (
    {
      ...filter,
      values: filter.values.map(v => ({ ...v, type: 'value' as const })),
      // use hierarchy information for entries that have no children and were skipped to infer sort order
      tree: sortBy(
        [
          ...hierarchy,
          ...topLevelFilterValues.map(v => ({ ...v, type: 'value' as const })),
        ],
        v => filter.hierarchy?.findIndex(h => h.id === v.id),
      ),
    }
  );
};
