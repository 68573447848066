export const baseMableWebURLFromWindow = () => {
  const hostname = window?.location?.hostname?.toLowerCase();
  if (!hostname) {
    return undefined;
  }

  if (hostname.startsWith('localhost')) {
    return 'http://localhost:3002/';
  }

  if (hostname.startsWith('staging')) {
    return 'https://staging.meetmable.com/';
  }

  return 'https://www.meetmable.com/';
};

export const baseAdminWebURLFromWindow = () => {
  const hostname = window?.location?.hostname?.toLowerCase();
  if (!hostname) {
    return undefined;
  }

  if (hostname.startsWith('localhost')) {
    return 'http://localhost:3001/';
  }

  if (hostname.startsWith('staging')) {
    return 'https://admin.staging.meetmable.com/';
  }

  return 'https://admin.meetmable.com/';
};

export const baseAPIURLFromWindow = () => {
  const hostname = window?.location?.hostname?.toLowerCase();
  if (!hostname) {
    return undefined;
  }

  if (hostname.startsWith('localhost')) {
    return 'http://localhost:3000/';
  }

  if (hostname.startsWith('staging')) {
    return 'https://api.staging.meetmable.com/';
  }

  return 'https://api.meetmable.com/';
};

export const honeycombDataset = () => {
  const hostname = window.location.hostname.toLowerCase();

  if (hostname.includes('staging') || hostname.includes('localhost')) {
    return 'staging-mable';
  }

  return 'prod-mable';
};


export const savedInviteKey = 'saved-invite-key';

// inspired by https://medium.com/trabe/using-bem-conventions-in-css-modules-leveraging-custom-webpack-loaders-fd985f72bcb2

// Camel Cases all sass/css module class names, as well as breaking up BEM
// identifiers into JS friendly sub objects. Modifiers are prefixed by a $
// This could be done via a modification to the webpack, as presented in the
// article above. Rather than get to deep into customizing our webpack config
// this early, we can use this function to transform the default styles object.
export const bemify = (() => {
  const camelCased = (s: string) => s && s.replace(/-([a-z0-9])/g, g => g[1].toUpperCase());
  const modifierPrefix = '$';
  return (styles: { [key: string]: string }) => {
    return Object.keys(styles)
      .sort()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((acc: any, identifier: string) => {
        const [rawBlockElement, rawModifier] = identifier.split('--');
        const [rawBlock, rawElement] = rawBlockElement.split('__');

        // Camelcase each part.
        const block = camelCased(rawBlock);
        const element = camelCased(rawElement);
        const modifier = camelCased(rawModifier);

        // Get the exported className for the identifier
        const className = styles[identifier];

        // Element modifier. Add the modifier toString placeholder
        // to the block element
        if (element && modifier) {
          acc[block] = {
            ...acc[block],
            [element]: {
              ...acc[block][element],

              [modifierPrefix + modifier]: {
                _bem_: true,
                toString: () => className,
              },
            },
          };

          // Element or block modifier. Add the element/modifier toString
          // placeholder to the the block
        } else if (modifier || element) {
          acc[block] = {
            ...acc[block],
            [element || `${modifierPrefix}${modifier}`]: {
              _bem_: true,
              toString: () => className,
            },
          };

          // New block. Add the toString placeholder
        } else {
          acc[block] = {
            _bem_: true,
            toString: () => className,
          };
        }

        return acc;
      }, {});
  };
})();

export type ClassNameValue = (string | boolean | null | undefined | { toString(): string } | ClassNameValue[]);
export const cn = (...classNames: ClassNameValue[]): string => (
  classNames
    .filter(elem => Boolean(elem))
    .map(elem => ((Array.isArray(elem) && elem.length > 0) ? cn(...elem) : `${elem}`))
    .join(' ')
);

export const downloadAsFile = ({ fileName, fileType, data }:
  {
    fileName: string;
    fileType: string;
    data: ArrayBuffer | string;
  }) => {
  const file = new Blob([data], { type: fileType });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = fileName;
  link.click();

  // IE11 does not support this method
  link.remove?.();

  // free up memory
  window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
};

export const openPdfResponse = async (res: Response, filename: string) => {
  const buffer = await res.arrayBuffer();
  downloadAsFile({
    data: buffer,
    fileName: `${filename}.pdf`,
    fileType: 'application/pdf',
  });
};

export const openPngResponse = async (res: Response, filename: string) => {
  const buffer = await res.arrayBuffer();
  downloadAsFile({
    data: buffer,
    fileName: `${filename}.png`,
    fileType: 'image/png',
  });
};

export const openCsvResponse = async (res: Response, filename: string) => {
  const buffer = await res.arrayBuffer();
  downloadAsFile({
    data: buffer,
    fileName: `${filename}.csv`,
    fileType: 'text/csv',
  });
};

export const openPdfInNewTab = async (response: Response) => {
  const buffer = await response.arrayBuffer();
  const file = new Blob([buffer], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, '_blank');
};
