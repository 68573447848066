var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useLink } from '@react-aria/link';
import isEmpty from 'lodash/isEmpty';
import NextLink from 'next/link';
import React, { useRef } from 'react';
import { mergeProps, useFocusRing, useHover } from 'react-aria';
import urlJoin from 'url-join';
import { notUndefined } from '@mablemarket/common-lib';
import useRouteMatch from '../../modules/NextRouter/hooks/useRouteMatch';
import { focusRing, noFocusRing } from '../../styles/sprinkles.css';
import { composeRefs } from '../../util';
import { cn } from '../../webutils/webutils';
import { useForeground } from '../Box';
import Text from '../Text';
import * as styles from './TextLink.css';
export var stringifyHref = function (href) { return (typeof href === 'string' ? href : urlJoin([
    href.origin,
    href.pathname,
    (href.query && !href.query.startsWith('?')) ? "?".concat(href.query) : href.query,
    (href.hash && href.hash !== '#') ? "#".concat(href.hash) : href.hash,
].filter(notUndefined))); };
/** Currently this uses the presence of a protocol to determine if the url is
 * external, which may or may not be sufficient
 */
export var matchProtocolUrl = function (urlOrPath) {
    return !!urlOrPath.match(/^([a-z]+:)?\/\//i);
};
// default to blue instead of black
var defaultColor = 'blueMas';
/**
 * When you need a link that is (conditionally) underlined text
 * Use `onPress` instead of `onClick` unless you need something in the `onClick` event that `onPress` doesn't expose.
*/
var TextLink = React.forwardRef(function (props, ref) {
    var href = props.href, replace = props.replace, scroll = props.scroll, shallow = props.shallow, passHref = props.passHref, prefetch = props.prefetch, locale = props.locale, givenUnderline = props.underline, givenForeground = props.foreground, disabled = props.disabled, external = props.external, activeProps = props.activeProps, exact = props.exact, overlayContainer = props.overlayContainer, onPress = props.onPress, onPressStart = props.onPressStart, onPressEnd = props.onPressEnd, onPressChange = props.onPressChange, onPressUp = props.onPressUp, rest = __rest(props, ["href", "replace", "scroll", "shallow", "passHref", "prefetch", "locale", "underline", "foreground", "disabled", "external", "activeProps", "exact", "overlayContainer", "onPress", "onPressStart", "onPressEnd", "onPressChange", "onPressUp"]);
    var _a = useHover(__assign(__assign({}, rest), { isDisabled: disabled })), hoverProps = _a.hoverProps, isHovered = _a.isHovered;
    var linkRef = useRef(null);
    var _b = useLink(__assign(__assign({}, rest), { onPress: onPress, onPressStart: onPressStart, onPressEnd: onPressEnd, onPressChange: onPressChange, onPressUp: onPressUp, isDisabled: disabled }), linkRef), linkProps = _b.linkProps, isPressed = _b.isPressed;
    var _c = useTextLinkStyles({
        foreground: givenForeground,
        background: props.background,
        underline: givenUnderline,
        disabled: disabled,
        isHovered: isHovered,
        isPressed: isPressed,
        overlayContainer: overlayContainer,
    }), className = _c.className, restStyles = __rest(_c, ["className"]);
    var stringHref = stringifyHref(href);
    var isExternal = external || matchProtocolUrl(stringHref);
    var match = useRouteMatch({
        path: isEmpty(activeProps) ? '' : stringHref,
        exact: exact,
    });
    var isActive = match !== null;
    var Anchor = (<Text as='a' display='inline-block' target={isExternal ? '_blank' : undefined} rel={isExternal ? 'noopener noreferrer' : undefined} href={stringHref} {...mergeProps(rest, (isActive && activeProps) || {})} 
    // We want this to override click handlers
    {...mergeProps(linkProps, restStyles, hoverProps)} ref={composeRefs(linkRef, ref)} className={[
            rest.className,
            className,
            isActive && (activeProps === null || activeProps === void 0 ? void 0 : activeProps.className),
        ]}/>);
    if (isExternal) {
        return Anchor;
    }
    return (<NextLink href={stringHref} scroll={scroll} shallow={shallow} replace={replace} passHref={passHref} prefetch={prefetch} locale={locale}>
      {Anchor}
    </NextLink>);
});
TextLink.displayName = 'TextLink';
export var useTextLinkStyles = function (props) {
    var _a;
    var givenForeground = props.foreground, givenUnderline = props.underline, disabled = props.disabled, isHovered = props.isHovered, isPressed = props.isPressed, overlayContainer = props.overlayContainer;
    var _b = useFocusRing({}), focusProps = _b.focusProps, isFocusVisible = _b.isFocusVisible;
    var contextForeground = (_a = useForeground(props)) !== null && _a !== void 0 ? _a : defaultColor;
    var foreground = (contextForeground === 'black' && givenForeground === undefined)
        ? defaultColor
        : contextForeground;
    // default to underlining link if using non-default foreground
    var underline = givenUnderline !== null && givenUnderline !== void 0 ? givenUnderline : (foreground !== defaultColor ? true : 'hover');
    return __assign(__assign({}, focusProps), { foreground: foreground, underline: underline, className: cn(((isHovered || isPressed) && !disabled) && styles.active[foreground === 'inherit' ? defaultColor : foreground], (underline === true || (isHovered && underline === 'hover' && !disabled))
            ? styles.underline
            // This feels dumb, but is needed to defeat global css
            : styles.notUnderlined, styles.textLink, disabled && styles.disabled, isFocusVisible ? focusRing : noFocusRing, overlayContainer && styles.overlayContainer) });
};
export default TextLink;
