import React, { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEffectAbort = (callback: (abortController: AbortController) => any, deps?: React.DependencyList) => {
  useEffect(() => {
    const abortController = new AbortController();
    callback(abortController);
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
