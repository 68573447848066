import csvtojson from 'csvtojson';
import { pipe } from 'fp-ts/function';
import { isLeft } from 'fp-ts/lib/These';
import * as t from 'io-ts';
import { parse as jsontocsv } from 'json2csv';
import mapKeys from 'lodash/mapKeys';

import { MableError } from './MableError';

export async function parseCSV<T extends t.Mixed>({
  csv,
  codec,
  mapping,
  csvtojsonParams,
} : {
  csv: string,
  codec: T,
  mapping?: Partial<Record<string, keyof t.TypeOf<T>>>,
  csvtojsonParams?: Omit<Parameters<typeof csvtojson>[0], 'ignoreEmpty'>,
}) {
  const res = pipe(
    await csvtojson({
      ignoreEmpty: true,
      ...csvtojsonParams,
    }).fromString(csv),
    // Optionally map column names to object properties
    mapping ? records => records.map((r) => {
      return mapKeys(r, (val, key) => {
        return mapping[key] || key;
      });
    }) : records => records,
    // Validate and decode the CSV data against the io-ts codec
    records => t.array(codec).decode(records),
  );
  if (isLeft(res)) {
    const errs = res.left.map((e) => {
      const colContextIndex = e.context.length - 1;
      const rowContextIndex = 1;
      const rowContext = e.context[rowContextIndex];
      const columnContext = e.context[colContextIndex];
      if (columnContext.actual === undefined || columnContext.actual === null) {
        return `Row ${Number(rowContext.key) + 1} expected column ${columnContext.key} to be of type <${columnContext.type.name}> but did not have a corresponding column`;
      }
      return `Row ${Number(rowContext.key) + 1} expected column ${columnContext.key} to be of type <${columnContext.type.name}> but got value <${columnContext.actual}>`;
    });
    return new MableError({
      code: 'CSVParsingError',
      message: 'One or more errors parsing csv',
      data: errs,
      logData: {
        errs,
      },
    });
  }
  return res.right;
}

export const csvToStringArray = async (csv: string) => {
  const headerRow = csv.split('\n')[0];
  if (!headerRow) {
    return new MableError({
      code: 'MissingCSVHeaderRow',
      message: 'Could not find a header row',
      data: undefined,
    });
  }

  const headers = headerRow.split(',').map(h => h.trim());

  const rawRows = await csvtojson().fromString(csv);
  const res = t.array(t.record(t.string, t.string)).decode(rawRows);
  if (isLeft(res)) {
    return new MableError({
      code: 'CSVRecordParsingError',
      message: 'Could not parse csv into records',
      data: undefined,
    });
  }
  const rows = res.right;

  return [headers, ...rows.map((r) => {
    return headers.map(h => r[h]);
  })];
};


export type CSVRow = (number|string|null|undefined)[]
export const rowsToCSV = (rows: CSVRow[], headers?: string[]) => {
  const quoteString = (s: string) => `"${s.replace(/"/g, '""')}"`;
  const quotedRows = rows.map(r => (
    r.map((v) => {
      if (v === null || v === undefined) {
        return '';
      }
      if (typeof v === 'string') {
        return quoteString(v);
      }
      return v;
    })
  ));
  const quotedHeaders = headers?.map(h => quoteString(h));
  const csvRows = quotedHeaders ? [quotedHeaders, ...quotedRows] : quotedRows;
  const csv = csvRows.map(r => r.join(',')).join('\r\n');
  return csv;
};


export function recordsToCSV<
  T extends Record<string, unknown>,
>(records: T[], codec:t.Type<T, Record<string, string | number>, unknown>, opts?: {
  headers: Extract<keyof T, string>[];
}) {
  const { headers } = opts ?? {};
  const rows = records.map(r => codec.encode(r));
  return jsontocsv(rows, {
    header: true,
    fields: headers,
  });
}
