import BigNumber from 'bignumber.js';
import compareDesc from 'date-fns/compareDesc';
import parse from 'date-fns/parse';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { isStringArray, isUnexpected } from '@mablemarket/common-lib';
import { SelfShipInfo, ShippingDetailsMetadata, TrackingInfo, TransactionLogEntry, TransactionSplit, TransactionWithoutDisplayInfo } from '@mablemarket/core-api-client';

/**
 * Determine whether the split is "accountable", i.e. whether it should be counted when computing
 * totals, metrics, etc.
 * For the DB equivalent you can use in an SQL query, see `is_accountable`.
 */
export function isAccountable(split: Pick<TransactionSplit, 'status'>) {
  return split.status !== 'cancelled' && split.status !== 'voided' && split.status !== 'failed';
}

export function isBuyerBilling(split: Pick<TransactionSplit, 'counterparty'>) {
  return !!split.counterparty.tags?.includes('buyerBilling');
}

export function isSellerRemittanceAccount(split: Pick<TransactionSplit, 'counterparty'>) {
  return !!split.counterparty.tags?.includes('sellerRemittance');
}

export function isLandedCostsAccount(split: Pick<TransactionSplit, 'counterparty'>) {
  return !!split.counterparty.tags?.includes('landedCosts');
}

export function isAccountablePurchase(split: Pick<TransactionSplit, 'status'|'counterparty'|'type'>) {
  return split.type === 'purchase' && isAccountable(split);
}

export function isAccountableProductSplit(split: Pick<TransactionSplit, 'status'|'productId'>) {
  return !!split.productId && isAccountable(split);
}

export function isAccountableSellerPurchase(split: Pick<TransactionSplit, 'status'|'counterparty'|'type'>) {
  return split.type === 'purchase' && isAccountable(split) && !isBuyerBilling(split);
}

export function isAccountableBuyerPurchase(split: Pick<TransactionSplit, 'status'|'counterparty'|'type'>) {
  return split.type === 'purchase' && isAccountable(split) && isBuyerBilling(split);
}

export function isAccountableLandedCostFee(split: Pick<TransactionSplit, 'status'|'counterparty'|'type'>) {
  return split.type === 'fee' && isAccountable(split) && isLandedCostsAccount(split);
}

export function isRefundablePaymentSplit(split: Pick<TransactionSplit, 'status'|'externalTransactionPartner'|'externalTransactionId'>) {
  return split.status === 'settled' && split.externalTransactionPartner === 'stripe' && !!split.externalTransactionId;
}

export function isSellerCommissionFee(split: Pick<TransactionSplit, 'status'|'counterparty'|'type'>) {
  return split.type === 'commission' && isAccountable(split) && isSellerRemittanceAccount(split);
}

export function isAccountableBuyerCreditSplit(split: Pick<TransactionSplit, 'status'|'counterparty'|'type'>) {
  return split.type === 'credit' && isAccountable(split) && isBuyerBilling(split);
}

export function isTransactionSettled(transaction: TransactionWithoutDisplayInfo) {
  if (isUnexpected(transaction.status)) {
    return false;
  }
  return ['shipped', 'paid', 'cancelled', 'invoiced'].includes(transaction.status);
}

function isTrackingInfo(value: unknown): value is TrackingInfo {
  return (value as TrackingInfo).cost !== undefined && (value as TrackingInfo).number !== undefined;
}

function isTrackingInfoArray(value: unknown): value is TrackingInfo[] {
  return Array.isArray(value) && value.every(element => isTrackingInfo(element));
}

function isSelfShipInfo(value: unknown): value is SelfShipInfo {
  return (value as SelfShipInfo).cost !== undefined && (value as SelfShipInfo).date !== undefined;
}

function isSelfShipInfoArray(value: unknown): value is SelfShipInfo[] {
  return Array.isArray(value) && value.every(element => isSelfShipInfo(element));
}

export function normalizeTrackingInfo(extraData: Record<string, unknown>): TrackingInfo[] {
  if (extraData.trackingNumber && typeof extraData.trackingNumber === 'string') {
    return [{ number: extraData.trackingNumber, cost: new BigNumber(0) }];
  }
  if (extraData.trackingNumbers && isStringArray(extraData.trackingNumbers)) {
    return extraData.trackingNumbers.map(tn => ({ number: tn, cost: new BigNumber(0) }));
  }
  if (extraData.trackingInfos && isTrackingInfoArray(extraData.trackingInfos)) {
    return extraData.trackingInfos;
  }
  return [];
}

export function normalizeSelfShipInfo(extraData: Record<string, unknown>): SelfShipInfo[] {
  if (extraData.selfShipDates && isStringArray(extraData.selfShipDates)) {
    return extraData.selfShipDates.map(date => ({ cost: new BigNumber(0), date: parse(date, 'MM/dd/yyyy', new Date()) }));
  }
  if (extraData.selfShipInfos && isSelfShipInfoArray(extraData.selfShipInfos)) {
    return extraData.selfShipInfos;
  }
  return [];
}

/**
 * This function takes all of a transaction's shipment log entries and presents them
 * in a singular data structure, regardless of how that information may have been
 * logged in the past
 *
 * @param logEntries A list of transaction logs
 */
export function getShippingDetailsMetadata(logEntries: TransactionLogEntry[]): ShippingDetailsMetadata {
  const logs = logEntries.slice().filter(log => log.type === 'ShippingInfoReceived').sort((a, b) => {
    const aDate = a.createdAt;
    const bDate = b.createdAt;
    if (!aDate || !bDate) {
      return 0;
    }
    return compareDesc(aDate, bDate);
  });
  // Let's determine whether we have logs in a format where we don't have to look back at older logs
  let trackingInfos: TrackingInfo[] | undefined;
  const mostRecentTrackingLog = find(logs, log => !isEmpty(log.extraData.trackingInfos as TrackingInfo[]));
  if (mostRecentTrackingLog && mostRecentTrackingLog.extraData.v === 1) {
    trackingInfos = mostRecentTrackingLog.extraData.trackingInfos as TrackingInfo[];
  }
  let selfShipInfos: SelfShipInfo[] | undefined;
  const mostRecentSelfShipLog = find(logs, log => !isEmpty(log.extraData.selfShipInfos as SelfShipInfo[]));
  if (mostRecentSelfShipLog && mostRecentSelfShipLog.extraData.v === 1) {
    selfShipInfos = mostRecentSelfShipLog.extraData.selfShipInfos as SelfShipInfo[];
  }
  // We have newer data for both, we're done
  if (trackingInfos && selfShipInfos) {
    return {
      selfShipInfos,
      trackingInfos,
    };
  }
  // Since we have either trackingInfo or selfShipDates in an older format, let's just reduce both and decide
  // what we need to use
  const reducedExtraData = logs
    .map((log) => {
      return {
        selfShipInfos: normalizeSelfShipInfo(log.extraData),
        trackingInfos: normalizeTrackingInfo(log.extraData),
      };
    })
    .reduce((metadata, curr) => ({
      selfShipInfos: uniq([...metadata.selfShipInfos, ...curr.selfShipInfos]),
      trackingInfos: uniqBy([...metadata.trackingInfos, ...curr.trackingInfos], 'number'),
    }), { selfShipInfos: [], trackingInfos: [] } as ShippingDetailsMetadata);

  return {
    selfShipInfos: selfShipInfos ?? reducedExtraData.selfShipInfos,
    trackingInfos: trackingInfos ?? reducedExtraData.trackingInfos,
  };
}
