import React, { createContext, useContext, useMemo, useState } from 'react';
import { OverlayContainer } from 'react-aria';
import { createPortal } from 'react-dom';
import * as ReversePortal from 'react-reverse-portal';

import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect';

export const portalNodes = {
  // These are hardcoded into index.html and it is not worth generalizing beyond that yet
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  floating: () => document.querySelector('#floating-portal-root')!,
  top: () => document.querySelector('#top-portal-root')!,
  topFixed: () => document.querySelector('#top-fixed-portal-root')!,
  bottom: () => document.querySelector('#bottom-portal-root')!,
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
} as const;

type Portals = keyof typeof portalNodes;

const usePortal = (location: Portals, order: number) => {
  const [elem, setElem] = useState<HTMLDivElement | null>(null);

  useIsomorphicLayoutEffect(() => {
    const elem = document.createElement('div');
    setElem(elem);
    portalNodes[location]().appendChild(elem);
    return () => {
      if (portalNodes[location]().contains(elem)) {
        portalNodes[location]().removeChild(elem);
      }
    };
  }, [location]);

  useIsomorphicLayoutEffect(() => {
    if (elem) {
      elem.style.order = order.toString();
    }
  }, [elem, order]);

  return elem;
};

type ReversePortalContextValue = ReversePortal.HtmlPortalNode<React.Component<unknown>>;

const ReversePortalContext = createContext<ReversePortalContextValue | null>(null);

const useReversePortalContext = () => {
  const context = useContext(ReversePortalContext);
  if (context === null) {
    throw new Error('useReversePortalContext must be used inside of Reverse.');
  }
  return context;
};

export const InPortal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const reversePortalNode = useReversePortalContext();
  return (
    <ReversePortal.InPortal node={reversePortalNode}>
      {children}
    </ReversePortal.InPortal>
  );
};

export const OutPortal: React.FC<React.PropsWithChildren> = () => {
  const reversePortalNode = useReversePortalContext();
  return (
    <ReversePortal.OutPortal node={reversePortalNode} />
  );
};

type Reverse = React.FC<React.PropsWithChildren> & {
  InPortal: typeof InPortal;
  OutPortal: typeof OutPortal;
};

export const Reverse: Reverse = ({ children }) => {
  const reversePortalNode = useMemo(ReversePortal.createHtmlPortalNode, []);
  return (
    <ReversePortalContext.Provider value={reversePortalNode}>
      {children}
    </ReversePortalContext.Provider>
  );
};

Reverse.InPortal = InPortal;
Reverse.OutPortal = OutPortal;

interface PortalProps {
  location?: Portals;
  order?: number;
  className?: string;
  children?: React.ReactNode;
}

type Portal = React.FC<PortalProps> & {
  Reverse: typeof Reverse;
}

const Portal: Portal = ({ children, location = 'floating', order = 0, className }) => {
  const target = usePortal(location, order);
  useIsomorphicLayoutEffect(() => {
    if (target) {
      target.className = ['mable-portal-node', className].join(' ');
    }
  }, [className, target]);

  if (!target) return null;
  return createPortal(children, target);
};

Portal.Reverse = Reverse;

/** Per this unmerge PR https://github.com/adobe/react-spectrum/pull/2524
 * OverlayContainer attempts to access the dom before it is ready during SSR.
 * We can accomplish the same fix with `usePortal`.
 * TODO: Remove this when the above is merged and we are updated.
*/
export const OverlayContainerSSR = (props: React.ComponentProps<typeof OverlayContainer>) => {
  const target = usePortal('floating', 0);
  if (!target) return null;
  return <OverlayContainer {...props} portalContainer={target} />;
};

export default Portal;
