import React from 'react';
import { mergeProps } from 'react-aria';
import { FieldPath, FieldValues, PathValue, useController, UseControllerProps, useWatch } from 'react-hook-form';

import BigNumberInput, { BigNumberInputProps } from '../BigNumberInput';
import Checkbox, { CheckboxProps } from '../Checkbox';
import NumberInput, { NumberInputProps } from '../NumberInput';
import RadioGroup, { RadioGroupProps } from '../RadioGroup';
import Select, { SelectProps } from '../Select';
import TextArea, { TextAreaProps } from '../TextArea';
import TextInput, { TextInputProps } from '../TextInput';

type BaseHookFormProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  = UseControllerProps<TFieldValues, TName>;

type HFRadioGroupProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  = Omit<RadioGroupProps, 'defaultValue' | 'name'> & BaseHookFormProps<TFieldValues, TName>;

const HFRadioGroupInternal = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: HFRadioGroupProps<TFieldValues, TName>) => {
  const { rules: _rules, ...rest } = props;
  // Lie to RHF about the disabled state because otherwise it makes the value
  // `undefined` on validation/submit
  const { disabled, ...opts } = props;
  const { field, fieldState } = useController<TFieldValues, TName>(opts);

  return (
    <RadioGroup
      error={fieldState.error?.message}
      {...mergeProps(field, rest, { disabled })}
    />
  );
};

export const HFRadioGroup = Object.assign(HFRadioGroupInternal, {
  Item: RadioGroup.Item,
});


type HFCheckboxProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  = Omit<CheckboxProps, 'defaultValue' | 'name' | 'value'> & BaseHookFormProps<TFieldValues, TName> & {
    value?: string | number | undefined;
  };

export const HFCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: HFCheckboxProps<TFieldValues, TName>) => {
  const { rules: _rules, ...rest } = props;
  // Lie to RHF about the disabled state because otherwise it makes the value
  // `undefined` on validation/submit
  // Extract value to also lie to RHF so we can replicate the default behavior
  // of uncontrolled checkboxes where multiple with the same name will create an
  // array of values instead of a single value or true/false
  // We cannot detect if multiple checkboxes have been registered with the same
  // name, so the value/defaultValue must be an array for this to work properly
  const { disabled, value, ...opts } = props;
  const { field, fieldState } = useController<TFieldValues, TName>(opts);

  const checked = (() => {
    if (Array.isArray(field.value)) {
      return field.value.includes(value);
    }
    if ((value ?? '') === '') {
      return !!field.value;
    }
    return field.value === value;
  })();

  return (
    <Checkbox
      error={fieldState.error?.message}
      {...mergeProps(
        field,
        rest,
        { disabled },
      )}
      value={value}
      checked={checked}
      onChange={(e) => {
        if (Array.isArray(field.value)) {
          if (e.target.checked) {
            field.onChange([...field.value, value]);
          } else {
            field.onChange(field.value.filter((v: string | number | undefined) => v !== value));
          }
        } else if (e.target.checked) {
          field.onChange((value ?? '') === '' ? true : value);
        } else {
          field.onChange(false);
        }
        props.onChange?.(e);
      }}
    />
  );
};

type HFNumberInputProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  = Omit<NumberInputProps, 'value' | 'name'> & BaseHookFormProps<TFieldValues, TName> & {
    value?: PathValue<TFieldValues, TName>;
  };

export const HFNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: HFNumberInputProps<TFieldValues, TName>) => {
  const { rules: _rules, ...rest } = props;
  // Lie to RHF about the disabled state because otherwise it makes the value
  // `undefined` on validation/submit
  const { disabled, ...opts } = props;
  const { field, fieldState } = useController<TFieldValues, TName>(opts);
  const value = useWatch(opts);

  return (
    <NumberInput
      error={fieldState.error?.message}
      {...mergeProps(
        rest,
        { ...field, value, disabled },
      )}
    />
  );
};

type HFBigNumberInputProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  = Omit<BigNumberInputProps, 'value' | 'name'> & BaseHookFormProps<TFieldValues, TName> & {
    value?: PathValue<TFieldValues, TName>;
  };

export const HFBigNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: HFBigNumberInputProps<TFieldValues, TName>) => {
  const { rules: _rules, ...rest } = props;
  // Lie to RHF about the disabled state because otherwise it makes the value
  // `undefined` on validation/submit
  const { disabled, ...opts } = props;
  const { field, fieldState } = useController<TFieldValues, TName>(opts);
  const value = useWatch(opts);

  return (
    <BigNumberInput
      error={fieldState.error?.message}
      {...mergeProps(
        rest,
        { ...field, value, disabled },
      )}
    />
  );
};


type HFTextInputProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  = Omit<TextInputProps, 'pattern' | 'name' | 'defaultValue' | 'value'> & BaseHookFormProps<TFieldValues, TName> & {
    value?: PathValue<TFieldValues, TName>;
    defaultValue?: PathValue<TFieldValues, TName>;
  };

export const HFTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: HFTextInputProps<TFieldValues, TName>) => {
  const { rules: _rules, ...rest } = props;
  // Lie to RHF about the disabled state because otherwise it makes the value
  // `undefined` on validation/submit
  const { disabled, ...opts } = props;
  const { field, fieldState } = useController<TFieldValues, TName>(opts);

  return (
    <TextInput
      error={fieldState.error?.message}
      {...mergeProps(
        field,
        rest,
        { disabled },
      )}
    />
  );
};


type HFTextAreaProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  = Omit<TextAreaProps, 'pattern' | 'name' | 'defaultValue' | 'value'> & BaseHookFormProps<TFieldValues, TName> & {
    value?: PathValue<TFieldValues, TName>;
    defaultValue?: PathValue<TFieldValues, TName>;
  };

export const HFTextArea = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: HFTextAreaProps<TFieldValues, TName>) => {
  const { rules: _rules, ...rest } = props;
  // Lie to RHF about the disabled state because otherwise it makes the value
  // `undefined` on validation/submit
  const { disabled, ...opts } = props;
  const { field, fieldState } = useController<TFieldValues, TName>(opts);

  return (
    <TextArea
      error={fieldState.error?.message}
      {...mergeProps(
        field,
        rest,
        { disabled },
      )}
    />
  );
};

type HFSelectProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  = Omit<SelectProps, 'pattern' | 'name' | 'defaultValue' | 'value'> & BaseHookFormProps<TFieldValues, TName> & {
    value?: PathValue<TFieldValues, TName>;
    defaultValue?: PathValue<TFieldValues, TName>;
  };

export const HFSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: HFSelectProps<TFieldValues, TName>) => {
  const { rules: _rules, ...rest } = props;
  // Lie to RHF about the disabled state because otherwise it makes the value
  // `undefined` on validation/submit
  const { disabled, ...opts } = props;
  const { field, fieldState } = useController<TFieldValues, TName>(opts);

  return (
    <Select
      error={fieldState.error?.message}
      {...mergeProps(
        field,
        rest,
        { disabled },
      )}
    />
  );
};
