// These functions were originally created for distributor data
// processing, and were migrated over for use in internal data tools.
/* eslint-disable @typescript-eslint/prefer-regexp-exec */
import { ExcludeUnexpected, numberOrUndefined } from '@mablemarket/common-lib';
import { ProductSizeUnit } from '@mablemarket/core-api-client';

// export type Unit = 'oz' | 'lb' | 'floz' | 'ml' | 'l' | 'gal' | 'pt' | 'qt' | 'g' | 'kg' | 'ct';

export const eachSizeUnits: ExcludeUnexpected<ProductSizeUnit>[] = [
  'oz',
  'lb',
  'floz',
  'ml',
  'l',
  'gal',
  'pt',
  'qt',
  'g',
  'kg',
  'ct',
];

export interface EachInfo {
  eachSize?: number;
  eachSizeUnit?: ExcludeUnexpected<ProductSizeUnit>;
  eachName?: string;
  eachCount?: number;
}

type ProductSizeUnitValues = ExcludeUnexpected<ProductSizeUnit>;

export const normalizeSizeAndUnitString = (
  str: string,
  additionalMappings?: Record<string, ProductSizeUnitValues>,
) => {
  let result = str
    .toLowerCase()
    .replace(/lbs/i, 'lb')
    .replace(/pk/i, 'ct')
    .replace(/each/i, 'ct')
    .replace(/ltr/i, 'l')
    .replace(/liter/i, 'l')
    .replace(/litre/i, 'l')
    .replace(/quart\b/, 'qt')
    .replace(/gallon/i, 'gal')
    .replace(/gl/i, 'gal')
    .replace(/fl.oz./, 'floz');

  if (additionalMappings) {
    Object.keys(additionalMappings).forEach((pattern) => {
      result = result.replace(pattern, additionalMappings[pattern]);
    });
  }

  return result;
};

export const getEachSizeAndUnit = (rawName: string, opts?: {
  additionalUnitMappings?: Record<string, ProductSizeUnitValues>
}): Partial<EachInfo> => {
  const { additionalUnitMappings } = opts ?? {};
  const name = normalizeSizeAndUnitString(rawName, additionalUnitMappings);

  // e.g. '1.1 gal'
  // eslint-disable-next-line no-restricted-syntax
  for (const unit of eachSizeUnits) {
    const regex = new RegExp(`(\\d+\\.{0,1}\\d*?)\\s*(${unit})\\b`);
    // console.log(`regex: ${regex}`);
    const matches = name.match(regex);
    if (matches) {
      // console.log(`matches: ${JSON.stringify(matches, null, 2)}`);
      const result = {
        eachSize: numberOrUndefined(matches[1]),
        eachSizeUnit: matches[2] as ExcludeUnexpected<ProductSizeUnit>,
      };
      // console.log(`EachInfo for '${name}': ${JSON.stringify(result, null, 2)}`);
      return result;
    }
  }

  // e.g. 'gal'
  const singularSizeUnits = [
    'lb',
    'floz',
    'gal',
    'pt',
    'qt',
    'g',
    'kg',
    'ct',
  ];
  // eslint-disable-next-line no-restricted-syntax
  for (const unit of singularSizeUnits) {
    const regex = new RegExp(`\\b(${unit})\b`);
    // console.log(`regex: ${regex}`);
    const matches = name.match(regex);
    if (matches) {
      // console.log(`matches: ${JSON.stringify(matches, null, 2)}`);
      const result = {
        eachSize: 1,
        eachSizeUnit: matches[1] as ExcludeUnexpected<ProductSizeUnit>,
      };
      // console.log(`EachInfo for '${name}': ${JSON.stringify(result, null, 2)}`);
      return result;
    }
  }

  return {};
};

export const getEachCount = (name: string, opts?: {
  additionalUnitMappings?: Record<string, ProductSizeUnitValues>
}): Partial<EachInfo> => {
  // Multiple packs of N - 2 Packs of 6 Bars Each
  const multiplePacksMatches = name.match(/(\d+)\s*?packs of (\d+)\s*(\w+)/i);
  if (multiplePacksMatches) {
    let eachName = multiplePacksMatches[3].trim() || undefined;
    if (eachName && eachName.endsWith('s')) {
      eachName = eachName.slice(0, -1);
    }

    return {
      eachName,
      eachCount: (numberOrUndefined(multiplePacksMatches[1]) ?? 0)
        * (numberOrUndefined(multiplePacksMatches[2]) ?? 0),
    };
  }

  // "50 Pack"
  const matchesPack = name.match(/(\d+)(\s{0,1}packs?)/i);
  if (matchesPack) {
    return {
      eachCount: numberOrUndefined(matchesPack[1]),
    };
  }
  // "50pk"
  const matchesPk = name.match(/(\d+)(\s{0,1}pk)/i);
  if (matchesPk) {
    return {
      eachCount: numberOrUndefined(matchesPk[1]),
    };
  }

  // "100ct"
  const ctMatches = name.match(/(\d+)(\s{0,1}ct)/i);
  if (ctMatches) {
    return {
      eachCount: numberOrUndefined(ctMatches[1]),
    };
  }

  // "100cs"
  const csMatches = name.match(/(\d+)(\s{0,1}cs)/i);
  if (csMatches) {
    return {
      eachCount: numberOrUndefined(csMatches[1]),
    };
  }

  // "Case of 15"
  const caseMatches = name.match(/case of (\d+)/i);
  if (caseMatches) {
    return {
      eachCount: numberOrUndefined(caseMatches[1]),
    };
  }

  // 30 rolls/case - e.g. Paper Towel - Standard Ply Kitchen Rolls (30 rolls/case)
  const slashCaseMatches = name.match(/(\d+)\s*?(\w*?)\s*?\/\s*?(?:case|cs|pack)/i);
  if (slashCaseMatches) {
    let eachName = slashCaseMatches[2].trim();
    if (eachName.endsWith('s')) {
      eachName = eachName.slice(0, -1);
    }
    return {
      eachName,
      eachCount: numberOrUndefined(slashCaseMatches[1]),
    };
  }

  // CS/12
  const reverseSlashCaseMatches = name.match(/(?:case|cs|pack)\/\s*?(\d+)\s*?(\w*?)\s*?/i);
  if (reverseSlashCaseMatches) {
    return {
      eachCount: numberOrUndefined(reverseSlashCaseMatches[1]),
    };
  }

  // 30/10 oz
  // eslint-disable-next-line no-restricted-syntax
  for (const unit of eachSizeUnits) {
    const normalizedName = normalizeSizeAndUnitString(name, opts?.additionalUnitMappings);
    const regex = new RegExp(`(\\d+)\\s{0,1}/\\s{0,1}(\\d+\\.{0,1}\\d*?)\\s{0,1}(${unit})\\b`);
    // console.log(`regex: ${regex}`);
    const matches = normalizedName.match(regex);
    if (matches) {
      // console.log(`matches: ${JSON.stringify(matches, null, 2)}`);
      const result = {
        eachCount: numberOrUndefined(matches[1]),
      };
      // console.log(`EachInfo for '${name}': ${JSON.stringify(result, null, 2)}`);
      return result;
    }
  }

  // 24 rolls per case - e.g. Paper Towel - Premium Ultra Soft Rolls (24 rolls per case)
  const perCaseMatches = name.match(/(\d+)\s*?(\w*?) (per|in a|units a) (?:case|cs)/i);
  if (perCaseMatches) {
    let eachName = perCaseMatches[2].trim() || undefined;
    if (eachName && eachName.endsWith('s')) {
      eachName = eachName.slice(0, -1);
    }

    return {
      eachName,
      eachCount: numberOrUndefined(perCaseMatches[1]),
    };
  }

  // Keg
  const kegMatches = name.match(/(\d+)?\s*kegs?/i);
  if (kegMatches) {
    return {
      eachName: 'keg',
      eachCount: numberOrUndefined(kegMatches[1]),
    };
  }

  return {};
};

export const getEachInfo = (name: string, opts?: {
  additionalUnitMappings: Record<string, ProductSizeUnitValues>;
}): EachInfo => {
  return {
    ...getEachCount(name, opts),
    ...getEachSizeAndUnit(name, opts),
  };
};


export const isBarcode = (str?: string) => {
  if (!str) {
    return false;
  }
  const noSpaces = str.replace(/\s/g, '');
  const digitsOnly = str.replace(/\D/g, '');
  if (digitsOnly.length !== noSpaces.length) {
    return false;
  }

  return digitsOnly.length === 12 || // UPC-A
    digitsOnly.length === 6 || // UPC-E
    digitsOnly.length === 13 || // EAN-13
    digitsOnly.length === 8 || // EAN-8
    digitsOnly.length === 10; // ISBN - Books only?
};

export const getShelfLifeDays = (s: string): number | undefined => {
  const yearExp = new RegExp(/(\d+)\s*(year|years|yr|yrs|y)/i);
  const yearRes = yearExp.exec(s);
  if (yearRes && yearRes[1]) {
    return Number(yearRes[1]) * 365;
  }

  const monExp = new RegExp(/(\d+)\s*(month|months|mon|mo|m)/i);
  const monRes = monExp.exec(s);
  if (monRes && monRes[1]) {
    return Number(monRes[1]) * 30;
  }

  const daysExp = new RegExp(/(\d+)/i);
  const daysRes = daysExp.exec(s);
  if (daysRes && daysRes[1]) {
    return Number(daysRes[1]);
  }

  return undefined;
};
