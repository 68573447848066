import BigNumber from 'bignumber.js';
import isBefore from 'date-fns/isBefore';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';

import { BuyerLocation, Contact, OrderDisplayInfo, OrderInvoiceDisplayInfo, TransactionDiscountClaim, TransactionGroup } from '@mablemarket/core-api-client';

type OrderGroup = {
  orders: OrderDisplayInfo[];
  date: Date | undefined;
  total: BigNumber;
  id: number;
  discountClaims?: TransactionDiscountClaim[] | undefined;
}
export const getOrderGroups = (orders: OrderDisplayInfo[], groups: Record<string, TransactionGroup>) : OrderGroup[] => {
  const keyedOrders = groupBy(orders, order => order.transactionGroupId);

  return uniq(orders.map(order => order.transactionGroupId))
    .map((groupId) => {
      const orders = keyedOrders[groupId] ?? [];
      return {
        ...(groups[groupId] ?? {}),
        orders,
        date: orders.find(o => o.createdAt)?.createdAt, // should all be same, b/c transactions
        total: orders.reduce((total, order) => total.plus(order.total), new BigNumber(0)),
      };
    });
};

export const isPaid = (invoice?: OrderInvoiceDisplayInfo) => {
  return invoice?.status === 'paid' || invoice?.status === 'paymentPending';
};

export const getPaymentStatusNote = (opts: {
  invoice: Pick<OrderInvoiceDisplayInfo, 'paidDate' | 'dueDate' | 'status' | 'scheduledPayment'>,
  invoiceUrl: string | undefined,
  noteFormat?: 'short' | 'long';
  dateFormat?: 'short' | 'long';
}) => {
  const { invoice, invoiceUrl, dateFormat = 'short', noteFormat = 'short' } = opts;
  const { paidDate, dueDate, status, scheduledPayment } = invoice;
  let content: string | undefined;
  const dateOptions = dateFormat === 'short'
    ? { month: 'short', day: 'numeric' } as const
    : { month: 'short', day: 'numeric', year: 'numeric' } as const;
  if (status === 'paymentPending') {
    content = 'Payment Pending';
  } else if (status === 'paid') {
    const date = paidDate?.toLocaleString('en-us', dateOptions);
    content = date ? `Paid ${date}` : 'Paid';
  } else if (status === 'cancelled') {
    content = 'Cancelled';
  } else if (status === 'pending' || status === 'overdue') {
    const autoString = scheduledPayment?.date?.toLocaleString('en-us', dateOptions);
    const dueString = dueDate?.toLocaleString('en-us', dateOptions);
    if (scheduledPayment?.date && !invoiceUrl) {
      if (noteFormat === 'long') {
        content =
          [
            dueDate && `Due ${dueString}.`,
            'Auto pay scheduled',
            autoString && `on ${autoString}`,
          ]
            .filter(x => x)
            .join(' ');
      } else {
        content = autoString ? `Auto Pay ${autoString}` : 'Auto Pay';
      }
    } else {
      content = dueString ? `Due ${dueString}` : 'Due';
    }
  }
  return content;
};

export const isBeforeInvoiceDueDate = ({
  invoice,
} : {
  invoice: Pick<OrderInvoiceDisplayInfo, 'dueDate'>,
}) => {
  const dueDate = invoice.dueDate;
  const d = new Date();
  const utcDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes());
  return dueDate && isBefore(utcDate, dueDate);
};

export function getOrderGroupByLine(opts: {buyerContact?: Contact, orderGroup: OrderGroup, buyerLocations?: BuyerLocation[]}) {
  if (opts.buyerLocations === undefined || opts.buyerLocations.length < 2) {
    if (opts.buyerContact?.firstName && opts.buyerContact?.lastName) {
      // do not abbreviate the contact's "last name" for location names
      const last = (opts.buyerLocations && opts.buyerLocations.length > 0)
        ? opts.buyerContact.lastName
        : `${opts.buyerContact.lastName.charAt(0).toUpperCase()}.`;
      return `by ${opts.buyerContact.firstName} ${last}`;
    }
    if (opts.buyerContact?.email) {
      return `by ${opts.buyerContact.email}`;
    }
  } else {
    const buyerLocation = opts.buyerLocations.find(location => location.id === opts.orderGroup.orders[0].buyerLocationId);
    if (buyerLocation !== undefined) {
      return `by ${buyerLocation.name}`;
    }
  }
  return '';
}
