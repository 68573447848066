var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useNextLocation from '../hooks/useNextLocation';
import { matchPath } from '../hooks/useRouteMatch';
// Switch will inject the computedMatch prop so we don't have to recalculate,
// but normal callers should not be able to set it
var Redirect = function (props) {
    var router = useRouter();
    var location = useNextLocation();
    var _a = props.computedMatch, computedMatch = _a === void 0 ? false : _a;
    var match = computedMatch
        || matchPath(location.pathname, __assign(__assign({}, props), { path: props.path || '/', location: location }));
    useEffect(function () {
        if (match) {
            var navigate = props.push ? router.push : router.replace;
            navigate(typeof props.to === 'string'
                ? props.to
                : [props.to.pathname, props.to.search, props.to.hash].join('')).catch(function () { return null; });
        }
    }, [match, router, props.to, props.push]);
    return null;
};
export default Redirect;
