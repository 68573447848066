import { ComplexStyleRule, StyleRule } from '@vanilla-extract/css';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

export const breakpointNames = ['desktop', 'tablet', 'phone'] as const;
export type BreakPointNames = typeof breakpointNames[number];

// TODO: Should these be 600 and 900? Ask Cindy
export const breakpointsMax = {
  phone: 599,
  tablet: 899,
  desktop: Infinity,
} as const;

export const breakpointsMin = {
  phone: 0,
  tablet: 600,
  desktop: 900,
} as const;

const tabletBreakpoint = `screen and (max-width: ${breakpointsMax.tablet}px)`;
const phoneBreakpoint = `screen and (max-width: ${breakpointsMax.phone}px)`;

export type Breakpoint = keyof typeof breakpointsMax;

export const responsiveConditions = {
  defaultCondition: 'desktop',
  conditions: {
    desktop: {
    },
    tablet: {
      '@media': `screen and (max-width: ${breakpointsMax.tablet}px)`,
    },
    phone: {
      '@media': `screen and (max-width: ${breakpointsMax.phone}px)`,
    },
  },
} as const;

export const responsiveStyle = (rules: Record<Breakpoint, StyleRule>): ComplexStyleRule => {
  return {
    ...rules.desktop,
    '@media': {
      [tabletBreakpoint]: rules.tablet,
      [phoneBreakpoint]: rules.phone,
    },
  };
};

/** A fake, minimal version of `defineProperties(responsiveConditions)` for use in below types */
const baseResponsiveProperties = {
  conditions: {
    defaultCondition: responsiveConditions.defaultCondition,
    conditionNames: Object.keys(responsiveConditions.conditions) as Array<keyof typeof responsiveConditions['conditions']>,
  },
};

// Recreating a bunch of types to support conditionals with non-standard values
type Conditions<ConditionName extends string> = {
  conditions: {
    defaultCondition: ConditionName | false;
    conditionNames: Array<ConditionName>;
    responsiveArray?: Array<ConditionName>;
  };
};
type ExtractDefaultCondition<SprinklesProperties extends Conditions<string>> = SprinklesProperties['conditions']['defaultCondition'];
type ExtractConditionNames<SprinklesProperties extends Conditions<string>> = SprinklesProperties['conditions']['conditionNames'][number];

type ConditionalCustomValue<SprinklesProperties extends Conditions<string>, Value extends unknown> =
  (ExtractDefaultCondition<SprinklesProperties> extends false ? never : Value) | Partial<Record<ExtractConditionNames<SprinklesProperties>, Value>>;

type RequiredConditionalCustomObject<RequiredConditionName extends string, OptionalConditionNames extends string, Value extends unknown> =
  Record<RequiredConditionName, Value> & Partial<Record<OptionalConditionNames, Value>>;

type RequiredConditionalCustomValue<SprinklesProperties extends Conditions<string>, Value extends unknown> =
  ExtractDefaultCondition<SprinklesProperties> extends false ? never : Value
  | RequiredConditionalCustomObject<
    Exclude<ExtractDefaultCondition<SprinklesProperties>, false>,
    Exclude<ExtractConditionNames<SprinklesProperties>,
      ExtractDefaultCondition<SprinklesProperties>>,
    Value
  >;

/** Responsive value with nonstandard type. Intended for use as props and internally mapped to `string | number` */
export type OptionalResponsiveCustomValue<Value extends unknown> =
  ConditionalCustomValue<typeof baseResponsiveProperties, Value>;
/** Responsive value with nonstandard type. Intended for use as props and internally mapped to `string | number` */
export type RequiredResponsiveCustomValue<Value extends unknown> =
  RequiredConditionalCustomValue<typeof baseResponsiveProperties, Value>;

const createNormalizeCustomValueFn = <SprinklesProperties extends Conditions<string>>(properties: SprinklesProperties) => {
  type NormalizeFunction = <Value extends unknown>(
    value: ConditionalCustomValue<SprinklesProperties, Value>
  ) => Partial<Record<ExtractConditionNames<SprinklesProperties>, Value>>;
  const { conditions } = properties;

  if (!conditions) {
    throw new Error('Styles have no conditions');
  }

  const normalizeValue: NormalizeFunction = (value: unknown) => {
    if (
      typeof value === 'object'
      && value !== null
      && properties.conditions.conditionNames.some(conditionName => conditionName in value)
    ) {
      return pick(value, properties.conditions.conditionNames);
    }

    if (!conditions.defaultCondition) {
      throw new Error('No default condition');
    }
    return { [conditions.defaultCondition]: value };
  };

  return normalizeValue;
};

/** Normalize `OptionalResponsiveCustomValue` and `ResponsiveCustomValue` into responsive objects */
export const normalizeCustomResponsiveValue = createNormalizeCustomValueFn(baseResponsiveProperties);

const createMapCustomValueFn = <SprinklesProperties extends Conditions<string>>(
  properties: SprinklesProperties,
) => {
  const { conditions } = properties;

  if (!conditions) {
    throw new Error('Styles have no conditions');
  }

  const normalizeValue = createNormalizeCustomValueFn(properties);

  const mapValue = <OutputValue extends unknown, Value extends unknown>(
    value: ConditionalCustomValue<SprinklesProperties, Value>,
    mapFn: (
      inputValue: Value | undefined,
      key: ExtractConditionNames<SprinklesProperties>,
    ) => OutputValue,
  ): Partial<Record<ExtractConditionNames<SprinklesProperties>, OutputValue>> => {
    const normalizedObject = normalizeValue(value);
    return mapValues(
      normalizedObject,
      (value, key) => mapFn(value, key as keyof typeof normalizedObject),
    );
  };

  return mapValue;
};

/** Transform non-standard responsive (`OptionalResponsiveCustomValue` and
 * `ResponsiveCustomValue`) with custom values, like a boolean, and transform it down to
 * values sprinkles takes, strings and numbers.
 * This is primarily used for props that accept non-standard responsive values,
 * like booleans or arrays, but need to be translated down into a normal
 * responsive value when passed into a sprinkles function.
 */
export const mapCustomResponsiveValue = createMapCustomValueFn(baseResponsiveProperties);
