import BigNumber from 'bignumber.js';
import React, { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';

import { formatCurrencyInput } from '@mablemarket/common-lib';

import TextInput, { TextInputProps } from '../TextInput';

export interface BigNumberInputProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  value: BigNumber | undefined;
  onChange?: (value: BigNumber | undefined) => void;
  isCurrencyInput?: boolean;
}

export const BigNumberInput = React.forwardRef((props: BigNumberInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const { value, onChange, isCurrencyInput = false, ...rest } = props;

  const stringifiedValue = (() => {
    if (value === undefined) {
      return '';
    }
    if (isCurrencyInput) {
      return value.toFixed(2);
    }
    return value.toFixed();
  })();

  const formattedStringifiedValue = isCurrencyInput ? formatCurrencyInput(value?.toFixed(2) ?? '', {
    allowZero: true,
    allowCents: true,
  }) : stringifiedValue;

  const [internalValue, setInternalValue] = useState(stringifiedValue);
  const [isFocused, setIsFocused] = useState(false);

  // Detect when the given value is changed and reset the internal value (read:
  // what the input actually displays) to it
  const [previousStringifiedValue, setPreviousStringifiedValue] = useState(stringifiedValue);
  useEffect(() => {
    setPreviousStringifiedValue(stringifiedValue);
  }, [stringifiedValue]);
  // We do this during render so that it appears to happen synchronously instead of one-render-late
  if (
    stringifiedValue !== previousStringifiedValue
    && (value === undefined ? (internalValue !== '') : !value.eq(new BigNumber(internalValue ?? '')))
  ) {
    setInternalValue(stringifiedValue);
  }

  const derivedValue = (() => {
    if (isCurrencyInput && !isFocused) {
      return formattedStringifiedValue;
    }
    if (
      new BigNumber(internalValue).eq(value ?? NaN)
      || (internalValue !== '' && new BigNumber(internalValue).isNaN())
    ) {
      return internalValue;
    }
    return stringifiedValue;
  })();

  return (
    <TextInput
      ref={ref}
      value={derivedValue}
      {...rest}
      onFocus={(event) => {
        rest.onFocus?.(event);
        const elem = event.target;
        const selectionStart = elem.selectionStart ?? undefined;
        const selectionEnd = elem.selectionEnd ?? undefined;
        if (isCurrencyInput) {
          // Focusing causes the value to be unformatted, potentially changing
          // the value externally and causing the cursor positon to reset to the
          // end of the input. Instead, flush that update then set the cursor
          // positon to where the user intended
          flushSync(() => {
            setIsFocused(true);
          });
          if (selectionStart !== undefined) {
            elem.selectionStart = selectionStart - (formattedStringifiedValue.slice(0, selectionStart).match(/,/)?.length ?? 0);
          }
          if (selectionEnd !== undefined) {
            elem.selectionEnd = selectionEnd - (formattedStringifiedValue.slice(0, selectionEnd).match(/,/)?.length ?? 0);
          }
        } else {
          setIsFocused(true);
        }
      }}
      onChange={(event) => {
        const cleanedValue = event.target.value.replace(new RegExp(`[^-0-9${getDecimalSeparator()}]`, 'g'), '');
        setInternalValue(cleanedValue);
        let parsedValue = cleanedValue === '' ? undefined : new BigNumber(cleanedValue);
        if (parsedValue?.eq(value ?? NaN) || parsedValue?.isNaN()) return;
        if (isCurrencyInput) {
          parsedValue = parsedValue?.decimalPlaces(2, BigNumber.ROUND_DOWN);
        }
        onChange?.(parsedValue);
      }}
      onBlur={(event) => {
        rest.onBlur?.(event);
        setIsFocused(false);
        setInternalValue(stringifiedValue);
      }}
    />
  );
});

const getDecimalSeparator = () => {
  return (1.1).toLocaleString().substring(1, 2);
};


export default BigNumberInput;
