import React, { HTMLAttributes } from 'react';

export const useForwardClick = (props: {
  targetRef: React.RefObject<HTMLElement>,
}): { forwardClickProps: HTMLAttributes<HTMLElement> } => {
  const { targetRef } = props;
  const redirectEvent = (e: React.MouseEvent<Element, MouseEvent>) => {
    // Forward click events to the input if possible
    if (targetRef.current) {
      // If the target is nested inside of the forwarded element ignore real and
      // cloned events that make it to the target
      if (e.target === targetRef.current) return;
      // For input elements, non-editable elements should not fire click events
      const inputRef = targetRef.current as HTMLInputElement;
      if (inputRef.disabled || inputRef.readOnly) {
        return;
      }
      let clonedEvent: MouseEvent;
      try {
        clonedEvent = new MouseEvent(e.type, { ...e, view: undefined });
        if (e.isDefaultPrevented()) {
          clonedEvent.preventDefault();
        }
        if (e.isPropagationStopped()) {
          clonedEvent.stopPropagation();
        }
      } catch (error) {
        // one off polyfill for ie11
        if ('initMouseEvent' in MouseEvent) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          clonedEvent = (MouseEvent as any).initMouseEvent(
            e.type,
            e.bubbles,
            e.cancelable,
            window,
            e.detail,
            e.screenX,
            e.screenY,
            e.clientX,
            e.clientY,
            e.ctrlKey,
            e.altKey,
            e.shiftKey,
            e.metaKey,
            e.button,
            e.relatedTarget,
          );
        } else {
          throw error;
        }
      }
      e.preventDefault();
      e.stopPropagation();
      targetRef.current.dispatchEvent(clonedEvent);
      if (e.type === 'click' || e.type === 'pointerdown') {
        targetRef.current.focus();
      }
    }
  };
  return {
    forwardClickProps: {
      onClick: redirectEvent,
      onPointerDown: redirectEvent,
      onPointerUp: redirectEvent,
      onPointerCancel: redirectEvent,
    },
  };
};

export default useForwardClick;
