import * as Yup from 'yup';

import { formatPhoneNumber } from '@mablemarket/common-lib';
import { Address, BuyerLocation, Contact } from '@mablemarket/core-api-client';

export type AddressFormValues = Partial<Address> & { buyerLocationId?: string };

export const addressValidationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  organization: Yup.string().required('Required'),
  line1: Yup.string().required('Required'),
  locality: Yup.string().required('Required'),
  administrativeAreaLevel1: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  buyerLocationId: Yup.string(),
});

export const initialAddressValuesTemplate = {
  firstName: '',
  lastName: '',
  organization: '',
  line1: '',
  line2: '',
  country: '',
  locality: '',
  administrativeAreaLevel1: '',
  postalCode: '',
  phone: '',
  deliveryNotes: '',
  buyerLocationId: undefined,
};

export const initialAddressFormValues = (opts: {
  existingAddress?: Address;
  contact?: Contact;
  buyerName?: string;
  buyerLocations?: BuyerLocation[];
}): AddressFormValues => {
  const {
    existingAddress,
    buyerLocations,
    contact,
    buyerName,
  } = opts;

  if (existingAddress) {
    return {
      ...initialAddressValuesTemplate,
      ...{ ...existingAddress, phone: existingAddress.phone ? formatPhoneNumber(existingAddress.phone) : '', organization: existingAddress?.organization ?? '' },
    };
  }

  if (contact) {
    return {
      ...initialAddressValuesTemplate,
      firstName: contact.firstName ?? '',
      lastName: contact.lastName ?? '',
      organization: buyerName ?? '',
      phone: contact.phone ? formatPhoneNumber(contact.phone) : '',
      buyerLocationId: buyerLocations?.[0]?.id,
    };
  }

  return {
    ...initialAddressValuesTemplate,
    buyerLocationId: buyerLocations?.[0]?.id,
  };
};
