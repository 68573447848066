var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef } from 'react';
import { mergeProps, useButton, useHover } from 'react-aria';
import { composeRefs } from '../../util';
import Text from '../Text';
import { useTextLinkStyles } from '../TextLink';
/**
 * When you need something that looks like text link, but behaves like a button
 * Use `onPress` instead of `onClick` unless you need something in the `onClick` event that `onPress` doesn't expose.
*/
export var TextLinkButton = React.forwardRef(function (props, ref) {
    var givenForeground = props.foreground, givenUnderline = props.underline, children = props.children, disabled = props.disabled, overlayContainer = props.overlayContainer, onPress = props.onPress, onPressStart = props.onPressStart, onPressEnd = props.onPressEnd, onPressChange = props.onPressChange, onPressUp = props.onPressUp, rest = __rest(props, ["foreground", "underline", "children", "disabled", "overlayContainer", "onPress", "onPressStart", "onPressEnd", "onPressChange", "onPressUp"]);
    var buttonRef = useRef(null);
    var _a = useHover(__assign(__assign({}, rest), { isDisabled: disabled })), hoverProps = _a.hoverProps, isHovered = _a.isHovered;
    var _b = useButton(__assign(__assign({}, rest), { onPress: onPress, onPressStart: onPressStart, onPressEnd: onPressEnd, onPressChange: onPressChange, onPressUp: onPressUp, isDisabled: disabled }), buttonRef), buttonProps = _b.buttonProps, isPressed = _b.isPressed;
    var _c = useTextLinkStyles({
        foreground: givenForeground,
        background: props.background,
        underline: givenUnderline,
        disabled: disabled,
        isHovered: isHovered,
        isPressed: isPressed,
        overlayContainer: overlayContainer,
    }), className = _c.className, restStyles = __rest(_c, ["className"]);
    // Workaround for
    // https://github.com/facebook/react/issues/9809
    // https://github.com/adobe/react-spectrum/issues/1513
    var hasOnClickHandler = !!props.onClick;
    useEffect(function () {
        var button = buttonRef.current;
        var preventDefault = function (e) {
            var _a, _b;
            // Disabling touchend breaks form submission on mobile
            if (((_a = e.currentTarget) === null || _a === void 0 ? void 0 : _a.getAttribute('type')) === 'submit'
                || ((_b = e.currentTarget) === null || _b === void 0 ? void 0 : _b.getAttribute('type')) === 'reset') {
                return;
            }
            e.preventDefault();
        };
        if (!hasOnClickHandler) {
            button === null || button === void 0 ? void 0 : button.addEventListener('touchend', preventDefault, { passive: false });
        }
        return function () { return button === null || button === void 0 ? void 0 : button.removeEventListener('touchend', preventDefault); };
    }, [hasOnClickHandler]);
    return (<Text as='button' type='button' {...rest} 
    // we want this to override the click based event handlers
    {...mergeProps(buttonProps, restStyles, hoverProps)} ref={composeRefs(buttonRef, ref)} className={[
            rest.className,
            className,
        ]}>
      {children}
    </Text>);
});
TextLinkButton.displayName = 'TextLinkButton';
export default TextLinkButton;
