import { assignInlineVars } from '@vanilla-extract/dynamic';
import React from 'react';

import Box, { BoxOwnProps, useForeground } from '../Box';

import * as styles from './Text.css';

import type { ForwardRefComponent } from '@radix-ui/react-polymorphic';

const TextDefaultElement = 'div' as const;
type TextDefaultElement = typeof TextDefaultElement;

export interface TextOwnProps extends BoxOwnProps {
  /** Truncate text to fit layout/width. Useful for user generated content that
   *  may be long. The text trimming workaround requires the use of this prop instead
   *  of passing in `text-overlow: ellipsis` manually.
   * Passing a number instead specifies the number of lines text should be
   *  truncated to
   */
  truncate?: boolean | number;
  /** Trim the leading and trailing line height from the text node. Only turn
   *  this off if you know what you are doing.
   */
  textBoxTrim?: boolean;
}

/** Use this for text
 *  - Automatically trims leading and trailing line-height to allow exact
 *  placement and spacing. Use `Flex`, `Grid` or padding to space appropriately.
 *  If you really, really need that random line-height spacing then use `Box`.
 *  - Avoid nesting text.
 *  - Automatically sets foreground color based on Box background.
 *  - use `Heading` for titles
 * - defaults to `div`. Use `as='span'` or `display='inline'` if you need inline text, though that
      will remove the trimming. This should mostly be used inside of another
      `Text` node in order to style or color a section of the passage.
 */
const Text: ForwardRefComponent<TextDefaultElement, TextOwnProps> = React.forwardRef((props, ref) => {
  const { truncate, textBoxTrim = true, ...rest } = props;
  const isTruncated = truncate === true || (truncate || 0) > 0;
  const foreground = useForeground(rest);
  const isInline = (rest as { as: string }).as === 'span' || rest.display === 'inline';
  return (
    <Box
      foreground={foreground}
      // these must be set by default to make text trimming work
      fontFamily='sans'
      fontSize='medium'
      // Workaround for truncation when a Flex/Grid child https://css-tricks.com/flexbox-truncated-text/
      minWidth={isTruncated ? 0 : undefined}
      {...rest}
      className={[!isInline && textBoxTrim && styles.capsizeClass, props.className]}
      ref={ref}
    >
      {isTruncated ? (
        <Box
          as='span'
          fontSize='inherit'
          style={assignInlineVars({
            [styles.linesToClamp]: typeof truncate === 'number' ? truncate.toString() : '',
          })}
          className={[styles.truncate, typeof truncate === 'number' ? styles.lineClamp : styles.singleLine]}
        >
          {props.children}
        </Box>
      ) : props.children}
    </Box>
  );
}) as ForwardRefComponent<TextDefaultElement, TextOwnProps>;

export default Text;
