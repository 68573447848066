import * as t from 'io-ts';
import { useRef, useState } from 'react';

import { ApiResponse, UnspeccedReqOptions } from '@mablemarket/api-client-support';
import { ensureMableError, MableError } from '@mablemarket/common-lib';
import { ApiClient, routeInfo } from '@mablemarket/core-api-client';

import { useEffectAbort } from './useEffectAbort';

export type Body<RouteName extends keyof typeof routeInfo> = ApiResponse<t.TypeOf<typeof routeInfo[RouteName]['responseCodec']>>['body'];

export type Input<RouteName extends keyof typeof routeInfo> = t.TypeOf<typeof routeInfo[RouteName]['inputCodec']>;

export type Request<RouteName extends keyof typeof routeInfo> = {
  data: Body<RouteName> | null;
  setData: React.Dispatch<React.SetStateAction<Body<RouteName> | null>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: Error | MableError | null;
  setError: React.Dispatch<React.SetStateAction<Error | MableError | null>>;
  request: RequestCallback<RouteName>;
  loadedTime: Date | undefined;
}

export type RequestDelayed<RouteName extends keyof typeof routeInfo> = Omit<Request<RouteName>, 'request'> & {
  request: RequestCallbackRequireInput<RouteName>;
}

// See the below comment on why these two are neccessary

type RequestCallback<RouteName extends keyof typeof routeInfo> = (
  args: {
    input?: Input<RouteName>;
    abortController?: AbortController;
  }
) => Promise<{
  body?: Body<RouteName>;
  error?: Error | MableError;
}>;

type RequestCallbackRequireInput<RouteName extends keyof typeof routeInfo> = (
  args: {
    input: Input<RouteName>;
    abortController?: AbortController;
  }
) => Promise<{
  body?: Body<RouteName>;
  error?: Error | MableError;
}>;


// Allow useRequest input to be optional, but in the case that it is not
// provided the request callback needs an input argument. In the case that input
// is given to useRequest, input may still be given to override the hook input.

export function useRequest<RouteName extends keyof typeof routeInfo>(
  client: ApiClient,
  routeName: RouteName,
  input: Input<RouteName>,
  deps?: unknown[]
): Request<RouteName>;

export function useRequest<RouteName extends keyof typeof routeInfo>(
  client: ApiClient,
  routeName: RouteName
): RequestDelayed<RouteName>;

export function useRequest<RouteName extends keyof typeof routeInfo>(
  client: ApiClient,
  routeName: RouteName,
  input?: Input<RouteName>,
  deps?: unknown[],
) {
  const [data, setData] = useState<Body<RouteName> | null>(null);
  const [error, setError] = useState<Error | MableError | null>(null);
  const [loading, setLoading] = useState<boolean>(() => !!deps);
  const [loadedTime, setLoadedTime] = useState<Date | undefined>(undefined);
  const reqIdRef = useRef<Symbol>();

  const req = async (input: Input<RouteName>, abortController?: AbortController) => {
    const thisReqId = Symbol('currentRequest');
    reqIdRef.current = thisReqId;
    setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await client.req(routeName, input, { abortController });
      const body = res.body as Body<RouteName>;
      setLoadedTime(new Date());
      setData(body ?? null);
      setError(null);
      if (thisReqId === reqIdRef.current) {
        setLoading(false);
      }
      return { body };
    } catch (err) {
      const error = err instanceof Error ? err : ensureMableError(err);
      setError(error);
      if (thisReqId === reqIdRef.current) {
        setLoading(false);
      }
      return { error };
    }
  };

  useEffectAbort(async (abortController) => {
    if (deps && input) {
      await req(input, abortController);
    }
  }, deps);

  const hookInput = input;

  const request: RequestCallback<RouteName> = ({ input, abortController }) => {
    return req(input ?? hookInput ?? {}, abortController);
  };

  return {
    data,
    setData,
    error,
    setError,
    loading,
    setLoading,
    request,
    loadedTime,
  };
}

export function useUnspeccedRequest(
  client: ApiClient,
  method: string,
  path: string,
  deps?: unknown[],
) {
  const [response, setResponse] = useState<Response | null>(null);
  const [error, setError] = useState<Error | MableError | null>(null);
  const [loading, setLoading] = useState<boolean>(() => !!deps);
  const reqIdRef = useRef<Symbol>();

  const req = async (opts: UnspeccedReqOptions): Promise<{ res: Response, error: undefined } | { res: undefined, error: Error }> => {
    const thisReqId = Symbol('currentRequest');
    reqIdRef.current = thisReqId;
    setLoading(true);
    try {
      const res = await client.unspeccedReq(method, path, opts);
      setResponse(res);
      setError(null);
      if (thisReqId === reqIdRef.current) {
        setLoading(false);
      }
      return { res, error: undefined };
    } catch (err) {
      const error = err instanceof Error ? err : ensureMableError(err);
      setError(error);
      if (thisReqId === reqIdRef.current) {
        setLoading(false);
      }
      return { res: undefined, error };
    }
  };

  useEffectAbort(async (abortController) => {
    if (deps) {
      await req({ abortController });
    }
  }, deps);

  const request = (opts: UnspeccedReqOptions = {}) => {
    return req(opts);
  };

  return {
    response,
    setResponse,
    error,
    setError,
    loading,
    setLoading,
    request,
  };
}
