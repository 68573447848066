import BigNumber from 'bignumber.js';
import * as t from 'io-ts';

import { NumberFromString } from '@mablemarket/api-client-support';
import { MableError } from '@mablemarket/common-lib';
import { Product, ProductVariant, SellerDisplayInfo } from '@mablemarket/core-api-client';

// /////////////////////////////////////////////////////////////
// This file contains types used in offline cart storage by the
// app and Mable Web.
// /////////////////////////////////////////////////////////////

export const CartItemInputCodec = t.intersection([
  t.type({
    product: Product,
    variant: ProductVariant,
    seller: SellerDisplayInfo,
    quantity: t.number,
  }),
  t.partial({
    showSellerName: t.boolean,
  }),
], 'CartItemInputCodec');

export type CartItemInput = t.TypeOf<typeof CartItemInputCodec>;

export interface CartItemInfo {
  productId: number;
  variantId: number;
  sellerId: number;
  quantity: number;
  showSellerName?: boolean;
}
export const CartItemInfoCodec = t.type({
  productId: t.number,
  variantId: t.number,
  sellerId: t.number,
  quantity: t.number,
}, 'CartItemInfoCodec');
export const CartItemInfosCodec = t.array(CartItemInfoCodec);
export type CartItemInfos = t.TypeOf<typeof CartItemInfosCodec>;

export interface CartItemInfoAndObjects extends CartItemInfo {
  product?: Product;
  seller?: SellerDisplayInfo;
}

export const CartItemCodec = t.intersection([
  CartItemInputCodec,
  t.type({
    subtotal: NumberFromString,
    subtotalDisplay: t.string,
  }),
], 'CartItemCodec');

export type CartItem = t.TypeOf<typeof CartItemCodec>;

// TODO: A lot of this can be deleted! But maybe not all of it
export interface SellerGroup {
  seller: SellerDisplayInfo;
  items: CartItem[];
  subtotal: BigNumber;
  subtotalDisplay: string;
  unmetMinimum: boolean;
  // TODO: add belowFreeShippingMinimum or something like it
}

export interface CartTotal {
  totalDisplay: string;
  totalBigNumber: BigNumber;
  containsPricessItems: boolean;
}

export interface RefreshItemsResult {
  // If anything went wrong, there will be a value here. Check this first.
  error?: MableError;
  // Up to date items matching the CartItemInfo IDs passed in
  items: CartItem[];
  // Objects that are still valid and available
  matchedInfos: CartItemInfo[];
  // Objects that no matches were found for; they may have been deleted or marked unavailable.
  missingInfos: CartItemInfo[];
  // Messages to be presented to the user, in case anything changed
  messages?: string[];
}
