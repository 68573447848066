var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useHover } from 'react-aria';
import Flex from '../Flex';
import IconMeatballs from '../Icons/IconMeatballs';
import Menu from '../Menu';
import UnstyledButton from '../UnstyledButton';
var OptionsMenu = function (props) {
    var isDisabled = props.isDisabled, onOpenChange = props.onOpenChange, isOpen = props.isOpen, closeOnSelect = props.closeOnSelect, ariaLabel = props["aria-label"], rest = __rest(props, ["isDisabled", "onOpenChange", "isOpen", "closeOnSelect", 'aria-label']);
    var _a = useHover({ isDisabled: isDisabled }), hoverProps = _a.hoverProps, isHovered = _a.isHovered;
    return (<Menu trigger='press' isDisabled={isDisabled} onOpenChange={onOpenChange} closeOnSelect={closeOnSelect} isOpen={isOpen}>
      <Menu.Trigger>
        <UnstyledButton aria-label={ariaLabel !== null && ariaLabel !== void 0 ? ariaLabel : 'Options menu'} {...hoverProps}>
          <Flex alignItems='center' style={{
            width: 36,
            height: 36,
            transition: 'background 0.3s',
        }} justifyContent='center' background={isHovered ? 'greyFill' : 'white'} borderRadius='round'>
            <IconMeatballs height={3} width={13}/>
          </Flex>
        </UnstyledButton>
      </Menu.Trigger>
      <Menu.Contents {...rest}/>
    </Menu>);
};
export default Object.assign(OptionsMenu, {
    Item: Menu.Item,
    LinkItem: Menu.LinkItem,
});
