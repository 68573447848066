import { isRight } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import * as t from 'io-ts';
import fromPairs from 'lodash/fromPairs';
import stubTrue from 'lodash/stubTrue';
import React from 'react';
import { mergeProps } from 'react-aria';
import { AnySchema as YupSchema } from 'yup';

import { makeMableDecodeError, notUndefined } from '@mablemarket/common-lib';
import { ApiClient, Image } from '@mablemarket/core-api-client';

export const setRef = <T>(ref: React.Ref<T>, value: T) => {
  // eslint-disable-next-line no-unused-expressions
  ref && (
    typeof ref === 'function'
      ? ref(value)
      // This cast is fine, React just doesn't want us to prevent us from
      // casually overriding refs
      : (ref as React.MutableRefObject<unknown>).current = value
  );
};

export const composeRefs = <T>(...refs: (React.Ref<T> | undefined)[]) => (value: T) => {
  refs.filter(notUndefined).forEach(ref => setRef(ref, value));
};

/**
 * Adds a custom test to a yup schema via an io-ts codec, and ensures the schema
 * type is a valid input type for the codec. There is some possibility we could
 * do extensive monkey patching with yup.addMethod to make this less annoying.
 * @param schema yup schema
 * @param codec A codec whose input type, I (usually unknown), matches the schema type
 */
export const refineYupSchema = <A extends unknown, Y extends YupSchema<I>, O = A, I = unknown>(schema: Y, codec: t.Type<A, O, I>) => {
  return schema.test({
    name: codec.name,
    test(value: I) {
      const either = codec.decode(value);
      return isRight(either) ? true : this.createError({ message: makeMableDecodeError(either.left).message });
    },
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createUploadWidget = (client: ApiClient, options: object, callback: (error: any, result: any) => void) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).cloudinary.createUploadWidget({
    cloudName: 'mable',
    api_key: '923524768139652',
    uploadPreset: 'SignedServerSideCrop',
    sources: ['local', 'url'],
    theme: 'white',
    multiple: false,
    resource_type: 'image',
    clientAllowedFormats: ['png', 'gif', 'jpg', 'jpeg'],
    cropping: true,
    croppingShowDimensions: true,
    croppingValidateDimensions: true,
    croppingShowBackButton: false,
    croppingDefaultSelectionRatio: 0.1,
    showSkipCropButton: false,
    showPoweredBy: false,
    styles: {
      palette: {
        window: '#FFFFFF',
        windowBorder: '#515151',
        tabIcon: '#0A24CC',
        menuIcons: '#515151',
        textDark: '#515151',
        textLight: '#FFFFFF',
        link: '#0A24CC',
        action: '#0A24CC',
        inactiveTabIcon: '#515151',
        error: '#D13030',
        inProgress: '#0A24CC',
        complete: '#005B19',
        sourceBg: '#FFFFFF',
      },
      fonts: {
        default: null,
        'sans-serif': {
          url: null,
          active: true,
        },
      },
    },
    uploadSignature: async (callback: (signature: string) => unknown, paramsToSign: unknown) => {
      const res = await client.req('POST /v1/cloudinaryUploadSignature', { params: paramsToSign as Record<string, unknown> });
      if (res.body?.signature) {
        callback(res.body.signature);
      }
    },
    ...options,
  }, callback);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const imageFromCloudinaryResult = (result: any) => {
  const newImage: Image = {
    type: 'cloudinary',
    externalBucketId: 'mable',
    externalId: result.info.public_id,
    version: result.info.version.toString(),
    format: result.info.format,
    width: result.info.width,
    height: result.info.height,
  };
  return newImage;
};

export const uploadImageURL = async (client: ApiClient, url: string) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uploader = createUploadWidget(client, {}, (error: any, result: any) => {
      if (!error) {
        if (result && result.event === 'success') {
          const newImage = imageFromCloudinaryResult(result);
          resolve(newImage);
        }
      } else {
        reject(error);
      }
    });
    uploader.open(null, {
      files: [url],
    });
  });
};


/** A dumb hack to allow coexistance of react-aria hooks and certain libraries that require event propagation to work
 *  see https://github.com/adobe/react-spectrum/issues/2100 for possible resolution in the future
 */
export const reactAriaAllowPropagation = (props: React.HTMLAttributes<HTMLElement>) => {
  return mergeProps(
    pipe(
      Object.keys(props),
      keys => keys.filter(key => !!key.match(/^on[A-Z]/)),
      eventKeys => eventKeys.map(key => [key, (e: React.SyntheticEvent) => {
        Object.assign(e, { stopPropagation: stubTrue, preventDefault: stubTrue });
      }]),
      fromPairs,
    ),
    props,
  );
};
