var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { mergeProps } from 'react-aria';
import { notUndefined } from '@mablemarket/common-lib';
import { resets } from '../../styles/sprinkles.css';
import Box from '../Box';
import Grid from '../Grid';
import { InputDescription, InputErrorMessage, InputLabel, InputLabelProvider, useInputLabelContext, useTextInputStyles } from '../TextInput';
var TextArea = React.forwardRef(function (props, ref) {
    var className = props.className, label = props.label, description = props.description, error = props.error, style = props.style, textAlign = props.textAlign, flexBasis = props.flexBasis, flexGrow = props.flexGrow, flexShrink = props.flexShrink, gridArea = props.gridArea, gridColumnEnd = props.gridColumnEnd, gridColumnStart = props.gridColumnStart, gridRowEnd = props.gridRowEnd, gridRowStart = props.gridRowStart, outerRef = props.outerRef, rest = __rest(props, ["className", "label", "description", "error", "style", "textAlign", "flexBasis", "flexGrow", "flexShrink", "gridArea", "gridColumnEnd", "gridColumnStart", "gridRowEnd", "gridRowStart", "outerRef"]);
    var _a = useTextInputStyles({
        disabled: rest.disabled,
        error: !!error,
        left: false,
        right: false,
    }), containerProps = _a.containerProps, inputProps = _a.inputProps;
    var _b = useInputLabelContext({
        inputId: props.id,
    }), inputId = _b.inputId, descriptionId = _b.descriptionId, errorId = _b.errorId;
    return (<InputLabelProvider inputId={inputId} descriptionId={descriptionId} errorId={errorId}>
      <Grid gridAutoFlow='row' gap='space-0.75' ref={outerRef} style={style} className={className} flexBasis={flexBasis} flexGrow={flexGrow} flexShrink={flexShrink} gridArea={gridArea} gridColumnEnd={gridColumnEnd} gridColumnStart={gridColumnStart} gridRowEnd={gridRowEnd} gridRowStart={gridRowStart} textAlign={textAlign}>
        {label && (<InputLabel>{label}</InputLabel>)}
        {description && (<InputDescription>{description}</InputDescription>)}
        <Box as='textarea' id={inputId} aria-invalid={!!error} aria-describedby={[
            description ? descriptionId : undefined,
            error ? errorId : undefined,
        ].filter(notUndefined).join(' ')} {...mergeProps(containerProps, inputProps, rest, {
        className: resets.input,
    })} ref={ref}/>
        {error && error !== true && <InputErrorMessage>{error}</InputErrorMessage>}
      </Grid>
    </InputLabelProvider>);
});
export default TextArea;
