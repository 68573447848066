import { ForwardRefComponent } from '@radix-ui/react-polymorphic';
import { SprinklesFn } from '@vanilla-extract/sprinkles/dist/declarations/src/createSprinkles';
import { SprinklesProperties } from '@vanilla-extract/sprinkles/dist/declarations/src/types';
import React, { useContext } from 'react';

import { BoxExcludeProps } from '../styles/atomValues';
import { flexBasisTheme, gridAreaTheme, gridColumnEndTheme, gridColumnStartTheme, gridRowEndTheme, gridRowStartTheme, OptionalResponsiveValue, sprinkles } from '../styles/sprinkles.css';
import { Backgrounds, backgrounds } from '../styles/theme.css';
import { assignResponsiveProperty, DynamicResponsiveProps } from '../styles/utils';
import { ClassNameValue, cn } from '../webutils/webutils';

export type SprinklesProps<Sprinkles extends Array<SprinklesProperties>> = Parameters<SprinklesFn<Sprinkles>>[0];
const BoxDefaultElement = 'div' as const;
type BoxDefaultElement = typeof BoxDefaultElement;

export const seperateSprinklesProps = <
  Sprinkles extends Array<SprinklesProperties>,
  Props extends {}
>(sprinkles: SprinklesFn<Sprinkles>, props: Props) => {
  const sprinklesHolder: Record<string, unknown> = {};
  const cleanHolder: Record<string, unknown> = {};

  Object.keys(props).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = (props as any)[key];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (sprinkles.properties.has(key as any)) {
      sprinklesHolder[key] = value;
    } else {
      cleanHolder[key] = value;
    }
  });

  const sprinklesProps = sprinklesHolder as Parameters<SprinklesFn<Sprinkles>>[0];
  const cleanProps = cleanHolder as Omit<Props, keyof Parameters<SprinklesFn<Sprinkles>>[0]>;

  return { cleanProps, sprinklesProps };
};

export const createBox = <Sprinkles extends Array<SprinklesProperties>>(
  sprinkles: SprinklesFn<Sprinkles>,
  defaultClassName?: string,
) => {
  type PolymorphicBox = ForwardRefComponent<BoxDefaultElement, SprinklesProps<Sprinkles> & {
    className?: ClassNameValue;
  }>;

  const Box = React.forwardRef<unknown, React.ComponentProps<PolymorphicBox>>(({
    as: is,
    ...props
  }, ref) => {
    const Element: React.ElementType = is || BoxDefaultElement;
    const { sprinklesProps, cleanProps } = seperateSprinklesProps(
      sprinkles,
      props,
    );

    return React.createElement(Element, {
      ...cleanProps,
      ref,
      className: cn(
        defaultClassName,
        props.className,
        sprinkles(sprinklesProps),
      ),
    });
  }) as PolymorphicBox;
  return Box;
};

type SprinklesAtomsProps = Parameters<typeof sprinkles.atoms>[0];
export type InternalBoxOwnProps = SprinklesAtomsProps & {
  className?: ClassNameValue;
} & DynamicResponsiveProps<typeof flexBasisTheme> &
  DynamicResponsiveProps<typeof gridAreaTheme> &
  DynamicResponsiveProps<typeof gridColumnStartTheme> &
  DynamicResponsiveProps<typeof gridColumnEndTheme> &
  DynamicResponsiveProps<typeof gridRowStartTheme> &
  DynamicResponsiveProps<typeof gridRowEndTheme>;
/** For use by common-react-lib layout components early, as it exposes flex/grid
 * props that should only be used through the Flex/Grid components
 */
export const InternalBox: ForwardRefComponent<BoxDefaultElement, InternalBoxOwnProps> = React.forwardRef(({
  as: is,
  flexBasis,
  gridArea,
  gridColumnStart,
  gridColumnEnd,
  gridRowStart,
  gridRowEnd,
  style,
  ...props
}, ref) => {
  const Element: React.ElementType = is || BoxDefaultElement;
  const { sprinklesProps, cleanProps } = seperateSprinklesProps(
    sprinkles.atoms,
    props,
  );

  const flexBasisProps = assignResponsiveProperty(flexBasisTheme, flexBasis);
  const gridAreaProps = assignResponsiveProperty(gridAreaTheme, gridArea);
  const gridColumnStartProps = assignResponsiveProperty(gridColumnStartTheme, gridColumnStart);
  const gridColumnEndProps = assignResponsiveProperty(gridColumnEndTheme, gridColumnEnd);
  const gridRowStartProps = assignResponsiveProperty(gridRowStartTheme, gridRowStart);
  const gridRowEndProps = assignResponsiveProperty(gridRowEndTheme, gridRowEnd);

  const Component = React.createElement(Element, {
    ...cleanProps,
    // eslint-disable-next-line prefer-object-spread
    style: Object.assign(
      {},
      style,
      flexBasisProps.style,
      gridAreaProps.style,
      gridColumnStartProps.style,
      gridColumnEndProps.style,
      gridRowStartProps.style,
      gridRowEndProps.style,
    ),
    ref,
    className: cn(
      sprinkles.baseClass,
      props.className,
      sprinkles.atoms({ fontSize: props.fontSize === undefined ? 'medium' : undefined, minWidth: 0, ...sprinklesProps }),
      flexBasisProps.className,
      gridAreaProps.className,
      gridColumnStartProps.className,
      gridColumnEndProps.className,
      gridRowStartProps.className,
      gridRowEndProps.className,
    ),
  });
  if (!sprinklesProps.foreground && sprinklesProps.background) {
    return (
      <backgroundContext.Provider value={sprinklesProps.background}>
        {Component}
      </backgroundContext.Provider>
    );
  }
  return Component;
}) as ForwardRefComponent<BoxDefaultElement, InternalBoxOwnProps>;

export type BoxOwnProps = Omit<InternalBoxOwnProps, BoxExcludeProps | 'display'> & {
  className?: ClassNameValue;
  /** Don't use `flex` to apply layout. Use it when you need the element to not
   * be a block element (ex. to ignore the minimum height set by line-height)
   */
  display?: OptionalResponsiveValue<'none' | 'block' | 'inline' | 'inline-block' | 'flex' | 'inline-flex'>
};

export type FlexAndGridChildProps = Pick<BoxOwnProps, 'flexBasis' | 'flexGrow' | 'flexShrink' | 'gridArea' | 'gridColumnEnd' | 'gridColumnStart' | 'gridRowEnd' | 'gridRowStart' | 'alignSelf' | 'justifySelf'>;


/**
 * The base component with styling and minimal behavior
 * @param className - You can add your own styles, and you will have to as the
 * style props purposefully do not fully cover all possible requirements. Prefer
 * style props when available as they sometimes have compounding behavior.
 * Do not set the `boxShadow` property directly if you intend to use the
 * `border` or `shadow` props, as they all rely on `boxShadow`. Instead, set
 * `boxShadowVar` from `sprinkles.css.ts` on the element and it will behave as
 * expected.
 * @param padding - This is one of many responsive properties. The types may not
 * make it obvious at first glance, but its value can either be specified
 * normally, `padding='space-1'` or via a responsive object `padding={{ desktop:
 * 'space-1', tablet: 'space-0.5', phone: 'space-0.25' }}`
 * For a full listing of responsive properties, see `responsriveProperties` in
 * `atomValues`
*/
const Box = InternalBox as ForwardRefComponent<BoxDefaultElement, BoxOwnProps>;

export default Box;

export const backgroundContext = React.createContext<keyof Backgrounds | null>(null);
export const useForeground = (props: Partial<Pick<BoxOwnProps, 'foreground' | 'background'>>): BoxOwnProps['foreground'] | undefined => {
  const backgroundContextValue = useContext(backgroundContext);
  const background = props.background ?? backgroundContextValue;
  if (props.foreground) return props.foreground;
  // Automatic default foreground value, overwritten by props value
  const foreground = (background && background in backgrounds)
    ? backgrounds[background as keyof typeof backgrounds]?.foreground
    : undefined;
  return foreground;
};
