import { CSSProperties, StyleRule } from '@vanilla-extract/css';
import { calc } from '@vanilla-extract/css-utils';
import mapValues from 'lodash/mapValues';
import { HTMLProps } from 'react';

import './reset.css';
import { fontMetrics, FontMetrics, vars } from './theme.css';

const space = {
  ...vars.space,
  none: 0,
} as const;

/** Trick to set multiple `box-shadows` on the element to support both borders and drop shadows
  * We avoid the `border` prop because it adds width/height to the element. An
  * inset box-shadow looks exactly the same, but adds no additional size.
  * However, if we want to set another box-shadow (like a drop shadow) it would
  * override the border. By using css vars we can set them independently and
  * then combine them back into a single property.
  *
  * This also works for user supplied `box-shadow` using an additional var.
  */
export const makeBoxShadowProperties = ({
  borderVar,
  borderTopVar,
  borderBottomVar,
  borderLeftVar,
  borderRightVar,
  shadowVar,
}: {
  borderVar: string,
  borderTopVar: string,
  borderBottomVar: string,
  borderLeftVar: string,
  borderRightVar: string,
  shadowVar: string,
}) => {
  return {
    // trailing comma is required due to contrusction of multiple variables into the box shadow property
    // This may require a separate set of colors
    borderTop: mapValues({ ...vars.color.foreground, ...vars.color.background }, color => ({
      vars: {
        [borderTopVar]: `inset 0 1px 0 0 ${color},`,
        [borderVar]: `${borderTopVar} ${borderBottomVar} ${borderLeftVar} ${borderRightVar}`,
      },
    })),
    borderBottom: mapValues({ ...vars.color.foreground, ...vars.color.background }, color => ({
      vars: {
        [borderBottomVar]: `inset 0 -1px 0 0 ${color},`,
        [borderVar]: `${borderTopVar} ${borderBottomVar} ${borderLeftVar} ${borderRightVar}`,
      },
    })),
    borderLeft: mapValues({ ...vars.color.foreground, ...vars.color.background }, color => ({
      vars: {
        [borderLeftVar]: `inset 1px 0 0 0 ${color},`,
        [borderVar]: `${borderTopVar} ${borderBottomVar} ${borderLeftVar} ${borderRightVar}`,
      },
    })),
    borderRight: mapValues({ ...vars.color.foreground, ...vars.color.background }, color => ({
      vars: {
        [borderRightVar]: `inset -1px 0 0 0 ${color},`,
        [borderVar]: `${borderTopVar} ${borderBottomVar} ${borderLeftVar} ${borderRightVar}`,
      },
    })),
    shadow: mapValues(vars.shadow, shadow => ({ vars: { [shadowVar]: `${shadow},` } })),
  };
};


const calculateCapHeightTrimConstant = (fm: FontMetrics) => {
  const { ascent, capHeight, lineGap, descent, unitsPerEm } = fm;
  // constant factor in equation to determine excess leading for given font
  // derived from https://github.com/seek-oss/capsize/blob/master/packages/core/src/precomputeValues.ts
  // returned without units, but effectively in em's (decimal percentage of font size)
  return (-1 * (((ascent / 2) - capHeight - (lineGap / 2) - (Math.abs(descent) / 2)) / unitsPerEm)).toString();
};

const calculateBaselineTrimConstant = (fm: FontMetrics) => {
  const { ascent, descent, unitsPerEm } = fm;
  // constant factor in equation to determine excess trailing line height for given font
  // derived from https://github.com/seek-oss/capsize/blob/master/packages/core/src/precomputeValues.ts
  // returned without units, but effectively in em's (decimal percentage of font size)
  return (-1 * ((Math.abs(descent) - ascent) / (2 * unitsPerEm))).toString();
};

const calculateFontSizeToCapHeightConstant = (fm: FontMetrics) => {
  const { capHeight, unitsPerEm } = fm;
  // TODO: Should we round this here?
  return (capHeight / unitsPerEm).toString();
};

// TODO: vanilla-extract should export more types, this is a copy-paste job
type AtomicProperties = {
  [Property in keyof CSSProperties]?: Record<string, CSSProperties[Property] | Omit<StyleRule, 'selectors' | '@media' | '@supports'>> | ReadonlyArray<CSSProperties[Property]>;
};

/** Enforce type of AtomicProperties but still allow us to get a const type */
const enforceProperties = <T extends AtomicProperties>(properties: T): T => properties;

export const makeUnresponsiveProperties = ({
  capHeightTrimConstantVar,
  baselineTrimConstantVar,
  fontSizeToCapHeightConstantVar,
}: {
  capHeightTrimConstantVar: string;
  baselineTrimConstantVar: string;
  fontSizeToCapHeightConstantVar: string;
}) => {
  return enforceProperties({
    background: vars.color.background,
    foreground: {
      ...mapValues(vars.color.foreground, color => ({ color }) as const),
      inherit: 'inherit',
    },
    overflow: ['hidden', 'scroll', 'visible', 'auto'],
    overflowWrap: ['normal', 'break-word'],
    wordBreak: ['normal', 'break-word'],
    whiteSpace: ['normal', 'nowrap', 'pre-line'],
    userSelect: ['none'],
    zIndex: {
      0: 0,
      1: 1,
      2: 2,
      // TODO: collect and standardize z-index values
      // dropdownBackdrop: 90,
      // dropdown: 100,
      // sticky: 200,
      // modalBackdrop: 290,
      // modal: 300,
      // notification: 400,
    },
    cursor: ['default', 'pointer'],
    pointerEvents: ['none'],
    top: [0],
    bottom: [0],
    left: [0],
    right: [0],
    minWidth: {
      0: '0%',
      '100%': '100%',
    },
    maxWidth: {
      content: vars.contentWidth,
    },
    minHeight: ['0', '100%'],
    boxSizing: ['border-box', 'content-box'],
    fontFamily: mapValues(vars.typography.family, (fontFamily, key) => ({
      fontFamily,
      vars: {
        [capHeightTrimConstantVar]: calculateCapHeightTrimConstant(fontMetrics[key as keyof typeof vars.typography.family]),
        [baselineTrimConstantVar]: calculateBaselineTrimConstant(fontMetrics[key as keyof typeof vars.typography.family]),
        [fontSizeToCapHeightConstantVar]: calculateFontSizeToCapHeightConstant(fontMetrics[key as keyof typeof vars.typography.family]),
      },
    })),
  } as const);
};

export type UnresponsiveProperties = keyof ReturnType<typeof makeUnresponsiveProperties>;

const selfPositioning = [
  'auto',
  'start',
  'center',
  'end',
  'stretch',
  'baseline',
  'flex-start',
  'flex-end',
] as const;

export const flexPositioning = [
  ...selfPositioning,
  'space-between',
  'space-around',
  'space-evenly',
] as const;

const flexOnlyProperties = enforceProperties({
  flexWrap: ['wrap', 'nowrap', 'wrap-reverse'],
  flexDirection: ['row', 'row-reverse', 'column', 'column-reverse'],
} as const);

const gridOnlyProperties = enforceProperties({
  gridAutoFlow: ['row', 'column'],
  rowGap: space,
  columnGap: space,
} as const);

const flexGridCommonProperties = {
  alignItems: flexPositioning,
  alignJustify: flexPositioning,
  justifyContent: flexPositioning,
  justifyItems: flexPositioning,
} as const;

/** Properties in sprinkles, but only meant to be exposed as props on the `Flex` component */
export type FlexProperties = 'placeItems' | keyof typeof flexOnlyProperties | keyof typeof flexGridCommonProperties;
export const flexProperties: FlexProperties[] = [
  ...Object.keys(flexOnlyProperties) as Array<keyof typeof flexOnlyProperties>,
  ...Object.keys(flexGridCommonProperties) as Array<keyof typeof flexGridCommonProperties>,
  'placeItems',
];
/** Properties in sprinkles, but only meant to be exposed as props on the `Grid` component */
export type GridProperties = 'gap' | 'placeItems' | keyof typeof gridOnlyProperties | keyof typeof flexGridCommonProperties;
export const gridProperties: GridProperties[] = [
  'gap' as const,
  'placeItems' as const,
  ...Object.keys(gridOnlyProperties) as Array<keyof typeof gridOnlyProperties>,
  ...Object.keys(flexGridCommonProperties) as Array<keyof typeof flexGridCommonProperties>,
];

/** Properties in sprinkles, but meant to be excluded from the user facing `Box` */
export type BoxExcludeProps = FlexProperties | GridProperties;

export const makeResponsiveProperties = ({
  fontSizeVar,
  lineHeightVar,
  capHeightVar,
  fontSizeToCapHeightConstantVar,
}: {
  fontSizeVar: string,
  lineHeightVar: string,
  capHeightVar: string,
  fontSizeToCapHeightConstantVar: string,
}) => {
  return enforceProperties({
    display: ['none', 'block', 'inline', 'inline-block', 'flex', 'inline-flex', 'grid', 'inline-grid'],
    visibility: ['visible', 'hidden'],
    position: ['static', 'relative', 'absolute', 'fixed'],
    borderRadius: {
      none: '0px',
      pill: '9999px',
      round: '100%',
      ...vars.borderRadius,
    },
    paddingTop: space,
    paddingBottom: space,
    paddingRight: space,
    paddingLeft: space,

    textAlign: ['left', 'center', 'right'],
    fontWeight: vars.typography.weight,
    fontSize: {
      ...mapValues(vars.typography.size, size => ({
        ...size,
        vars: {
          [fontSizeVar]: size.fontSize,
          [lineHeightVar]: size.lineHeight,
          [capHeightVar]: calc.multiply(size.fontSize, fontSizeToCapHeightConstantVar),
        },
      }) as const),
      inherit: 'inherit',
    },

    flexShrink: [0, 1],
    flexGrow: [0, 1],
    alignSelf: selfPositioning,
    justifySelf: selfPositioning,

    ...flexOnlyProperties,
    ...gridOnlyProperties,
    ...flexGridCommonProperties,
  } as const);
};

export type ResponsiveProperties = keyof ReturnType<typeof makeResponsiveProperties>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OverwrittenPropNames = Extract<keyof HTMLProps<any>, ResponsiveProperties | UnresponsiveProperties>;
type IntrinsicPropCanary = OverwrittenPropNames extends never ? true : false;
/** If the build is failing here, then that means that the sprinkles atoms
 *  contain a name already used by an intrinsic HTML element.
 *  You can find the offending names in OverwrittenPropNames.
 *  If there is a better way to do a type assert then feel free to change this.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const intrinsicPropCanary: IntrinsicPropCanary = true;
