var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Yup from 'yup';
import { formatPhoneNumber } from '@mablemarket/common-lib';
export var addressValidationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    organization: Yup.string().required('Required'),
    line1: Yup.string().required('Required'),
    locality: Yup.string().required('Required'),
    administrativeAreaLevel1: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
    buyerLocationId: Yup.string(),
});
export var initialAddressValuesTemplate = {
    firstName: '',
    lastName: '',
    organization: '',
    line1: '',
    line2: '',
    country: '',
    locality: '',
    administrativeAreaLevel1: '',
    postalCode: '',
    phone: '',
    deliveryNotes: '',
    buyerLocationId: undefined,
};
export var initialAddressFormValues = function (opts) {
    var _a, _b, _c, _d, _e;
    var existingAddress = opts.existingAddress, buyerLocations = opts.buyerLocations, contact = opts.contact, buyerName = opts.buyerName;
    if (existingAddress) {
        return __assign(__assign({}, initialAddressValuesTemplate), __assign(__assign({}, existingAddress), { phone: existingAddress.phone ? formatPhoneNumber(existingAddress.phone) : '', organization: (_a = existingAddress === null || existingAddress === void 0 ? void 0 : existingAddress.organization) !== null && _a !== void 0 ? _a : '' }));
    }
    if (contact) {
        return __assign(__assign({}, initialAddressValuesTemplate), { firstName: (_b = contact.firstName) !== null && _b !== void 0 ? _b : '', lastName: (_c = contact.lastName) !== null && _c !== void 0 ? _c : '', organization: buyerName !== null && buyerName !== void 0 ? buyerName : '', phone: contact.phone ? formatPhoneNumber(contact.phone) : '', buyerLocationId: (_d = buyerLocations === null || buyerLocations === void 0 ? void 0 : buyerLocations[0]) === null || _d === void 0 ? void 0 : _d.id });
    }
    return __assign(__assign({}, initialAddressValuesTemplate), { buyerLocationId: (_e = buyerLocations === null || buyerLocations === void 0 ? void 0 : buyerLocations[0]) === null || _e === void 0 ? void 0 : _e.id });
};
