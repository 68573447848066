import Fuse from 'fuse.js';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from 'downshift';
import { OverlayContainer, useOverlayPosition } from 'react-aria';
import { OmnisearchResult, OmnisearchResultObject } from '@mablemarket/core-api-client';
import { useDebounce } from 'use-debounce';
import { useRequest, useEffectAbort, cn, useApiClient } from '@mablemarket/common-react-lib';
import styles from './Omnisearch.module.scss';
import { useRouter } from 'next/router';


const omnisearchHelp = [
  { command: 'p', description: 'Product', example: 'chocolate' },
  { command: 'p', description: 'Product by ID', example: '1234' },
  { command: 'v', description: 'Variant by ID', example: '1234' },
  { command: 'gtin', description: 'Variant by GTIN', example: '1234567890123' },
  { command: 'uin', description: 'Variant by McLane UIN', example: '771234' },
  { command: 'apn', description: 'Variant by USFoods APN', example: 'abc123' },
  { command: 's', description: 'Seller', example: 'creminelli' },
  { command: 's', description: 'Seller by ID', example: '1234' },
  { command: 'sa', description: 'Seller Account', example: 'creminelli' },
  { command: 'b', description: 'Buyer Account', example: 'paradise' },
  { command: 'b', description: 'Buyer Account by ID', example: '1234' },
  { command: 'c', description: 'Category by name', example: 'keto' },
  { command: 'c', description: 'Category by ID', example: '1234' },
  { command: 'sku', description: 'Mable SKU', example: '1234' },
  { command: 'tr', description: 'Tracking code', example: '1AX3P' },
  { command: 'u', description: 'Account by user ID', example: '1234' },
  { command: 'e', description: 'Account by email', example: 'jared@meetmable.com' },
  { command: 'r', description: 'Account by referral code', example: 'yy4X' },
  { command: 'con', description: 'Account by contact name/email/phone', example: 'jimi' },
  { command: 't', description: 'Account by tag', example: 'alltown' },
  { command: 'a', description: 'Account by name', example: 'mclane' },
  { command: 'a', description: 'Account by ID', example: '1234' },
  { command: 'po', description: 'Orders by External PO', example: '123ABC' },
];

export interface OmnisearchProps {
  className?: string,
  forcedCommand?: string | string[],
  customActionHandler?: (id: string, params: { inputValue?: string, command?: string }, object?: OmnisearchResultObject) => any,
  blurAfterSelection?: boolean,
  retainSelection?: boolean,
  pageSize?: number,
  customEnterHandler?: (inputValue: string) => any,
  inputValueChangedHandler?: (inputValue: string) => any,
  inputValue?: string,
  initialInputValue?: string,
  constantOptions?: OmnisearchResult[], // Search a discreet set of options rather than calling the omnisearch endpoint
  includeFullObject?: boolean,
}

const actionRegex = new RegExp(/(.*?):(.*)/);

const Omnisearch: React.FC<OmnisearchProps> = ({
  className,
  forcedCommand,
  customActionHandler,
  blurAfterSelection,
  retainSelection,
  pageSize,
  customEnterHandler,
  inputValueChangedHandler,
  inputValue,
  initialInputValue,
  constantOptions,
  includeFullObject = false,
}) => {
  const client = useApiClient();
  const router = useRouter();

  const [inputItems, setInputItems] = useState<OmnisearchResult[]>([]);
  const [skipNextSearch, setSkipNextSearch] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const comboReducer = (state: UseComboboxState<OmnisearchResult | null>, actionAndChanges: UseComboboxStateChangeOptions<OmnisearchResult | null>) => {
    switch (actionAndChanges.type) {
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
        // Search for the current input value if you press enter with no selection
        if (state.highlightedIndex === -1) {
          return { ...state, isOpen: false };
        }
        return actionAndChanges.changes;
      // Keep highlighted index on mouse leave to prevent scroll jumping
      case useCombobox.stateChangeTypes.MenuMouseLeave:
        return { ...actionAndChanges.changes, highlightedIndex: state.highlightedIndex };
      case useCombobox.stateChangeTypes.InputBlur:
        return {
          ...actionAndChanges.changes,
          inputValue: state.inputValue,
        };
      default:
        return actionAndChanges.changes; // fallthrough to default behavior
    }
  };

  const suggestReq = useRequest(client, 'GET /v1/admin/omnisearch');
  const [_comboBoxInputValue, _setComboBoxInputValue] = useState(inputValue ?? '');
  const comboBoxInputValue = inputValue ?? _comboBoxInputValue;
  const setComboBoxInputValue = inputValueChangedHandler ?? _setComboBoxInputValue;

  const forcedCommands = (() => {
    if (!forcedCommand) return undefined;
    return Array.isArray(forcedCommand) ? forcedCommand : [forcedCommand];
  })();

  const { commands, completeCommand, nonDebouncedQuery } = (() => {
    if (forcedCommands) {
      return {
        commands: forcedCommands,
        completeCommand: true,
        nonDebouncedQuery: comboBoxInputValue,
      }
    }
    const splits = actionRegex.exec(comboBoxInputValue);
    return {
      commands: splits ? [splits[1].toLowerCase()] : [comboBoxInputValue],
      completeCommand: !!splits,
      nonDebouncedQuery: splits ? splits[2] : '',
    }
  })();

  const [query] = useDebounce(nonDebouncedQuery, 200, { trailing: true });

  // No constantOptions - debounce the call to suggestReq
  useEffectAbort((abortController) => {
    if (constantOptions) return;
    if (skipNextSearch) {
      setSkipNextSearch(false);
      return;
    }
    if (query.length === 0) return setInputItems([]);
    suggestReq.request({
      input: {
        commands,
        query,
        pageSize,
        includeFullObject,
      },
      abortController,
    });
  }, [query]);

  // Yes constantOptions - non-debounced local search
  useEffect(() => {
    if (!constantOptions) return;
    const matchedOptions =
      comboBoxInputValue
        ? (new Fuse(constantOptions, { keys: ['id', 'name'] }).search(comboBoxInputValue).map(result => result.item))
        : [];

    setInputItems(matchedOptions);
  }, [comboBoxInputValue]);

  useEffect(() => {
    if (inputValue === '') {
      setInputItems([]);
    }
  }, [inputValue]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
    setInputValue,
    inputValue: innerInputValue,
  } = useCombobox({
    stateReducer: comboReducer,
    items: inputItems,
    itemToString: (i) => i?.adminAction.text ? i.adminAction.text : '',
    // useCombobox won't render the initialInputValue at all if the key `inputValue` is in this object,
    // even if the value is undefined.
    ...(inputValue ? { inputValue } : {}),
    initialInputValue,
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        if (customActionHandler && changes.selectedItem.adminAction.id) {
          customActionHandler(
            changes.selectedItem.adminAction.id,
            { inputValue: changes.selectedItem.adminAction.text, command: changes.selectedItem.adminAction.command },
            changes.selectedItem.object,
          );
        } else if (changes.selectedItem.adminAction.url) {
          router.push(changes.selectedItem.adminAction.url);
        }
      }

      if (retainSelection) {
        // Don't do another pointless search when an item value is selected.
        setSkipNextSearch(true);
        setInputValue(changes.selectedItem?.adminAction.text ?? '');
      } else {
        setInputValue('');
        setInputItems([]);
      }

      if (blurAfterSelection ?? true) {
        inputRef.current?.blur();
      }
    },
    onInputValueChange: (changes) => {
      setComboBoxInputValue(changes.inputValue ?? '');
    }
  });

  // This is ripped from react-aria's source, I didn't waste time writing this.
  // It just isn't released yet. Overlay's cannot be accurately repositioned
  // during/after scrolling, so we hide it. This is also usually what a user wants
  // to happen.
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const onScroll = (e: Event) => {
      // Ignore if scrolling an scrollable region outside the trigger's tree.
      const target = e.target;
      const trigger = inputRef.current;
      // window is not a Node and doesn't have contain, but window contains everything
      if (!trigger || ((target instanceof Node) && !target.contains(trigger))) {
        return;
      }
      closeMenu();
    };

    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [isOpen, closeMenu, inputRef]);

  useEffect(() => {
    if (!suggestReq.data) {
      return;
    }

    setInputItems(suggestReq.data.results);
  }, [suggestReq.data]);

  const shouldDisplayHelp = (!inputItems.length && !constantOptions);

  const overlayRef = useRef<HTMLDivElement>(null);
  const { overlayProps: overlayPositionProps } = useOverlayPosition({
    targetRef: inputRef,
    overlayRef,
    isOpen,
    placement: 'bottom left',
    offset: 10,
    shouldFlip: true,
  });

  return (
    <div {...getComboboxProps()} className={cn(styles.wrapper, className)}>
      <span className={styles.searchIcon}>
        {suggestReq.loading ? '⏳' : '🔎'}
      </span>
      <input
        {...getInputProps({
          onFocus: openMenu,
          ref: inputRef,
          className: styles.input,
          ...(customEnterHandler ? ({
            onKeyDown: event => {
              if (highlightedIndex === -1) {
                if (event.key === 'Enter') {
                  customEnterHandler(innerInputValue);
                }
              }
            },
          }) : {}),
        })}
      />

      {(shouldDisplayHelp || !!inputItems.length)
        ? (
          <OverlayContainer portalContainer={document.querySelector('#floating-portal-root') ?? undefined}>
            <div className={styles.menu} {...getMenuProps({ ref: overlayRef, ...overlayPositionProps })} hidden={!isOpen}>
              {shouldDisplayHelp && !forcedCommand && (
                <div className={styles.help}>
                  <p>Go places fast with prefix-based searches:</p>
                  <ul>
                    {omnisearchHelp.map(help => ((commands[0] === '' || commands.find(c => (completeCommand ? help.command === c : help.command.startsWith(c))))
                      ? <li key={help.description}>
                        {help.description}: <code>{help.command}:{help.example}</code>
                      </li>
                      : undefined
                    ))}
                  </ul>
                </div>
              )}
              {shouldDisplayHelp && forcedCommands && (
                <div className={styles.help}>
                  <p>Search for:</p>
                  <ul>
                    {forcedCommands.flatMap(command =>
                      omnisearchHelp.filter(help => help.command === command).map(help => (
                        <li key={help.description}>
                          {help.description}: <code>{help.example}</code>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              )}
              {!!inputItems.length && (
                <ul className={styles.options}>
                  {inputItems.map((item, index) => (
                    <li
                      className={cn(
                        styles.option,
                        highlightedIndex === index && styles.selectedOption,
                        // styles.option.$selected,
                      )}
                      {...getItemProps({ item, index })}
                      key={`${item.adminAction.url}${item.adminAction.text}${item.adminAction.id}`}
                    >
                      {item.adminAction.text}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </OverlayContainer>
        ) : (
          <div
            // A menu must always be rendered for a11y/downshift
            {...getMenuProps()}
          />
        )}
    </div >
  );
};

export default Omnisearch;
