import { ForwardRefComponent } from '@radix-ui/react-polymorphic';
import { pipe } from 'fp-ts/lib/function';
import uniqBy from 'lodash/uniqBy';
import React from 'react';

import { notUndefined, optionalizeFunctionArgument } from '@mablemarket/common-lib';
import { Image } from '@mablemarket/core-api-client';
import { ImageHelpers } from '@mablemarket/mable-lib';


import { BreakPointNames, breakpointsMax, mapCustomResponsiveValue, OptionalResponsiveCustomValue } from '../../styles/breakpoints';
import { sprinkles } from '../../styles/sprinkles.css';
import { cn } from '../../webutils/webutils';
import Box from '../Box';

import * as styles from './Img.css';

const emptyGif = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const svgAspectRatio = ({ width, height }: { width: number, height: number }) => (
  `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`
);

const suffix = /\.\w+$/;

export type Src = React.ComponentProps<'img'>['src'] | Image;
export type Transform = ImageHelpers.ImageTransformation | string;

const srcToStrs = (src: Src, transform: string) => {
  const defaultSrc = typeof src === 'string'
    ? src
    : optionalizeFunctionArgument(ImageHelpers.imageURLfromImage, 0)(src, transform);
  const webpSrc = typeof src === 'string' ? undefined : (defaultSrc?.replace(suffix, '.webp'));
  if (defaultSrc === webpSrc) {
    return { defaultSrc };
  }

  return { defaultSrc, webpSrc };
};

export type ImgProps = Omit<React.ComponentProps<'img'>, 'src' | 'height' | 'width'> & {
  /** used to specify the aspect ratio of the image, along with width. If they
   * are both set hidpi clients will automatically recieve retina images.
   */
  height?: number;
  /** used to specify the aspect ratio of the image, along with height. If they
   * are both set hidpi clients will automatically recieve retina images.
   */
  width?: number;
  /** Allow the image to grow (via the aspect ratio) above the given height and width */
  grow?: boolean;
  className?: string;
  /** load the image only when it enters the viewport */
  lazy?: boolean;
} & (
    {
      src: string | undefined;
      retinaSrc?: string;
    } | {
      src: Image | undefined;
      /** May either be a string via
       * https://cloudinary.com/documentation/image_transformations, or an object
       * with a transform function that produces the previous string, as well as
       * responsive width values that are given to the transform function. The
       * transform function may be given additional width values (ex. for retina
       * images) during use, so it should expect to handle any non-negative integer
       * value
       */
      transform: Transform;
    }
  );

/** Replacement for `img` that can handle transforming cloudinary `Image`
 * objects and transforms into urls, sizing images to aspect ratios, and
 * automatically serving retina images to hidpi clients.
 */
const Img = React.forwardRef((props: ImgProps, ref: React.ForwardedRef<HTMLImageElement>) => {
  const {
    src,
    retinaSrc,
    transform = '',
    height,
    width,
    grow,
    className,
    alt,
    lazy = true,
    ...rest
  } = { retinaSrc: undefined, transform: undefined, ...props };
  let sources: Partial<Record<'normal' | 'webp', ResponsiveSource>>;
  if (typeof src === 'string') {
    sources = {
      normal: {
        src,
        retinaSrc,
        width,
        retinaWidth: width !== undefined ? width * 2 : undefined,
      },
    };
  } else if (typeof transform === 'string') {
    const srcs = srcToStrs(src, transform);
    sources = {
      normal: { src: srcs.defaultSrc },
      webp: { src: srcs.webpSrc },
    };
  } else {
    // We can only auto generate retina images if the caller passes in fixed
    // dimensions/aspect ratio, as otherwise they may be relying on the
    // intrinsic image size for layout.
    const allowAutoRetina = (width ?? height) !== undefined;
    sources = {
      normal: mapCustomResponsiveValue(transform.width, width => (
        width === undefined
          ? undefined
          : {
            src: srcToStrs(src, transform.transform(width, 1)).defaultSrc,
            width,
            retinaSrc: allowAutoRetina ? srcToStrs(src, transform.transform(width * 2, 2)).defaultSrc : undefined,
            retinaWidth: width * 2,
          }
      )),
      webp: mapCustomResponsiveValue(transform.width, width => (
        width === undefined
          ? undefined
          : {
            src: srcToStrs(src, transform.transform(width, 1)).webpSrc,
            width,
            retinaSrc: allowAutoRetina ? srcToStrs(src, transform.transform(width * 2, 2)).webpSrc : undefined,
            retinaWidth: width * 2,
          }
      )),
    };
  }

  if (height === undefined || width === undefined) {
    return (
      <picture
        // These ensure picture has the same height as the contained `img`
        style={{ lineHeight: 0 }}
        className={sprinkles.atoms({ display: 'flex' })}
      >
        <ImageSource as='source' sources={sources.webp} webp />
        <ImageSource
          {...rest}
          ref={ref}
          as='img'
          sources={sources.normal}
          webp={false}
          fallbackSrc={emptyGif}
          fallbackClassName={styles.empty}
          loading={lazy ? 'lazy' : undefined}
          alt={alt}
          className={className}
        />
      </picture>
    );
  }
  return (
    <Box position='relative' style={{ maxWidth: grow ? '100%' : width, width: '100%', height: '100%' }}>
      <Box
        position='relative'
        style={{ paddingTop: `${((height / width) * 100).toFixed()}%`, height: 0 }}
      />
      <picture
        style={{ lineHeight: 0 }}
        className={sprinkles.atoms({ display: 'flex' })}
      >
        <ImageSource as='source' sources={sources.webp} webp />
        <ImageSource
          {...rest}
          ref={ref}
          as='img'
          sources={sources.normal}
          webp={false}
          fallbackSrc={svgAspectRatio({ width, height })}
          loading={lazy ? 'lazy' : undefined}
          alt={alt}
          className={cn(
            styles.image,
            grow && styles.grow,
            className,
          )}
        />
      </picture>
    </Box>
  );
  // Must cast as the forwardRef breaks the props 'src' discrimination for some reason
}) as (props: ImgProps & React.RefAttributes<HTMLImageElement>) => JSX.Element;

export default Object.assign(Img, { transforms: ImageHelpers.ImageTransforms });

type ImageSourceOwnProps = {
  sources: ResponsiveSource | undefined;
  fallbackSrc?: string;
  fallbackClassName?: string;
  webp: boolean;
};

const ImageSource = React.forwardRef((props, ref) => {
  const { sources, webp, as: is = 'img', fallbackSrc, className, fallbackClassName, ...rest } = props;
  const { phone, tablet, desktop } = mapCustomResponsiveValue(sources, (image, breakpoint) => {
    if (image === undefined) return undefined;
    const { src, width, retinaSrc, retinaWidth } = image;
    if (src === undefined) return undefined;
    return {
      src,
      srcSets: ([
        width !== undefined ? ([makeSrcSet(src, width), width] as const) : undefined,
        (retinaSrc !== undefined && retinaWidth !== undefined) ? ([makeSrcSet(retinaSrc, retinaWidth), retinaWidth] as const) : undefined,
      ]).filter(notUndefined),
      sizes: width === undefined ? undefined : makeSizes(breakpoint, width),
    };
  });
  // Property of the format 'image-1.jpg 100w, image-2 200w, image-3 300w',
  // where `Nw` is the image's intrinsic width in pixels.
  const srcSet = pipe(
    [...(phone?.srcSets ?? []), ...(tablet?.srcSets ?? []), ...(desktop?.srcSets ?? [])],
    // The srcSet property must be unique by width value, so we refine the srcSet
    // tuples to only have one value per width
    srcSets => uniqBy(srcSets, ([, width]) => width),
    srcSets => srcSets.map(([srcSetValue]) => srcSetValue),
  ).join(', ');
  // Property of the format `(max-width: 600px): 100px, (max-width: 800px): 200px, 300px`, where the media
  // query is used to find the preferred image width.
  // That width (here in pixels) is used to lookup the closest value in
  // `srcSet`. It allows for use of the retina/hidpi images because here the width
  // is multiplied by the users display pixel ratio to find an image that better
  // matches the real resolution of the device instead of the fake one used in
  // media queries.
  // Browsers may also be intellignet enough to skip this step, allowing the
  // device to download a lower resolution image when the user is on slow
  // bandwidth, for example.
  // That is what makes this confusing approach better than more explicit
  // solutions (additional elements with the `media` attribute for dpi, or using
  // css background images with media queries), as well as adding 0 additional
  // dom elements to the page
  const sizes = [phone?.sizes, tablet?.sizes, desktop?.sizes]
    .filter(notUndefined).join(', ');
  const imgSrc = desktop?.src ?? fallbackSrc;
  if (!imgSrc) return null;
  const Component = is as 'img' | 'source';
  if (Component === 'img') {
    return (
      <img
        alt=''
        {...rest}
        ref={ref}
        srcSet={srcSet}
        sizes={sizes}
        src={imgSrc}
        className={cn(className, imgSrc === fallbackSrc && fallbackClassName)}
      />
    );
  }
  return (
    <source
      {...rest as JSX.IntrinsicElements['source']}
      srcSet={srcSet}
      sizes={sizes}
      src={imgSrc}
      type={webp ? 'image/webp' : undefined}
    />
  );
}) as ForwardRefComponent<'img', ImageSourceOwnProps>;

const makeSrcSet = (url: string | undefined, width: number) => {
  if (!url) return undefined;
  return `${url} ${width}w`;
};

const makeSizes = (breakpoint: BreakPointNames, width: number) => {
  if (breakpoint === 'desktop') {
    return `${width}px`;
  }
  return `(max-width: ${breakpointsMax[breakpoint]}px) ${width}px`;
};

type Source = {
  src?: string,
  retinaSrc?: string;
  width?: number,
  retinaWidth?: number,
};

type ResponsiveSource = OptionalResponsiveCustomValue<Source>;
