const checkSessionStorage = (mode: 'localStorage' | 'sessionStorage') => {
  try {
    const testKey = '___isomorphic_session_storage___';
    window[mode].setItem(testKey, 'test');
    window[mode].removeItem(testKey);
    return true;
  } catch (_err) {
    return false;
  }
};

export default class IsomorphicStorage {
  constructor(opts: { mode: 'sessionStorage' | 'localStorage' | 'memory' }) {
    this.mode = opts.mode;
    if (opts.mode === 'memory') {
      this.isMemoryFallback = true;
    } else {
      this.isMemoryFallback = checkSessionStorage(opts.mode);
    }
  }

  private memoryStore: Record<string, string> = {};

  mode: 'memory' | 'sessionStorage' | 'localStorage';

  isMemoryFallback: boolean;

  setItem(key: string, value: string) {
    const setMemory = () => {
      this.memoryStore[key] = value;
    };
    if (this.isMemoryFallback || this.mode === 'memory') {
      setMemory();
    } else {
      try {
        window[this.mode].setItem(key, value);
        // TODO: Are some errors recoverable, like storage being full?
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.isMemoryFallback = true;
        setMemory();
      }
    }
  }

  getItem(key: string) {
    const getMemory = () => {
      return this.memoryStore[key] ?? null;
    };
    if (this.isMemoryFallback || this.mode === 'memory') {
      return getMemory();
    }
    try {
      return window[this.mode].getItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.isMemoryFallback = true;
      return getMemory();
    }
  }

  removeItem(key: string) {
    const deleteMemory = () => {
      delete this.memoryStore[key];
    };
    if (this.isMemoryFallback || this.mode === 'memory') {
      deleteMemory();
    } else {
      try {
        window[this.mode].removeItem(key);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.isMemoryFallback = true;
        deleteMemory();
      }
    }
  }

  clear() {
    const clearMemory = () => {
      this.memoryStore = {};
    };
    if (this.isMemoryFallback || this.mode === 'memory') {
      clearMemory();
    } else {
      try {
        window[this.mode].clear();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.isMemoryFallback = true;
        clearMemory();
      }
    }
  }

  testEnvironment(transfer: 'overwrite' | 'overwriteUndefined' | 'discard') {
    if (this.mode !== 'memory') {
      // Typesccript will loses the narrowing otherwise
      const mode = this.mode;
      const isStorageAvailable = checkSessionStorage(this.mode);
      if (isStorageAvailable && this.isMemoryFallback) {
        this.isMemoryFallback = false;
        if (transfer === 'discard') return;
        Object.entries(this.memoryStore).forEach(([key, value]) => {
          if (transfer === 'overwriteUndefined' && window[mode].getItem(key)) {
            return;
          }
          // Have to check is the `setItem` method mutated this due to an eror
          if (!this.isMemoryFallback) {
            this.setItem(key, value);
          }
        });
        if (!this.isMemoryFallback) {
          this.memoryStore = {};
        }
      }
    }
  }
}

let local: IsomorphicStorage;
export const isomorphicLocalStorage = () => {
  if (!local) {
    local = new IsomorphicStorage({ mode: 'localStorage' });
  }
  return local;
};

let session: IsomorphicStorage;
export const isomorphicSessionStorage = () => {
  if (!session) {
    session = new IsomorphicStorage({ mode: 'sessionStorage' });
  }
  return session;
};

let memory: IsomorphicStorage;
export const isomorphicMemoryStorage = () => {
  if (!memory) {
    memory = new IsomorphicStorage({ mode: 'memory' });
  }
  return memory;
};
