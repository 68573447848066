import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import nth from 'lodash/nth';
import upperCase from 'lodash/upperCase';

import { notUndefined } from '@mablemarket/common-lib';
import { UserDisplayInfo } from '@mablemarket/core-api-client';

export function emailAddress(user: UserDisplayInfo) {
  return user?.contact?.email || user?.identifier || '';
}

export function initials(user: UserDisplayInfo) {
  const email = emailAddress(user);
  let inits = [first(user.contact?.firstName), first(user.contact?.lastName)].filter(notUndefined);
  if (isEmpty(inits)) {
    inits = [first(email), nth(email, 1)].filter(notUndefined);
  }
  const joined = inits.join('');
  if (isEmpty(joined)) {
    return 'A';
  }
  return upperCase(joined);
}
