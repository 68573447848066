import * as t from 'io-ts';
import wrap from 'lodash/wrap';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { getValueOrThrow } from '@mablemarket/common-lib';

import { useIsomorphicLayoutEffect } from '../../../hooks/useIsomorphicLayoutEffect';
import IsomorphicStorage, { isomorphicSessionStorage } from '../../../webutils/isomorphicStorage';


export const storageKey = (key: string) => (
  `scroll-${key}`
);

export type RememberScroll = (key: string | undefined, opts?: { onlyRestoreScroll?: boolean }) => void;

interface RememberScrollContextValue {
  storage: IsomorphicStorage;
  rememberScroll: RememberScroll;
}

export const RememberScrollContext = React.createContext<RememberScrollContextValue | null>(null);

export interface NextLocation {
  pathname: string;
  search: string;
  hash: string;
  key?: string;
}

export const NextLocationContext = React.createContext<NextLocation | null>(null);

const historyWrapperCanary = '__mable_remember_scroll__';

export const LastLocationContext = React.createContext<NextLocation | null>(null);

export const IsHydratedContext = React.createContext<boolean | null>(null);

const lastLocationKey = '__mable_last_location__';

const JSONSerializedCodec = new t.Type<unknown, string, string>(
  'JSONSerialized',
  (_input): _input is unknown => true,
  (input, context) => {
    try {
      return t.success(JSON.parse(input));
    } catch (e) {
      return t.failure(input, context);
    }
  },
  json => JSON.stringify(json),
);

const LastLocationCodec = t.intersection([
  t.type({
    pathname: t.string,
    search: t.string,
    hash: t.string,
  }),
  t.partial({
    key: t.string,
  }),
]);

const LastLocationSerializedCodec = JSONSerializedCodec.pipe(LastLocationCodec);

const makeLocationKey = () => (
  uuid()
);

const makeRefreshPageKey = () => `scroll-persist-${window.location.pathname + window.location.search + window.location.hash}`;

// Inject unique key prop into history state, provide it inside location and use
// it to implement scroll restoration
export const NextRouterProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const storage = useMemo(() => isomorphicSessionStorage(), []);
  const router = useRouter();
  const { asPath } = router;

  // Wrap global history navigation functions to inject unique location keys, as
  // the ones nextjs uses are undocumented and reset between session
  useIsomorphicLayoutEffect(() => {
    try {
      // on mount, try to restore location key from session storage.
      // We want to do this in the useEffect so that it runs on the client, but
      // also to keep the SSR/client hydration value of 'root' consistent
      // TODO: If we attempt to restore scroll position of a refreshed page
      // during after the first render it will not yet have this key and won't have
      // the scroll position restored. I am punting on this.
      if (!window.history.state?.locationKey) {
        const locationKey = storage.getItem(makeRefreshPageKey());
        if (locationKey) {
          storage.removeItem(makeRefreshPageKey());
          window.history.replaceState({
            ...(window.history.state ?? {}),
            locationKey,
          }, '');
        }
      }
    } catch (_e) { }
    if (historyWrapperCanary in window.history) return;
    type HistoryFn = typeof window.history.pushState | typeof window.history.replaceState;
    window.addEventListener('popstate', (event) => {
      // Only odd stuff would cause a lack of state here, but we should cover it
      if (!event.state) {
        window.history.replaceState({ locationKey: makeLocationKey() }, '');
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const historyWrapper = (version: 'push' | 'replace') => (historyFn: HistoryFn, state: any, title: string, url?: string | null | undefined) => {
      const providedLocationKey: string | undefined = state?.locationKey;
      const oldLocationKey: string | undefined = window.history.state?.locationKey;
      if (state) {
        if (providedLocationKey) {
          // something else has already taken care of it, do nothing
        } else if (version === 'replace' && (!url || window.history.state?.as === url)) {
          // If no url change, or given url matches old url (state.as is an
          // internal nextjs value that we can rely on for now) then we want to
          // keep the value.
          // This is to catch stray logic that ends up calling history.replace
          // for no reason, like when updating query params, but doesn't actually
          // mean to change anything about the route/history location
          state.locationKey = oldLocationKey;
        } else {
          state.locationKey = makeLocationKey();
        }
      }
      historyFn.call(window.history, state, title, url);
    };
    window.history.pushState = wrap(window.history.pushState, historyWrapper('push'));
    window.history.replaceState = wrap(window.history.replaceState, historyWrapper('replace'));
    // nextjs wants to clear the history state on umount, but I want to keep the key and the scroll position
    window.addEventListener('beforeunload', () => {
      const locationKey = window.history.state?.locationKey;
      if (locationKey) {
        storage.setItem(makeRefreshPageKey(), window.history.state?.locationKey);
        storage.setItem(storageKey(window.history.state?.locationKey), window.scrollY.toString());
      }
    });
    // Prevent monkey patching multiple times if this compononent is remounted.
    // In dev you will need to hard refresh to see changes to the above functions.
    Object.defineProperty(window.history, historyWrapperCanary, { value: true, writable: false });
  }, [storage]);

  // Grap the key out of the history state and merge it into the location
  // object, preserving the behavior of react-router's useLocation
  // We have to grab it during render, which is sketchy, and not during
  // memoization because asPath doesn't change on a router.replaceState, but
  // they key may.
  const locationKey: string = (typeof window !== 'undefined' && window.history.state?.locationKey) || 'root';

  const location = useMemo(() => {
    // need to add a dummy domain to get it to parse
    const { pathname, search, hash } = new URL(`https://www.meetmable.com${asPath}`);
    return { pathname, search, hash, key: locationKey };
  }, [asPath, locationKey]);

  useIsomorphicLayoutEffect(() => {
    // transition to session storage on browser if available
    if (storage.isMemoryFallback) storage.testEnvironment('overwrite');
  }, [storage]);

  const rememberScroll = useCallback((key: string | undefined, opts?: { onlyRestoreScroll?: boolean }) => {
    const item = storage.getItem(storageKey(key ?? 'root'));
    if (opts?.onlyRestoreScroll && item === null) return;
    const y = Number(item);
    Number.isFinite(y) && window.scrollTo(0, y);
  }, [storage]);

  const rememberScrollValue = useMemo(() => ({
    storage,
    rememberScroll,
  }), [storage, rememberScroll]);

  const lastLocationRef = useRef<NextLocation | null>(null);
  const lastLocation = lastLocationRef.current ?? location;


  useEffect(() => {
    // try to pull the stored lastLocation off the client if we need it for the intial value
    try {
      const stored = storage.getItem(lastLocationKey);
      if (lastLocationRef.current === null && stored) {
        lastLocationRef.current = getValueOrThrow(LastLocationSerializedCodec.decode(stored));
        return;
      }
    } catch (_e) { }

    // only update and store the last location when we are *hyrdrated* on the client, otherwise it can contain unresolved slugs
    if (router.isReady) {
      try {
        // Make sure to store the last value, not the current location, otherwise
        // on refresh it will just be the current url
        if (lastLocationRef.current) {
          storage.setItem(lastLocationKey, LastLocationSerializedCodec.encode(lastLocationRef.current));
        }
        lastLocationRef.current = location;
      } catch (_e) { }
    }
  }, [router.isReady, location, storage]);

  const [isHydrated, setIsHydrated] = useState(false);
  useEffect(() => {
    if (router.isReady) {
      setIsHydrated(true);
    }
  }, [router.isReady]);

  return (
    <RememberScrollContext.Provider value={rememberScrollValue}>
      <NextLocationContext.Provider value={location}>
        <LastLocationContext.Provider value={lastLocation}>
          <IsHydratedContext.Provider value={isHydrated}>
            {children}
          </IsHydratedContext.Provider>
        </LastLocationContext.Provider>
      </NextLocationContext.Provider>
    </RememberScrollContext.Provider>
  );
};
