import React from 'react';

import Box from '../Box';

import useIconProps, { IconDefaultProps } from './useIconProps';

const rotation = {
  up: 'rotate(0deg)',
  right: 'rotate(90deg)',
  down: 'rotate(180deg)',
  left: 'rotate(270deg)',
};

export const IconTriangle = (props: IconDefaultProps & { direction?: 'left' | 'right' | 'up' | 'down' }) => {
  const { height = 9, width = 9, ...rest } = props;
  const iconProps = useIconProps({ ...rest, height });
  return (
    <Box
      as='svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 9 6'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
      {...iconProps}
      style={{
        ...iconProps.style,
        transformOrigin: 'center',
        transition: 'transform 0.1s linear',
        transform: rotation[props.direction ?? 'up'],
      }}
    >
      <g id='triangle' fill='currentColor' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <polygon points='4.5 0 9 6 0 6' />
      </g>
    </Box>
  );
};
