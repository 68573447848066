var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { mapCustomResponsiveValue } from '../../styles/breakpoints';
import Box from '../Box';
import * as styles from './PageContainer.css';
/**
 * A centered container with a maximum width constrained to `contentWidth`
 * @param paddding - horizontal padding for screen edge gap. Defaults to `true`.
 * @param bleed - contains content with paddinng, but allow
 * overflow to spill over into it. Used in mobile designs when an element,
 * like a carousel, wants to be visible to the edge of the screen
 */
var PageContainer = function (_a) {
    var children = _a.children, className = _a.className, _b = _a.padding, padding = _b === void 0 ? true : _b, _c = _a.bleed, bleed = _c === void 0 ? false : _c, rest = __rest(_a, ["children", "className", "padding", "bleed"]);
    return (<Box {...rest} maxWidth='content' as='div' className={[
            className,
            styles.base,
            styles.pageContainerSprinkles({
                padding: mapCustomResponsiveValue(padding, function (p) { return (p ? 'true' : 'false'); }),
                bleed: mapCustomResponsiveValue(bleed, function (b) { return (b ? 'true' : 'false'); }),
            }),
        ]}>
      {children}
    </Box>);
};
export default PageContainer;
