var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isURL from 'validator/lib/isURL';
import * as yup from 'yup';
import { inceptionMethods, isNonEmptyString } from '@mablemarket/common-lib';
import { postalCodeRegex } from '@mablemarket/mable-lib/build/helpers/AddressHelpers';
// These need to be in sync with `storeCategoryToRecommendedCategorySlugs` on the buyer dashboard
export var storeCategories = [
    'Air Travel Convenience',
    'Bakery/Cafe/Coffee Shop',
    'Beer & Wine Shop',
    'Co-op',
    'Farm Stand',
    'Foodservice',
    'General Store',
    'Gift Shop',
    'Grocery Store',
    'Ground Travel Convenience',
    'Health | Fitness | Recreational',
    'Hospitality | Lodging',
    'Market',
    'Pet Store',
    'Specialty Food Store',
    'Specialty Online Reseller',
    'Restaurant',
    'University/Education',
    'Other Reseller',
];
export var locationsRanges = [
    '1',
    '2-4',
    '5-9',
    '10-49',
    '50-99',
    '100+',
    'none',
];
export var locationsRangesOptions = locationsRanges.map(function (r) { return (r === 'none'
    ? { value: r, label: 'None (i.e. I sell online only)' }
    : { value: r, label: r }); });
export var urlSchema = yup.string().trim().test('is-url', 'Must be a valid url', function (value) { return !!value && isURL(value); });
export var signUpSchema = function (options) {
    var _a = options.externalCustomerNumberRequired, externalCustomerNumberRequired = _a === void 0 ? false : _a, _b = options.externalCustomerNumberCollected, externalCustomerNumberCollected = _b === void 0 ? false : _b, _c = options.confirmReseller, confirmReseller = _c === void 0 ? false : _c, _d = options.websiteRequired, websiteRequired = _d === void 0 ? true : _d, _e = options.inceptionMethodRequired, inceptionMethodRequired = _e === void 0 ? false : _e;
    var externalCustomerNumber = externalCustomerNumberRequired
        ? yup.string().required('Required')
        : yup.string();
    var noCustomerNumber = yup.boolean();
    if (externalCustomerNumberCollected && !externalCustomerNumberRequired) {
        externalCustomerNumber = yup.string()
            .when('noCustomerNumber', {
            is: function (noCustomerNumber) { return noCustomerNumber; },
            otherwise: function (schema) { return schema.required('Provide customer number or check the box below'); },
        });
        noCustomerNumber = yup.boolean()
            .when('externalCustomerNumber', {
            is: function (externalCustomerNumber) { return isNonEmptyString(externalCustomerNumber); },
            otherwise: function (schema) { return schema.oneOf([true], 'Enter customer number or check this box'); },
        });
    }
    return yup.object().shape(__assign(__assign({ credentials: yup.object().shape({
            email: yup.string().trim().required('Required').email('Must be a valid email'),
            password: yup.string().required('Required').min(5, 'Must be at least 5 characters long'),
        }), contact: yup.object().shape({
            firstName: yup.string().required('Required'),
            lastName: yup.string().required('Required'),
            phone: yup.string().required('Required').test('is-us-phone', 'Please enter a valid phone number', function (value) {
                if (!value) {
                    return false;
                }
                return /^1?[0-9]{10}$/.test(value.replace(/\D/g, ''));
            }),
        }), websiteUrl: websiteRequired ? urlSchema.required('Required') : yup.string(), businessName: yup.string().required('Required'), referralCode: yup.string(), storeCategory: yup.string().required('Required').oneOf(storeCategories, 'Required'), locationsRange: yup.string().required('Required').oneOf(locationsRanges, 'Required'), customerNumberInfo: yup.object().shape({
            externalCustomerNumber: externalCustomerNumber,
            noCustomerNumber: noCustomerNumber,
        }, [['externalCustomerNumber', 'noCustomerNumber']]), postalCode: yup.string().required('Required').matches(postalCodeRegex, 'Must be a valid US zipcode') }, (confirmReseller ? {
        confirmReseller: yup.boolean().oneOf([true], 'Required'),
        ein: yup.string().matches(/^\d{9}$/g, 'Must be 9 digits long'),
    } : {})), (inceptionMethodRequired ? {
        inceptionMethod: yup.string().required('Required').oneOf(Object.keys(inceptionMethods), 'Required'),
    } : {})));
};
var defaultSchema = signUpSchema({});
export function initialValues(defaults) {
    var _a, _b, _c, _d;
    return {
        credentials: {
            email: (_a = defaults.email) !== null && _a !== void 0 ? _a : '',
            password: '',
        },
        contact: {
            firstName: '',
            lastName: '',
            phone: '',
        },
        websiteUrl: '',
        businessName: '',
        referralCode: (_b = defaults.initialReferralCode) !== null && _b !== void 0 ? _b : '',
        storeCategory: '',
        locationsRange: '',
        customerNumberInfo: {
            externalCustomerNumber: (_c = defaults.externalCustomerNumber) !== null && _c !== void 0 ? _c : '',
            noCustomerNumber: false,
        },
        confirmReseller: false,
        ein: '',
        // We need some entry here for inceptionMethod so it will be validated
        // without having interacted with it, but it is also an enum in the API
        // so we need to give it this specific typing. Later we remove it from the
        // call body if it is falsy (the empty string) leaving only the valid strings
        inceptionMethod: ((_d = defaults.inceptionMethod) !== null && _d !== void 0 ? _d : ''),
    };
}
