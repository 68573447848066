import React, { useCallback, useRef, useState } from 'react';
import { mergeProps, useFocusRing, useHover, VisuallyHidden } from 'react-aria';

import { notUndefined } from '@mablemarket/common-lib';

import { useForwardClick } from '../../hooks/useForwardClick';
import { focusRing, sprinkles } from '../../styles/sprinkles.css';
import { vars } from '../../styles/theme.css';
import { composeRefs } from '../../util';
import Box, { BoxOwnProps, FlexAndGridChildProps } from '../Box';
import Grid from '../Grid';
import { InputDescription, InputErrorMessage, InputLabel, InputLabelProvider, useInputLabelContext } from '../TextInput';

const CheckboxOutlineVariants = {
  deselected: {
    stroke: vars.color.foreground.greyLine,
    fill: vars.color.background.white,
  },
  selectedError: {
    stroke: vars.color.foreground.redError,
    fill: vars.color.background.white,
  },
  deselectedError: {
    stroke: vars.color.foreground.redError,
    fill: vars.color.background.white,
  },
  deselectedHovered: {
    stroke: vars.color.foreground.black,
    fill: vars.color.background.white,
  },
  selected: {
    stroke: vars.color.foreground.greyLine,
    fill: vars.color.background.white,
  },
  selectedHovered: {
    stroke: vars.color.foreground.black,
    fill: vars.color.background.white,
  },
  disabled: {
    fill: vars.color.foreground.greyLine,
    stroke: undefined,
  },
  disabledSelected: {
    fill: vars.color.foreground.greyLine,
    stroke: undefined,
  },
};

const CheckboxFillingVariants = {
  deselected: {
    scale: 0,
    opacity: 0,
  },
  deselectedError: {
    scale: 0,
    opacity: 0,
  },
  deselectedHovered: {
    scale: 0,
    opacity: 0,
  },
  selected: {
    scale: 1,
    opacity: 1,
  },
  selectedHovered: {
    scale: 1,
    opacity: 1,
  },
  selectedError: {
    scale: 1,
    opacity: 1,
  },
  disabled: {
    scale: 0,
    opacity: 0,
  },
  disabledSelected: {
    scale: 1,
    opacity: 1,
  },
};

export const CheckboxIcon: React.FC<React.SVGAttributes<SVGElement> & {
  state?: 'deselected' | 'selected',
  hovered?: boolean,
  error?: boolean,
  disabled?: boolean,
  noBorder?: boolean,
}> = (props) => {
  const {
    height = 20,
    width = 20,
    state = 'deselected',
    className,
    hovered = false,
    error = false,
    disabled = false,
    noBorder = false,
    ...rest
  } = props;

  const borderStrokeWidth = noBorder ? '0' : undefined;

  const derivedState: keyof typeof CheckboxFillingVariants & keyof typeof CheckboxOutlineVariants = (() => {
    if (disabled) {
      return state === 'selected' ? 'disabledSelected' : 'disabled';
    }
    if (state === 'selected') {
      if (hovered) return 'selectedHovered';
      if (error) return 'selectedError';
      return 'selected';
    }
    if (hovered) return 'deselectedHovered';
    if (error) return 'deselectedError';
    return 'deselected';
  })();

  return (
    <svg
      height={height}
      width={width}
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g fill='none' fillRule='evenodd'>
        <rect
          x='.5'
          y='.5'
          width='19'
          height='19'
          rx='3'
          strokeWidth={borderStrokeWidth}
          style={{
            fill: CheckboxOutlineVariants[derivedState].fill,
            stroke: CheckboxOutlineVariants[derivedState].stroke,
          }}
        />
        <path
          stroke='#111'
          strokeWidth='2.138'
          d='M4 9l4.48 5.325 7.302-8.995'
          style={{
            transform: `scale(${CheckboxFillingVariants[derivedState].scale})`,
            transformOrigin: '50%',
            opacity: CheckboxFillingVariants[derivedState].opacity,
            transition: 'transform 0.3s, opacity 0.3s',
          }}
        />
      </g>
    </svg>
  );
};

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'>,
  FlexAndGridChildProps,
  Pick<BoxOwnProps, 'className'> {
  outerRef?: React.Ref<HTMLDivElement>;
  noBorder?: boolean;
  label?: React.ReactNode;
  description?: React.ReactNode;
  error?: boolean | React.ReactNode;
}

export const Checkbox = React.forwardRef((props: CheckboxProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const {
    className,
    style,
    flexBasis,
    flexGrow,
    flexShrink,
    gridArea,
    gridColumnEnd,
    gridColumnStart,
    gridRowEnd,
    gridRowStart,
    outerRef,
    noBorder,
    label,
    description,
    error,
    ...rest
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { forwardClickProps } = useForwardClick({ targetRef: inputRef });
  const checkboxHeight = 20;

  // must track 'uncontrolled' state to render icon correctly
  const [internalChecked, setInternalChecked] = useState(false);
  const checked = props.checked === undefined ? internalChecked : props.checked;
  const setInitialInternalChecked = useCallback((elem: HTMLInputElement | null) => {
    elem && setInternalChecked(elem.checked);
  }, []);

  const { isHovered: isCheckboxHovered, hoverProps: checkboxHoverProps } = useHover({});
  const { isHovered: isLabelHovered, hoverProps: labelHoverProps } = useHover({});
  const isHovered = isCheckboxHovered || isLabelHovered;

  const { inputId, descriptionId, errorId } = useInputLabelContext({
    inputId: props.id,
  });

  const { focusProps, isFocusVisible } = useFocusRing({ within: true });

  const checkbox = (
    <Box display='flex' {...mergeProps(checkboxHoverProps, focusProps)} className={isFocusVisible ? focusRing : undefined}>
      <span {...forwardClickProps} style={{ height: `${checkboxHeight}px` }}>
        <CheckboxIcon
          state={checked ? 'selected' : 'deselected'}
          error={!!error}
          noBorder={noBorder}
          hovered={isHovered}
          disabled={rest.disabled}
          aria-hidden
          height={checkboxHeight}
        />
      </span>
      {/* The visually hidden input element will still capture all of the normal
          events, like focus as well as forwarded click events from an
          associated label */}
      <VisuallyHidden>
        <input
          type='checkbox'
          id={inputId}
          aria-describedby={[
            description ? descriptionId : undefined,
            error ? errorId : undefined,
          ].filter(notUndefined).join(' ')}
          {...mergeProps(
            rest,
            { onChange: (e: React.ChangeEvent<HTMLInputElement>) => setInternalChecked(e.target.checked) },
          )}
          checked={checked}
          ref={composeRefs(inputRef, ref, setInitialInternalChecked)}
        />
      </VisuallyHidden>
    </Box>
  );

  return (
    <InputLabelProvider inputId={inputId} descriptionId={descriptionId} errorId={errorId}>
      <Grid
        ref={outerRef}
        style={style}
        className={className}
        gridTemplateColumns='auto 1fr'
        rowGap='space-0.25'
        alignItems='center'
        flexBasis={flexBasis}
        flexGrow={flexGrow}
        flexShrink={flexShrink}
        gridArea={gridArea}
        gridColumnEnd={gridColumnEnd}
        gridColumnStart={gridColumnStart}
        gridRowEnd={gridRowEnd}
        gridRowStart={gridRowStart}
      >
        {checkbox}
        {label && (
          <InputLabel {...labelHoverProps} className={sprinkles.atoms({ paddingLeft: 'space-0.5' })}>{label}</InputLabel>
        )}
        {description && (
          <InputDescription
            gridColumnStart='2'
            className={sprinkles.atoms({ paddingLeft: 'space-0.5' })}
          >
            {description}
          </InputDescription>
        )}
        {error && error !== true && (
          <InputErrorMessage
            gridColumnStart='2'
            className={sprinkles.atoms({ paddingLeft: 'space-0.5' })}
          >
            {error}
          </InputErrorMessage>
        )}
      </Grid>
    </InputLabelProvider>
  );
});

export default Checkbox;
