import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import getUnixTime from 'date-fns/getUnixTime';
import subHours from 'date-fns/subHours';
import subMinutes from 'date-fns/subMinutes';

export const honeycombTraceURL = (dataset: string, correlationId: string, approximateTime?: Date) => {
  const searchAround = approximateTime ?? new Date();
  // Honeycomb is fiddly about finding traces and if you're a little wrong, you'll miss it.
  // But it also has no problems searching over many hours of time, so go back a day just to be sure
  const searchFrom = getUnixTime(subHours(searchAround, 24));
  // Honeycomb defaults to only searching a 10 minute range if end time isn't specified
  // There's also some discrepency between client time and recorded event time.
  // Search well into the future to be sure to capture the entire span.
  const searchTo = getUnixTime(addHours(searchAround, 24));
  return `https://ui.honeycomb.io/mable-test/datasets/${dataset}/trace?trace_id=${correlationId}&trace_start_ts=${searchFrom}&trace_end_ts=${searchTo}`;
};

export const honeycombOrderURL = ({ dataset, orderId, searchFrom, searchUntil } : {
  dataset: string;
  orderId: number;
  searchFrom: Date;
  searchUntil?: Date;
}) => {
  const startTime = getUnixTime(subMinutes(searchFrom, 15));
  const endTime = getUnixTime(addMinutes(searchUntil ?? new Date(), 15));
  const granularity = Math.floor((endTime - startTime) / 100);
  return `https://ui.honeycomb.io/mable-test/datasets/${dataset}?query={"start_time":${startTime},"end_time":${endTime},"granularity":${granularity},"breakdowns":["req.method","req.path","params.queue.name"],"calculations":[{"op":"COUNT"},{"op":"P95","column":"duration_ms"},{"op":"HEATMAP","column":"duration_ms"}],"filters":[{"column":"req.path","op":"starts-with","value":"/v1/orders/${orderId}"},{"column":"req.path","op":"starts-with","value":"/v1/admin/transactions/${orderId}"},{"column":"params.transactionId","op":"=","value":${orderId}},{"column":"params.transactionIds","op":"contains","value":"${orderId}"}],"filter_combination":"OR","orders":[{"column":"duration_ms","op":"P95","order":"descending"}],"limit":1000}`;
};
