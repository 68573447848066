var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef } from 'react';
import { FocusRing, useButton } from 'react-aria';
import { focusRing } from '../../styles/sprinkles.css';
import { composeRefs } from '../../util';
import { cn } from '../../webutils/webutils';
import Box from '../Box';
var UnstyledButton = React.forwardRef(function (props, ref) {
    var children = props.children, disabled = props.disabled, rest = __rest(props, ["children", "disabled"]);
    var buttonRef = useRef(null);
    var buttonProps = useButton(__assign(__assign({}, rest), { isDisabled: disabled }), buttonRef).buttonProps;
    // Workaround for
    // https://github.com/facebook/react/issues/9809
    // https://github.com/adobe/react-spectrum/issues/1513
    var hasOnClickHandler = !!props.onClick;
    useEffect(function () {
        var button = buttonRef.current;
        var preventDefault = function (e) {
            var _a, _b;
            // Disabling touchend breaks form submission on mobile
            if (((_a = e.currentTarget) === null || _a === void 0 ? void 0 : _a.getAttribute('type')) === 'submit'
                || ((_b = e.currentTarget) === null || _b === void 0 ? void 0 : _b.getAttribute('type')) === 'reset') {
                return;
            }
            e.preventDefault();
        };
        if (!hasOnClickHandler) {
            button === null || button === void 0 ? void 0 : button.addEventListener('touchend', preventDefault, { passive: false });
        }
        return function () { return button === null || button === void 0 ? void 0 : button.removeEventListener('touchend', preventDefault); };
    }, [hasOnClickHandler]);
    return (<FocusRing focusRingClass={focusRing}>
      <Box as='button' type='button' {...rest} {...buttonProps} ref={composeRefs(buttonRef, ref)} className={cn(rest.className)}>
        {children}
      </Box>
    </FocusRing>);
});
export default UnstyledButton;
