import BigNumber from 'bignumber.js';
import isEmpty from 'lodash/isEmpty';

/**
 * Formats a value into a money string
 * @param options.decimalPlaces The number of decimal places to include in the return
 * value. If specified, this value will supercede the behavior specified in options.alwaysShowCents
 * @param options.alwaysShowCents Pass in `false` to exclude decimals, when possible.
 * @param options.prefix String to prefix the return value with. Defaults to '$'
 * @param options.decimalSeparator String that separates the whole part & the decimal part of the number. Defaults to the American standard '.'.
 * @param options.groupSeparator String that separates numbers over 999 into 3 digit
 * groups for readability. Defaults to the American standard ',', e.g. 1,234.56.
 * Pass in empty string to remove any separators, which may be desired if this is not meant for human readability.
 */
export const formatMoney = (input: number | string | BigNumber,
  options?: {
    alwaysShowCents?: boolean;
    prefix?: string;
    decimalSeparator?: string;
    groupSeparator?: string;
    decimalPlaces?: number;
  }) => {
  const {
    alwaysShowCents = true,
    prefix = '$',
    decimalSeparator = '.',
    groupSeparator = ',',
  } = options ?? {};
  const amount = typeof input === 'string'
    ? input.replace(/[$,]/g, '')
    : input;
  const bn = new BigNumber(amount);
  const excludeCents = !alwaysShowCents && bn.mod(1).isZero();
  const decimalPlaces = options?.decimalPlaces ??
    (excludeCents ? 0 : 2);
  return (bn.lt(0) ? '-' : '') + (bn.abs()).toFormat(decimalPlaces, {
    prefix,
    decimalSeparator,
    groupSeparator,
    groupSize: 3,
  });
};

export const formatPhoneNumber = (number: string, opts: { partial?: boolean } = {}) => {
  const cleaned = number.replace(/\D/g, '');
  const regex = opts.partial
    ? /^(1|)?(\d{3})?(\d{1,3})?(\d{1,4})?$/
    : /^(1|)?(\d{3})?(\d{3})(\d{4})$/;
  return cleaned.replace(regex, (_, intlCode, area, prefix, line) => {
    let output = '';
    if (intlCode) {
      output += `${intlCode} `;
    }
    if (area) {
      output += `(${area}) `;
    }
    if (prefix) {
      output += prefix;
    }
    if (line) {
      output += `-${line}`;
    }
    return output.trim();
  });
};

export const formatCurrencyInput = (value: string, options?: { allowZero?: boolean, allowCents?: boolean }) => {
  const {
    allowZero = false,
    allowCents = true,
  } = options ?? {};

  if (isEmpty(value)) {
    return '';
  }

  const digits = (value.match(/[\d-]/g) ?? []).join('');
  const number = (() => {
    if (!allowCents) {
      return Number.parseFloat(digits);
    }
    const head = digits.slice(0, digits.length - 2);
    const tail = digits.slice(digits.length - 2).padStart(2, '0');
    return Number.parseFloat(`${head}.${tail}`);
  })();

  if (Number.isNaN(number) || (!allowZero && number === 0)) {
    return '';
  }

  const formatted = number.toLocaleString('en-US', {
    minimumFractionDigits: allowCents ? 2 : 0,
    maximumFractionDigits: allowCents ? 2 : 0,
  });

  return formatted;
};

export const ordinalizeNumber = (num: number | string) => {
  const lastTwoDigits = `${num}`.slice(-2);

  if (lastTwoDigits.length === 2 && lastTwoDigits.startsWith('1')) {
    return `${num}th`;
  }

  if (lastTwoDigits.endsWith('1')) {
    return `${num}st`;
  }
  if (lastTwoDigits.endsWith('2')) {
    return `${num}nd`;
  }
  if (lastTwoDigits.endsWith('3')) {
    return `${num}rd`;
  }

  return `${num}th`;
};

/** Given a number of days, translate it to "display-friendly" months, days and years. Assume 30-day month. */
export const shelfLifeDaysToMDY = (numDays: number) => {
  return {
    years: Math.floor(numDays / 365) > 1 ? Math.floor(numDays / 365) : 0,
    months: Math.floor(numDays / 365) > 1 ? Math.ceil((numDays % 365) / 30) : Math.floor(numDays / 30),
    days: Math.floor(numDays / 365) >= 1 ? 0 : Math.floor(numDays % 30),
  };
};

/** Given a number of days, make it "display-friendly" human-readable. Assume 30-day month. */
export const formatShelfLifeDays = (numDays: number) => {
  const { days, months, years } = shelfLifeDaysToMDY(numDays);
  const formattedArr: string[] = [];
  if (years > 1) {
    formattedArr.push(`${years} years`);
  }
  if (months > 0) {
    formattedArr.push(`${months} month${months > 1 ? 's' : ''}`);
  }
  if (days > 0) {
    formattedArr.push(`${days} day${days > 1 ? 's' : ''}`);
  }
  return formattedArr.join(', ');
};

export const cardBrandToDisplay = (brand: string) => (
  (brand === 'visa' && 'VISA')
  || (brand === 'mastercard' && 'MC')
  || (brand === 'amex' && 'AMEX')
  || (brand === 'discover' && 'DISC')
  || brand
);
