var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as t from 'io-ts';
import wrap from 'lodash/wrap';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { getValueOrThrow } from '@mablemarket/common-lib';
import { useIsomorphicLayoutEffect } from '../../../hooks/useIsomorphicLayoutEffect';
import { isomorphicSessionStorage } from '../../../webutils/isomorphicStorage';
export var storageKey = function (key) { return ("scroll-".concat(key)); };
export var RememberScrollContext = React.createContext(null);
export var NextLocationContext = React.createContext(null);
var historyWrapperCanary = '__mable_remember_scroll__';
export var LastLocationContext = React.createContext(null);
export var IsHydratedContext = React.createContext(null);
var lastLocationKey = '__mable_last_location__';
var JSONSerializedCodec = new t.Type('JSONSerialized', function (_input) { return true; }, function (input, context) {
    try {
        return t.success(JSON.parse(input));
    }
    catch (e) {
        return t.failure(input, context);
    }
}, function (json) { return JSON.stringify(json); });
var LastLocationCodec = t.intersection([
    t.type({
        pathname: t.string,
        search: t.string,
        hash: t.string,
    }),
    t.partial({
        key: t.string,
    }),
]);
var LastLocationSerializedCodec = JSONSerializedCodec.pipe(LastLocationCodec);
var makeLocationKey = function () { return (uuid()); };
var makeRefreshPageKey = function () { return "scroll-persist-".concat(window.location.pathname + window.location.search + window.location.hash); };
// Inject unique key prop into history state, provide it inside location and use
// it to implement scroll restoration
export var NextRouterProvider = function (_a) {
    var _b, _c;
    var children = _a.children;
    var storage = useMemo(function () { return isomorphicSessionStorage(); }, []);
    var router = useRouter();
    var asPath = router.asPath;
    // Wrap global history navigation functions to inject unique location keys, as
    // the ones nextjs uses are undocumented and reset between session
    useIsomorphicLayoutEffect(function () {
        var _a, _b;
        try {
            // on mount, try to restore location key from session storage.
            // We want to do this in the useEffect so that it runs on the client, but
            // also to keep the SSR/client hydration value of 'root' consistent
            // TODO: If we attempt to restore scroll position of a refreshed page
            // during after the first render it will not yet have this key and won't have
            // the scroll position restored. I am punting on this.
            if (!((_a = window.history.state) === null || _a === void 0 ? void 0 : _a.locationKey)) {
                var locationKey_1 = storage.getItem(makeRefreshPageKey());
                if (locationKey_1) {
                    storage.removeItem(makeRefreshPageKey());
                    window.history.replaceState(__assign(__assign({}, ((_b = window.history.state) !== null && _b !== void 0 ? _b : {})), { locationKey: locationKey_1 }), '');
                }
            }
        }
        catch (_e) { }
        if (historyWrapperCanary in window.history)
            return;
        window.addEventListener('popstate', function (event) {
            // Only odd stuff would cause a lack of state here, but we should cover it
            if (!event.state) {
                window.history.replaceState({ locationKey: makeLocationKey() }, '');
            }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var historyWrapper = function (version) { return function (historyFn, state, title, url) {
            var _a, _b;
            var providedLocationKey = state === null || state === void 0 ? void 0 : state.locationKey;
            var oldLocationKey = (_a = window.history.state) === null || _a === void 0 ? void 0 : _a.locationKey;
            if (state) {
                if (providedLocationKey) {
                    // something else has already taken care of it, do nothing
                }
                else if (version === 'replace' && (!url || ((_b = window.history.state) === null || _b === void 0 ? void 0 : _b.as) === url)) {
                    // If no url change, or given url matches old url (state.as is an
                    // internal nextjs value that we can rely on for now) then we want to
                    // keep the value.
                    // This is to catch stray logic that ends up calling history.replace
                    // for no reason, like when updating query params, but doesn't actually
                    // mean to change anything about the route/history location
                    state.locationKey = oldLocationKey;
                }
                else {
                    state.locationKey = makeLocationKey();
                }
            }
            historyFn.call(window.history, state, title, url);
        }; };
        window.history.pushState = wrap(window.history.pushState, historyWrapper('push'));
        window.history.replaceState = wrap(window.history.replaceState, historyWrapper('replace'));
        // nextjs wants to clear the history state on umount, but I want to keep the key and the scroll position
        window.addEventListener('beforeunload', function () {
            var _a, _b, _c;
            var locationKey = (_a = window.history.state) === null || _a === void 0 ? void 0 : _a.locationKey;
            if (locationKey) {
                storage.setItem(makeRefreshPageKey(), (_b = window.history.state) === null || _b === void 0 ? void 0 : _b.locationKey);
                storage.setItem(storageKey((_c = window.history.state) === null || _c === void 0 ? void 0 : _c.locationKey), window.scrollY.toString());
            }
        });
        // Prevent monkey patching multiple times if this compononent is remounted.
        // In dev you will need to hard refresh to see changes to the above functions.
        Object.defineProperty(window.history, historyWrapperCanary, { value: true, writable: false });
    }, [storage]);
    // Grap the key out of the history state and merge it into the location
    // object, preserving the behavior of react-router's useLocation
    // We have to grab it during render, which is sketchy, and not during
    // memoization because asPath doesn't change on a router.replaceState, but
    // they key may.
    var locationKey = (typeof window !== 'undefined' && ((_b = window.history.state) === null || _b === void 0 ? void 0 : _b.locationKey)) || 'root';
    var location = useMemo(function () {
        // need to add a dummy domain to get it to parse
        var _a = new URL("https://www.meetmable.com".concat(asPath)), pathname = _a.pathname, search = _a.search, hash = _a.hash;
        return { pathname: pathname, search: search, hash: hash, key: locationKey };
    }, [asPath, locationKey]);
    useIsomorphicLayoutEffect(function () {
        // transition to session storage on browser if available
        if (storage.isMemoryFallback)
            storage.testEnvironment('overwrite');
    }, [storage]);
    var rememberScroll = useCallback(function (key, opts) {
        var item = storage.getItem(storageKey(key !== null && key !== void 0 ? key : 'root'));
        if ((opts === null || opts === void 0 ? void 0 : opts.onlyRestoreScroll) && item === null)
            return;
        var y = Number(item);
        Number.isFinite(y) && window.scrollTo(0, y);
    }, [storage]);
    var rememberScrollValue = useMemo(function () { return ({
        storage: storage,
        rememberScroll: rememberScroll,
    }); }, [storage, rememberScroll]);
    var lastLocationRef = useRef(null);
    var lastLocation = (_c = lastLocationRef.current) !== null && _c !== void 0 ? _c : location;
    useEffect(function () {
        // try to pull the stored lastLocation off the client if we need it for the intial value
        try {
            var stored = storage.getItem(lastLocationKey);
            if (lastLocationRef.current === null && stored) {
                lastLocationRef.current = getValueOrThrow(LastLocationSerializedCodec.decode(stored));
                return;
            }
        }
        catch (_e) { }
        // only update and store the last location when we are *hyrdrated* on the client, otherwise it can contain unresolved slugs
        if (router.isReady) {
            try {
                // Make sure to store the last value, not the current location, otherwise
                // on refresh it will just be the current url
                if (lastLocationRef.current) {
                    storage.setItem(lastLocationKey, LastLocationSerializedCodec.encode(lastLocationRef.current));
                }
                lastLocationRef.current = location;
            }
            catch (_e) { }
        }
    }, [router.isReady, location, storage]);
    var _d = useState(false), isHydrated = _d[0], setIsHydrated = _d[1];
    useEffect(function () {
        if (router.isReady) {
            setIsHydrated(true);
        }
    }, [router.isReady]);
    return (<RememberScrollContext.Provider value={rememberScrollValue}>
      <NextLocationContext.Provider value={location}>
        <LastLocationContext.Provider value={lastLocation}>
          <IsHydratedContext.Provider value={isHydrated}>
            {children}
          </IsHydratedContext.Provider>
        </LastLocationContext.Provider>
      </NextLocationContext.Provider>
    </RememberScrollContext.Provider>);
};
