import React from 'react';

const childrenAreLiteral = (children: React.ReactNode) => {
  let isLiteralOrFragment = false;
  React.Children.forEach(children, (child) => {
    if (isLiteralOrFragment) return;
    if (typeof child === 'string' || typeof child === 'number') {
      isLiteralOrFragment = true;
      return;
    }
    if (React.isValidElement(child) && child.type === React.Fragment) {
      isLiteralOrFragment = childrenAreLiteral(child.props.children);
    }
  });

  return isLiteralOrFragment;
};

export default childrenAreLiteral;
