import { Contact } from '@mablemarket/core-api-client';

import * as utils from '../utils';

export function cleanContactForApi(contact?: Contact): (Contact | undefined) {
  if (!contact) {
    return undefined;
  }

  const result = utils.removeBlankStringsForKeys(contact, [
    'phone', 'email', 'firstName', 'lastName', 'hubSpotId',
  ]);
  result.phone = result.phone ? result.phone.replace(/[^0-9]/g, '') : undefined;

  const values = [
    result.email,
    result.firstName,
    result.lastName,
    result.phone,
    result.hubSpotId,
  ];

  // If everything is `undefined`, return `undefined` for the contact
  // eslint-disable-next-line no-restricted-syntax
  for (const val of values) {
    if (val && val.length > 0) {
      return result;
    }
  }

  return undefined;
}

export function fullName(contact?: Contact): string | undefined {
  return [contact?.firstName, contact?.lastName].filter(x => x).join(' ') || undefined;
}

export const contactSummary = (contact: Contact) => {
  return [
    [
      fullName(contact),
      contact.email && `<${contact.email}>`,
    ].filter(s => s).join(' '),
    contact.phone,
  ].filter(s => s).join(', ');
};

export function contactIdentifier(contact?: Contact): string | undefined {
  if (!contact) {
    return undefined;
  }
  return fullName(contact) || contact.email;
}
