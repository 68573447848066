import React, { useContext, useMemo, useRef } from 'react';
import { mergeProps, useFocusRing, useHover, useId } from 'react-aria';

import { notUndefined } from '@mablemarket/common-lib';

import { focusRing, resets, sprinkles } from '../../styles/sprinkles.css';
import { composeRefs } from '../../util';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import { cn } from '../../webutils/webutils';
import Box, { BoxOwnProps, FlexAndGridChildProps } from '../Box';
import Grid from '../Grid';
import Text from '../Text';

import * as styles from './TextInput.css';

interface InputLabelContextValue {
  inputId: string;
  descriptionId: string;
  errorId: string;
}
const InputLabelContext = React.createContext<InputLabelContextValue | null>(null);

interface InputLabelProviderProps {
  inputId?: string;
  descriptionId?: string;
  errorId?: string;
  children?: React.ReactNode;
}

export const InputLabelProvider = (props: InputLabelProviderProps) => {
  const value = useInputLabelContext(props);

  return (
    <InputLabelContext.Provider value={value}>
      {props.children}
    </InputLabelContext.Provider>
  );
};

interface useLabelProviderProps {
  inputId?: string;
  descriptionId?: string;
  errorId?: string;
}

export const useInputLabelContext = (props: useLabelProviderProps) => {
  const contextValue = useContext(InputLabelContext);

  const internalInputId = useId();
  const internalDescriptionId = useId();
  const internalErrorId = useId();

  const inputId = props.inputId ?? contextValue?.inputId ?? internalInputId;
  const descriptionId = props.descriptionId ?? contextValue?.descriptionId ?? internalDescriptionId;
  const errorId = props.errorId ?? contextValue?.errorId ?? internalErrorId;

  const value = useMemo(() => ({ inputId, descriptionId, errorId }), [inputId, descriptionId, errorId]);

  return value;
};

export interface TextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'>,
  FlexAndGridChildProps,
  Pick<BoxOwnProps, 'className' | 'textAlign'> {
  outerRef?: React.Ref<HTMLDivElement>;
  left?: React.ReactNode;
  right?: React.ReactNode;
  label?: React.ReactNode;
  description?: React.ReactNode;
  error?: boolean | React.ReactNode;
  condensed?: boolean;
}

const TextInput = React.forwardRef((props: TextInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const {
    className,
    style,
    flexBasis,
    flexGrow,
    flexShrink,
    gridArea,
    gridColumnEnd,
    gridColumnStart,
    gridRowEnd,
    gridRowStart,
    textAlign,
    outerRef,
    left,
    right,
    label,
    description,
    error,
    condensed,
    ...rest
  } = props;

  const internalInputRef = useRef<HTMLInputElement>(null);
  const { containerProps, inputProps } = useTextInputStyles({
    disabled: rest.disabled,
    error: !!error,
    left: !!left,
    right: !!right,
  });

  const { inputId, descriptionId, errorId } = useInputLabelContext({
    inputId: props.id,
  });
  return (
    <InputLabelProvider inputId={inputId} descriptionId={descriptionId} errorId={errorId}>
      <Grid
        gridAutoFlow='row'
        gap={condensed ? 'space-0.25' : 'space-0.75'}
        ref={outerRef}
        style={style}
        className={className}
        flexBasis={flexBasis}
        flexGrow={flexGrow}
        flexShrink={flexShrink}
        gridArea={gridArea}
        gridColumnEnd={gridColumnEnd}
        gridColumnStart={gridColumnStart}
        gridRowEnd={gridRowEnd}
        gridRowStart={gridRowStart}
      >
        {label && (<InputLabel>{label}</InputLabel>)}
        {description && (<InputDescription>{description}</InputDescription>)}
        <Box {...containerProps}>
          {left ? <Box flexShrink={0} display='flex' paddingLeft='space-0.75'>{left}</Box> : null}
          <input
            id={inputId}
            ref={composeRefs(ref, internalInputRef)}
            aria-invalid={!!error}
            aria-describedby={[
              description ? descriptionId : undefined,
              error ? errorId : undefined,
            ].filter(notUndefined).join(' ')}
            {...mergeProps(inputProps, rest, {
              className: cn(resets.input, styles.input, sprinkles.atoms({ textAlign })),
            })}
          />
          {right ? <Box flexShrink={0} display='flex' paddingRight='space-0.75'>{right}</Box> : null}
        </Box>
        {error && error !== true && <InputErrorMessage>{error}</InputErrorMessage>}
      </Grid>
    </InputLabelProvider>
  );
});

interface InputLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement>, FlexAndGridChildProps {
}
export const InputLabel = (props: InputLabelProps) => {
  const { inputId } = useInputLabelContext({});
  return (
    childrenAreLiteral(props.children)
      ? <Text as='label' htmlFor={inputId} {...props}>{props.children}</Text>
      : <Box as='label' htmlFor={inputId} {...props}>{props.children}</Box>
  );
};

interface InputDescriptionProps extends React.HTMLAttributes<HTMLDivElement>, FlexAndGridChildProps {
}
export const InputDescription = (props: InputDescriptionProps) => {
  const { descriptionId } = useInputLabelContext({ descriptionId: props.id });
  return childrenAreLiteral(props.children)
    ? <Text id={descriptionId} {...props} fontSize='small' foreground='greyDark'>{props.children}</Text>
    : <Box id={descriptionId} {...props} fontSize='small' foreground='greyDark'>{props.children}</Box>;
};

interface InputErrorMessageProps extends React.HTMLAttributes<HTMLDivElement>, FlexAndGridChildProps {
}
export const InputErrorMessage = (props: InputErrorMessageProps) => {
  const { errorId } = useInputLabelContext({ errorId: props.id });
  return childrenAreLiteral(props.children)
    ? <Text id={errorId} {...props} fontSize='small' foreground='redError' whiteSpace='pre-line'>{props.children}</Text>
    : <Box id={errorId} {...props} fontSize='small' foreground='redError' whiteSpace='pre-line'>{props.children}</Box>;
};


interface useTextInputStylesProps {
  disabled: boolean | undefined;
  error: boolean | undefined;
  left: boolean;
  right: boolean;
}

export const useTextInputStyles = (props: useTextInputStylesProps) => {
  const { disabled, error, left, right } = props;
  const { hoverProps, isHovered } = useHover({
    isDisabled: disabled,
  });

  const { focusProps, isFocused, isFocusVisible } = useFocusRing({
    isTextInput: true,
    within: true,
  });

  return {
    containerProps: mergeProps(
      hoverProps,
      {
        className: cn(
          styles.container,
          !isHovered && !isFocused && !error && styles.base,
          isHovered && styles.hovered,
          isFocused && styles.focused,
          error && styles.invalid,
          disabled && styles.disabled,
          isFocusVisible && focusRing,
        ),
      },
    ),
    inputProps: mergeProps(
      focusProps,
      {
        className: cn(
          styles.input,
          left ? styles.withLeft : styles.withoutLeft,
          right ? styles.withRight : styles.withoutRight,
        ),
      },
    ),
  };
};


export default TextInput;
