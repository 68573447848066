import React from 'react';

import Box from '../Box';

import useIconProps, { IconDefaultProps } from './useIconProps';

export const IconInfo = (props: IconDefaultProps & { title?: string }) => {
  const { title = 'info', width = 16, height = 16, ...rest } = props;
  const iconProps = useIconProps({ ...rest, height });
  return (
    <Box
      as='svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
      {...iconProps}
    >
      <title>{title}</title>
      <g fill='currentColor' fillRule='nonzero'>
        <circle cx={8} cy={4.727} r={1} />
        <path d='M8 .8c3.97 0 7.2 3.23 7.2 7.2s-3.23 7.2-7.2 7.2S.8 11.97.8 8 4.03.8 8 .8M8 0a8 8 0 100 16A8 8 0 008 0z' />
        <path d='M7.44 6.593h1.12v5.469H7.44z' />
      </g>
    </Box>
  );
};

export default IconInfo;
