import {
  CancelOptions,
  useMutation as externalUseMutation,
  UseMutationOptions as ExternalUseMutationOptions,
  UseMutationResult as ExternalUseMutationResult,
  useQuery as externalUseQuery,
  useQueryClient as externalUseQueryClient,
  UseQueryOptions as ExternalUseQueryOptions,
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryClient,
  SetDataOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { QueryFilters, Updater } from '@tanstack/react-query/build/types/packages/query-core/src/utils';
import React, { useContext, useMemo } from 'react';

import { ErrorMiddleware } from '@mablemarket/api-client-support';
import { ensureMableError, MableError } from '@mablemarket/common-lib';
import { ApiClient, routeInfo } from '@mablemarket/core-api-client';

import { Body, Input } from './useRequest';


export type RouteName = keyof typeof routeInfo;
// Filters routes for GET requests and search, which is a POST only so we can give it a body
// TODO: Deliberatively tag requests as mutative or not and use that to filter
export type GetRouteName = { [Route in RouteName]: Route extends `GET ${string}` | 'POST /v1/search' | 'POST /v1/admin/reports/{reportId}' ? Route : never }[RouteName];
export type MutateRouteName = Exclude<RouteName, GetRouteName>;

type ErrorMiddlewareOption = {
  errorMiddlewares?: ErrorMiddleware[]
};

export type UseQueryOptions<
  Route extends GetRouteName,
  Data extends Body<Route>,
  SelectData = Data
  > = Omit<ExternalUseQueryOptions<
    Data,
    Error | MableError,
    SelectData,
    QueryKey<Route>
  >, 'queryKey' | 'queryFn'> & ErrorMiddlewareOption;
export type UseMutationOptions<RouteName extends MutateRouteName, TContext = undefined> = Omit<
  ExternalUseMutationOptions<Body<RouteName>, Error | MableError, Input<RouteName>, TContext>,
  'mutationKey' | 'mutationFn'
> & ErrorMiddlewareOption;
export type QueryKey<Route extends RouteName> = [route: Route, input: Input<Route>];
export type PartialQueryKey<Route extends RouteName> = [route: Route, input?: Partial<Input<Route>>];
export type ModifiedUseQueryResult<TData = unknown, TError = unknown> = UseQueryResult<TData, TError> & {
  /** Loading indicator getting new data when keepPreviousData is true.
   * isLoading is only true when data is null, so we require an additional indicator.
   * isFetching is too broad as it also covers staleness refreshes.
   */
  isLoadingNewData: boolean;
}
type QueryUpdater<Route extends RouteName> = Updater<Body<Route> | undefined, Body<Route> | undefined>;
interface QueryRouteFilters<Route extends RouteName> extends Omit<QueryFilters, 'queryKey'> {
  queryKey?: PartialQueryKey<Route>;
}
interface InvalidateQueryRouteFilters<Route extends RouteName> extends Omit<InvalidateQueryFilters<Body<Route>>, 'queryKey'> {
  queryKey?: PartialQueryKey<Route>;
}

const ApiClientContext = React.createContext<ApiClient | null>(null);

export const ApiClientProvider = ApiClientContext.Provider as React.Provider<ApiClient>;
export const useApiClient = () => {
  const client = useContext(ApiClientContext);
  if (client === null) {
    throw new Error('useApiClient must be used inside of ApiClientProvider');
  }
  return client;
};

export function useQuery<RouteName extends GetRouteName, Data extends Body<RouteName>, SelectData = Data>(
  routeName: RouteName,
  input: Input<RouteName>,
  options?: UseQueryOptions<RouteName, Data, SelectData>,
): ModifiedUseQueryResult<SelectData, Error | MableError> {
  const client = useApiClient();
  const req = externalUseQuery({
    queryKey: [routeName, input],
    queryFn: async ({ queryKey: [routeName, input] }) => {
      const abortController = new AbortController();
      const promise = new Promise<Data>((resolve, reject) => {
        client
          .req(routeName, input, { abortController, errorMiddlewares: options?.errorMiddlewares })
          .then(res => resolve(res.body as Data))
          .catch(e => reject(e));
      });
      const promiseWithCancel = Object.assign(promise, {
        cancel: () => {
          abortController.abort();
        },
      });
      return promiseWithCancel;
    },
    ...options,
  });
  const modifiedReq = useMemo(() => {
    return ({
      ...req,
      isLoadingNewData: req.isLoading || (req.isPreviousData && req.isFetching),
    });
  }, [req]);
  return modifiedReq;
}

export const dontReportErrorMiddleware = (codesToNotReport: string[]): ErrorMiddleware => {
  return ({ err }) => {
    if (codesToNotReport.includes(err.code)) {
      return ensureMableError(err, { dontReport: true });
    }
    return err;
  };
};

export type UseMutationResult<RouteName extends MutateRouteName, TContext = undefined> = ExternalUseMutationResult<
  Body<RouteName>,
  MableError | Error,
  Input<RouteName>,
  TContext
>;


export function useMutation<RouteName extends MutateRouteName, TContext = undefined>(
  routeName: RouteName,
  options?: UseMutationOptions<RouteName, TContext>,
): UseMutationResult<RouteName, TContext> {
  const client = useApiClient();
  return externalUseMutation<Body<RouteName>, MableError | Error, Input<RouteName>, TContext>({
    mutationFn: (input) => {
      const abortController = new AbortController();
      const promise = new Promise<Body<RouteName>>((resolve, reject) => {
        client
          .req(routeName, input, { abortController, errorMiddlewares: options?.errorMiddlewares })
          .then(res => resolve(res.body))
          .catch(e => reject(e));
      });
      const promiseWithCancel = Object.assign(promise, {
        cancel: () => {
          abortController.abort();
        },
      });
      return promiseWithCancel;
    },
    ...options,
  });
}

// All of these functions have the same signatures as `QueryClient`, except the
// key and data values have been restricted to the types dictated by our routes
// TODO: Wrap additional functions types as needed
interface QueryClientOverrides {
  invalidateQueries<Route extends GetRouteName>(
    queryKey: PartialQueryKey<GetRouteName>,
    filters?: InvalidateQueryRouteFilters<Route>,
    options?: InvalidateOptions,
  ): Promise<void>;
  invalidateQueries<Route extends GetRouteName>(
    filters: InvalidateQueryRouteFilters<Route>,
    options?: InvalidateOptions,
  ): Promise<void>;
  cancelQueries<Route extends GetRouteName>(
    queryKey?: QueryKey<Route>,
    filters?: QueryRouteFilters<Route>,
    options?: CancelOptions
  ): Promise<void>;
  cancelQueries<Route extends GetRouteName>(
    filters?: QueryRouteFilters<Route>,
  ): Promise<void>;
  getQueryData<Route extends GetRouteName>(
    queryKey: QueryKey<Route>,
    filters?: QueryRouteFilters<Route> | undefined,
  ): Body<Route> | undefined;
  getQueriesData<Route extends GetRouteName>(
    queryKey: PartialQueryKey<Route>,
    filters?: QueryRouteFilters<Route> | undefined,
  ): [QueryKey<Route>, Body<Route>][];
  getQueriesData<Route extends GetRouteName>(
    filters: QueryRouteFilters<Route>,
  ): [QueryKey<Route>, Body<Route>][];
  setQueryData<Route extends GetRouteName>(
    queryKey: QueryKey<Route>,
    updator: QueryUpdater<Route>,
    options?: SetDataOptions | undefined,
  ): Body<Route>;
  setQueriesData<Route extends GetRouteName>(
    queryKey: PartialQueryKey<Route>,
    updator: QueryUpdater<Route>,
    options?: SetDataOptions | undefined,
  ): [QueryKey<Route>, Body<Route>][];
  setQueriesData<Route extends GetRouteName>(
    filters: QueryRouteFilters<Route>,
    updator: QueryUpdater<Route>,
    options?: SetDataOptions | undefined,
  ): [QueryKey<Route>, Body<Route>][];
}

export interface MableQueryClient extends Omit<QueryClient, keyof QueryClientOverrides>, QueryClientOverrides {
}

export function useQueryClient() {
  const client = externalUseQueryClient();
  // We unfortunately have to cast this through unknown, as we make a stronger
  // guarantee on the return type of the data for the get/set functions than
  // react-query
  return client as unknown as MableQueryClient;
}
