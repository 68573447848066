import { useEffect, useState } from 'react';

export const useFirstRender = () => {
  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    setIsFirst(false);
  }, []);

  return isFirst;
};
