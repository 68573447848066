var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { cn } from '../../webutils/webutils';
import Flex from '../Flex';
import * as styles from './Loading.css';
var Loading = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 100 : _b, center = _a.center, fullscreen = _a.fullscreen, grow = _a.grow, children = _a.children, _c = _a.loading, loading = _c === void 0 ? true : _c, fadeIn = _a.fadeIn, strokeWidth = _a.strokeWidth, limitHeight = _a.limitHeight, color = _a.color, rest = __rest(_a, ["className", "size", "center", "fullscreen", "grow", "children", "loading", "fadeIn", "strokeWidth", "limitHeight", "color"]);
    var shouldMask = loading && children;
    var containerClass = cn(styles.container, center && styles.centered, fullscreen && styles.fullHeight, shouldMask && styles.mask, limitHeight && styles.limitHeight, className);
    return (<Flex alignItems='center' justifyContent='center' className={cn(loading ? containerClass : className)} flexGrow={grow ? 1 : 0} {...rest}>
      {loading && (<LoadingSpinner strokeWidth={strokeWidth} color={color} className={cn(fadeIn && styles.spinnerFadeIn, shouldMask && styles.spinnerMask)} width={size} height={size}/>)}
      {shouldMask ? null : children}
    </Flex>);
};
var LoadingSpinner = function (_a) {
    var className = _a.className, _b = _a.strokeWidth, strokeWidth = _b === void 0 ? 2 : _b, _c = _a.width, width = _c === void 0 ? 100 : _c, _d = _a.height, height = _d === void 0 ? 100 : _d, _e = _a.color, color = _e === void 0 ? 'grey' : _e;
    var viewBox = 38 + strokeWidth;
    var translate = 1 + (strokeWidth / 2);
    return (<svg className={cn(className)} width={width} height={height} xmlns='http://www.w3.org/2000/svg' aria-label='loading' viewBox={"0 0 ".concat(viewBox, " ").concat(viewBox)} stroke={"".concat(color)}>
      <g fill='none' fillRule='evenodd'>
        <g strokeWidth={strokeWidth} transform={"translate(".concat(translate, " ").concat(translate, ")")} strokeLinecap='round'>
          <circle strokeOpacity='.5' cx='18' cy='18' r='18'/>
          <path d='M36 18c0-9.94-8.06-18-18-18'>
            <animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite'/>
          </path>
        </g>
      </g>
    </svg>);
};
export default Loading;
