import React from 'react';
import { ToggleProps, useToggleState } from 'react-stately';

import { cn } from '../../webutils/webutils';
import Flex from '../Flex';
import UnstyledButton, { UnstyledButtonProps } from '../UnstyledButton';

import * as styles from './ToggleButton.css';

interface ToggleButtonProps extends Pick<ToggleProps, 'onChange' | 'isSelected' | 'defaultSelected' | 'isReadOnly'>, Omit<UnstyledButtonProps, 'onChange'> {
}

const ToggleButton = React.forwardRef((props: ToggleButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const {
    className,
    children,
    onChange,
    isSelected,
    defaultSelected,
    isReadOnly,
    ...rest
  } = props;
  const toggleState = useToggleState({
    isDisabled: rest.disabled,
    onChange,
    isSelected,
    defaultSelected,
    isReadOnly,
  });

  return (
    <UnstyledButton
      ref={ref}
      type='button'
      aria-pressed={toggleState.isSelected}
      border={toggleState.isSelected ? 'blueMas' : 'greyLine'}
      foreground={toggleState.isSelected ? 'blueMas' : 'greyDark'}
      background={toggleState.isSelected ? 'blueFill' : 'white'}
      borderRadius='small'
      paddingY='space-0.5'
      paddingX='space-0.75'
      className={cn(
        styles.toggle,
        className,
      )}
      {...rest}
      onPress={(e) => {
        props.onPress?.(e);
        toggleState.toggle();
      }}
    >
      {children}
    </UnstyledButton>
  );
});

interface GroupProps {
  children: React.ReactNode;
}
const Group = ({ children }: GroupProps) => {
  return (
    <Flex className={styles.toggleGroup}>
      {children}
    </Flex>
  );
};

export default Object.assign(ToggleButton, {
  Group,
});
