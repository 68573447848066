import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';

import { formatPhoneNumber, isNonBlankString, notUndefined } from '@mablemarket/common-lib';
import { Address, BuyerLocation } from '@mablemarket/core-api-client';

export const postalCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/g;

export const cityStateZip = (address: Partial<Address>) => {
  const cityState = [address.locality, address.administrativeAreaLevel1].filter(s => s).join(', ');
  return [cityState, address.postalCode].filter(s => s).join(' ');
};

export const addressElementsForStyle = (name: string, address: Partial<Address>, style: ('letter' | 'order_detail' | 'three_line' | 'full')) => {
  switch (style) {
    case 'full':
    case 'letter': {
      // e.g.:
      // Name               Jimi Hendrix
      // Organization       The Experience, LLC
      // Line 1             1604 Broadway
      // Line 2             Ground Floor
      // City, State Zip    Seattle, WA 98122
      return [
        name,
        address.organization,
        address.line1,
        address.line2,
        cityStateZip(address),
        style === 'full' ? address.deliveryNotes : undefined,
      ].filter(isNonBlankString);
    }
    case 'order_detail': {
      // e.g.:
      // Name, Org                        Jimi Hendrix, The Experience, LLC
      // Line 1, Line2, City, State Zip   1604 Broadway, Ground Floor, Seattle, WA 98122
      return [
        [name, address.organization].filter(isNonBlankString).join(', '),
        [address.line1, address.line2, cityStateZip(address)].filter(isNonBlankString).join(', '),
      ];
    }
    case 'three_line': {
      return [
        address.line1,
        address.line2,
        cityStateZip(address),
      ].filter(isNonBlankString);
    }
    default: {
      return [
        address.line1,
        address.line2,
        cityStateZip(address),
      ].filter(isNonBlankString);
    }
  }
};

export function addressSummaryString(address: Partial<Address>,
  options: {
    style: ('letter' | 'single_line' | 'order_detail' | 'three_line' | 'full');
    includePhone?: boolean;
    includeName?: boolean;
    includeOrganization?: boolean;
    includeDeliveryNotes?: boolean;
  }): string {
  const {
    style,
    includePhone,
    includeOrganization = true,
    includeName = true,
    includeDeliveryNotes = false,
  } = options;

  let name = '';
  if (includeName) {
    name = [address.firstName?.trim(), address.lastName?.trim()].filter(item => item).join(' ');
  }

  if (style !== 'single_line') {
    const addressElements = addressElementsForStyle(name, address, style);
    if (includePhone && address.phone) {
      addressElements?.push(formatPhoneNumber(address.phone));
    }

    if (includeDeliveryNotes && address.deliveryNotes) {
      addressElements?.push('-- Delivery Notes --', address.deliveryNotes);
    }

    return addressElements?.filter(s => s).join('\n') ?? '';
  }

  let streetElements = [
    includeOrganization && address.organization,
    address.line1,
    address.line2,
    address.locality,
    address.administrativeAreaLevel1,
    address.administrativeAreaLevel2,
  ];
  streetElements = streetElements.filter(item => item);

  const finalElements = [
    address.postalCode,
    address.country,
  ];

  if (includePhone) {
    finalElements.push(address.phone ? formatPhoneNumber(address.phone) : '');
  }

  if (includeName && name.length > 0) {
    streetElements.unshift(name);
  }

  return [
    streetElements.join(', '),
    finalElements.filter(item => item).join(' '),
  ].filter(item => item).join(' ');
}

export function addressInputsAreEqual(input1: Partial<Address>, input2: Partial<Address>) {
  const normalized1: Address = {
    id: input1.id ?? -1,
    accountId: input1.accountId,
    administrativeAreaLevel1: input1.administrativeAreaLevel1 ?? '',
    administrativeAreaLevel2: input1.administrativeAreaLevel2,
    billTo: input1.billTo,
    buyerLocationIds: input1.buyerLocationIds,
    country: input1.country,
    createdByUserId: input1.createdByUserId,
    deliverability: input1.deliverability,
    deliveryNotes: input1.deliveryNotes,
    easypostId: input1.easypostId,
    firstName: input1.firstName,
    isRetailLocation: input1.isRetailLocation,
    lastName: input1.lastName,
    line1: input1.line1 ?? '',
    line2: input1.line2,
    locality: input1.locality ?? '',
    organization: input1.organization,
    phone: input1.phone?.replace(/\D/g, ''),
    postalCode: input1.postalCode ?? '',
    shipFrom: input1.shipFrom,
    shipTo: input1.shipTo,
    status: input1.status,
    version: input1.version ?? -1,
  };
  const normalized2: Address = {
    id: input2.id ?? -1,
    accountId: input2.accountId,
    administrativeAreaLevel1: input2.administrativeAreaLevel1 ?? '',
    administrativeAreaLevel2: input2.administrativeAreaLevel2,
    billTo: input2.billTo,
    buyerLocationIds: input2.buyerLocationIds,
    country: input2.country,
    createdByUserId: input2.createdByUserId,
    deliverability: input2.deliverability,
    deliveryNotes: input2.deliveryNotes,
    easypostId: input2.easypostId,
    firstName: input2.firstName,
    isRetailLocation: input2.isRetailLocation,
    lastName: input2.lastName,
    line1: input2.line1 ?? '',
    line2: input2.line2,
    locality: input2.locality ?? '',
    organization: input2.organization,
    phone: input2.phone?.replace(/\D/g, ''),
    postalCode: input2.postalCode ?? '',
    shipFrom: input2.shipFrom,
    shipTo: input2.shipTo,
    status: input2.status,
    version: input2.version ?? -1,
  };

  return isEqual(normalized1, normalized2);
}

interface AddressComponentWithDifference {
  value: string;
  different: boolean;
}

export function addressStringComponentsWithDifferences(opts: {
  address: Partial<Address>;
  compareTo: Partial<Address>;
  includeNames: boolean;
}) {
  const { address, compareTo, includeNames } = opts;

  const streetComponents: AddressComponentWithDifference[] = [];
  if (includeNames) {
    const name = [address.firstName, address.lastName].filter(s => s).join(' ');
    if (name) {
      const compareName = [compareTo.firstName, compareTo.lastName].filter(s => s).join(' ');
      streetComponents.push({
        value: name,
        different: name.toUpperCase() !== compareName.toUpperCase(),
      });
    }
    if (address.organization) {
      streetComponents.push({
        value: address.organization,
        different: address.organization.toUpperCase() !== compareTo.organization?.toUpperCase(),
      });
    }
  }

  if (address.line1) {
    streetComponents.push({
      value: address.line1,
      different: address.line1.toUpperCase() !== compareTo.line1?.toUpperCase(),
    });
  }
  if (address.line2) {
    streetComponents.push({
      value: address.line2,
      different: address.line2.toUpperCase() !== compareTo.line2?.toUpperCase(),
    });
  }
  if (address.locality) {
    streetComponents.push({
      value: address.locality,
      different: address.locality.toUpperCase() !== compareTo.locality?.toUpperCase(),
    });
  }
  if (address.administrativeAreaLevel1) {
    streetComponents.push({
      value: address.administrativeAreaLevel1,
      different: address.administrativeAreaLevel1.toUpperCase() !== compareTo.administrativeAreaLevel1?.toUpperCase(),
    });
  }
  if (address.administrativeAreaLevel2) {
    streetComponents.push({
      value: address.administrativeAreaLevel2,
      different: address.administrativeAreaLevel2.toUpperCase() !== compareTo.administrativeAreaLevel2?.toUpperCase(),
    });
  }

  const finalComponets: AddressComponentWithDifference[] = [];
  if (address.postalCode) {
    finalComponets.push({
      value: address.postalCode,
      different: address.postalCode.toUpperCase() !== compareTo.postalCode?.toUpperCase(),
    });
  }
  if (address.country) {
    finalComponets.push({
      value: address.country,
      different: address.country.toUpperCase() !== compareTo.country?.toUpperCase(),
    });
  }

  return {
    streetComponents,
    finalComponets,
  };
}

export const isAddress = (thing: unknown): thing is Address => {
  try {
    return (
      (thing as Address).version !== undefined
        && (thing as Address).administrativeAreaLevel1 !== undefined
        && (thing as Address).locality !== undefined
        && (thing as Address).line1 !== undefined
        && (thing as Address).postalCode !== undefined
    );
  } catch (e) {
    return false;
  }
};

export type LocationAddress = {
  location: BuyerLocation
  address: Address
}

export const pairAddressesWithLocations = ({ addresses, locations }: {
  addresses?: Address[],
  locations?: BuyerLocation[]
}): LocationAddress[] => {
  const locationMap = keyBy(locations, l => l.id);
  return flatMap<Address, LocationAddress>(
    addresses,
    address => (address.buyerLocationIds ?? [])
      .map(id => locationMap[id])
      .filter(notUndefined)
      .map(location => ({ location, address })),
  );
};

export const simpleAddressIdentifier = (address: Partial<Address>) => {
  return addressSummaryString(address, { style: 'single_line', includePhone: true, includeOrganization: true, includeName: true }).replace(/\s/g, '');
};
