import React, { useEffect, useRef } from 'react';
import { AriaButtonProps, mergeProps, PressHookProps, useButton, useHover } from 'react-aria';


import { composeRefs } from '../../util';
import Text, { TextOwnProps } from '../Text';
import { TextLinkProps, useTextLinkStyles } from '../TextLink';

interface TextLinkButtonProps extends
  Pick<TextLinkProps, 'underline' | 'overlayContainer'>,
  TextOwnProps,
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'>,
  Omit<PressHookProps, 'isDisabled'> {
  /** overlayContainer
   * Works like Bootstrap's 'streched-link'. It adds an
   * absolutely positioned ::before To fill the container with clickable area that
   * will trigger the link. This allows us to do things like make cards
   * clickable without resorting to onClick events that break things like right or
   * middle clicking, or wrapping the whole thing in a Link.
   *
   * DO NOT USE THIS ON TABLES. It will work in modern Chrome and Firefox, but the
   * CSS 2.1 spec does not define position: relative on table elements, and it will
   * be broken on Safari. The transfrom trick does not work either.
   *
   * To use, you must make the parent you wish to act as a link `position:
   * relative`, and force a new stacking context. You can ensure this by setting a
   * transform property, like `transform: rotate(0)`.
   *
   * To make other items in that container clickable, set their z-index greater
   * than 1. This will stack them above the ::before.
   */
  overlayContainer?: boolean;
}

/**
 * When you need something that looks like text link, but behaves like a button
 * Use `onPress` instead of `onClick` unless you need something in the `onClick` event that `onPress` doesn't expose.
*/
export const TextLinkButton = React.forwardRef((props: TextLinkButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const {
    foreground: givenForeground,
    underline: givenUnderline,
    children,
    disabled,
    overlayContainer,
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,
    ...rest
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const { hoverProps, isHovered } = useHover({
    ...rest,
    isDisabled: disabled,
  });

  const { buttonProps, isPressed } = useButton({
    // Need to cast because react aria expects event handlers for HTMLElement,
    // which conflict with ours for HTMLButtonElement. The types are compatible,
    // but typescript isn't smart enough to know that.
    ...rest as AriaButtonProps,
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,
    isDisabled: disabled,
  }, buttonRef);

  const { className, ...restStyles } = useTextLinkStyles({
    foreground: givenForeground,
    background: props.background,
    underline: givenUnderline,
    disabled,
    isHovered,
    isPressed,
    overlayContainer,
  });

  // Workaround for
  // https://github.com/facebook/react/issues/9809
  // https://github.com/adobe/react-spectrum/issues/1513
  const hasOnClickHandler = !!props.onClick;
  useEffect(() => {
    const button = buttonRef.current;
    const preventDefault = (e: TouchEvent) => {
      // Disabling touchend breaks form submission on mobile
      if (
        (e.currentTarget as HTMLElement)?.getAttribute('type') === 'submit'
        || (e.currentTarget as HTMLElement)?.getAttribute('type') === 'reset'
      ) {
        return;
      }
      e.preventDefault();
    };
    if (!hasOnClickHandler) {
      button?.addEventListener('touchend', preventDefault, { passive: false });
    }
    return () => button?.removeEventListener('touchend', preventDefault);
  }, [hasOnClickHandler]);

  return (
    <Text
      as='button'
      type='button'
      {...rest}
      // we want this to override the click based event handlers
      {...mergeProps(buttonProps, restStyles, hoverProps)}
      ref={composeRefs(buttonRef, ref)}
      className={[
        rest.className,
        className,
      ]}
    >
      {children}
    </Text>
  );
});

TextLinkButton.displayName = 'TextLinkButton';

export default TextLinkButton;
