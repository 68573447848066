import { useEffect, useRef } from 'react';

const usePrevious = <T extends unknown>(value: T, initial?: T): T => {
  const ref = useRef(initial ?? value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
