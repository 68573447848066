var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { pathToRegexp } from 'path-to-regexp';
import useNextLocation from './useNextLocation';
var cache = {};
var cacheLimit = 10000;
var cacheCount = 0;
// Modified from react-router v5
// https://github.com/remix-run/react-router/blob/5e2b04018847c799e8da1a5f9cc632b6d02da152/packages/react-router/modules/matchPath.js
var compilePath = function (path, options) {
    var cacheKey = "".concat(options.exact).concat(options.strict).concat(options.sensitive);
    var pathCache = cache[cacheKey] || (cache[cacheKey] = {});
    if (pathCache[path])
        return pathCache[path];
    var keys = [];
    var regexp = pathToRegexp(path, keys, __assign(__assign({}, options), { end: options.exact }));
    var result = { regexp: regexp, keys: keys };
    if (cacheCount < cacheLimit) {
        pathCache[path] = result;
        cacheCount += 1;
    }
    return result;
};
export var matchPath = function (pathname, options) {
    if (options === void 0) { options = {}; }
    var _a = options.path, path = _a === void 0 ? '' : _a, _b = options.exact, exact = _b === void 0 ? false : _b, _c = options.strict, strict = _c === void 0 ? false : _c, _d = options.sensitive, sensitive = _d === void 0 ? false : _d;
    var paths = typeof path === 'string' ? [path] : path;
    return paths.reduce(function (matched, path) {
        if (!path && path !== '')
            return null;
        if (matched)
            return matched;
        var _a = compilePath(path, {
            exact: exact,
            strict: strict,
            sensitive: sensitive,
        }), regexp = _a.regexp, keys = _a.keys;
        var match = regexp.exec(pathname);
        if (!match)
            return null;
        var url = match[0], values = match.slice(1);
        var isExact = pathname === url;
        if (exact && !isExact)
            return null;
        return {
            path: path,
            url: (path === '/' && url === '') ? '/' : url,
            isExact: isExact,
            params: keys.reduce(function (memo, key, index) {
                memo[key.name] = values[index];
                return memo;
            }, {}),
        };
    }, null);
};
var useRouteMatch = function (path) {
    var nextLocation = useNextLocation();
    var location = (typeof path === 'object' && !Array.isArray(path) && path.location) || nextLocation;
    var options = (typeof path === 'object' && !Array.isArray(path))
        ? __assign(__assign({}, path), { path: path.path || '/', location: location }) : { path: path };
    return matchPath(location.pathname, options);
};
export default useRouteMatch;
